import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SubstituteThumbnailGhost() {
  return (
    <>
      <div
        className="substitute-thumbnail ghost"
      >
        <div className="pertinence">
          <Skeleton circle={true} height={30} width={30} />
        </div>

        <div className="firstName">
          <Skeleton width={75} />
        </div>

        <div className="avatar">
          <Skeleton circle={true} height={130} width={130} />
        </div>

        <div className="graduation">
          <div className="graduation-year">
            <Skeleton width={100} />
          </div>
        </div>
        
        <div className="skills">
          <Skeleton height={20} width={170} />
        </div>

        <div className="is-available">
          <Skeleton height={15} width={110} />
        </div>

        <Skeleton height={30} width={200} style={{borderRadius: "37.5px"}} />
      </div>
    </>
  )
}
