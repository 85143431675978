import React, {useRef} from 'react';
import { reasons } from "../../../../Constants/reasons";
import ListSelect from '../../../../Components/Forms/Inputs/ListSelect/ListSelect';

export const ReasonsBlock = (context: newMissionProps) => {
  const { reason } = context.state;
  const _reasons = useRef<HTMLDivElement>(null);

  const handleSelect = (name:string, value:any) => {
    context.handleSelect(name, value);
  }

  return (
    <>
      <h4 className="question-reason">Pour quel motif ?</h4>
      <ListSelect 
        value={reason}
        name="reason"
        placeHolder="Motif du recrutement (facultatif)"
        condition={true}
        list={reasons}
        handleSelect={handleSelect}
        width={435}
        ref={_reasons}
      />
    </>
  )
}