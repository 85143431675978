import React from "react";
import Cookies from "js-cookie";
import history from "../../history";
import prefixes from "../../assets/prefixes.json";
import { updateUserSendinBlue } from "./SendinBlue";
import { fetchApi } from "./Api";
import { io } from "socket.io-client";
import { AxiosResponse } from "axios";
import LogRocket from "logrocket";

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
export const AuthContext = React.createContext({} as AuthProps);
export const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends React.Component<any, AuthState> {
  private _isMounted: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      language: "FR",
      credits: 0,
      newCandidates: 0,
      estAlreadyExists: false,
      barMessage: null,
      isError: false,
      isLoading: false,
      isPublicViewUrlLoading: false,
      notification: false,
      subscriptionStatus: undefined,
      isDemoAccount: false,
      currentPlan: undefined,
      successPopup: false,
      page: 1,
      tipOpen: "tip1",
      email: null,
      emailAlreadyExists: false,
      incorrectLogs: false, // user-not-found OR invalid-password
      userIsNotValidated: false, // user-isnt-validated
      userIsNotPro: false, // user-isnt-pro
      password: null,
      password2: null,
      invalidPassword: false,
      incorrectEmail: false,
      id: null,
      firstName: null,
      lastName: null,
      countryCode: "+33",
      phoneNumber: "",
      gender: null,
      role: null,
      daysLeft: null,
      searchText: null,
      searchOfficeType: null,
      allEstablishments: null,
      allProfessions: [],
      listIsOpen: false,
      office: null,
      officeLogo: "",
      userNavIsOpen: false,
      substitutesCount: undefined,
      searchingCount: undefined,
      newCodeSent: false,
      newCodeError: false,
      user: {
        token: Cookies.get("token") || "",
        firstName: Cookies.get("firstName") || "",
        id: Cookies.get("id") || "",
        office: {
          name: Cookies.get("officeName") || "",
          id: Cookies.get("officeId") || "",
          finess: Cookies.get("officeFiness") || "",
        },
      },
      isUserTokenExpired: false,
      socketInstance: null,
      TotalNewMessage: 0,
      ActiveNewMessage: 0,
      ArchivedNewMessage: 0,
      substitutesPage: {
        state: {
          subsIsLoading: false,
          allSubstitutes: [],
          count: 0,
          substitute: undefined,
          substitutesLiked: [],
          skills: [],
          isLoading: true,
          isAvailable: false,
          profession: null,
          type: "substitute",
          isHired: false,
          minXp: "graduated",
          index: 0,
          preventScroll: false,
          noMoreData: false,
          exercices: [],
          maxDistance: 100, // ? > 90 : "France entière"
        },
      },
    };
  }

  setTipOpen = (tip: string) => {
    this.setState({ tipOpen: tip });
  };

  popBarMessage = (text: string) => {
    this.setState({
      barMessage: text
    });
  };

  emptyBarMessage = () => {
    this.setState({
      barMessage: null
    });
  };

  redirectToPricing = () => {
    history.push("/pricing");
    this.emptyBarMessage();
  }

  getSubstitutesCount = () => {
    !this.state.substitutesCount && fetchApi("/practitioner/total/60dee5d03cfdec010e4674e3?type=substitute") // ? 60dee5d03cfdec010e4674e3 : Physiotherapist
      .then(response => {
        this.setState(
          {
            substitutesCount: response.data.total,
          },
        );
      })
      // .catch(error => this.setUserTokenIsExpired());
  };

  updatePractitionerDetails = async (
    gender: string,
    firstName: string,
    lastName: string,
    role: string,
    oldPassword: string,
    newPassword: string,
    verifPassword: string
  ) => {
    await this.setState({ isLoading: true });
    fetchApi(`/practitioner/${this.state.user?.id}`, "PUT", {
      gender,
      firstName,
      lastName,
      role
    }).then(response => {
      if (response.status === 200) {
        updateUserSendinBlue(response.data?._id);
        this.handleUpdateUser({
          token: this.state.user?.token,
          firstName: response.data.firstName,
          id: response.data._id,
          office: {
            name: response.data.office.name,
            id: response.data.officeIds[0],
            finess: response.data.office.finess
          }
        });
        this.setState(
          {
            gender: response.data.gender,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            role: response.data.role
          },
          () => {
            if (oldPassword && newPassword && verifPassword) {
              this.handleUpdatePassword(
                oldPassword,
                newPassword,
                verifPassword
              );
            } else {
              this.setState({ isLoading: false, notification: true }, () =>
                this.removeNotif(5000)
              );
            }
          }
        );
      }
    });
  };

  setUserTokenIsExpired = async () => {
    Cookies.remove("token");
    Cookies.remove("firstName");
    Cookies.remove("id");
    Cookies.remove("officeName");
    Cookies.remove("officeId");
    Cookies.remove("officeFiness");

    this.setState({ isUserTokenExpired: true });
  }

  handleUpdatePassword = (
    oldPassword: string,
    newPassword: string,
    verifPassword: string
  ) => {
    fetchApi(`/practitioner/change-password`, "POST", {
      oldPassword,
      newPassword,
      verifPassword
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({ isLoading: false, notification: true }, () =>
            this.removeNotif(5000)
          );
        }
      })
      .catch(error => {
        if (error) {
          this.setState({ isLoading: false, isError: true }, () =>
            this.removeNotif(5000)
          );
        }
      });
  };

  removeNotif = (time: number) => {
    setTimeout(() => {
      this.setState({
        notification: false,
        isError: false,
        estAlreadyExists: false
      });
    }, time);
  };

  handleToggle = (name: keyof AuthState) => {
    this.setState({ [name]: !this.state[name] } as unknown as Pick<AuthState, keyof AuthState>);
  };

  handlePreviousPage = () => {
    this.setState({ page: this.state.page - 1 }, () => {
      const { socketInstance, ...oldState } = this.state
      localStorage.setItem("state", JSON.stringify({ ...oldState, isLoading: false }));
    });
  };

  handleNextPage = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      const { socketInstance, ...oldState } = this.state
      localStorage.setItem("state", JSON.stringify({ ...oldState, isLoading: false }));
    });
  };

  handleInput = (event: any) => {
    const targetName: keyof AuthState = event.target?.name;
    this.setState({ ...this.state, emailAlreadyExists: false, [targetName]: event.target?.value, incorrectLogs: false });
  };

  handleSearch = (event: any) => {
    if (event.target.value.length > 4) {
      this.setState(
				{
					searchText: event.target.value,
					listIsOpen: true,
					office: null,
					isLoading: true
				},
				() =>
					fetchApi(
						this.state.searchOfficeType
							? `/offices/search?str=${this.state.searchText}&type=${this.state.searchOfficeType}`
							: `/offices/search?str=${this.state.searchText}`
					).then(response => {
						this.setState({
							allEstablishments: response.data,
							isLoading: false
						});
					})
			);
    } else {
      this.setState({ searchText: null, listIsOpen: false, isLoading: false});
    }
  };

  handleSetOfficeType = (event?: any) => {
    event
			? this.setState({ searchOfficeType: event.target.value })
			: this.setState({ searchOfficeType: null });

      this.setState({ isLoading: true }, () =>
				fetchApi(
					this.state.searchOfficeType
						? `/offices/search?str=${this.state.searchText}&type=${this.state.searchOfficeType}`
						: `/offices/search?str=${this.state.searchText}`
				).then(response => {
					this.setState({
						allEstablishments: response.data,
						isLoading: false
					});
				})
			);
  }

  handleResetOfficeSearch = () => {
    this.setState({ searchText: null, searchOfficeType: null });
  }

  handleCheckBox = (name: string, value: any) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleSelect = (name: string, value: any) => {
    if (name === "office") {
      this.state[name] === value
        ? this.setState({ [name]: null })
        : this.setState({
            [name]: value
          });
    } else {
      //@ts-ignore
      this.setState({ [name]: value });
    }
  };

  handleSetUser = (user: UserProps) => {
    this.handleUpdateUser(user);
    this.setState({
      page: this.state.page + 1,
      emailAlreadyExists: false,
      incorrectLogs: false,
      resetEmailSent: false,
      incorrectEmail: false,
    });
  };

  handleUpdateUser = (user: UserProps) => {
    Cookies.set("token", user.token || "");
    Cookies.set("firstName", user.firstName);
    Cookies.set("id", user.id);
    Cookies.set("officeName", user.office.name);
    Cookies.set("officeId", user.office.id);
    Cookies.set("officeFiness", user.office.finess);
    this.setState({ user: user });
  };

  handleSocketOn = () => {
    if (this.state.user?.token && !this.state.socketInstance) {
      const socket = io(global.config.api, {
        path: "/appines",
        transportOptions: {
          polling: {
            extraHeaders: {
              BearerToken: `${this.state.user.token}`,
            },
          },
        },
      });

      socket.on("new-badge", (obj: { total: number; archived: number; active: number }) => {
        this.setState({
          TotalNewMessage: obj.total,
          ActiveNewMessage: obj.active,
          ArchivedNewMessage: obj.archived,
        });
      });

      this.setState({
        socketInstance: socket,
      });
    }

    if (this.state.socketInstance) {
      this.state.socketInstance.on("new-badge", (obj: {total: number, archived: number, active: number}) => {
        this.setState({
          TotalNewMessage: obj.total,
          ActiveNewMessage: obj.active,
          ArchivedNewMessage: obj.archived,
        });
      });
    }
  };

  handleSocketOff = () => {
    const socket = this.state.socketInstance;
    socket?.disconnect();
    this.setState({socketInstance: undefined});
  }

  handleLogIn = () => {
    const { email, password } = this.state;

    if (email && password) {
      this.setState({
        isLoading: true,
        incorrectLogs: false,
        userIsNotValidated: false,
        userIsNotPro: false,
        isError: false,
      });

      fetchApi(`/session`, "POST", {
        email: email,
        password: password,
        pro: true,
      })
        .then((response) => {
          if (response.status === 200) {
            this.handleSetUser({
              id: response.data.userId,
              token: response.data.accessToken,
              firstName: response.data.firstName,
              office: {
                name: response.data.office.name,
                id: response.data.officeIds[0],
                finess: response.data.office.finess,
              },
            });

            this.getUserDetails();
            this.handleSocketOn();
            this.handleFetchAllProfessions();
            this.getOfficeDetails().then();
            this.setState({ isUserTokenExpired: false });

            LogRocket.identify(response.data.userId, {
              // required variables
              name: response.data.office.name,
              email: email,

              // custom variables
              currentPlan:  this.state.currentPlan || "unknown",
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data.code;

          this.setState({
            incorrectLogs: errorMessage === "user-not-found" || errorMessage === "invalid-password",
            userIsNotValidated: errorMessage === "user-isnt-validated",
            userIsNotPro: errorMessage === "user-isnt-pro",
            isError: errorMessage === undefined,
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleLogOut = () => {
    Cookies.remove("token");
    Cookies.remove("firstName");
    Cookies.remove("id");
    Cookies.remove("officeName");
    Cookies.remove("officeId");
    Cookies.remove("officeFiness");

    this.handleUpdateUser({
      token: "",
      firstName: "",
      id: "",
      office: {
        name: "",
        id: "",
        finess: "",
      },
    });

    this.setState({
      page: 1,
      userNavIsOpen: false,
      subscriptionStatus: undefined,
      currentPlan: undefined,
      email: null,
      password: null,
      officeLogo: "",
    });

    history.push("/authentification");

    this.handleSocketOff();
  };

  isEmailAvailable = async (): Promise<void> => {
    this.setState({ emailAlreadyExists: false, isLoading: true });

    fetchApi("/users/check-mail", "POST", { email: this.state.email })
      .then(() => this.handleNextPage())
      .catch(() => this.setState({ emailAlreadyExists: true }))
      .finally(() => this.setState({ isLoading: false }));
  }

  handleSubscription = async (): Promise<Promise<AxiosResponse> | void> => {
    const { firstName, lastName, gender, role, email, office, password } = this.state;

    const phoneNumber = phoneUtil.format(
      phoneUtil.parseAndKeepRawInput(
        this.state.phoneNumber,
        prefixes?.find((prefix) => prefix.dial_code === this.state.countryCode)?.code
      ),
      PNF.E164
    );

    this.setState({ isLoading: true });

    if (office)
      return await fetchApi("/user/pro", "POST", {
        firstName,
        lastName,
        password,
        phoneNumber,
        gender,
        role,
        email,
        office: {
          finess: office.finess,
          address: {
            number: office.address.number,
            street: office.address.street,
            zipCode: office.address.zipCode,
            city: office.address.city,
            country: office.address.country,
          },
          name: office.name,
        },
      }).finally(() => this.setState({ isLoading: false }));
  };

  handleResetPassword = () => {
    const { email } = this.state;
    this.setState({
      isLoading: true,
    });
    fetchApi(`/practitioner/reset-password`, "POST", {
      email: email?.toLowerCase(),
      /*
      it doesn't matter if the api doesn't recognize the user's email,
      the application should have the same behavior for security reasons
       */
    }).finally(() => this.setState({ page: 2, email: "", isLoading: false }));
  };

  handleResetEmail = () => {
    this.setState({ email: null, page: 1 });
  };

  checkIfIsDemoAccount = (response: { data: { email: string } }) => {
    const regExp = new RegExp(/-demo@appines\.fr$/);
    const userEmail = response.data.email;

    if (userEmail) {
      this.setState({ isDemoAccount: regExp.test(userEmail) });
    }
  }

  handleSetSubscription = (response: {data: { email: string, subscription: { plan: Exclude<CurrentPlan, undefined>, isActive: boolean, subscriptionEndDate: string } }}) => {
    let userSubscriptionStatus = this.state.subscriptionStatus || "never_subscribed";
    let currentPlan = this.state.currentPlan || "standard";

    // if subscription object is empty or undefined, current user has never subscribed
    if (
      response.data.subscription &&
      response.data.subscription.isActive &&
      new Date(response.data.subscription.subscriptionEndDate) >= new Date()
    ) {
      userSubscriptionStatus = "subscribed";
      currentPlan = response.data.subscription.plan;
    }

    if (
      response.data.subscription &&
      (!response.data.subscription.isActive ||
        new Date(response.data.subscription.subscriptionEndDate) < new Date())
    ) {
      userSubscriptionStatus = "subscription_ended";
    }

    this.setState({
      email: response.data.email,
      subscriptionStatus: userSubscriptionStatus,
      currentPlan: currentPlan,
    });
  }

  handleAddSubstituteToLikedList = (substituteId: string, date: Date = new Date()) => {
    const { substitutesLiked } = this.state.substitutesPage.state;

    if (!substitutesLiked.map((substitute) => substitute.substituteId).includes(substituteId)) {
      this.setState({
        substitutesPage: {
          state: {
            ...this.state.substitutesPage.state,
            substitutesLiked: [...substitutesLiked, { substituteId: substituteId, date: date }],
          },
        },
      });
    }
  }

  handleRemoveSubstituteToLikedList = (substituteId: string) => {
    this.setState(({
      substitutesPage: {
        state: {
          ...this.state.substitutesPage.state,
          substitutesLiked: this.state.substitutesPage.state.substitutesLiked.filter((favorite) => favorite.substituteId !== substituteId),
        }
      }
    }))
  }

  getUserDetails = () => {
    if (this._isMounted) {
      fetchApi(`/practitioner/${this.state.user.id}`).then((response) => {
        this.checkIfIsDemoAccount(response);
        this.handleSetSubscription(response);
      });
    }
  };

  updateStatus = async (status: SubscriptionStatus, plan: CurrentPlan) => {
    await this.setState({ subscriptionStatus: status, currentPlan: plan });
    history.push("/remplacants");
  };

  updatePlan = async (plan: CurrentPlan) => {
    if (!plan) {
      plan = "standard";
    }
    await this.setState({ currentPlan: plan });
  };

  handleUpdateSubstitutesPageContext = (state: SubstitutesPageState) => {
    this.setState({
      substitutesPage: {
        state: { ...state },
      },
    })
  }

  getOfficeDetails = async () => {
    if (this.state.user.id && !this.state.officeLogo) {
      fetchApi(
        `/offices?officeIds=${encodeURI(
          JSON.stringify([this.state.user.office.id])
        )}`
      ).then(response => {
        if (response.status === 200) {
          this.setState({
            office: response.data[0],
            officeLogo: response.data[0] ? response.data[0]?.logo?.thumbnail?.url : null
          });
        }
      });
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          emptyBarMessage: this.emptyBarMessage,
          redirectToPricing: this.redirectToPricing,
          popBarMessage: this.popBarMessage,
          state: this.state,
          setTipOpen: this.setTipOpen,
          handleToggle: this.handleToggle,
          handleInput: this.handleInput,
          handleSearch: this.handleSearch,
          handleCheckBox: this.handleCheckBox,
          handleSelect: this.handleSelect,
          handleSetOfficeType: this.handleSetOfficeType,
          handleResetOfficeSearch: this.handleResetOfficeSearch,
          handleLogIn: this.handleLogIn,
          handleLogOut: this.handleLogOut,
          isEmailAvailable: this.isEmailAvailable,
          handleSubscription: this.handleSubscription,
          handlePreviousPage: this.handlePreviousPage,
          handleNextPage: this.handleNextPage,
          handleResetPassword: this.handleResetPassword,
          handleResetEmail: this.handleResetEmail,
          getPlanStatus: this.getUserDetails,
          updatePractitionerDetails: this.updatePractitionerDetails,
          substractCredit: this.substractCredit,
          updateStatus: this.updateStatus,
          updateNewCandidate: this.getNewCandidates,
          resetNewCandidate: this.resetNewCandidates,
          setUserTokenIsExpired: this.setUserTokenIsExpired,
          handleUpdateSubstitutesPageContext: this.handleUpdateSubstitutesPageContext,
          handleAddSubstituteToLikedList: this.handleAddSubstituteToLikedList,
          handleRemoveSubstituteToLikedList: this.handleRemoveSubstituteToLikedList,
        }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }

  getNewCandidates = () => {
    fetchApi(`/missions-subscriptions/new/${this.state.user.id}`).then(
      response => {
        if (response?.status === 200) {
          this.setState({ newCandidates: response.data?.count });
        }
      }
    );
  };

  resetNewCandidates = () => {
    this.setState({ newCandidates: 0 });
  };

  substractCredit = () => {
    this.setState({ credits: this.state.credits - 1 });
  };

  handleFetchAllProfessions = () => {
    if (this.state.allProfessions.length === 0) {
      fetchApi(`/professions`).then((res) => {
        this.setState({
          allProfessions: res.data,
        });
      });
    }
  }

  UNSAFE_componentWillMount() {
    this._isMounted = true;
  }

  componentDidMount() {
    if (this.state.user?.token) {
      this.getUserDetails();
      this.getSubstitutesCount();
      this.getNewCandidates();
      this.handleSocketOn();
      this.handleFetchAllProfessions();
      this.getOfficeDetails();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.handleSocketOff();
  }

  componentDidUpdate(prevProps: any, prevState: AuthState) {
    if (this.state.user?.token && this._isMounted) {
      if (prevState.user.token !== this.state.user?.token) {
        if (this.state.user.officeIds || this.state.user.office.id) {
          this.getSubstitutesCount();
        }
        // Update le nombre de remplacants avant la page de succes d'inscription, grace au tokem
      }

      if (prevState.subscriptionStatus !== this.state.subscriptionStatus) {
        if (this.state.currentPlan) {
          this.updatePlan(this.state.currentPlan);
        }
      }
    }

  }
}

export default AuthProvider;
