import React from "react";

interface Props {
  tipOpen?: string;
  texts?: any[];
  severalTips?: boolean;
  threeTips?: boolean;
  smallText?: React.ReactElement;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
}

const Tips = (props: Props) => {
  const { tipOpen } = props;
  return (
    <div className="tips">
      <div className="thumbnail">
        <h3>Conseils & Tips</h3>
        <div className="tips-paragraphs">
          {props.texts?.map((text, key) => <div key={key} className="paragraph">{text}</div>)}
        </div>
        {props.severalTips && (
          <div className="circle-selector">
            <div
              onMouseEnter={() => props.onMouseEnter && props.onMouseEnter("tip1")}
              className={tipOpen === 'tip1' ? "green-circle" : "grey-circle"}
            />
            <div
              onMouseEnter={() => props.onMouseEnter && props.onMouseEnter("tip2")}
              className={tipOpen === 'tip2' ? "green-circle" : "grey-circle"}
            />
            {props.threeTips && (
              <div
                onMouseEnter={() => props.onMouseEnter && props.onMouseEnter("tip3")}
                className={tipOpen === 'tip3' ? "green-circle" : "grey-circle"}
              />
            )}
          </div>
        )}
        <>{props.smallText}</>
      </div>
    </div>
  );
};

export default Tips;
