import React, { useContext } from "react";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import { Link } from "react-router-dom";
import AuthTextInput from "../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import logo from "../../../assets/img/logo-ecriture-noir.png";
import Loader from "react-loader-spinner";
import { History } from "history";
import { isValidEmail } from "../../../Tools/StringTools";
import ValidButton from "../../../Components/Forms/Buttons/ValidButton/ValidButton";

interface Props {
  history: History;
}
const ResetRequest = (props: Props) => {
  const context = useContext(AuthContext);

  return (
        <div className="content slide-one">
          <Link to="authentification">
            <div className="backward" onClick={() => props.history.goBack()}>
              <i className="fas fa-chevron-left" />
              <span>Retour</span>
            </div>
          </Link>
          <img src={logo} alt="App'Ines" className="top-logo" />
          <div className="reset-title">Mot de passe oublié ?</div>
          <div className="text">
            Réinitialisez le mot de passe en deux étapes rapides.
          </div>
          {!context.state.isLoading ? (<div className="center">
            <AuthTextInput
              type="email"
              name="email"
              placeHolder="Email"
              handleInput={context.handleInput}
              value={context.state.email}
              minLength={1}
              maxLength={320}
              condition={!!context.state.email}
            />
            <ValidButton
              text="Réinitialiser"
              onClick={context.handleResetPassword}
              condition={isValidEmail(context.state.email)}
            /></div>) : (
              <Loader type="Puff" color="#25bba5" height={100} width={100} />
            )}
        </div>
  );
};

export default ResetRequest;
