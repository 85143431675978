const formatTimeStamp = (timestamp: string) => {
  return (
    new Date(timestamp).toLocaleDateString("fr-FR", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }) +
    ", " +
    new Date(timestamp).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};

export default formatTimeStamp;
