import React, { useState } from "react";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import SelectButton from "../../../../Components/Forms/Buttons/SelectButton/SelectButton";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";

interface Props {
  mission: any;
  substitute: any;
  handleMoveMission: Function;

  // optional
  source?: string | null;
  handleSelect?: Function;
}

export const FillMission = ({
  mission,
  source,
  substitute,
  handleSelect,
  handleMoveMission
}: Props) => {
  const [openFillMission, setOpenFillMission] = useState(false);

  const confirmFillMission = () => {
    setOpenFillMission(false);
    handleMoveMission(mission, "hiredAds");
  };

  // TODO : create an helper function
  const removeDuplicatesCandidates = (substitutes: any) => {
    //show the candidate only once even if he has several candidates
    const substitutesId = substitutes.map((e: any) => e.id);

    const candidatesFiltred: any = [];

    for (let i = 0; i < substitutesId.length; i++) {
      candidatesFiltred.map((e: any) => e.id).indexOf(substitutesId[i]) ===
        -1 && candidatesFiltred.push(substitutes[i]);
    }

    return candidatesFiltred;
  };

  const allCandidates = removeDuplicatesCandidates(
    mission.missionSubscribers.map((e: any) => e.substitute)
  );

  if (mission.exercise === "internship") return null;

  return (
    <div className="mission-is-not-filled">
      Mission pourvue ?
      <div className="is-not-filled" onClick={() => setOpenFillMission(true)}>
        <div />
        Non
      </div>
      {openFillMission && (
        <div className="is-filled-block">
          <div className="mission-is-filled">
            Mission pourvue ?
            <div
              className="is-filled"
              onClick={() => setOpenFillMission(false)}
            >
              <div />
              Oui
            </div>
          </div>
          <div className="questions">
            <div className="info">
              Vous retrouverez cette annonce dans l'onglet "Pourvues", une fois
              les informations complétées.
            </div>
            <div className="question">
              Comment avez-vous trouvé votre candidat ?
            </div>
            <div className="how-did-you-find">
              {mission.missionSubscribers.length > 0 && (
                <SelectButton
                  handleSelect={handleSelect}
                  isSelected={source === "appines"}
                  name="source"
                  dbRole="appines"
                  category="App'Ines"
                />
              )}
              <SelectButton
                handleSelect={handleSelect}
                isSelected={source === "ads-website"}
                name="source"
                dbRole="ads-website"
                category="Site d'annonces"
              />
              <SelectButton
                handleSelect={handleSelect}
                isSelected={source === "social-media"}
                name="source"
                dbRole="social-media"
                category="Réseaux sociaux"
              />
              <SelectButton
                handleSelect={handleSelect}
                isSelected={source === "former-substitute"}
                name="source"
                dbRole="former-substitute"
                category="Ancien remplaçant"
              />
              <SelectButton
                handleSelect={handleSelect}
                isSelected={source === "internal"}
                name="source"
                dbRole="internal"
                category="En interne"
              />
              <SelectButton
                handleSelect={handleSelect}
                isSelected={source === "other"}
                name="source"
                dbRole="other"
                category="Autre"
              />
            </div>
            {source === "appines" && (
              <div>
                <div className="question">
                  Quel candidat avez-vous recruté ?
                </div>
                <div className="all-candidates">
                  {allCandidates.map((candidate: any) => (
                    <div key={candidate._id}>
                      <CheckBox
                        className="circle"
                        name="substitute"
                        value={candidate}
                        text={
                          <div className="candidate">
                            <div>
                              {candidate.firstName[0]}
                              {candidate.firstName.substring(1).toLowerCase()}
                            </div>
                            <div>
                              {candidate.lastName && candidate.lastName[0]}
                            </div>
                          </div>
                        }
                        handleCheckBox={handleSelect}
                        condition={substitute === candidate}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="button">
              <ValidButton
                type="submit"
                text="Confirmer"
                onClick={() => confirmFillMission()}
                condition={
                  !!(source === "appines"
                    ? !!(source && substitute?.id)
                    : source)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
