import React, { useMemo } from "react";
import moment from "moment";

const AlertThumbnail = (props: Props) => {
  const { research } = props;

  const availabilityDates: string | undefined = useMemo(() => {
    /**
     * Given a date as a string, return a new date as a locale date string.
     * @param {string} date - string - the date we want to format
     * @returns A new Date object with the date formatted to a locale date string.
     */
    const formatDate = (date: string) => {
      return new Date(date).toLocaleDateString();
    };

    const startDate = research.query.startDate;
    const endDate = research.query.endDate;

    if (startDate && endDate) return "Du " + formatDate(startDate) + " au " + formatDate(endDate);
    if (startDate && !endDate) return "À partir du " + formatDate(startDate);
    if (!startDate && !endDate) return "Toutes dates";
    if (!startDate && endDate) return "Jusqu'au " + formatDate(endDate);
  }, [research]);

  const availabilitySector = useMemo((): JSX.Element | undefined => {
    const formatDistance = (dist: number) => {
      if (dist) {
        dist = dist / 1000;
        return "< " + dist + " km,";
      }
    };

    if (props.sector === "out") {
      return (
        <>
          <div className="alert-zones">
            <span className="alert-zone zone-far">
              {research.query.point
                ? formatDistance(research.query.point.maxDistance) + " " + research.query.point.name
                : research.query.zone}
            </span>
          </div>
        </>
      );
    }

    if (props.sector === "in") {
      return (
        <>
          <div className="alert-zones">
            <span className="alert-zone zone-near">
              {research.query.point
                ? formatDistance(research.query.point.maxDistance) + " " + research.query.point.name
                : research.query.zone}
            </span>
          </div>
        </>
      );
    }
  }, [research]);

  const typeOfContracts = useMemo(() => {
    const exercices: Exercise[] = research.query.exercise;
    const exercicesFormated: string[] = [];

    if (exercices.length === 0) return "💼 Tous types de postes";

    //prettier-ignore
    if (exercices.includes("cdi") || exercices.includes("cdd")) exercicesFormated.push("💼 Salariat");
    if (exercices.includes("substitute")) exercicesFormated.push("🔁 Vacation");
    //prettier-ignore
    if (exercices.includes("assistantship") || exercices.includes("collaboration")) exercicesFormated.push("👨‍ Assistanat / Collaboration");
    if (exercices.includes("internship")) exercicesFormated.push("🎓 Stage");

    return exercicesFormated.map((exercice, i) =>
      i + 1 === exercicesFormated.length ? (
        <div key={i}>{exercice}</div>
      ) : (
        <div key={i} style={{ marginBottom: "10px" }}>{`${exercice}, `}</div>
      )
    );
  }, [research]);

  const availabilityUpdateDate = useMemo(() => {
    const createdAt = research.createdAt;
    const updatedAt = research.updatedAt;
    let date;
    if (createdAt && updatedAt && updatedAt > createdAt) date = updatedAt;
    else date = createdAt;

    const wording = updatedAt ? "Mise à jour" : "Créée";
    if (!date)
      return (
        <div className="alert-date-divider date-old">
          <span>Date de création non renseignée</span>
        </div>
      );
    const days = moment().diff(moment(date), "days");
    const weeks = moment().diff(moment(date), "weeks");
    const months = moment().diff(moment(date), "months");

    if (days === 0) {
      return (
        <div className="alert-date-divider">
          <span>{wording} aujourd'hui</span>
        </div>
      );
    }

    if (days < 7) {
      return (
        <div className="alert-date-divider">
          <span>
            {wording} il y a {days} jour{days > 1 && "s"}
          </span>
        </div>
      );
    }

    if (months < 3) {
      return (
        <div className="alert-date-divider date-old">
          <span>
            {wording} il y a {weeks} semaine{weeks > 1 && "s"}
          </span>
        </div>
      );
    }

    return (
      <div className="alert-date-divider date-very-old">
        <span>
          {wording} il y a {months} mois
        </span>
      </div>
    );
  }, [research]);

  return (
    <div>
      {availabilityUpdateDate}
      <div className="substitute-alert">
        <div>
          <div>🗓 {availabilityDates}</div>
        </div>

        <div>
          <div>{typeOfContracts}</div>
        </div>

        <div>
          <div className="alert-col">{availabilitySector}</div>
        </div>
      </div>
    </div>
  );
};

export default AlertThumbnail;

type Props = {
  research: Research;
  sector: "in" | "out";
};
