export const missionTypes = [
  { back: "cdi", front: "CDI", IsForbidden: false },
  { back: "cdd", front: "CDD", IsForbidden: false },
  { back: "internship", front: "Stage", IsForbidden: false },
  { back: "substitute", front: "Vacation", IsForbidden: false, newFeature: true }
];

export const remunerationType = [
  { back: "annual-salary", front: "Salaire brut annuel" },
  { back: "mensual-salary", front: "Salaire brut mensuel" },
  { back: "hourly-rate", front: "Taux horaire brut" },
  { back: "per-act", front: "À l’acte" },
  { back: "retrocession", front: "Rétrocession d’honoraires" },
  { back: "depends-on-profile", front: "Selon profil" },

  // Internship
  { back: "mensual-indemnity", front: "Mensuel" },
  { back: "all-time", front: "Au total" },
  { back: "free", front: "Pas d'indémnisation" }
];
