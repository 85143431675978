import React, { useContext, useEffect, useMemo } from "react";
import logo from "../../assets/img/logo-ecriture-noir.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Services/Contexts/AuthProvider";
import AuthTextInput from "../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./react-toastify-custom.scss";
import { useQuery } from "../../Tools/customHooks";
import { fetchApi } from "../../Services/Contexts/Api";

const Authentification = (props: { accountVerification?: boolean }) => {
  const context = useContext(AuthContext);
  const token = useQuery().get("token");

  const pendingMessage = useMemo(() => "Validation de votre email en cours ...", []);
  const successMessage = useMemo(() => "Votre compte est validé ! 🎉", []);
  const errorMessage = useMemo(
    () =>
      "Une erreur est survenue. Veuillez réessayer ou écrivez-nous à cette adresse : support@appines.fr",
    []
  );

  useEffect(() => {
    if (props.accountVerification) {
      toast
        .promise(
          fetchApi("/validate-email/" + token),
          {
            pending: pendingMessage,
            success: successMessage,
            error: errorMessage,
          },
          { theme: "colored" }
        )
        .then();
    }
  }, [props.accountVerification, token]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        style={{ fontSize: "13px", lineHeight: "1.5" }}
      />

      <>
        <div className="authentification content">
          <img src={logo} alt="App'Ines" className="top-logo" />
          <div className="glad-to-see-you">Ravi de vous revoir !</div>
          <div className="green-text">Accédez à votre espace de recrutement</div>
          <AuthTextInput
            type="email"
            name="email"
            placeHolder="adresse@email.com"
            handleInput={context.handleInput}
            minLength={1}
            condition={!!context.state.email}
          />
          {/* the form tag handles 'enter' key input */}
          <form
            onSubmit={(_e) => {
              _e.preventDefault();
              if (context.state.password && context.state.password.length >= 8) {
                context.handleLogIn();
              }
            }}
          >
            <AuthTextInput
              type="password"
              name="password"
              placeHolder="mot de passe"
              handleInput={context.handleInput}
              minLength={8}
              condition={!!(context.state.password && context.state.password.length >= 8)}
            />
          </form>

          {context.state.isError && (
            <div className={"invalid"}>Une erreur est survenue. Veuillez recharger la page</div>
          )}

          {context.state.incorrectLogs && (
            <div className={"invalid"}>Identifiants incorrects !</div>
          )}

          {context.state.userIsNotValidated && (
            <div className={"invalid"}>Veuillez valider votre compte avant de vous connecter</div>
          )}

          {context.state.userIsNotPro && (
            <div className={"invalid"}>
              <>
                Seuls les établissements avec un numéro FINESS peuvent à accéder à la plateforme
                <br />
                rendez-vous sur l'application mobile App'Ines 😉
              </>
            </div>
          )}

          {context.state.isUserTokenExpired && (
            <div className="invalid">Votre connexion a expiré. Veuillez vous reconnecter</div>
          )}

          {context.state.isLoading && (
            <Loader type={"Puff"} color={"#27BBA5"} width={50} height={50} />
          )}

          {!context.state.isLoading && (
            <button onClick={() => context.handleLogIn()} className="redirect-green">
              Se connecter
            </button>
          )}

          <Link to="/reset-password">
            <div onClick={() => context.handleResetEmail()} className="reset">
              Mot de passe oublié ?
            </div>
          </Link>

          {/* -- Deactivate subscription link for now -- */}

          {/*<div className="subscribe">*/}
          {/*  <span>Pas encore inscrit ?</span>*/}
          {/*  <Link to="/subscription">*/}
          {/*    <span className="green-text"> Je crée mon compte</span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </>
    </>
  );
};

export default Authentification;
