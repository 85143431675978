import React, { useState, useEffect, useRef } from "react";
import AuthTextInput from "../../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import SearchBar from "../../../../Components/Forms/Inputs/SearchBar/SearchBar";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";
import { fetchApi } from "../../../../Services/Contexts/Api";

//@ts-ignore
import ReactCountryFlag from "react-country-flag";

export const InternshipTerms = (context: newMissionProps) => {
  const [allSchools, setAllSchools] = useState<School[]>([]);
  const [schoolsList, setSchoolsList] = useState<School[]>([]);
  const [schoolsSelected, setSchoolsSelected] = useState<School[]>([]);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [inputSchool, setInputSchool] = useState("");

  const _ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchApi(`/schools?professionId=${context.state.profession._id}`).then(
      response => {
        setAllSchools(response.data);
      }
    );
  }, []);

  useEffect(() => {
    if (allSchools.length > 0) {
      let schoolsAllreadySelected: any[] = [];

      context.state.approvedSchools?.map((school: string) => {
        return schoolsAllreadySelected.push(school);
      });

      setSchoolsSelected(schoolsAllreadySelected);
    }
  }, [allSchools]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (_ref.current && !_ref.current.contains(event?.target)) {
        setListIsOpen(false);
      }
    }

    function handleEscapeDown(event: any) {
      if (event.keyCode === 27) {
        setListIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeDown);
    return () => {
      // Unbind the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeDown);
    };
  }, [_ref]);

  useEffect(() => {
    if (listIsOpen) {
      if (inputSchool.length > 0 && schoolsList.length <= 0) {
        setListIsOpen(false);
      }
    } else {
      if (schoolsList.length > 0) {
        setListIsOpen(true);
      }
    }
  }, [inputSchool]);

  const SchoolElement = (props: School) => {
    let find = schoolsSelected?.find((school: any) => school._id === props._id);

    return (
      <li onClick={() => handleSelectSchool(null, props)}>
        <CheckBox
          text=""
          value={props}
          className="square"
          handleCheckBox={undefined}
          condition={!!find}
        />

        {props.country && (
          <ReactCountryFlag
            svg
            style={{ marginRight: 10 }}
            countryCode={props.country}
          />
        )}
        {props.name}
      </li>
    );
  };

  const renderSchools = (context: any) => {
    return schoolsSelected.map((school: any, index: number) => (
      <div key={index} className="confirmed-school">
        <div>
          {school.country && (
            <ReactCountryFlag
              svg
              style={{ marginRight: 10 }}
              countryCode={school.country}
            />
          )}
          <p>{school.name}</p>
        </div>
        <i
          className="fas fa-times"
          title="Retirer"
          onClick={() => handleRemoveSchool(school)}
        />
      </div>
    ));
  };

  const handleSearch = (e: any) => {
    let input = e.target.value;
    let regex = new RegExp(input, "igm");

    let schoolsMatching = allSchools.filter(
      (school: any) => school.name.match(regex) || school.country.match(regex)
    );
    setInputSchool(input);
    setSchoolsList(schoolsMatching);
  };

  const handleSelectSchool = (name: any, value: any) => {
    let index = schoolsSelected?.findIndex(
      (school: School) => school._id === value._id
    );

    let newSchoolsSelected = [...schoolsSelected];
    if (index >= 0) {
      newSchoolsSelected.splice(index, 1);
    } else {
      newSchoolsSelected.push(value);
    }

    context.handleCheckBox("approvedSchools", value);
    setSchoolsSelected(newSchoolsSelected);
  };

  const handleRemoveSchool = (value: any) => {
    let index = schoolsSelected?.findIndex(
      (school: School) => school._id === value._id
    );
    let newSchoolsSelected = [...schoolsSelected];
    newSchoolsSelected.splice(index, 1);

    context.handleCheckBox("approvedSchools", value);
    setSchoolsSelected(newSchoolsSelected);
  };

  return (
    <>
      <div className="interns">
        <p>Nombre de stagiaires acceptées ? </p>
        <AuthTextInput
          type="number"
          name="internsAllowed"
          condition={!!context.state.internsAllowed}
          disabled={false}
          handleInput={context.handleInput}
          min={0}
          max={50}
          value={context.state.internsAllowed}
        />
      </div>

      <div className="accepted-years">
        <p>Quelle années d'études acceptées ? </p>
        <div>
          <CheckBox
            name="acceptedYears"
            value={1}
            text="1ère Année"
            className="square"
            handleCheckBox={context.handleCheckBox}
            isError={
              context.state.displayErrors &&
              !!context.state?.errors?.slideInternship?.acceptedYears
            }
            condition={!!context.state.acceptedYears?.find(year => year === 1)}
          />
          <CheckBox
            name="acceptedYears"
            value={2}
            text="2ème Année"
            className="square"
            handleCheckBox={context.handleCheckBox}
            isError={
              context.state.displayErrors &&
              !!context.state?.errors?.slideInternship?.acceptedYears
            }
            condition={!!context.state.acceptedYears?.find(year => year === 2)}
          />
          <CheckBox
            name="acceptedYears"
            value={3}
            text="3ème Année"
            className="square"
            handleCheckBox={context.handleCheckBox}
            isError={
              context.state.displayErrors &&
              !!context.state?.errors?.slideInternship?.acceptedYears
            }
            condition={!!context.state.acceptedYears?.find(year => year === 3)}
          />
          <CheckBox
            name="acceptedYears"
            value={4}
            text="4ème Année"
            className="square"
            handleCheckBox={context.handleCheckBox}
            isError={
              context.state.displayErrors &&
              !!context.state?.errors?.slideInternship?.acceptedYears
            }
            condition={!!context.state.acceptedYears?.find(year => year === 4)}
          />
          <CheckBox
            name="compensatoryInternship"
            text="Stage compensatoire ARS accepté"
            className="square"
            handleCheckBox={context.handleToggle}
            condition={!!context.state.compensatoryInternship}
          />
        </div>
        <div className="errors-block">
          {context.state.displayErrors && (
            <div className="error">
              {context.state?.errors?.slideInternship?.acceptedYears}
            </div>
          )}
        </div>
      </div>

      <div className="tutors">
        <p>Nombre de tuteurs ? </p>
        <AuthTextInput
          type="number"
          name="tutors"
          condition={!!context.state.tutors}
          disabled={false}
          handleInput={context.handleInput}
          min={0}
          max={50}
          value={context.state.tutors}
        />
      </div>

      <div className="schools">
        <p>Sélectionnez vos écoles partenaires : </p>
        <div className="schools-input" ref={_ref}>
          <SearchBar
            type="text"
            value={inputSchool}
            placeHolder="Sélectionnez vos écoles partenaires"
            name="searchText"
            minLength={0}
            maxLength={50}
            condition={false}
            isSearch={true}
            autoComplete={"off"}
            onClick={() => setListIsOpen(true)}
            handleInput={handleSearch}
            className={listIsOpen ? "open" : "close"}
          />

          {listIsOpen && (
            <ul
              className="schools-list"
              onClick={() => context.handleToggle("listIsOpen")}>
              {schoolsList?.length > 0
                ? schoolsList.map((school: any, index: any) => {
                    return <SchoolElement {...school} key={index} />;
                  })
                : allSchools.map((school: any, index: any) => {
                    return <SchoolElement {...school} key={index} />;
                  })}
            </ul>
          )}
        </div>
      </div>

      <div className="all-confirmed-schools">{renderSchools(context)}</div>
    </>
  );
};
