import React from "react";
import classNames from "classnames";

interface Props {
  condition: boolean;
  className: string;
  handleCheckBox: Function | undefined;

  // optional
  name?: string;
  isError?: boolean;
  value?: any;
  link?: string;
  text: React.ReactElement | string;
  forbidden?: boolean;
  newFeature?: boolean;
}

const CheckBox = (props: Props) => {
  const text = props.link ? (
    <a
      className="description link"
      target="_blank"
      rel="noopener noreferrer"
      href={props.link}>
      {props.text}
    </a>
  ) : (
    <div className="description">{props.name === "missionType" && props.forbidden ? `🔒 ${props.text}` : props.text}</div>
  );

  return (
    <>
      {!props.condition ? (
        <div
          className="checkbox"
          onClick={() =>
            !props.forbidden &&
            props.handleCheckBox &&
            props.handleCheckBox(props.name, props.value)
          }>
          <div
            className={classNames(
              props.className,
              props.isError && "circle__error",
              props.forbidden && "forbidden circle__forbidden"
            )}
          />
          <div className={props.forbidden ? "forbidden" : ""}>{text}</div>

          {props.newFeature && <div className="new-feature">✨ Nouveau !</div>}
        </div>
      ) : (
        <div
          className="checkbox checkbox-blue"
          onClick={() =>
            !props.forbidden &&
            props.handleCheckBox &&
            props.handleCheckBox(props.name, props.value)
          }>
          <div className={props.className}>
            <div className="inner-blue-circle" />
          </div>
          <div className={props.forbidden ? "forbidden" : ""}>{text}</div>

          {props.newFeature && <div className="new-feature">✨ Nouveau !</div>}
        </div>
      )}


    </>
  );
};

export default CheckBox;
