// DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import dayjs from "dayjs";

// ASSETS
import { missionTypes } from "../../../../Constants/mission";
import { fetchApi } from "../../../../Services/Contexts/Api";
import Button from "../../../../Components/Forms/Buttons/Button/Button";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import { byPass } from "../../../../Tools/StringTools";

const formatDate = (date: string) => dayjs(date).format("DD/MM/YY");

interface ISubscription {
  subscription: {
    _id: string;
    title: string;
    createdAt: string;
    startDate: string;
    exercise: string;
    message: string;
    endDate?: string;
  };
}

const SubscriptionThumbnail = (props: ISubscription) => {
  const { subscription } = props;

  const context = useContext(AuthContext);

  // -- states --
  const [isLoading, setLoading] = useState(false);
  const [showButtonAccept, setShowButtonAccept] = useState(false);
  const [showButtonRefuse, setShowButtonRefuse] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [userIsPremium, setUserIsPremium] = useState(false);

  // -- helpers --
  const getExercise = (exercise: string) => {
    return missionTypes.map((mission: { front: string }) => mission.front)[
      missionTypes.map((mission: { back: string }) => mission.back).indexOf(exercise)
    ];
  };

  const handleAcceptCandidate = (id: string) => {
    setLoading(true);
    fetchApi(`/missions-subscriptions/${id}/accept`, "PUT").then(() => {
      setLoading(false);
    });
  };

  const handleRefuseCandidate = (id: string) => {
    setLoading(true);
    fetchApi(`/missions-subscriptions/${id}/change-status`, "PUT", {
      status: "REFUSED",
    }).then(() => {
      setLoading(false);
    });
  };

  const handleResetCta = () => {
    setShowButtonAccept(false);
    setShowButtonRefuse(false);
    setShowButtonCancel(false);
  };

  const handlePopBarMessage = () => {
    context.popBarMessage(
      "Vous devez souscrire à l'offre Smile ou Happy pour répondre aux candidatures"
    );
  };

  const handleConfirmationRequest = (request: "accept" | "refuse") => {
    if (request === "accept") {
      setShowButtonAccept(true);
      setShowButtonRefuse(false);
    }

    if (request === "refuse") {
      setShowButtonAccept(false);
      setShowButtonRefuse(true);
    }

    setShowButtonCancel(true);
  };

  useEffect(() => {
    setUserIsPremium(context.state.currentPlan === "smile" || context.state.currentPlan == "happy");
  }, [context.state.currentPlan]);

  return (
    <div className={"subscription"}>
      <div className={"title"}>
        {subscription.title} - {getExercise(subscription.exercise)}
      </div>

      <div className={"createdAt"}>Reçue le {formatDate(subscription.createdAt)}</div>

      <div className={"availability"}>
        {subscription.endDate
          ? `Disponible du ${formatDate(subscription.startDate)} au ${formatDate(
              subscription.endDate
            )}`
          : `Disponible à partir du ${formatDate(subscription.startDate)}`}
      </div>

      <div className={"message"}>
        {userIsPremium ? subscription.message : byPass(subscription.message)}
      </div>

      <div className={"cta"}>
        {!isLoading && (
          <>
            {!showButtonRefuse ? (
              userIsPremium ? (
                <Button
                  condition
                  className={"accept"}
                  text={showButtonAccept ? "Confirmer ?" : "Accepter"}
                  onClick={
                    showButtonAccept
                      ? () => handleAcceptCandidate(subscription._id)
                      : () => handleConfirmationRequest("accept")
                  }
                />
              ) : (
                <Button
                  condition
                  className={"accept forbidden"}
                  text={"🔒 Accepter"}
                  onClick={handlePopBarMessage}
                />
              )
            ) : (
              <Button condition className={"cancel"} text={"Annuler"} onClick={handleResetCta} />
            )}

            {!showButtonAccept ? (
              userIsPremium ? (
                <Button
                  condition
                  className={"refuse"}
                  className2={"refuse forbidden"}
                  text={showButtonRefuse ? "Confirmer ?" : "Refuser"}
                  onClick={
                    showButtonRefuse
                      ? () => handleRefuseCandidate(subscription._id)
                      : () => handleConfirmationRequest("refuse")
                  }
                />
              ) : (
                <Button
                  condition
                  className={"refuse forbidden"}
                  text={"🔒 Refuser"}
                  onClick={handlePopBarMessage}
                />
              )
            ) : (
              <Button condition className={"cancel"} text={"Annuler"} onClick={handleResetCta} />
            )}
          </>
        )}
        {isLoading && <Loader type={"Puff"} width={20} height={20} color={"#25bba5"} />}
      </div>
    </div>
  );
};

export default SubscriptionThumbnail;
