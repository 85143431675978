import React from "react";
//@ts-ignore
import ReactFileReader from "react-file-reader";

interface Props {
    pictures: IPicture[];
    handleFiles: Function;
    handleRemoveFiles: Function;
}

const PreviewPictures = (props: Props) => {

    const makePreviews = () => {
        let previews = [];

        for (let i = 0; i < 12; i++) {
            previews.push(
                <div className="preview" key={i}>
                    {props.pictures[i] ? (
                        <div>
                            <img src={props.pictures[i].thumbnail.url} alt="Preview" />
                            <div className="trash">
                                <i
                                    className="fas fa-trash"
                                    onClick={() => props.handleRemoveFiles(props.pictures[i]._id)}
                                />
                            </div>
                        </div>
                    ) : (
                            <ReactFileReader
                                fileTypes={[".png", ".jpg"]}
                                base64={true}
                                multipleFiles={true}
                                handleFiles={props.handleFiles}
                            >
                                <i className="fas fa-camera" />
                            </ReactFileReader>
                        )}
                </div>
            )
        }
        return previews;
    }


    return (
        <div>
            {makePreviews()}
        </div>
    );
}

export default PreviewPictures;
