import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SubstitutePersonalPageGhost() {
  return (
    <div className="substitute-page ghost">
      <div className="content">
        <div className="container">
          <div className="left-block">
            <div className="backward" style={{cursor: "default"}}>
              <span>&nbsp;</span>
            </div>

            <div className="introduction">
              <div className="avatar">
                <Skeleton circle={true} height={180} width={180} />
              </div>

              <Skeleton width={200} height={32} style={{marginTop: "20px", marginBottom: "30px"}}/>

              <Skeleton width={250} />
              <Skeleton width={175} />
            </div>
          </div>


          <div className="main-block">
            <div className="top-block">
              <div className="administrative-details">
                <div className="student">
                  <Skeleton width={250} height={32} style={{marginTop: "20px", marginBottom: "30px"}}/>
                </div>

                <div>
                  <div>
                    <div className="graduation">
                      <div>
                        <Skeleton width={180}/>
                      </div>
                    </div>

                    <div className="school">
                      <Skeleton width={120}/>
                    </div>

                    <div className="ordinal">
                      <div>
                        <Skeleton width={150}/>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Skeleton width={120}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="middle-block">
              <Skeleton width={400} height={32} style={{marginTop: "20px", marginBottom: "30px", display: "block"}}/>
              <Skeleton width={600}/>
              <Skeleton width={180}/>

            </div>
                          
          </div>
        </div>
      </div>
    </div>
  )
}
