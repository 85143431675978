const allProfessions = [
  // { back: null, front: "Sélectionner une profession..." },
  { back: "balneotherapy agent", front: "Agent Balnéothérapie" },
  { back: "allergist", front: "Allergologue" },
  { back: "anaesthetist-reanimator", front: "Anesthésiste Réanimateur" },
  { back: "angiologist", front: "Angiologue" },
  { back: "AS", front: "AS (Aide soignant(e))" },
  { back: "ASH", front: "ASH (Agent de Service Hospitalier)" },
  { back: "biologist", front: "Biologiste" },
  { back: "stretcher-bearer", front: "Brancardier" },
  { back: "health-admin", front: "Cadre de santé" },
  { back: "rehabilitation-admin", front: "Cadre de rééducation" },
  { back: "care-admin", front: "Cadre de soins" },
  { back: "cardiologist", front: "Cardiologue" },
  { back: "dental-surgeon", front: "Chirurgien Dentiste" },
  { back: "plastic-surgeon", front: "Chirurgien Esthétique" },
  { back: "stomatologist", front: "Chirurgien Oral - Stomatologue" },
  { back: "vascular-surgeon", front: "Chirurgien vasculaire" },
  { back: "visceral-surgeon", front: "Chirurgien Viscéral & Digestif" },
  { back: "dietitian", front: "Diététicienne Nutritionniste" },
  { back: "dermatologist", front: "Dermatologue" },
  { back: "endocrinologist", front: "Endocrinologue" },
  { back: "apa-teacher", front: "Enseignant APA (Activité Physique Adaptée)" },
  { back: "ergotherapist", front: "Ergothérapeute" },
  { back: "gastroenterologist", front: "Gastro-entérologue" },
  { back: "geriatrician", front: "Gériatre" },
  { back: "gynecologist", front: "Gynécologue" },
  { back: "hematologist", front: "Hématologue" },
  { back: "IAED", front: "IADE (Infirmier Anesthésiste Diplômé d’Etat)" },
  {
    back: "IBODE",
    front: "IBODE (Infirmier de Bloc Opératoire Diplômé d’Etat)",
  },
  { back: "nurse", front: "IDE (Infirmier(e) Diplômé(e) d’État)" },
  { back: "IDEC", front: "IDEC (Infirmier(e) Diplômé d’État Coordinateur)" },
  { back: "ISP", front: "ISP (Infirmier Secteur Psychiatrique)" },
  { back: "physiotherapist", front: "Masseur-Kinésithérapeute" },
  { back: "public-health-physician", front: "Médecin de santé publique" },
  { back: "occupational-physician", front: "Médecin du Travail" },
  { back: "sport-physician", front: "Médecin du Sport"},
  { back: "general-practitioner", front: "Médecin Généraliste" },
  { back: "coordinating-physician", front: "Médecin Coordonnateur" },
  { back: "nuclear-physician", front: "Médecin Nucléaire" },
  { back: "mpr-doctor", front: "Médecin MPR" },
  { back: "pMI-doctor", front: "Médecin PMI" },
  { back: "nephrologist", front: "Néphrologue" },
  { back: "neurosurgeon", front: "Neurochirurgien" },
  { back: "neuropsychologist", front: "Neuropsychologue" },
  { back: "neurologist", front: "Neurologue" },
  { back: "oncologist", front: "Oncologue" },
  { back: "ophthalmology", front: "Ophtalmologue" },
  { back: "otorhinolaryngologist", front: "ORL Oto-Rhino-Laryngologiste" },
  { back: "orthodontist", front: "Orthodontiste" },
  { back: "orthopaedist", front: "Orthopédiste" },
  { back: "speech-language-pathologist", front: "Orthophoniste" },
  { back: "orthoprosthesist", front: "Orthoprothésiste" },
  { back: "orthoptist", front: "Orthoptiste" },
  { back: "osteopath", front: "Ostéopathe" },
  { back: "pediatrician", front: "Pédiatre" },
  { back: "pedicure-podiatrist", front: "Pédicure Podologue" },
  { back: "pharmacist-(student)", front: "Pharmacien (Étudiant)" },
  { back: "pharmacist-(thesed)", front: "Pharmacien (Thésé)" },
  { back: "podo-orthésiste", front: "Podo-orthésiste" },
  { back: "pneumologist", front: "Pneumologue" },
  { back: "pharmacy-preparer", front: "Préparateur en pharmacie" },
  { back: "psychiatrist", front: "Psychiatre" },
  { back: "psychologist", front: "Psychologue" },
  { back: "psychomotor-therapist", front: "Psychomotricien" },
  { back: "radiologist", front: "Radiologue" },
  { back: "re-educator", front: "Rééducateur" },
  { back: "rheumatologist", front: "Rhumatologue" },
  { back: "midwife", front: "Sage-Femme" },
  { back: "emergency-doctor", front: "Urgentiste" },
  { back: "urologist", front: "Urologue" },
];
export default allProfessions;
