import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";

interface Props {
  value: any;
  name: string;
  placeHolder: string;
  condition: boolean;
  handleSelect: Function;

  // optional
  list?: any[];
  listString?: string[];
  classname?: string;
  width?: number;
  version?: 1 | 2;
  sorted?: boolean;
  isError?: boolean;

  // May be needed...
  // onClick?: Function;
}

// TODO: onClick is working but need to check everywhere where ListSelect is used to be sure (that's why it' s commented)
// TODO: clean comments

/*
    Version:
        1 - V1 : Display list of objects with front and back field, back field is the reference 
            e.g {front: "Kinésithérapeute", back: "physiotherapist"}
        2 - V2 : Display list of objects with language (since lists are dynamic on MongoDB) and use _id field as a reference
            e.g {_id: "1234", name: {FR: "Kinésithérapeute", EN: "physiotherapist"}, ...}
*/
const ListSelect = React.forwardRef((props: Props, ref: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(AuthContext);
  const language = context.state.language;

  useEffect(() => {
    if (props.sorted === true) {
      sort(props.list);
    }
  }, [props]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref.current.contains(event?.target)) {
        setIsOpen(false);
      }
    }

    function handleEscapeDown(event: any) {
      if (event.keyCode === 27) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeDown);
    return () => {
      // Unbind the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeDown);
    };
  }, [ref]);

  const sort = (array: any) => {
    if (props.version === 2) {
      return array.sort(function(a: any, b: any) {
        if (a.name[language] < b.name[language]) {
          return -1;
        }
        if (a.name[language] > b.name[language]) {
          return 1;
        }
        return 0;
      });
    } else {
      // A TESTER...
      return array.sort(function(a: any, b: any) {
        if (a.front < b.front) {
          return -1;
        }
        if (a.front > b.front) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sanitize = (array: any) => {
    let tmpArray = [];
    for (const item of array) {
      if (item !== null) {
        tmpArray.push(item);
      }
    }
    return tmpArray;
  };

  if (props.version === 2) {
    return (
      <div
        className={classNames(
          "ListSelect",
          props.classname,
          props.isError ? "error" : props.value || isOpen ? "success" : ""
        )}
        style={{ width: props.width }}
        ref={ref}>
        {props.condition && (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="ListSelect__container">
            <i className="fas fa-caret-down ListSelect__triangle" />

            {props.value ? (
              <span className="value">{props.value}</span>
            ) : (
              <span className="placeholder">{props.placeHolder}</span>
            )}

            {isOpen && (
              <div className="ListSelect__list">
                {props.list && (
                  <>
                    <div
                      className="ListSelect__list__item"
                      key="null"
                      onClick={() => props.handleSelect(props.name, null)}>
                      Non renseignée
                    </div>

                    {props.list?.map((item, index) => (
                      <div
                        className="ListSelect__list__item"
                        key={index}
                        onClick={() => props.handleSelect(props.name, item)}>
                        {item.name[language]}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          "ListSelect",
          props.classname,
          props.isError ? "error" : props.value || isOpen ? "success" : ""
        )}
        style={{ width: props.width }}
        ref={ref}
        // onClick={e => {e.stopPropagation(); if (props.onClick){ props.onClick && props.onClick()}}}
      >
        <div
          className={`ListSelect__label ${!props.value && "hidden"} ${
            props.condition
              ? "blue-label"
              : props.isError
              ? "error-label"
              : "orange-label"
          }`}>
          {props.placeHolder}
        </div>
        {props.condition && (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="ListSelect__container"
            style={{ borderColor: (props.value || isOpen) && "#29b6e5" }}>
            <i
              className="fas fa-caret-down ListSelect__triangle"
              style={{ color: (props.value || isOpen) && "#29b6e5" }}
            />
            {props.value && props.value.back !== null ? (
              <span className="value">{props.value.front ?? props.value}</span>
            ) : (
              <span className="placeholder">{props.placeHolder}</span>
            )}

            {isOpen && (
              <div className="ListSelect__list">
                {props.list && (
                  <>
                    <div
                      className="ListSelect__list__item"
                      key="null"
                      onClick={() =>
                        props.handleSelect(props.name, { back: null })
                      }>
                      Non renseignée
                    </div>
                    {props.list?.map((item, index) => (
                      <>
                        {item?.front && (
                          <div
                            className="ListSelect__list__item"
                            key={index}
                            onClick={() =>
                              props.handleSelect(props.name, item)
                            }>
                            {item.front}
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}

                {props.listString && (
                  <>
                    {sanitize(props.listString)?.map((item, index) => (
                      <div
                        className="ListSelect__list__item"
                        key={index}
                        onClick={() =>
                          props.handleSelect(props.name, item, index)
                        }>
                        {item}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
});

export default ListSelect;
