import React from "react";
import { AuthConsumer } from "../../Services/Contexts/AuthProvider";
import ResetRequest from "./Slide1/ResetRequest";
import ResetSuccess from "./Slide2/ResetSuccess";
import { History } from "history";

interface Props {
  history: History;
}

class ResetPasswordAuth extends React.Component<Props> {
  state = {};
  render() {
    return (
      <AuthConsumer>
        {(context: AuthProps) => (
          <div className="reset-password">
            {context.state.page === 1 && (
              <ResetRequest history={this.props.history} />
            )}
            {context.state.page === 2 && <ResetSuccess />}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default ResetPasswordAuth;
