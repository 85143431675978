import React, { useEffect, useMemo, useReducer, useState } from "react";
import calendar from "../../../../assets/img/calendar.png";
import contractType from "../../../../assets/img/contract-type.png";
import pinMap from "../../../../assets/img/pin-map.png";
import AlertThumbnail from "../AlertThumbnail/AlertThumbnail";
import * as service from "../../../../Services/candidate.services";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";

export default function AvailabilityInfos(props: Props) {
  const [page, setPage] = useState(0);

  const [researches, dispatch] = useReducer(
    (state: State, action: { type: "getResearches" | "isLoading"; payload?: State }): State => {
      switch (action.type) {
        case "isLoading":
          return { ...state, isLoading: true };

        case "getResearches":
          return action.payload || state;
      }
    },
    { inSector: [], outSector: [], outSectorTotal: 0, isLoading: true }
  );

  const numberOfPage = useMemo<number>(() => Math.ceil(researches.outSectorTotal / 10), [
    researches.outSectorTotal,
  ]);

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      dispatch({ type: "isLoading" });

      const researches = await service.getCandidateResearches(props.substituteId, page);

      dispatch({
        type: "getResearches",
        payload: {
          inSector: researches.inSector,
          outSector: researches.outSector,
          outSectorTotal: researches.outSectorTotal,
          isLoading: false,
        },
      });
    })();
  }, [page]);

  /*
! JSX rendering
*/
  return (
    <div className="bottom-block">
      <h2>Disponibilités</h2>
      <div className="alerts-header">
        <div>
          <div className="logo-background">
            <img src={calendar} alt="calendar" />
          </div>
          <div className="label">Date(s) souhaitée(s)</div>
        </div>
        <div>
          <div className="logo-background">
            <img src={contractType} alt="pen" />
          </div>
          <div className="label">Poste(s) recherché(s)</div>
        </div>
        <div>
          <div className="logo-background">
            <img src={pinMap} alt="pin-map" />
          </div>
          <div className="label">Secteur(s) recherché(s)</div>
        </div>
      </div>

      {researches.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type={"Puff"} color={"#29b6e5"} width={50} height={50} />
        </div>
      )}

      {/* * Aucune dispo*/}
      {!researches.isLoading &&
        researches.inSector.length === 0 &&
        researches.outSector.length === 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>Aucune disponibilité</div>
        )}

      {/* * Dans votre secteur*/}
      {!researches.isLoading && researches.inSector.length > 0 && (
        <>
          <div className={"sector in"}>📍 Dans votre secteur</div>
          {researches.inSector.map((research: Research) => (
            <AlertThumbnail research={research} key={research._id} sector={"in"} />
          ))}
        </>
      )}

      {/* * Hors secteur*/}
      {!researches.isLoading && researches.outSector.length > 0 && (
        <>
          <div className={"sector out"}>📍 Hors secteur</div>
          {researches.outSector.map((research: Research) => (
            <AlertThumbnail research={research} key={research._id} sector={"out"} />
          ))}
        </>
      )}

      {numberOfPage > 1 && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={numberOfPage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={(page) => setPage(page.selected)}
          containerClassName={"pagination pagination-alerts"}
          activeClassName={"active"}
          forcePage={page}
        />
      )}
    </div>
  );
}

type Props = {
  substituteId: string;
  alert: AlertProps[];
};

type State = {
  inSector: Array<Research>;
  outSector: Array<Research>;
  outSectorTotal: number;
  isLoading: Boolean;
};
