import React, { useRef, useEffect, ReactElement } from 'react';

const HoverErrorComponent = ({ children, setIsOpen, className }: Props) => {

  const _ref = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => setIsOpen(true);
  const handleMouseOut = () => setIsOpen(false);

  useEffect(
    () => {
      const node = _ref.current;

      node?.addEventListener('mouseenter', handleMouseOver);
      node?.addEventListener('mouseleave', handleMouseOut);

      return () => {
        node?.removeEventListener('mouseenter', handleMouseOver);
        node?.removeEventListener('mouseleave', handleMouseOut);
      };
    },
  );

  return (
    <div ref={_ref} className={className}>
      {children}
    </div>
  );
}

interface Props {
  children: ReactElement;
  setIsOpen: Function;
  className?: string;
}

export default HoverErrorComponent;