// DEPENDENCIES
import React, { useEffect, useState } from "react";

// UTILS
import formatTimeStamp from "../../../Tools/formatTimeStamp";

// ASSETS
import defaultAvatar from "../../../assets/img/grey-monkey.svg";
import { ICandidate, ILastConnection, IMessage } from "../IDiscussions";
import { byPass } from "../../../Tools/StringTools";

interface DiscussionMailProps {
  currentUserId: string;
  currentPlan: "standard" | "smile" | "happy" | "zen" | undefined;
  users: ICandidate[];
  lastMessage: IMessage;
  lastConnection: ILastConnection[];
  selected: Boolean;
}

const DiscussionThumbnail = (props: DiscussionMailProps) => {
  const [pictureUrl, setPictureUrl] = useState<string>();
  const [isActive, setIsActive] = useState<Boolean>();
  const [isNewMessage, setIsNewMessage] = useState<Boolean>(false);
  const [isWaitingForCandidate, setIsWaitingForCandidate] = useState<Boolean>(false);
  const [isPendingCandidate, setIsPendingCandidate] = useState<Boolean>(false);
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [lastMessagePreview, setLastMessagePreview] = useState<string>("");

  const { currentUserId, currentPlan, users, lastMessage, lastConnection, selected } = props;

  // prettier-ignore
  const candidateFirstName = users.filter((user: ICandidate) => user._id !== currentUserId)[0].firstName;

  useEffect(() => {
    setUserIsPremium(currentPlan === "smile" || currentPlan === "happy");
  }, [currentPlan]);

  // handle avatar
  useEffect(() => {
    setPictureUrl(
      users.filter((user: ICandidate) => user._id !== currentUserId)[0].picture?.thumbnail?.url || defaultAvatar
    );
  }, []);

  // handle candidate status badge
  useEffect(() => {
    setIsActive(
      users.filter((user: ICandidate) => user._id !== currentUserId)[0].activity?.isActive
    );
  }, [users]);

  useEffect(() => {
    // prettier-ignore
    setIsNewMessage(lastConnection.filter((connection) => connection.userId === currentUserId)[0].hasUnreadMessages);
    // prettier-ignore
    setIsWaitingForCandidate(lastConnection.filter((connection) => connection.userId !== currentUserId)[0].needReply);
    // prettier-ignore
    setIsPendingCandidate(lastConnection.filter((connection) => connection.userId === currentUserId)[0].needReply);
  }, [lastConnection]);

  useEffect(() => {
    let preview: string;

    if (lastMessage.content?.message) preview = lastMessage.content.message;
    else if (lastMessage.content?.description) preview = lastMessage.content.description;
    else preview = lastMessage.content?.toString();

    !userIsPremium && lastMessage.author?.userId !== currentUserId
      ? setLastMessagePreview(byPass(preview))
      : setLastMessagePreview(preview);
  }, [userIsPremium, lastMessage.content]);

  const getLastMessageStatus = () => {
    if (isNewMessage) {
      return <div className={"status new-message"}>📩 Nouveau message non lu</div>;
    }

    // prettier-ignore
    if (isWaitingForCandidate) {
      return (<div className={"status waiting-for-candidate"}>En attente d'une réponse du candidat</div>);
    }

    if (isPendingCandidate) {
      return <div className={"status pending-candidate"}>En attente de votre réponse</div>;
    }
  };

  return (
    // prettier-ignore
    <div className={`discussion-thumbail${selected ? " selected" : ""}${isNewMessage ? " new-message" : ""}`}>
      <div className={"last-update-timestamp"}>{formatTimeStamp(lastMessage.date)}</div>

      <div className={"row-container"}>
        <img
          className={"profile-picture"}
          src={pictureUrl}
          alt={"candidate-avatar"}
          onError={() => setPictureUrl(defaultAvatar)}
        />
        {isActive && <span className={"isActive"} />}
        <div>
          <div className={"firstname"}>{candidateFirstName}</div>
          <div className={"last-message-preview"}>
            {lastMessagePreview}
          </div>
        </div>
      </div>

      {getLastMessageStatus()}
    </div>
  );
};

export default DiscussionThumbnail;
