const config = {
  api: "https://prod-backend.appines.com",
  branch_url: "https://api2.branch.io/v1/url",
  branch_key: 'key_live_iar7NEPeZmL2UyoK3kLW4adoCvnY2dEy',
  googlemaps_key: "AIzaSyBtmDhVMjjfvFfOSCCr0O_kKg77fVQQ-sA",
  analytics: {
    trackingId: "G-P5JQ1R5NGB",
    siteSpeedSampleRate: 100,
  },
  ads: {
    trackingId: "AW-792685791",
    conversion_target: "AW-792685791/s2L9COSg2ZkDEN_Z_fkC"
  },
  bing: {
    conversion_id: "134626947"
  },
  facebook: {
    pixel_id: "503596763543098"
  },
  sendinblue_url: "https://api.sendinblue.com/v3",
  sendinblue_key: "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",
  sendinblue_list: 24
};

module.exports = config;
