import React from "react";
import AdsList from "./AdsList/AdsList";
import { AdsState } from "../Ads";

interface Props extends AdsState {
  user: UserProps;
  handleMoveMission: Function;
  handleSelect: Function;
  onMouseEnter: Function;
  onMouseLeave: Function;
  handleGetMission: Function;
  tab: "active" | "expired" | "hired" | "closed";
  isDemoAccount: Boolean;
}

class Missions extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  render() {
    return (
      <section className="ads-page content">
        <div className="container">
          <h2>{`Bonjour ${this.props.user.firstName}`}</h2>
          <div className="office-name">
            <h4>Retrouvez toutes les annonces de votre établissement</h4>
            <span className="establishment-name" title={this.props.user.office.name}>
              {this.props.user.office.name}
            </span>
          </div>
          <ul className="status">
            <li
              className={this.props.tab === "active" ? "blue-link" : "undefined"}
              onClick={() => this.props.handleSelect("tab", "active")}
            >
              {this.props.activeAds.total && this.props.activeAds.total > 1
                ? "Actives "
                : "Active "}
              ({this.props.activeAds.total || 0})
            </li>
            <li
              className={this.props.tab === "hired" ? "blue-link" : "undefined"}
              onClick={() => this.props.handleSelect("tab", "hired")}
            >
              {this.props.hiredAds.total && this.props.hiredAds.total > 1
                ? "Pourvues "
                : "Pourvue "}
              ({this.props.hiredAds.total || 0})
            </li>
            <li
              className={this.props.tab === "expired" ? "blue-link" : "undefined"}
              onClick={() => this.props.handleSelect("tab", "expired")}
            >
              {this.props.expiredAds.total && this.props.expiredAds.total > 1
                ? "Expirées "
                : "Expirée "}
              ({this.props.expiredAds.total || 0})
            </li>
            <li
              className={this.props.tab === "closed" ? "blue-link" : "undefined"}
              onClick={() => this.props.handleSelect("tab", "closed")}
            >
              {this.props.closedAds.total && this.props.closedAds.total > 1
                ? "Supprimées "
                : "Supprimée "}
              ({this.props.closedAds.total || 0})
            </li>
          </ul>
          <div>
            <AdsList
              tab={this.props.tab}
              source={this.props.source}
              substitute={this.props.substitute}
              ads={{
                active: this.props.activeAds,
                hired: this.props.hiredAds,
                expired: this.props.expiredAds,
                closed: this.props.closedAds,
              }}
              user={this.props.user}
              cantEditIsOpen={this.props.cantEditIsOpen}
              publicViewTip={this.props.publicViewTip}
              handleMoveMission={this.props.handleMoveMission}
              handleSelect={this.props.handleSelect}
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              handleGetMission={this.props.handleGetMission}
              isDemoAccount={this.props.isDemoAccount}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Missions;
