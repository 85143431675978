// DEPENDENCIES
import React, { useState } from "react";

// COMPONENTS
import Header from "../../Components/Header/Header";
import Card from "./Card/Card";
import Simulator from "./Simulator/Simulator";

// ASSETS
import orangeBg from "../../assets/img/orange-form-pricing.svg";
import greenBg from "../../assets/img/green-form-pricing.svg";

interface PricingProps {
  user: UserProps;
  email: string;
  currentPlan: string;
}

const Pricing = (props: PricingProps) => {
  const pricing: any = {
    smile: {
      quarterly: { numberOfMonths: 3, amountPerMonth: 89 },
      biAnnual: { numberOfMonths: 6, amountPerMonth: 82 },
      annual: { numberOfMonths: 12, amountPerMonth: 75 },
    },
    happy: {
      quarterly: { numberOfMonths: 3, amountPerMonth: 182 },
      biAnnual: { numberOfMonths: 6, amountPerMonth: 170 },
      annual: { numberOfMonths: 12, amountPerMonth: 145 },
    },
    zen: { cdd: { commission: 8 }, cdi: { commission: 12 } }, // % salaire brut
  };

  const switchValues: any = {
    smile: {
      quarterly: "3 mois",
      biAnnual: "6 mois",
      annual: "12 mois",
    },
    happy: {
      quarterly: "3 mois",
      biAnnual: "6 mois",
      annual: "12 mois",
    },
    zen: {
      cdd: "CDD",
      cdi: "CDI",
    },
  };

  const benefits = {
    smile: [
      "Nombre d'annonces illimitées (CDD, CDI, Stage)",
      "Multiposting (Google Jobs, Facebook, StaffSanté)",
      "Création d'un site carrière",
      "Notification instantanée aux candidats les plus pertinents sur mobile dès diffusion de votre annonce",
      "Accès aux candidatures illimité",
      "Boost des annonces pendant 15 jours",
      "Accès aux statistiques d'annonces avancées",
    ],
    happy: [
      "Nombre d'annonces illimitées (CDD, CDI, Stage)",
      "Multiposting (Google Jobs, Facebook, StaffSanté)",
      "Création d'un site carrière",
      "Notification instantanée aux candidats les plus pertinents sur mobile dès diffusion de votre annonce",
      "Accès aux candidatures illimité",
      "Boost des annonces pendant 15 jours",
      "Accès aux statistiques d'annonces avancées",
      "Contact direct et illimité avec les candidats via notre messagerie sécurisée",
      "Annonces de vacations auprès des professionnels libéraux",
    ],
    zen: [
      {
        Sourcing: ["Optimisation, acquisition, recherche et engagement candidats"],
      },
      {
        Qualification: [
          "Sélection des candidats",
          "Vérification des données candidats",
          "Entretiens individuels",
        ],
      },
      {
        Recrutement: [
          "Présentation d'une short list",
          "Accompagnement prise de décision",
          "Suivi intégration",
        ],
      },
    ],
  };

  const { user, email, currentPlan } = props;

  const [showSimulator, setShowSimulator] = useState(false);
  const [periodicitySmilePlan, setPeriodicitySmilePlan] = useState("quarterly");
  const [periodicityHappyPlan, setPeriodicityHappyPlan] = useState("quarterly");

  const [contractType, setContractType] = useState("cdd");

  const handleShowSimulator = () => {
    setShowSimulator(!showSimulator);
  };

  return (
    <>
      {/* Header */}
      <Header user={props.user} />

      <div className="pricing">
        {/* Background */}
        <img src={orangeBg} alt="orange background" className="orange-background" />
        <img src={greenBg} alt="green background" className="green-background" />

        {/* Content */}
        <div className="content">
          <div className="plans">
            <Card
              plan="smile"
              benefits={benefits}
              pricing={pricing}
              switchValues={switchValues}
              handleShowSimulator={handleShowSimulator}
              setChoice={setPeriodicitySmilePlan}
              choice={periodicitySmilePlan}
              userEmail={email}
              etsFiness={user.office.finess}
              currentPlan={currentPlan}
            />
            <Card
              plan="happy"
              benefits={benefits}
              pricing={pricing}
              switchValues={switchValues}
              handleShowSimulator={handleShowSimulator}
              setChoice={setPeriodicityHappyPlan}
              choice={periodicityHappyPlan}
              userEmail={email}
              etsFiness={user.office.finess}
              currentPlan={currentPlan}
            />
            <Card
              plan="zen"
              benefits={benefits}
              pricing={pricing}
              switchValues={switchValues}
              handleShowSimulator={handleShowSimulator}
              setChoice={setContractType}
              choice={contractType}
              userEmail={email}
              etsFiness={user.office.finess}
              currentPlan={currentPlan}
            />
          </div>

          {/* // TODO : for other professions
          <div className="conditions">
            <div>
              <em>(1)</em>Tarif pour une profession. Profession supplémentaire : 20 € / mois
            </div>
          </div> */}
        </div>
      </div>

      <Simulator
        pricing={pricing}
        switchValues={switchValues}
        showSimulator={showSimulator}
        handleShowSimulator={handleShowSimulator}
        contratTypeSelected={contractType}
        setSelectedType={setContractType}
      />
    </>
  );
};

export default Pricing;
