import React, { Component } from "react";
import Header from "../../Components/Header/Header";
import { AuthConsumer } from "../../Services/Contexts/AuthProvider";
import axios from "axios";
import CheckBox from "../../Components/Forms/Inputs/CheckBox/CheckBox";
import AuthTextInput from "../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import ValidButton from "../../Components/Forms/Buttons/ValidButton/ValidButton";
import Loader from "react-loader-spinner";
import roles from "../../assets/roleMapping.json";

interface State {
  gender: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string;
  email: string | null;
  password1: string | null;
  newPassword: string | null;
  newPassword2: string | null;
  isLoading: Boolean;
}

class MyProfile extends Component<AuthState, State> {
  constructor(props: AuthState) {
    super(props);
    this.state = {
      gender: null,
      firstName: null,
      lastName: null,
      role: '',
      email: null,
      password1: null,
      newPassword: null,
      newPassword2: null,
      isLoading: true,
    };
  }

  getUserDetails = () => {
    axios
      .get(`${global.config.api}/practitioner/${this.props.user.id}`, {
        headers: {
          Authorization: "Bearer " + this.props.user.token
        }
      })
      .then(response => {
        if (response.status === 200) {
          const { firstName, lastName, gender, email, role } = response.data;
          this.setState({
            firstName,
            lastName,
            gender,
            email,
            role,
            isLoading: false
          });
        }
      });
  };

  selectGender = () => {
    if (this.state.gender === "female") {
      this.setState({ gender: "male" });
    } else {
      this.setState({ gender: "female" });
    }
  };

  handleInput = (event: any) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  componentDidMount() {
    this.getUserDetails();
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="profile-page">
          <Header user={this.props.user} />
          <div className="loading-block">
            <Loader type="Puff" color="#25bba5" height={100} width={100} />
          </div>
        </div>
      );
    }

    const typedRoles: { [key: string]: string } = roles;
    return (
      <div className="profile-page">
        <AuthConsumer>
          {(context: AuthProps) => (
            <>
              {(context.state.subscriptionStatus === "never_subscribed" ||
                context.state.subscriptionStatus === "subscription_ended") && (
                <div style={{ height: "50px" }} />
              )}
              <Header user={this.props.user} />
              <div className="content">
                <h2>Mon profil</h2>
                <div className="two-blocks">
                  <div className="left-block">
                    <h4 className="gender-title">Civilité</h4>
                    <div className="gender">
                      <CheckBox
                        className="circle"
                        text="Mme"
                        condition={this.state.gender === "female"}
                        handleCheckBox={this.selectGender}
                      />
                      <CheckBox
                        className="circle"
                        text="M"
                        condition={this.state.gender === "male"}
                        handleCheckBox={this.selectGender}
                      />
                    </div>
                    <div className="inputs">
                      <AuthTextInput
                        name="firstName"
                        placeHolder="Prénom"
                        handleInput={this.handleInput}
                        value={this.state.firstName}
                        condition={!!this.state.firstName}
                      />
                      <AuthTextInput
                        name="lastName"
                        placeHolder="Nom"
                        handleInput={this.handleInput}
                        value={this.state.lastName}
                        condition={!!this.state.lastName}
                      />
                      <AuthTextInput
                        name="role"
                        placeHolder="Fonction"
                        handleInput={this.handleInput}
                        value={typedRoles[this.state.role]}
                        condition={!!this.state.role}
                      />
                      <AuthTextInput
                        name="email"
                        placeHolder="Adresse e-mail"
                        handleInput={this.handleInput}
                        value={this.state.email}
                        condition={!!this.state.email}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={"right-block border"}>
                        <div className="title">
                          <h4>Changement du mot de passe</h4>
                        </div>

                        <div className="inputs">
                          <AuthTextInput
                            name="password1"
                            placeHolder="Mot de passe actuel"
                            type="password"
                            handleInput={this.handleInput}
                            value={this.state.password1}
                            condition={!!(this.state.password1 && this.state.password1.length >= 8)}
                          />
                          <AuthTextInput
                            name="newPassword"
                            placeHolder="Nouveau mot de passe"
                            type="password"
                            handleInput={this.handleInput}
                            value={this.state.newPassword}
                            condition={
                              !!(this.state.newPassword && this.state.newPassword?.length >= 8)
                            }
                          />
                          <AuthTextInput
                            name="newPassword2"
                            placeHolder="Confirmer mot de passe"
                            type="password"
                            handleInput={this.handleInput}
                            value={this.state.newPassword2}
                            condition={
                              !!(
                                this.state.newPassword2 &&
                                this.state.newPassword2.length >= 8 &&
                                this.state.newPassword2 === this.state.newPassword
                              )
                            }
                          />
                        </div>
                  </div>
                </div>
                <div className="submit-changes">
                  <ValidButton
                    condition={!context.state.isLoading}
                    onClick={() =>
                      context.updatePractitionerDetails(
                        this.state.gender,
                        this.state.firstName,
                        this.state.lastName,
                        this.state.role,
                        this.state.password1,
                        this.state.newPassword,
                        this.state.newPassword2
                      )
                    }
                  />
                  {context.state.notification && (
                    <div className="success-message">
                      Vos changements ont bien été pris en compte !
                    </div>
                  )}
                  {context.state.isError && (
                    <div className="error-message">Veuillez vérifier votre mot de passe !</div>
                  )}
                </div>
              </div>
            </>
          )}
        </AuthConsumer>
      </div>
    );
  }
}

export default MyProfile;
