import React from "react";
import classNames from "classnames";

interface Props {
  condition: boolean;

  // optional
  className?: string;
  type?: string;
  value?: any;
  placeHolder?: any;
  name?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: Boolean;
  min?: number;
  max?: number;
  required?: boolean;
  autoComplete?: string;
  isList?: boolean;
  isError?: boolean;
  handleInput?: Function;
  handleToggle?: Function;
  onClick?: Function;
  style?: any;
  listOpen?: boolean;
}

const AuthTextInput = (props: Props) => {
  return (
    <div
      className={classNames(
        "input-block",
        props.listOpen ? "open" : "",
        props.className
      )}>
      <div
        className={`input-label ${!props.value && "hidden"} ${
          props.condition
            ? "blue-label"
            : props.isError
            ? "error-label"
            : "orange-label"
        }`}>
        {props.placeHolder}
      </div>

      <input
        className={
          props.condition
            ? "blue-input"
            : props.isError
            ? "error-input"
            : "orange-input"
        }
        type={props.type}
        value={props.value}
        placeholder={props.placeHolder}
        name={props.name}
        onChange={event => props.handleInput && props.handleInput(event)}
        minLength={props.minLength}
        maxLength={props.maxLength ? props.maxLength : 2048}
        disabled={!!props.disabled}
        min={props.min}
        max={props.max}
        required={props.required}
        autoComplete={props.autoComplete}
        style={props.style}
      />
      {props.isList && (
        <i
          className={classNames(
            "fas fa-caret-down",
            props.condition
              ? "blue-selector"
              : props.isError
              ? "error-selector"
              : "orange-selector"
          )}
          onClick={e => {
            e.stopPropagation();
            props.handleToggle && props.handleToggle();
          }}
        />
      )}
    </div>
  );
};

export default AuthTextInput;
