// images for placeholders
const placeholders = [
 require("../assets/img/substitutes-list/nopic1.svg"),
 require("../assets/img/substitutes-list/nopic2.svg"),
 require("../assets/img/substitutes-list/nopic3.svg"),
 require("../assets/img/substitutes-list/nopic4.svg"),
 require("../assets/img/substitutes-list/nopic5.svg"),
 require("../assets/img/substitutes-list/nopic6.svg"),
 require("../assets/img/substitutes-list/nopic7.svg"),
 require("../assets/img/substitutes-list/nopic8.svg"),
 require("../assets/img/substitutes-list/nopic9.svg"),
 require("../assets/img/substitutes-list/nopic10.svg")];

 export {placeholders};