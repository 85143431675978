import React from "react";
import classNames from "classnames";

interface Props {
  condition: boolean;

  // optional
  className?: string;
  type?: string;
  value?: any;
  placeHolder?: any;
  name?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: Boolean;
  min?: number;
  max?: number;
  required?: boolean;
  autoComplete?: string;
  isSearch?: boolean;
  isList?: boolean;
  isError?: boolean;
  handleInput?: Function;
  handleToggle?: Function;
  onClick?: Function;
  style?: any;
}

const SearchBar = React.forwardRef((props: Props, ref:any) => {
  return (
    <div 
      className={classNames('search', props.condition ? "input-success" : props.isError ? "input-error" : "", props.className)}
      onClick={e => {e.stopPropagation(); props.onClick && props.onClick()}}
    >
      {
        props.isSearch && (
          <i className="fas fa-search search-logo"></i>
        )
      }
      <input
        type={props.type}
        value={props.value}
        placeholder={props.placeHolder}
        name={props.name}
        onChange={event => props.handleInput && props.handleInput(event)}
        minLength={props.minLength}
        maxLength={props.maxLength ? props.maxLength : 2048}
        disabled={!!props.disabled}
        min={props.min}
        max={props.max}
        required={props.required}
        autoComplete={props.autoComplete}
        style={props.style}
        ref={ref}
      />
      {props.isList && (
        <i
          className={classNames("fas fa-caret-down", props.condition ? "blue-selector" : props.isError ? "error-selector" : "orange-selector")}
          onClick={e => { e.stopPropagation(); props.handleToggle && props.handleToggle() }}
        />
      )}
    </div>
  );
});

export default SearchBar;
