import { fetchApi } from "./Contexts/Api";

const endpoint = "/own/missions";

/**
 * It fetches the ads from the API
 * @param params
 * @returns An array of MissionProps objects.
 */
export const getAds = async (params?: {
  adsStatus?: AdsStatus;
  page?: number;
}): Promise<AdsResponse> => {
  const status = params?.adsStatus || "ACTIVE";
  const page = params?.page || 0;

  return (await fetchApi(`${endpoint}?status=${status}&page=${page}`)).data;
};

export const getActiveDemoAds = async (params?: { page: number }): Promise<AdsResponse> => {
  return await getAds({ adsStatus: "INVISIBLE", page: params?.page || 0 });
};

export const getActiveAds = async (params?: { page: number }): Promise<AdsResponse> => {
  return await getAds({ adsStatus: "ACTIVE", page: params?.page || 0 });
};

export const getHiredAds = async (params?: { page: number }): Promise<AdsResponse> => {
  return await getAds({ adsStatus: "HIRED", page: params?.page || 0 });
};

export const getExpiredAds = async (params?: { page: number }): Promise<AdsResponse> => {
  return await getAds({ adsStatus: "EXPIRED", page: params?.page || 0 });
};

export const getClosedAds = async (params?: { page: number }): Promise<AdsResponse> => {
  return await getAds({ adsStatus: "CLOSED", page: params?.page || 0 });
};

export const getAllAds = async (
  isDemoAccount: boolean = false
): Promise<{
  activeAds: AdsResponse;
  hiredAds: AdsResponse;
  expiredAds: AdsResponse;
  closedAds: AdsResponse;
}> => {
  const activeAdsPromise: Promise<AdsResponse> = isDemoAccount
    ? getActiveDemoAds()
    : getActiveAds();
  const hiredAdsPromise: Promise<AdsResponse> = getHiredAds();
  const expiredAdsPromise: Promise<AdsResponse> = getExpiredAds();
  const closedAdsPromise: Promise<AdsResponse> = getClosedAds();

  const promises: Array<Promise<AdsResponse>> = [
    activeAdsPromise,
    hiredAdsPromise,
    expiredAdsPromise,
    closedAdsPromise,
  ];

  return await Promise.all(promises).then((values) => {
    return {
      activeAds: values[promises.indexOf(activeAdsPromise)],
      hiredAds: values[promises.indexOf(hiredAdsPromise)],
      expiredAds: values[promises.indexOf(expiredAdsPromise)],
      closedAds: values[promises.indexOf(closedAdsPromise)],
    };
  });
};

export const deleteAd = (missionId: string): void => {
  (() => fetchApi(`/missions/${missionId}`, "DELETE"))();
};

export const setAdStatusToHired = (
  missionId: string,
  payload?: { substituteId: string | null; source: string | null }
): void => {
  (() => fetchApi(`/mission/${missionId}/hired`, "PUT", payload))();
};

export const getSkills = async (): Promise<Skill[]> => {
  return (await fetchApi("/skills?hideNotValidated=true")).data;
};
