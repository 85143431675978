import React from "react";
import history from "../../history";
import ValidButton from "../../Components/Forms/Buttons/ValidButton/ValidButton";
import palmTree from "../../assets/img/error-page/erreur@3x.png";
import brownStone from "../../assets/img/error-page/brown-stone.png";
import greenLeaves from "../../assets/img/error-page/green-leaves.png";

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-block">
        <img src={palmTree} alt="palmier" className="palm-tree" />
        <img src={greenLeaves} alt="feuilles vertes" className="green-leaves" />
        <img src={brownStone} alt="pierre" className="brown-stone" />
        <h2>On a un petit problème...</h2>
        <div>Il y un petit problème dans notre plantation.</div>
        <div>Revenez par ici un peu plus tard.</div>
        <ValidButton
          text="Retour à l'accueil"
          condition={true}
          onClick={() => history.push("/")}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
