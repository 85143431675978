import React from "react";

const HighlightedTip = (props: { text: any, x?: number, y: number }) => {
  return (
    <div
      className="highlighted-tip"
      style={{ left: (props.x || 0), top: props.y }}
    >
      <div className="text">{props.text}</div>
    </div>
  );
};

export default HighlightedTip;
