// DEPENDENCIES
import React from "react";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import moment from "moment";

// COMPONENTS
import ValidButton from "../../../Components/Forms/Buttons/ValidButton/ValidButton";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import { placeholders } from "../../../Constants/images";
import { renderDiplomaDate, shortenWord } from "../../../Tools/MathTools";
import HoverComponent from "../../../Components/useHover";

moment.locale("fr");

class SubstituteThumbnailLight extends React.Component<any, any> {
  static contextType = AuthContext;
  state = {
    isHover: false,
  };

  getProfessionName = (professionId: string) => {
    const index = this.context.state.allProfessions
      .map((e: Profession) => e._id)
      .indexOf(professionId);

    return this.context.state.allProfessions[index]
      ? this.context.state.allProfessions[index].name.FR
      : "Inconnu";
  };

  render() {
    if (this.props.isLoading) {
      return <div>loading</div>;
    }

    return (
      // SUB THUMBNAIL
      <div
        className="substitute-thumbnail"
        onMouseEnter={() =>
          this.setState({
            isHover: true,
          })
        }
        onMouseLeave={() =>
          this.setState({
            isHover: false,
          })
        }
      >
        {!this.props.substitute?.visibility.isVisible && this.props.substitute?.visibility.to && (
          <div className="availability">
            Profil indisponible jusqu'au{" "}
            {new Date(this.props.substitute?.visibility.to).toLocaleDateString()}
          </div>
        )}
        <div className="firstName">{this.props.substitute.firstName}</div>
        <div className="avatar">
          {this.props.substitute.picture?.thumbnail?.url ? (
            <img src={this.props.substitute.picture?.thumbnail?.url} alt="avatar" />
          ) : (
            <img
              src={
                placeholders[
                  parseInt(
                    this.props.substitute?.lastName
                      ?.charCodeAt(0)
                      .toString()
                      .slice(-1)
                  )
                ]
              }
              alt="monkey-avatar"
            />
          )}
        </div>
        <div className="profession">
          <div className="profession-icon">
            <i className="far fa-hand-paper" />
          </div>
          <p>{this.getProfessionName(this.props.substitute.professionId)}</p>
        </div>

        {this.props.substitute.type === "holder" ? (
          <div className="holder">
            <i className="far fa-building" />
            Titulaire
          </div>
        ) : (
          <div className="graduation">
            <div className="graduation-country">
              {this.props.substitute?.diploma?.country && (
                <ReactCountryFlag
                  svg
                  style={{ marginRight: 5 }}
                  countryCode={this.props.substitute?.diploma?.country || "FR"}
                />
              )}
            </div>
            <div className="graduation-year">
              {this.props.substitute && renderDiplomaDate(this.props.substitute)}
            </div>
          </div>
        )}

        {this.props.substitute.skills && (
          <div className="skills">
            {this.props.substitute.skills.length <= 2 &&
              this.props.substitute.skills.map((skill: any, index: number) => (
                <div key={index} className="skill">
                  {shortenWord(skill.name[this.context.state.language], 15)}
                </div>
              ))}
            {this.props.substitute?.skills?.length > 2 && (
              <div className="skill">
                {shortenWord(
                  this.props.substitute?.skills[0].name[this.context.state.language],
                  15
                )}
              </div>
            )}

            {this.props.substitute?.skills?.length > 2 && (
              <HoverComponent
                text={this.props.substitute.skills.map((skill: any, index: number) => (
                  <div key={index}>{skill.name[this.context.state.language]}</div>
                ))}
              >
                <div className="skill-popup">{`+ ${this.props.substitute.skills.length - 1}`}</div>
              </HoverComponent>
            )}
          </div>
        )}

        {!this.props.substitute.skills && <div className="no-skills" />}

        <ValidButton
          condition={true}
          text="Voir le profil"
          onTouchStart={() =>
            this.setState({
              isHover: true,
            })
          }
          onClick={this.props.handleGetPractitionerDetails}
        />
      </div>
    );
  }
}

export default SubstituteThumbnailLight;
