import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LogRocket from "logrocket";

/**
 * It returns a URLSearchParams object that represents the query string of the current URL
 * @returns A new instance of URLSearchParams.
 */
export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * It returns a debounced value that delays updating the returned value until after a certain amount of time has passed
 * @param {any} value - The value to be debounced.
 * @param {number} delay - The amount of time to wait before updating the state.
 * @returns A debounced value.
 */
export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
};

export const useLogRocketIdentify = (context: AuthProps): void => {
  useEffect(() => {
    LogRocket.identify(context.state.user.id, {
      name: context.state.user.office.name,
      email: context.state.email as string,
      currentPlan: context.state.currentPlan || "unknown",
    });
  }, [
    context.state.user,
    context.state.office?.name,
    context.state.email,
    context.state.currentPlan,
  ]);
};
