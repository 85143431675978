import React, { useState, useEffect, useContext, useRef } from "react";
import classNames from "classnames";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";

interface Props {
  value: any;
  name: string;
  placeHolder: string;
  condition: boolean;
  handleSelect: Function;
  inputPlaceholder: string;

  // optional
  list?: any[];
  listDisabled?: any[];
  listString?: string[];
  classname?: string;
  width?: number;
  sorted?: boolean;
  isError?: boolean;
}

// TODO: onClick is working but need to check everywhere where ListSelect is used to be sure (that's why it' s commented)

/*
    Version 2:
        Display list of objects with language (since lists are dynamic on MongoDB) and use _id field as a reference
            e.g {_id: "1234", name: {FR: "Kinésithérapeute", EN: "physiotherapist"}, ...}
*/
const ListSelectWithInput = React.forwardRef((props: Props, ref: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(AuthContext);
  const language = context.state.language;

  const htmlInput = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const regex = new RegExp(inputValue, "i");

  useEffect(() => {
    if (props.sorted === true) {
      sort(props.list);
    }
  }, [props]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref.current.contains(event?.target)) {
        setIsOpen(false);
      }
    }

    function handleEscapeDown(event: any) {
      if (event.keyCode === 27) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeDown);
    return () => {
      // Unbind the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeDown);
    };
  }, [ref]);

  const sort = (array: any) => {
    return array.sort(function(a: any, b: any) {
      if (a.name[language] < b.name[language]) {
        return -1;
      }
      if (a.name[language] > b.name[language]) {
        return 1;
      }
      return 0;
    });
  };

  const sanitize = (array: any) => {
    let tmpArray = [];
    for (const item of array) {
      if (item !== null) {
        tmpArray.push(item);
      }
    }
    return tmpArray;
  };

  return (
    <div
      className={classNames(
        "ListSelect",
        props.classname,
        props.isError ? "error" : props.value || isOpen ? "success" : ""
      )}
      style={{ width: props.width }}
      ref={ref}
      onClick={() => setTimeout(() => htmlInput.current?.focus(), 100)}>
      {props.condition && (
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="ListSelect__container">
          <i className="fas fa-caret-down ListSelect__triangle" />

          {props.value ? (
            <>
              <span className="value">{props.value}</span>
              {isOpen && (
                <span className="placeholder">
                  <input
                    className="ListSelect__input"
                    type="text"
                    ref={htmlInput}
                    onClick={e => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    placeholder={props.inputPlaceholder}
                  />
                </span>
              )}
            </>
          ) : (
            <span className="placeholder">
              {!isOpen ? (
                props.placeHolder
              ) : (
                <input
                  className="ListSelect__input"
                  type="text"
                  ref={htmlInput}
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  placeholder={props.inputPlaceholder}
                />
              )}
            </span>
          )}

          {isOpen && (
            <div className="ListSelect__list">
              {props.list && (
                <>
                  <div
                    className="ListSelect__list__item"
                    key="null"
                    onClick={() => props.handleSelect(props.name, null)}>
                    Non renseignée
                  </div>

                  {props.list
                    ?.filter(item => regex.test(item.name[language]))
                    .map((item, index) => (
                      <div
                        className="ListSelect__list__item"
                        key={index}
                        onClick={() => {
                          props.handleSelect(props.name, item);
                          setInputValue("");
                        }}>
                        {item.name[language]}
                      </div>
                    ))}

                  {props.listDisabled
                    ?.filter(item => regex.test(item.name[language]))
                    .map((item, index) => (
                      <div
                        className="ListSelect__list__item disabled"
                        key={index}>
                        {item.name[language]}
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default ListSelectWithInput;
