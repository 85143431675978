// prettier-ignore
import React, { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";

interface MessageInputFieldProps {
  inputValue: string;
  handleInputChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleSendMessage: any;
  handlePressKey: KeyboardEventHandler;
  cursorPosition: number;
  placeholder?: string;
  isSupport?: Boolean;
}

const MessageInputForm = (props: MessageInputFieldProps) => {
  const {
    inputValue,
    handleInputChange,
    handleSendMessage,
    handlePressKey,
    cursorPosition,
    placeholder,
    isSupport,
  } = props;

  const context = useContext(AuthContext);

  const newMessageTextAreRef = useRef<any>(null);

  useEffect(() => {
    if (newMessageTextAreRef && newMessageTextAreRef.current) {
      newMessageTextAreRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [inputValue]);

  if (context.state.currentPlan === "smile" || context.state.currentPlan === "happy" || isSupport)
    return (
      <form
        className={"message-input-form"}
        onKeyPress={handlePressKey}
        onSubmit={handleSendMessage}
      >
        <textarea
          ref={newMessageTextAreRef}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
        <button type={"submit"} disabled={!inputValue.trim()}>
          Envoyer
        </button>
      </form>
    );

  return (
    <form
      className={"message-input-form"}
      onClick={() =>
        context.popBarMessage(
          "Vous devez souscrire à l'offre Smile ou Happy pour reprendre la discussion"
        )
      }
    >
      <textarea value={"🔒"} disabled={true} />
      <button className={"forbidden"} type={"submit"} disabled={true}>
        Envoyer
      </button>
    </form>
  );
};

export default MessageInputForm;
