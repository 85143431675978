import React from "react";

interface Props {
  className: string;
  condition: boolean;
  text: string;

  // optional
  className2?: string;
  onClick?: any;
  iconLeft?: any;
  iconRight?: any;
}

const Button = (props: Props) => {
  return (
    <div className="button">
      {props.condition ? (
        <button className={props.className} onClick={props.onClick || undefined}>
          {props.iconLeft}
          {props.text}
          {props.iconRight}
        </button>
      ) : (
        <button className={props.className2} disabled>
          {props.iconLeft}
          {props.text}
          {props.iconRight}
        </button>
        )}
    </div>
  );
};

export default Button;
