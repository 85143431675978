import React from "react";
//@ts-ignore
import ReactFileReader from "react-file-reader";
import Header from "../../Components/Header/Header";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import office11 from "../../assets/img/office11.png";
import office21 from "../../assets/img/office21.png";
import office22 from "../../assets/img/office22.png";
import office31 from "../../assets/img/office31.png";
import office32 from "../../assets/img/office32.png";
import office41 from "../../assets/img/office41.png";
import office42 from "../../assets/img/office42.png";
import office51 from "../../assets/img/office51.png";
import office52 from "../../assets/img/office52.png";
import dashLine from "../../assets/img/etab-dashline@3x.png";
import { AuthConsumer } from "../../Services/Contexts/AuthProvider";
import allProfessions from "../../Constants/professions";
import GeneralInfos, { GeneralInfosState } from "./generalInfos";
import Organisation, { OrganisationState } from "./Organisations";
import Practices, { PracticesState } from "./Practices";
import ServicesAndAdvantages, { ServicesState } from "./ServicesAndAdvantages";
import Equipements, { EquipementsState } from "./Equipements";
import prefixes from "../../assets/prefixes.json";
import { PNF, phoneUtil } from "../../Tools/MathTools";
import PreviewPictures from "./PreviewPictures";
import { updateUserSendinBlue } from "../../Services/Contexts/SendinBlue";
import { fetchApi } from "../../Services/Contexts/Api";
import { convertTime } from "../../Tools/MathTools";
import imageCompression from "browser-image-compression";

export interface OfficeState
  extends ServicesState,
    OrganisationState,
    GeneralInfosState,
    PracticesState,
    EquipementsState {
  _id?: string;
  isPro?: boolean;
  category: string;
  user: UserProps | null;
  offices: OfficeProps[];
  pictures: IPicture[];
  logo: IPicture | null;
  description: string | null;
  slug: String;
  members: UserProps[];
  admins: UserProps[];
  superAdmins: UserProps[];
  isLoading: boolean;
  isPublicViewUrlLoading: boolean;
  picturesLoading: boolean;
  practitionnersTotal: null;
  nightSlots: number;
  daySlots: number;
  collegues: string[];
  isSaving: boolean;
  hasSaved: boolean;
  equipmentDescription: string;
}

export interface ProfessionProps {
  profession: string;
  quantity: number;
}

class OfficePage extends React.Component<AuthState, OfficeState> {
  public serviceAndAdvantageRef: any;

  constructor(props: AuthState) {
    super(props);

    this.state = {
      _id: undefined,
      isPro: undefined,
      category: "",
      user: null,
      offices: [],
      name: null,
      pictures: [],
      logo: null,
      parentOffice: null,
      number: null,
      street: null,
      zipCode: null,
      city: null,
      countryCode: "",
      phoneNumber: null,
      description: null,
      editorStateTechnical: undefined,
      editorState: undefined,
      slug: "",
      members: [],
      admins: [],
      superAdmins: [],
      isLoading: true,
      isPublicViewUrlLoading: false,
      picturesLoading: false,
      practitionnersTotal: null,
      nightSlots: 0,
      daySlots: 0,
      value1: 50,
      value2: 30,
      value3: 20,
      collegues: [],
      hasParking: false,
      hasSecretary: false,
      isHalfHourSession: false,
      offNomenclature: false,
      hasExtraFees: false,
      technicalDescription: null,
      equipmentDescription: "",
      hasBalneo: false,
      softwares: [],
      placesInDayHospital: 0,
      beds: 0,
      professions: [
        {
          profession: allProfessions[0].front,
          quantity: 0
        }
      ],
      isSaving: false,
      hasSaved: false,
      isUrbanFreeZone: false,
      isRuralDevelopmentZone: false,
      socialLinks: {
        facebook: "",
        linkedin: "",
        web: "",
        youtube: "",
        twitter: "",
        instagram: ""
      },
      openingHours: null
    };
    this.serviceAndAdvantageRef = React.createRef();
  }

  getOfficeDetails = (officeIds: string) => {
    fetchApi(`/offices?officeIds=${officeIds}`).then(response => {
      const contentBlock = response.data[0].richDescription
        ? htmlToDraft(response.data[0].richDescription)
        : null;
      let editorState = null;
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }

      const contentBlockTechnical = response.data[0].richEquipmentDescription
        ? htmlToDraft(response.data[0].richEquipmentDescription)
        : null;
      let editorStateTechnical = null;
      if (contentBlockTechnical) {
        const contentState = ContentState.createFromBlockArray(
          contentBlockTechnical.contentBlocks
        );
        editorStateTechnical = EditorState.createWithContent(contentState);
      }

      // countryCode computation
      // TODO: still work ?
      let countryCode = "+33";
      if (response.data[0].phoneNumber) {
        let allDialCodes = prefixes.map(prefix => prefix.dial_code);
        let joinedDialCodes = allDialCodes.join("|\\");
        joinedDialCodes = `^(\\${joinedDialCodes})`;
        const regex = new RegExp(joinedDialCodes);

        const match = response.data[0].phoneNumber.toString(10).match(regex);
        countryCode = match ? match[0] : "+33";
      }

      // convert opening hours to front
      let openingHours = response.data[0].openingHours;
      for (const key in openingHours) {
        if (Object.prototype.hasOwnProperty.call(openingHours, key)) {
          const day = openingHours[key];

          if (day) {
            day.map((part: any, index: any) => {
              let startConverted = convertTime(part.start);
              let endConverted = convertTime(part.end);

              return (openingHours[key][index] = {
                start: startConverted,
                end: endConverted
              });
            });
          }
        }
      }

      // state update
      let data = response.data[0];
      const { address, patientBase, collegues, ...office } = data;
      this.setState(
        {
          ...office,
          offices: response.data,
          countryCode,
          phoneNumber: office.phoneNumber
            ? office.phoneNumber.toString(10).substring(3)
            : null,
          description: office.richDescription || null,
          editorState: editorState || undefined,
          editorStateTechnical,
          professions: collegues,
          // address =>
          number: address.number,
          street: address.street,
          zipCode: address.cityInfo
            ? address.cityInfos.substring(0, 5)
            : address.zipCode,
          city: address.cityInfo
            ? address.cityInfos.substring(6)
            : address.city,
          //patientBase =>
          value1: patientBase?.office || 0,
          value2: patientBase?.atHome || 0,
          value3: patientBase?.other || 0
        },
        () => this.getMembers()
      );
    });
  };

  getMembers = () => {
    let membersArray: UserProps[] = [];
    let adminsArray: UserProps[] = [];
    let superAdminArray: UserProps[] = [];
    this.state.offices[0]?.rights.map(
      member =>
        axios.get(`${global.config.api}/practitioner/${member.userId}`, {
          headers: {
            Authorization: "Bearer " + this.props.user.token
          }
        }),

      this.setState(
        {
          members: membersArray,
          admins: adminsArray,
          superAdmins: superAdminArray,
          isLoading: false
        },
        () => this.getParentOffice()
      )
    );
  };

  getParentOffice = () => {
    if (this.state.parentOffice) {
      fetchApi(`/pro/office?finess=${this.state.parentOffice}`).then(response =>
        this.setState({ parentOffice: response.data.nameOffice })
      );
    }
  };

  handleLogo = (files: any) => {
    fetchApi(`/offices/${this.state.offices[0]._id}/uploadLogo`, "post", {
      data: files.base64
    }).then(response => {
      if (response?.status === 200) {
        updateUserSendinBlue(this.props.user?.id);
        this.setState({ logo: response.data.logo });
      }
    });
  };

  handleFiles = async (files: any) => {
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1920
    };

    // Loop through all images given with File format
    for (const file in files.fileList) {
      if (Number.isInteger(parseInt(file))) {
        try {
          this.setState({
            picturesLoading: true
          });

          // Compress the image in File/Blob format
          const compressedFile = await imageCompression(
            files.fileList[file],
            options
          );

          // Get the base64 string of the image from the Blob format
          await imageCompression
            .getDataUrlFromFile(compressedFile)
            .then(async res => {
              await fetchApi(
                `/offices/${this.state.offices[0]._id}/upload`,
                "post",
                { data: res }
              ).then(response => {
                this.setState({
                  pictures: response.data.pictures,
                  picturesLoading: false
                });

                updateUserSendinBlue(this.props.user?.id);
              });
            });
        } catch (error) {
          this.setState({
            picturesLoading: false
          });
        }
      }
    }
  };

  handleRemoveFiles = (pictureId: string) => {
      axios
        .post(
          `${global.config.api}/offices/${this.state.offices[0]?._id}/delete-img`,
          { pictureId },
          {
            headers: {
              Authorization: "Bearer " + this.props.user.token
            }
          }
        )
        .then(_ => {
          let filesArray = [...this.state.pictures];
          const index = filesArray.findIndex(picture => picture._id === pictureId);
          if (index !== -1) {
            filesArray.splice(index, 1);
            this.setState({ pictures: filesArray });
          }
          updateUserSendinBlue(this.props.user?.id);
        });
  };

  handleUpdateOffice = (state: OfficeState) => {
    // CONVERT OPENING HOURS TO BACK
    let openingHours = state.openingHours;
    for (const key in openingHours) {
      if (Object.prototype.hasOwnProperty.call(openingHours, key)) {
        const day = openingHours[key];

        if (day) {
          day.map((part: any, index: any) => {
            let startConverted = convertTime(part.start, true);
            let endConverted = convertTime(part.end, true);

            openingHours![key]![index] = {
              start: startConverted,
              end: endConverted
            };

            return openingHours;
          });
        }
      }
    }
    state.openingHours = openingHours;

    this.setState(
      {
        ...state
      },
      () => this.UpdateOffice()
    );
  };

  UpdateOffice = async () => {
    const phoneNumber = this.state.phoneNumber
      ? phoneUtil.format(
          phoneUtil.parseAndKeepRawInput(
            this.state.phoneNumber,
            prefixes.find(prefix => prefix.dial_code === this.state.countryCode)
              ?.code
          ),
          PNF.E164
        )
      : "";
    await this.setState({ isSaving: true });
    const {
      displayDetails,
      displayConvention,
      ...serviceAndAdvantageState
    } = this.serviceAndAdvantageRef.current.state;

    fetchApi(`/offices/${this.state.offices[0]._id}`, "put", {
      name: this.state.name,
      parentOfficeName: this.state.parentOffice,
      address: {
        number: this.state.number,
        street: this.state.street,
        zipCode: this.state.zipCode,
        city: this.state.city,
        country: "FRANCE"
      },
      phoneNumber: phoneNumber,
      description: this.state.description,
      collegues: this.state.professions,
      hasExtraFees: this.state.hasExtraFees,
      hasBalneo: this.state.hasBalneo,
      offNomenclature: this.state.offNomenclature,
      isHalfHourSession: this.state.isHalfHourSession,
      patientBase: {
        office: this.state.value1,
        atHome: this.state.value2,
        other: this.state.value3
      },
      softwares: this.state.softwares,
      equipmentDescription: this.state.technicalDescription,
      isUrbanFreeZone: this.state.isUrbanFreeZone,
      isRuralDevelopmentZone: this.state.isRuralDevelopmentZone,
      placesInDayHospital: this.state.placesInDayHospital,
      beds: this.state.beds,
      socialLinks: this.state.socialLinks,
      openingHours: this.state.openingHours,
      ...serviceAndAdvantageState
    }).then(response => {
      updateUserSendinBlue(this.props.user?.id);
      if (response?.status === 200) {
        this.setState({
          isSaving: false,
          hasSaved: true
          // displayPractitionnersList: false
        });

        setTimeout(() => this.setState({ hasSaved: false }), 3000);
      }
    });
  };
  
  handleClickPublicView = async () => {
    this.setState({ isPublicViewUrlLoading: true });

    fetchApi(`/offices/public-url/${this.state.slug}`)
      .then((response) => {
        window.open(response.data.url, "_blank");
        this.setState({ isPublicViewUrlLoading: false });
      })
      .catch(() => this.setState({ isPublicViewUrlLoading: true }));
  };

  render() {
    const totalCompletion =
      +!!this.state.name +
      +!!this.state.countryCode +
      +!!this.state.street +
      +!!this.state.zipCode +
      +!!this.state.city +
      +(this.state.softwares.length > 0) +
      +(
        (this.state.equipmentDescription &&
          this.state.equipmentDescription.trim() !== "" &&
          !!this.state.equipmentDescription) ||
        !!this.state.technicalDescription
      ) +
      +!!this.state.logo +
      +(this.state.pictures.length > 3) +
      +!!this.state.hasParking +
      +!!this.state.hasSecretary;

    if (this.state.isLoading) {
      return (
        <section className="spinner-page">
          <Header user={this.props.user} />
          <AuthConsumer>
            {(context: AuthProps) => (
              <div>
                {(context.state.subscriptionStatus === "never_subscribed" ||
                  context.state.subscriptionStatus ===
                    "subscription_ended") && <div style={{ height: "30px" }} />}
              </div>
            )}
          </AuthConsumer>
          <div>
            <Loader type="Puff" color="#25bba5" height={100} width={100} />
          </div>
        </section>
      );
    } else {
      return (
        <div>
          <Header user={this.props.user} />
          <AuthConsumer>
            {(context: AuthProps) => (
              <div>
                {!context.state.trialPeriod && !context.state.subscribed && (
                  <div style={{ height: "30px" }} />
                )}
              </div>
            )}
          </AuthConsumer>
          <section
            className="office-page content"
            // onClick={() => this.handleCloseAllLists()}
          >
            <a
              onClick={this.handleClickPublicView}
              className="public-office-button">
              {this.state.isPublicViewUrlLoading
                ? <Loader type={"Puff"} height={50} width={50} color={"white"}/>
                : <p>Consultez la page publique de votre établissement</p>
              }
            </a>
            <div className="container">
              <h2>{`Bonjour ${this.state.user?.firstName}`}</h2>
              <h4>
                Complétez la fiche de votre établissement pour valoriser votre
                image employeur.
              </h4>
              <div className="office-completion">
                <img src={office11} alt="office11" />
                {totalCompletion >= 7 ? (
                  <img src={office21} alt="office21" />
                ) : (
                  <img src={office22} alt="office22" />
                )}
                {totalCompletion >= 9 ? (
                  <img src={office31} alt="office31" />
                ) : (
                  <img src={office32} alt="office32" />
                )}
                {totalCompletion >= 10 ? (
                  <img src={office41} alt="office41" />
                ) : (
                  <img src={office42} alt="office42" />
                )}
                {totalCompletion === 11 ? (
                  <img src={office51} alt="office51" />
                ) : (
                  <img src={office52} alt="office52" />
                )}
                <img src={dashLine} alt="dash line" className="dash-line" />
              </div>
              <h4>
                Plus le profil est complet, plus l’établissement est visible
                auprès des candidats.
              </h4>
              <div className="green-left-scale">
                <div className="step">
                  <div className="green-circle" />
                  <div
                    className={
                      this.state.picturesLoading
                        ? "green-bar-loading"
                        : "green-bar"
                    }
                  />
                  <h3>
                    Établissement
                    <span className="establishment-name">
                      {this.state.name}
                    </span>
                  </h3>
                </div>
                <div className="step">
                  <div className="green-circle" />
                  <h3>Rajoutez des photos de votre établissement</h3>
                  <div className="pictures">
                    <div className="logo-and-pictures">
                      {this.state.logo?.thumbnail?.url ? (
                        <ReactFileReader
                          fileTypes={[".png", ".jpg"]}
                          base64={true}
                          multipleFiles={false}
                          handleFiles={this.handleLogo}>
                          <div className="add-logo">
                            <img src={this.state.logo.thumbnail.url} alt="main-logo" />
                          </div>
                        </ReactFileReader>
                      ) : (
                        <ReactFileReader
                          fileTypes={[".png", ".jpg"]}
                          base64={true}
                          multipleFiles={false}
                          handleFiles={this.handleLogo}>
                          <div className="add-logo">
                            <i className="fas fa-camera" />
                            <div>Logo</div>
                          </div>
                        </ReactFileReader>
                      )}
                      {this.state.pictures.length < 12 ? (
                        <ReactFileReader
                          fileTypes={[".png", ".jpg"]}
                          base64={true}
                          multipleFiles={true}
                          handleFiles={
                            this.state.pictures.length < 13 && this.handleFiles
                          }>
                          <div className="add-picture">
                            <i className="fas fa-camera" />
                            <div>Photos de l'établissement</div>
                          </div>
                        </ReactFileReader>
                      ) : (
                        <div className="no-more-picture">
                          <i className="fas fa-camera" />
                          <div>Nombre maximal de photos atteint !</div>
                        </div>
                      )}
                      <div className="total-pictures">
                        <div className="total-number">
                          {this.state.pictures.length}
                        </div>
                        <div>photo(s)</div>
                      </div>
                    </div>
                    <div className="previews">
                      <PreviewPictures
                        pictures={this.state.pictures}
                        handleFiles={this.handleFiles}
                        handleRemoveFiles={this.handleRemoveFiles}
                      />
                    </div>
                    {this.state.picturesLoading && (
                      <div className="picturesLoading">
                        <Loader
                          type="Puff"
                          color="#25bba5"
                          height={30}
                          width={30}
                        />
                        <p>
                          Traitement des images en cours... <br />
                          <span className="loading-msg">
                            Veuillez patienter
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="step">
                  <div className="green-circle" />
                  <h3>Complétez la fiche de votre établissement</h3>
                </div>

                <GeneralInfos
                  {...this.state}
                  updateGeneralInfos={this.handleUpdateOffice}
                />
                <Organisation
                  {...this.state}
                  handleUpdateOffice={this.handleUpdateOffice}
                />
                <Practices
                  {...this.state}
                  updateOffice={this.handleUpdateOffice}
                />
                <ServicesAndAdvantages
                  {...this.state}
                  ref={this.serviceAndAdvantageRef}
                  updateServices={this.handleUpdateOffice}
                />
                <Equipements
                  {...this.state}
                  updateOffice={this.handleUpdateOffice}
                />
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
  componentDidMount() {
    fetchApi(`/practitioner/${this.props.user?.id}`).then(response =>
      this.setState({ user: response.data }, () => {
        this.getOfficeDetails(
          encodeURI(JSON.stringify(this.state.user?.officeIds))
        );
      })
    );
  }
}

export default OfficePage;
