import React, { useContext, useEffect, useState, useRef } from "react";
import Tips from "../../../../Components/Tips/Tips";
import {
  NewMissionContext,
  NewMissionConsumer
} from "../../../../Services/Contexts/NewMissionProvider";
import { Link } from "react-router-dom";
import AuthTextInput from "../../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";
import { missionTypes } from "../../../../Constants/mission";
import InfoBox from "../../../../Components/InfoBox/InfoBox";
import { fetchApi } from "../../../../Services/Contexts/Api";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import UseHoverComponent from "../../../../Components/useHover";
import HoverErrorComponent from "../../../../Components/useHoverError";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import { ReasonsBlock } from "./ReasonsBlock";
import ListSelectWithInput from "../../../../Components/Forms/Inputs/ListSelectWithInput/ListSelectWithInput";

interface Props {
  office: UserOfficeProps;
  isEdit: Boolean;
  userCurrentPlan: String;
}

const WhereAndWhat = (props: Props) => {
  const context = useContext(NewMissionContext);
  const contextAuth = useContext(AuthContext);

  const [count, setCount] = useState(0);
  const [professions, setProfessions] = useState(contextAuth.state.allProfessions);
  const [isValid, setIsValid] = useState(false);

  const _profession = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchApi("/subs-count").then(res => {
      setCount(Math.ceil(res.data.result / 100) * 100);
    });

    if (props.isEdit) {
      // Allow to check errors thanks to validateMission THEN set isValid to true
      // False because in "edit mode" it is for sure correct
      context.displayErrors(true);
    }

    fetchApi("/professions").then(res => {
      setProfessions(
        res.data.filter(
          // to remove duplicate professions
          (element: any, index: number) =>
            res.data.map((element: any) => element._id).indexOf(element._id) ===
            index
        )
      );
    });
  }, []);

  useEffect(() => {
    setIsValid(
      !!context.state.errors?.slide1 &&
        Object.keys(context.state.errors?.slide1).length === 0
    );
  });

  const handleSelectProfession = (name: string, value: any) => {
    context.handleSelect(name, value);
  };

  return (
    <NewMissionConsumer>
      {(context: newMissionProps) => (
        <section
          className="slide-one"
          onClick={
            context.state.displayReasons
              ? () => context.handleToggle("displayReasons")
              : context.state.neoTipIsOpen
              ? () => context.handleToggle("neoTipIsOpen")
              : undefined
          }>
          <div className="content">
            <h3>
              {props.isEdit ? "Modifiez votre annonce" : "Nouvelle annonce"}
            </h3>

            {!context.state.infoBoxSeen && (
              <InfoBox
                closeBox={context.handleToggle}
                text="Les candidats les plus pertinents seront notifiés à la diffusion de l’annonce, veillez à ce qu’elle soit complète et attractive"
              />
            )}

            <div className="block block-office">
              <h4>Pour quel établissement ?</h4>
              <AuthTextInput
                value={props.office.name}
                type="text"
                name="office"
                handleInput={context.handleInput}
                minLength={1}
                condition={true}
                disabled={true}
              />
            </div>

            <div
              onMouseEnter={() => context.setTipOpen("tip2")}
              onMouseLeave={() => context.setTipOpen("tip1")}
              className="block block-profession">
              <h4>Que recherchez-vous ?</h4>

              <ListSelectWithInput
                value={
                  context.state.profession &&
                  context.state.profession.name[contextAuth.state.language]
                    ? context.state.profession.name[contextAuth.state.language]
                    : ""
                }
                name="profession"
                placeHolder="Profession"
                condition={true}
                list={professions.filter(
                  (profession: any) => profession.status.FRANCE !== "WAITING"
                )}
                listDisabled={professions
                  .filter(
                    (profession: any) => profession.status.FRANCE === "WAITING"
                  )
                  .map((profession: any) => {
                    const waitingLabel = " (Bientôt ⏳)";

                    if (profession.name.FR.indexOf(waitingLabel) === -1) {
                      profession.name.FR += waitingLabel;
                    }

                    return profession;
                  })}
                handleSelect={handleSelectProfession}
                width={435}
                sorted={true}
                isError={
                  !!context.state?.errors?.slide1.profession &&
                  context.state.displayErrors
                }
                ref={_profession}
                inputPlaceholder={"Profession"}
              />
              {context.state.displayErrors && (
                <div className="error">
                  {context.state?.errors?.slide1.profession}
                </div>
              )}
            </div>

            <div className="block block-reason">
              <ReasonsBlock {...context} />
            </div>

            {!props.isEdit && (
              <div className="block block-mission-type">
                <h4>Type de poste</h4>
                <div className="mission-type">
                  {missionTypes.map((missionType, index) => {
                      if (props.userCurrentPlan === "happy" || missionType.front !== "Vacation") {
                        return (
                          <CheckBox
                            key={index}
                            isError={
                              !!context.state.errors?.slide1.missionType &&
                              context.state.displayErrors
                            }
                            name="missionType"
                            value={missionType.back}
                            text={missionType.front}
                            className="circle"
                            handleCheckBox={props.isEdit ? undefined : context.handleCheckBox}
                            condition={context.state.missionType === missionType.back}
                            forbidden={missionType.IsForbidden}
                            newFeature={!!missionType.newFeature}
                          />
                        )
                      }
                      
                      if (props.userCurrentPlan !== "happy" && missionType.front === "Vacation") {
                        return (
                          <UseHoverComponent text={"🔒 Uniquement pour l'offre Happy"}>
                            <CheckBox
                              key={index}
                              isError={
                                !!context.state.errors?.slide1.missionType &&
                                context.state.displayErrors
                              }
                              name="missionType"
                              value={missionType.back}
                              text={missionType.front}
                              className="circle"
                              handleCheckBox={props.isEdit ? undefined : context.handleCheckBox}
                              condition={context.state.missionType === missionType.back}
                              forbidden={true}
                              newFeature={!!missionType.newFeature}
                            />
                          </UseHoverComponent>
                        );
                      }
                    }
                    
                  )}
                </div>
              </div>
            )}

            <div className="errors-block">
              {context.state.displayErrors && (
                <div className="error">
                  {context.state?.errors?.slide1.missionType}
                </div>
              )}
            </div>

            <HoverErrorComponent
              className="validate-terms"
              setIsOpen={context.displayErrors}>
              <ValidButton
                condition={isValid}
                onClick={context.handleNextPage}
              />
            </HoverErrorComponent>
          </div>

          <Tips
            {...context.state}
            onMouseEnter={context.setTipOpen}
            severalTips={false}
            smallText={
              <div className="small-text">
                <span> </span>
                <Link to="/authentification">
                  <span className="green-text"> </span>
                </Link>
              </div>
            }
            texts={
                 [
                    `App’Ines est disponible uniquement aux masseurs-kinésithérapeutes pour vous assurer une base de données de ${count} candidats.`,
                    "App’Ines sera bientôt ouvert aux autres professions de santé. Patience ..."
                  ]
            }
          />
        </section>
      )}
    </NewMissionConsumer>
  );
};

export default WhereAndWhat;
