// DEPENDENCIES
import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import dayjs from "dayjs";

// COMPONENTS
import TopDiscussionThumbnail from "../TopDiscussionThumbnail/TopDiscussionThumbnail";
import MissionThumbail from "../MissionThumbnail/MissionThumbail";
import AdminMessageThumbnail from "../AdminMessageThumbnail/AdminMessageThumbnail";
import MessageThumbnail from "../MessageThumbnail/MessageThumbnail";
import MessageInputForm from "../MessageInputForm/MessageInputForm";

// ASSETS
import formatTimeStamp from "../../../Tools/formatTimeStamp";
import { ICandidate, IDiscussion, IMessage } from "../IDiscussions";
import { fetchApi } from "../../../Services/Contexts/Api";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import { byPass } from "../../../Tools/StringTools";

interface CurrentDiscussionProps {
  selectedCandidate: ICandidate;
  selectedDiscussion: IDiscussion;
  currentUserId: string;
  candidateAvatar: string;
  officeLogo: string;
  socket: any;
}

export const generateString = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_&é'(§è!çà)\"";

  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 36; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const calculateResponseTime = (time: number) => {
  const duration = dayjs.duration(time);
  if (duration.asHours() < 24) return `${Math.ceil(duration.asHours())}h`;
  else if (duration.asDays() < 7) return `${Math.ceil(duration.asDays())}j`;
  else if (duration.asWeeks() < 4) {
    const week = Math.ceil(duration.asWeeks());
    return `${week} semaine${week > 1 ? "s" : ""}`;
  } else return "+ 1 mois";
};

export const handlePressKey = (
  event: KeyboardEvent | any,
  newMessage: string,
  setNewMessage: Function,
  setCursorPosition: Function
) => {
  // handle break line
  if (event.key === "Enter") {
    const cursorIndex = event.target.selectionStart;

    setNewMessage(newMessage.slice(0, cursorIndex) + "\n" + newMessage.slice(cursorIndex));
    setCursorPosition(cursorIndex + 1);
  }
};

const CurrentDiscussion = (props: CurrentDiscussionProps) => {
  const {
    selectedCandidate,
    selectedDiscussion,
    currentUserId,
    candidateAvatar,
    officeLogo,
    socket,
  } = props;

  const context = useContext(AuthContext);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [oldMessagesLoading, setOldMessagesLoading] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [page, setPage] = useState(0);
  const [userIsPremium, setUserIsPremimum] = useState(false);

  const lastMessageRef = useRef<HTMLDivElement>(null);
  const firstMessageRef = useRef<HTMLDivElement>(null);
  const currentDiscussionRef = useRef<HTMLDivElement>(null);

  const handleScrollToLastMessage = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSendMessage = (event: React.FormEvent<any>) => {
    event.preventDefault(); // prevent refresh (onSubmit event)

    if (newMessage !== "") {
      const randomString = generateString();

      const newMessageBody = {
        discussionId: selectedDiscussion._id,
        message: newMessage,
        returnId: randomString,
      };

      const newMessageState = {
        _id: randomString,
        discussionId: selectedDiscussion._id,
        type: "text",
        content: newMessage,
        author: {
          userId: currentUserId,
        },
        date: new Date(),
        returnId: randomString,
        sendingInProgress: true,
      };

      const newMessageList: any[] = messages ? [...messages] : [];

      newMessageList.unshift(newMessageState);

      fetchApi("/messages", "POST", newMessageBody).then(() =>
        handleScrollToLastMessage(lastMessageRef)
      );

      setMessages(newMessageList);
      setNewMessage("");
      handleScrollToLastMessage(lastMessageRef);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    setNewMessage(event.target.value);
    setCursorPosition(event.target.selectionStart);
  };

  const handleMessageThumbnailHTMLRef = (index: number) => {
    if (index === 0) return lastMessageRef;

    if (index === messages.length - 1) return firstMessageRef;

    return undefined;
  };

  // Handle scroll to last message after fetching data
  useEffect(() => {
    if (lastMessageRef.current) {
      handleScrollToLastMessage(lastMessageRef);
    }
  }, []);

  // Fetch messages of selected discussion and update messages state
  useEffect(() => {
    if (!noMoreMessages) {
      setOldMessagesLoading(true);

      fetchApi(`/messages/${selectedDiscussion?._id}?page=${page}`).then((response) => {
        setMessages(messages.concat(response.data));
        setIsLoading(false);
        setOldMessagesLoading(false);

        if (response.data.length === 0 || response.data.length < 20) {
          setNoMoreMessages(true);
        }
      });
    }
  }, [page]);

  // Handle infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        firstMessageRef.current &&
        firstMessageRef.current.getBoundingClientRect().y > 0 &&
        !oldMessagesLoading &&
        !noMoreMessages
      ) {
        setOldMessagesLoading(true);
        setPage(page + 1);
      }
    };

    currentDiscussionRef.current &&
      currentDiscussionRef.current.addEventListener("scroll", handleScroll);
    return () => {
      currentDiscussionRef.current &&
        currentDiscussionRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [page, oldMessagesLoading, noMoreMessages]);

  // Socket.io : new message listener
  useEffect(() => {
    if (!isLoading) {
      socket.on("newMessage", (res: { message: IMessage; returnId: string }) => {
        const index = messages.findIndex((message: IMessage) => message.returnId === res.returnId);
        const newMessagesList: IMessage[] = messages;

        if (res.message.type === "text" && index !== -1) {
          newMessagesList[index] = res.message;
        } else {
          newMessagesList.unshift(res.message);
        }

        setMessages(newMessagesList);
      });
    }

    return () => {
      socket.off("newMessage");
    };
  }, [isLoading, messages, socket]);

  useEffect(() => {
    setUserIsPremimum(
      context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
    );
  }, [context.state.currentPlan]);

  return (
    <>
      <TopDiscussionThumbnail
        firstName={selectedCandidate.firstName}
        candidateActivity={selectedCandidate.activity}
        candidateGender={selectedCandidate.gender}
        discussionId={selectedDiscussion._id}
        archive={selectedDiscussion.archive}
        replyRatePercentage={selectedCandidate.replyRatePercentage}
        replyTimeMillisecond={selectedCandidate.replyTimeMillisecond}
      />
      <div
        className={`current-discussion${isLoading ? " isLoading" : ""}`}
        ref={currentDiscussionRef}
      >
        {isLoading && (
          <div>
            <span>Chargement en cours</span>
            <Loader type={"Puff"} height={50} width={50} color={"#27BBA5"} />
          </div>
        )}
        {!isLoading &&
          messages &&
          messages?.length > 0 &&
          messages.map((message: IMessage, index: number) => {
            return (
              <div
                key={message._id}
                className={"message"}
                ref={handleMessageThumbnailHTMLRef(index)}
              >
                {message.type === "System" && (
                  <AdminMessageThumbnail
                    key={message._id}
                    title={message.content}
                    type={message.type}
                  />
                )}

                {message.type === "MissionSubscription" && message.content.status === "PENDING" && (
                  <MissionThumbail
                    key={message._id}
                    title={message.content.title}
                    messageContent={message.content.message}
                    startDate={message.content.startDate}
                    endDate={message.content.endDate}
                    timeStamp={message.date}
                    avatarUrl={message.author.avatarUrl}
                  />
                )}

                {message.type === "MissionSubscription" && message.content.status !== "PENDING" && (
                  <AdminMessageThumbnail
                    key={message._id}
                    title={message.content.title}
                    type={message.type}
                    status={message.content.status}
                    candidateFirstName={message.content.substitute.firstName}
                    date={formatTimeStamp(message.date)}
                  />
                )}

                {message.type === "text" && (
                  <>
                    <MessageThumbnail
                      sendingInProgress={message.sendingInProgress}
                      id={message._id}
                      key={message._id}
                      text={
                        !userIsPremium && message.author.userId !== currentUserId
                          ? byPass(message.content)
                          : message.content
                      }
                      avatarUrl={
                        message.author.userId === currentUserId ? officeLogo : candidateAvatar
                      }
                      timeStamp={message.date}
                      authorIsCandidate={message.author.userId !== currentUserId}
                    />
                  </>
                )}
              </div>
            );
          })}
        {oldMessagesLoading && !isLoading && (
          <div className={"message"} style={{ display: "flex", justifyContent: "center" }}>
            <Loader type={"Puff"} width={20} height={20} color={"#27BBA5"} />
          </div>
        )}
        {noMoreMessages && (
          <div className={"message"}>
            <AdminMessageThumbnail type={"Start"} />
          </div>
        )}
      </div>
      <MessageInputForm
        inputValue={newMessage}
        handleInputChange={handleInputChange}
        handleSendMessage={handleSendMessage}
        handlePressKey={(event) =>
          handlePressKey(event, newMessage, setNewMessage, setCursorPosition)
        }
        cursorPosition={cursorPosition}
        isSupport={selectedDiscussion.isSupport}
        placeholder={userIsPremium || selectedDiscussion.isSupport ? "Tapez un message" : ""}
      />
    </>
  );
};

export default CurrentDiscussion;
