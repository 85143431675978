import axios from "axios";
import Cookies from "js-cookie";

export const fetchApi = async (
  endpoint: string,
  method: any = "get",
  payload: any = undefined,
  header = {}
): Promise<any> => {
  const token = "Bearer " + Cookies.get("token");
  return axios(global.config.api + endpoint, {
    method,
    headers: {
      ...header,
      Pragma: "no-cache", // hack to force internet explorer to clear cache
      Authorization: token,
    },
    data: payload,
  });
};

