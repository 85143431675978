import React from "react";
import history from "../../history";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState } from "draft-js";
import { reasons } from "../../Constants/reasons";
import htmlToDraft from "html-to-draftjs";
import { fetchApi } from "./Api";
import moment from "moment";
import { remunerationType } from "../../Constants/mission";
import * as service from "../../Services/candidate.services";

export const NewMissionContext = React.createContext({} as newMissionProps);
export const NewMissionConsumer = NewMissionContext.Consumer;

class NewMissionProvider extends React.Component<any, newMissionState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSending: false,
      apiError: undefined,
      errors: undefined,
      x: null,
      y: null,
      page: 1,
      subPageTerms: 1,
      missionType: "",
      infoBoxSeen: false,
      office: null,
      tipOpen: "tip1",
      profession: {
        _id: "60dee5d03cfdec010e4674e3",
        name: { FR: "Masseur-Kinésithérapeute" },
        filesRef: { FRANCE: { code: "70" } },
        status: { FRANCE: "ACTIVE" },
        date: { FRANCE: null },
        mandatory: { FRANCE: true }
      },
      newGraduate: true,
      displayReasons: false,
      displayErrors: false,
      reason: null,
      neoTipIsOpen: false,
      startDate: undefined,
      endDate: undefined,
      isFullTime: true,
      isPartTime: false,
      planning: null,
      flexibleSchedule: false,
      isSubstituteAllowedtoMakeLiberal: false,
      displayCalendar1: false,
      displayCalendar2: false,
      skills: [],
      displaySkills: false,
      minActPerWeek: 0,
      maxActPerWeek: 0,
      remunerationType: { back: null, front: "Type de rémuné..." },
      remuneration: undefined,
      retroPercent: undefined,
      displayRemunerationType: false,
      hasHousing: false,
      hasVehicle: false,
      isAssistantShipPossible: false,
      title: null,
      description: undefined,
      editorState: null,
      retroMax: false,
      name: null,

      // internship
      internsAllowed: 0,
      tutors: 0,
      compensatoryInternship: false,
      acceptedYears: [],
      approvedSchools: [],

      displayLauchCandidateSearchButton: false,
    };
  }

  wipeData = () => {
    this.setState({
      _id: undefined
      // endDate: undefined,
      // startDate: undefined,
      // missionType: '',
      // minActPerWeek: 0,
      // maxActPerWeek: 0,
      // remunerationType: {
      //   back: null,
      //   front: "Type de rémuné..."
      // },
      // remuneration: 0,
      // skills: [],
      // reason: null
    });
  };

  setTipOpen = (tip: string) => {
    this.setState({ tipOpen: tip });
  };

  convertRichDescription = (description: string) => {
    const contentBlock = description ? htmlToDraft(description) : null;
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return null;
  };

  recoverData = async (data: any) => {
    let remuneration = remunerationType.find(
      element => element.back === data.remuneration.type
    );

    await this.setState({
      ...data,
      startDate: new Date(data.startDate),
      endDate: data.endDate ? new Date(data.endDate) : undefined,
      missionType: data.exercise,
      remunerationType: {
        back: data.remuneration.type,
        front: remuneration?.front || "Type de rémuné..."
      },
      remuneration: data.remuneration.amount,
      remunerationComment: data.retroDescription,
      skills: data.requestedSkills,
      name: data.title,
      reason: reasons.find(reason => reason.back === data.reason),
      editorState: this.convertRichDescription(data.richdescription)
      // description: data.richdescription
    });
    return true;
  };

  // Return true if planning's empty
  checkEmptyPlanning = (planning: planningWeek | null | undefined) => {
    for (let [keyDay, valueDay] of Object.entries(planning ? planning : {})) {
      for (let [keyPart, valuePart] of Object.entries(valueDay)) {
        if (valuePart) return false;
      }
    }
    return true;
  };

  // TODO: Refactor this function might be a good idea. I mean, look at this...
  validateMission = () => {
    let errors: newMissionErrors = {
      slide1: {},
      slide2: {},
      slide3: {},
      slideInternship: {}
    };
    const state = this.state;
    if (!state.profession) {
      errors.slide1.profession =
        "Vous devez choisir le métier que vous voulez recruter";
    }
    if (!state.missionType) {
      errors.slide1.missionType = "Vous devez choisir un type de poste";
    }
    if (!state.startDate && state.missionType !== "internship") {
      errors.slide2.date = "Vous devez choisir une date de départ";
    }
    if (
      moment(state.startDate).isBefore(moment(), "day") &&
      state.missionType !== "internship"
    ) {
      errors.slide2.date = `Vous ne pouvez choisir une date antérieure à aujourd'hui`;
    }
    if (!state.isFullTime && this.checkEmptyPlanning(state.planning)) {
      errors.slide2.planning =
        "Vous devez choisir au moins une période de temps";
    }
    if (state.skills.length < 1) {
      errors.slide2.skills = "Vous devez choisir au moins une compétence";
    }
    if (
      state.missionType !== "cdi" &&
      state.missionType !== "internship" &&
      state.missionType !== "volunteering"
    ) {
      if (!state.endDate) {
        errors.slide2.date = "Vous devez choisir une date de fin";
      }
      if (moment(state.endDate).isSameOrBefore(moment(state.startDate))) {
        errors.slide2.date =
          "Vous devez choisir une date de fin postérieure à la date de départ";
      }
    }
    if (state.missionType !== "volunteering") {
      if (!state.remunerationType.back) {
        errors.slide2.remunerationType =
          "Vous devez choisir un type de rémunération";
      }
      if (
        state.remunerationType.back !== "depends-on-profile" &&
        state.remunerationType.back !== "free"
      ) {
        if (state.remunerationType.back !== "retrocession") {
          if (!(state.remuneration && state.remuneration > 0))
            errors.slide2.remuneration = "Vous devez saisir une somme";
          else if (isNaN(state.remuneration))
            errors.slide2.remuneration = "Vous devez saisir une somme valide";
        }
        if (state.remunerationType.back === "retrocession") {
          if (
            !(
              state.retroPercent &&
              state.retroPercent > 0 &&
              state.retroPercent <= 50
            )
          )
            errors.slide2.retrocession =
              "Vous devez saisir une retrocession entre 0 et 50%";
          else if (isNaN(state.retroPercent))
            errors.slide2.retrocession =
              "Vous devez saisir une retrocession valide";
        }
      }
    }
    if (!state.title) {
      errors.slide3.title = "Vous devez renseigner un nom de mission";
    }
    // if (state.acceptedYears!.length < 1) {errors.slideInternship.acceptedYears = "Vous devez sélectionner au moins une année"}
    this.setState({ errors });
  };

  displayErrors = async (bool: boolean) => {
    await this.validateMission();
    this.setState({ displayErrors: bool });
  };

  createMissionFromAlert = async (alert: AlertProps) => {
    // TODO : refactor
    const { startDate, endDate } = alert;
    let { exercise } = alert;
    if (exercise.length !== 1) exercise = [""];
    else {
      exercise = [exercise[0]];
    }

    if (exercise && startDate && endDate) {
      await this.setState(
        {
          missionType: exercise.length === 1 ? exercise[0] : "",
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        },
        () => history.push("/create-mission")
      );
    } else if (startDate && endDate) {
      await this.setState(
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        },
        () => history.push("/create-mission")
      );
    } else if (exercise && startDate) {
      await this.setState(
        {
          missionType: exercise.length === 1 ? exercise[0] : "",
          startDate: new Date(startDate)
        },
        () => history.push("/create-mission")
      );
    } else if (exercise && endDate) {
      await this.setState(
        {
          missionType: exercise.length === 1 ? exercise[0] : "",
          endDate: new Date(endDate)
        },
        () => history.push("/create-mission")
      );
    } else if (endDate) {
      await this.setState(
        {
          endDate: new Date(endDate)
        },
        () => history.push("/create-mission")
      );
    } else if (startDate) {
      await this.setState(
        {
          startDate: new Date(startDate)
        },
        () => history.push("/create-mission")
      );
    } else if (exercise) {
      await this.setState(
        {
          missionType: exercise.length === 1 ? exercise[0] : ""
        },
        () => history.push("/create-mission")
      );
    }
  };

  dateInit = () => {
    const startDate = new Date();
    this.setState({
      startDate,
      endDate: undefined
    });
  };

  handleCloseAllNavs = () => {
    this.setState({
      displayCalendar1: false,
      displayCalendar2: false,
      displaySkills: false,
      displayRemunerationType: false
    });
  };

  handleToggle = (name: keyof newMissionState) => {
    if (name === "displaySkills" && this.state.displaySkills) {
      this.setState({
        [name]: !this.state[name],
        displayCalendar1: false,
        displayCalendar2: false,
        displayRemunerationType: false
      });
    } else if (name === "displaySkills" && !this.state.displaySkills) {
      this.setState({
        [name]: !this.state[name],
        displayCalendar1: false,
        displayCalendar2: false,
        displayRemunerationType: false
      });
    } else if (name === "displayCalendar1") {
      this.setState({
        [name]: !this.state[name],
        displaySkills: false,
        displayCalendar2: false,
        displayRemunerationType: false
      });
    } else if (name === "displayCalendar2") {
      this.setState({
        [name]: !this.state[name],
        displaySkills: false,
        displayCalendar1: false,
        displayRemunerationType: false
      });
    } else if (name === "displayRemunerationType") {
      this.setState({
        [name]: !this.state[name],
        displaySkills: false,
        displayCalendar1: false,
        displayCalendar2: false
      });
    } else if (name === "isFullTime") {
      this.setState({
        [name]: !this.state[name],
        isPartTime: false,
        planning: null
      });
    } else if (name === "isPartTime") {
      this.setState({
        [name]: !this.state[name],
        isFullTime: false,
        planning: {
          monday: { morning: true, afternoon: true, night: false },
          tuesday: { morning: true, afternoon: true, night: false },
          wednesday: { morning: true, afternoon: true, night: false },
          thursday: { morning: true, afternoon: true, night: false },
          friday: { morning: true, afternoon: true, night: false },
          saturday: { morning: false, afternoon: false, night: false },
          sunday: { morning: false, afternoon: false, night: false }
        }
      });
    } else {
      this.setState(
        //@ts-ignore
        {
          [name]: !this.state[name]
        }
      );
    }
  };

  handlePushToArray = (array: any[], item: any, limit?: number) => {
    let found = array.findIndex(itemArray => itemArray._id === item._id);

    if (found !== -1) {
      array.splice(found, 1);
    } else {
      if (limit && array.length >= limit) {
        this.setState({ displaySkills: false });
      } else {
        array.push(item);
      }
    }

    this.setState({ name: array });
    this.validateMission();
  };

  handlePreviousPage = () => {
    if (this.state.page === 3 && this.state.missionType === "internship") {
      this.setState({ page: 2, subPageTerms: 2, tipOpen: "tip1" });
    } else if (
      this.state.page === 2 &&
      this.state.subPageTerms === 2 &&
      this.state.missionType === "internship"
    ) {
      this.setState({ subPageTerms: 1 });
    } else {
      this.setState({ page: this.state.page - 1, tipOpen: "tip1" });
    }
  };

  handleNextPage = () =>
    this.setState({
      page: this.state.page + 1,
      tipOpen: "tip1",
      displayErrors: false
    });

  handleExitCreation = () => {
    this.setState({ page: 1, subPageTerms: 1, missionType: "" });
    history.push("/remplacants");
  };

  initCreationPage = () => {
    this.setState({ page: 1, subPageTerms: 1, missionType: "" });
  };

  handleInput = (event: any) => {
    const { name, value } = event.target;
    const { remunerationType } = this.state;
    if (name === "remuneration" && remunerationType.back === "retrocession") {
      this.setState({ retroPercent: value, remuneration: 0 }, () =>
        this.validateMission()
      );
    } else if (
      name === "remuneration" &&
      remunerationType.back !== "retrocession"
    ) {
      this.setState({ remuneration: value, retroPercent: 0 }, () =>
        this.validateMission()
      );
    } else {
      this.setState({ ...this.state, [name]: value }, () =>
        this.validateMission()
      );
    }
  };

  handleEditorInput = (description: EditorState) => {
    this.setState({ editorState: description });
    this.setState({
      description: draftToHtml(
        convertToRaw(description.getCurrentContent())
      ).replace(/\n/g, "<br/>")
    });
  };

  handleCheckBox = (name: string, value: any) => {
    if (name === "missionType" && value === "volunteering") {
      this.setState(
        {
          [name]: value,
          remunerationType: {
            back: "depends-on-profile",
            front: "Aucune"
          }
          // remuneration: 0,
        },
        () => this.validateMission()
      );
    } else if (name === "acceptedYears") {
      let valueParsed = parseInt(value);
      let index = this.state.acceptedYears?.findIndex(
        year => year === valueParsed
      );
      let newAcceptedYears = [...this.state.acceptedYears!];
      if (index || index === 0) {
        if (index >= 0) {
          newAcceptedYears.splice(index, 1);
        } else {
          newAcceptedYears.push(valueParsed);
        }
      }
      this.setState({ acceptedYears: newAcceptedYears }, () =>
        this.validateMission()
      );
    } else if (name === "approvedSchools") {
      let index = this.state.approvedSchools?.findIndex(
        (school: any) => school._id === value._id
      );
      let newApprovedSchools = [...this.state.approvedSchools!];
      if (index || index === 0) {
        if (index >= 0) {
          newApprovedSchools.splice(index, 1);
        } else {
          newApprovedSchools.push(value);
        }
      }
      this.setState({ approvedSchools: newApprovedSchools });
    } else if (name === "missionType" && value === "cdi") {
      this.setState({ [name]: value, endDate: undefined }, () =>
        this.validateMission()
      );
    } else {
      this.setState({ ...this.state, [name]: value }, () =>
        this.validateMission()
      );
    }
  };

  handlePlanning = (planning: planningWeek) => {
    this.setState({ ...this.state, planning: planning });
  };

  handleSelect = async (name: string, value: any) => {
    if (name === "remunerationType" && value.back === "depends-on-profile") {
      await this.setState({
        [name]: value,
        displayRemunerationType: false,
        remuneration: 0,
        retroPercent: 0
      });
    } else if (
      name === "remunerationType" &&
      this.state.remunerationType.back !== "retrocession" &&
      this.state.remunerationType.back !== "depends-on-profile"
    ) {
      await this.setState({
        [name]: value,
        displayRemunerationType: false,
        remuneration: 0,
        retroPercent: 0
      });
    } else if (
      name === "remunerationType" &&
      this.state.remunerationType.back === "retrocession"
    ) {
      await this.setState({
        [name]: value,
        displayRemunerationType: false,
        remuneration: 0,
        retroPercent: 0
      });
    } else if (name === "reason") {
      await this.setState({
        [name]: value,
        displayReasons: false
      });
    } else if (name === "startDate") {
      await this.setState(
        {
          startDate: value,
          displayCalendar1: false
        },
        () => {
          if (
            new Date(this.state.startDate as Date) >=
            new Date(this.state.endDate as Date)
          ) {
            this.setState({
              endDate: new Date(
                this.state.startDate?.getFullYear() as number,
                this.state.startDate?.getMonth() as number,
                (this.state.startDate?.getDate() as number) + 1
              )
            });
          }
        }
      );
    } else if (name === "endDate") {
      await this.setState({
        endDate: value,
        displayCalendar2: false
      });
    } else {
      await this.setState({ ...this.state, [name]: value });
    }
    this.validateMission();
  };

  removeNotif = () => {
    setTimeout(() => {
      this.setState({ isSending: false });
    }, 5000);
  };

  createMissionObject = (userID: string, officeID: string, missionId?: string) => {
    const {
      description,
      title,
      isAssistantShipPossible,
      hasVehicle,
      hasHousing,
      remuneration,
      remunerationType,
      minActPerWeek,
      maxActPerWeek,
      isSubstituteAllowedtoMakeLiberal,
      isFullTime,
      planning,
      endDate,
      startDate,
      reason,
      flexibleSchedule,
      newGraduate,
      missionType,
      skills,
      retroPercent,
      remunerationComment,
      internsAllowed,
      tutors,
      compensatoryInternship,
      acceptedYears,
      approvedSchools,
      profession
    } = this.state;
    startDate?.setMinutes(
      startDate.getMinutes() - startDate.getTimezoneOffset()
    );
    if (endDate) {
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
    }

    let retroDescriptionToSend;
    if (remunerationType.back !== "depends-on-profile") {
      retroDescriptionToSend = remunerationComment;
    } else {
      retroDescriptionToSend = null;
    }

    let missionObject = {
      retroPercent,
      nonCompetitionDuration: 0,
      isPremium: true,
      office: { _id: officeID },
      holderId: userID,
      exercise: missionType,
      beginnerAccepted: newGraduate,
      startDate: startDate?.toISOString(),
      endDate: endDate ? endDate.toISOString() : null,
      isFullTime,
      flexibleSchedule,
      isSubstituteAllowedtoMakeLiberal,
      requestedSkills: skills,
      minActPerWeek:
        minActPerWeek,
      maxActPerWeek:
        maxActPerWeek,
      remuneration: {
        type: remunerationType.back,
        amount: remuneration
      },
      reason: reason?.back,
      hasHousing,
      hasVehicle,
      isAssistantShipPossible,
      title,
      description,
      retroDescription: retroDescriptionToSend,
      planning,
      professionId: profession?._id,
    };

    let intershipObject = {
      isPremium: true,
      office: { _id: officeID },
      holderId: userID,
      exercise: missionType,
      beginnerAccepted: true,
      startDate: startDate?.toISOString(),
      endDate: endDate ? endDate.toISOString() : null,
      title,
      description,
      reason: reason?.back,
      requestedSkills: skills,
      remuneration: {
        type: remunerationType.back,
        amount: remuneration
      },
      hasHousing,
      hasVehicle,
      internsAllowed,
      tutors,
      compensatoryInternship,
      acceptedYears: acceptedYears!.sort((a, b) => a - b),
      approvedSchools,
      professionId: profession?._id,
    };

    if (isFullTime) {
      delete missionObject.planning;
    }

    // delete professionId if is a mission update
    if (missionId) {
      delete missionObject.professionId;
      delete intershipObject.professionId;
    }

    if (missionType === "internship") {
      return intershipObject;
    } else {
      return missionObject;
    }
  };

  displayApiError = (msg: string) => {
    this.setState({ apiError: msg }, () => {
      setTimeout(() => {
        this.setState({ apiError: undefined });
      }, 10000);
    });
  };

  handleSendMission = async (
    userID: string,
    officeID: string,
    missionID?: string
  ) => {
    this.setState({ isSending: true });
    const missionObject = this.createMissionObject(userID, officeID, missionID);

    await fetchApi(
      missionID ? `/missions/` + missionID : `/missions/holder`,
      missionID ? "put" : "post",
      missionObject,
      { "Access-Control-Allow-Origin": "*" }
    )
      .then(res => {
        if (res.status === 200) {
          this.setState({ isSending: false, page: 4 });
        }
      })
      .catch(e => {
        this.displayApiError(
          `Une erreur s'est produite au moment de la création de l'annonce.`
        );
      });
    return;
  };

  setSubPageTerms = (page: number) => {
    this.setState({ subPageTerms: page });
  };

  setDisplayLauchCandidateSearchButton = () => {
    service
      .getCandidatesProfiles(0, {
        type: "substitute",
        professionId: this.state.profession._id,
        exercise: (() => {
          if (this.state.missionType === "cdd" || this.state.missionType === "cdi")
            return ["cdd" as Exercise, "cdi" as Exercise];
          return [this.state.missionType as Exercise];
        })(),
        skills: this.state.skills,
        expYear: (() => {
          if (this.state.missionType === "internship") return "student";
          return "graduated";
        })(),
        location: [],
        maxDistance: 100,
      })
      .then((res: CandidateResponse) => {
        this.setState({ displayLauchCandidateSearchButton: res.total > 0 });
      });
  }

  // TO FINISH

  // handlePreFillMission = missionType => {
  //   this.setState({ missionType }, () =>
  //     {}
  //   );
  // };

  render() {
    return (
      <NewMissionContext.Provider
        value={{
          state: this.state,
          handleToggle: this.handleToggle,
          handleInput: this.handleInput,
          handleCheckBox: this.handleCheckBox,
          handlePlanning: this.handlePlanning,
          handleSelect: this.handleSelect,
          handlePreviousPage: this.handlePreviousPage,
          handleNextPage: this.handleNextPage,
          handlePushToArray: this.handlePushToArray,
          handleSendMission: this.handleSendMission,
          dateInit: this.dateInit,
          handleExitCreation: this.handleExitCreation,
          initCreationPage: this.initCreationPage,
          createMissionFromAlert: this.createMissionFromAlert,
          handleCloseAllNavs: this.handleCloseAllNavs,
          handleEditorInput: this.handleEditorInput,
          wipeData: this.wipeData,
          recoverData: this.recoverData,
          setTipOpen: this.setTipOpen,
          displayErrors: this.displayErrors,
          setSubPageTerms: this.setSubPageTerms,
          setDisplayLauchCandidateSearchButton: this.setDisplayLauchCandidateSearchButton,
        }}>
        {this.props.children}
      </NewMissionContext.Provider>
    );
  }
}

export default NewMissionProvider;
