import React, { useState, useContext } from "react";
import HoverComponent from "../../../../Components/useHover";
import history from "../../../../history";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import Loader from "react-loader-spinner";
import { fetchApi } from "../../../../Services/Contexts/Api";

interface Props {
  tab: string;
  mission: any;
  handleMoveMission: Function;
}

const exerciseSlug: any = {
  substitute: "remplacement",
  cdi: "cdi",
  cdd: "cdd",
  assistantship: "assistanat",
  collaboration: "collaboration",
  volunteering: "volontariat",
  internship: "stage"
};

export const RightIcons = ({ tab, mission, handleMoveMission }: Props) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isPublicUrlLoading, setIsPublicUrlLoading] = useState(false);
  const context = useContext(AuthContext);

  const editMission = () => {
    (context.state.currentPlan === "smile" || context.state.currentPlan === "happy")
      ? history.push("/create-mission", mission)
      : context.popBarMessage("Vous devez souscrire à l'offre Smile ou Happy pour modifier vos annonces");
  };

  const deleteMission = () => () => {
    setDeleteOpen(false);

    if (context.state.currentPlan === "smile" || context.state.currentPlan === "happy") {
      handleMoveMission(mission, "closedAds");
    } else {
      context.popBarMessage("Vous devez souscrire à l'offre Smile ou Happy pour modifier vos annonces");
    }
  };

  const handleClickPublicView = () => {
    setIsPublicUrlLoading(true);

    fetchApi(`/mission/public-url/${mission.slug}`)
      .then((response) => {
        window.open(response.data.url, "_blank");
        setIsPublicUrlLoading(false);
      })
      .catch(() => setIsPublicUrlLoading(false));
  }

  return (
    <div className="right-icons">
      {tab === "active" && (
        <div
          className={
            (context.state.currentPlan === "smile" || context.state.currentPlan === "happy") ? "blue-pen" : "blue-pen forbidden"
          }
          onClick={() => editMission()}
        >
          <i className="fas fa-pen" />
        </div>
      )}
      {tab === "active" ? (
        <HoverComponent text="Voir la vue publique de votre annonce" style={{borderColor: (context.state.currentPlan === "smile" || context.state.currentPlan === "happy") ? "#29b6e5" : "#979797"}}>
          {(context.state.currentPlan === "smile" || context.state.currentPlan === "happy") ? (
            <a
              className="blue-eye"
              onClick={handleClickPublicView}
            >
              {isPublicUrlLoading ? (
                <Loader type={"Puff"} color={"#29b6e5"} height={15} width={15} />
              ) : (
                <i className="far fa-eye" />
              )}
            </a>
          ) : (
            <a className="blue-eye forbidden" onClick={() => context.popBarMessage("Vous devez souscrire à l'offre Smile ou Happy pour visualiser vos annonces")}>
              <i className="far fa-eye" />
            </a>
          )}
        </HoverComponent>
      ) : null}
      {tab !== "closed" && tab !== "hired" ? (
        deleteOpen ? (
          <div
            className={
              (context.state.currentPlan === "smile" || context.state.currentPlan === "happy")
                ? "confirm-delete"
                : "confirm-delete forbidden"
            }
            onClick={deleteMission()}
          >
            <i className="fas fa-trash" />
            <div> Confirmer ?</div>
          </div>
        ) : (
          <div
            className={
              (context.state.currentPlan === "smile" || context.state.currentPlan === "happy") ? "delete" : "delete forbidden"
            }
            onClick={() => setDeleteOpen(true)}
          >
            <i className="fas fa-trash" />
          </div>
        )
      ) : null}
    </div>
  );
};
