import React from "react";
import computer from "../../assets/img/responsive-page/office52.png";
import logo from "../../assets/img/logo-ecriture-noir.png";

const ResponsiveError = () => {
  return (
    <div className="responsive-error">
      <img src={logo} alt="App'Ines" className="logo" />
      <img src={computer} alt="Ordinateur" className="computer" />
      <div>
        Pour une meilleure expérience, nous vous recommandons d'utiliser
        App'Ines depuis un ordinateur.
        <br />
        <br />
        Nos équipes travaillent pour vous proposer une version pour les petits
        écrans, tablettes et smartphones très rapidement.
        <br />
        <br />
        Merci de votre compréhension
      </div>
    </div>
  );
};

export default ResponsiveError;
