require("dotenv").config();

export const getGlobalConfig = () => {
  let config;

  if (process.env.REACT_APP_NODE_ENV === "development") {
    config = require("../config/development.js");
  } else if (process.env.REACT_APP_NODE_ENV !== "int") {
    config = require("../config/production.js");
  } else {
    config = require("../config/int.js");
  }

  return config;
}
