import React, { useContext, useEffect, useState } from "react";
import Tips from "../../../../Components/Tips/Tips";
import { NewMissionContext, NewMissionConsumer } from "../../../../Services/Contexts/NewMissionProvider";
import { Link } from "react-router-dom";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import { RemunerationBlock } from "./RemunerationBlock";
import { SkillsBlock } from "./SkillsBlock";
import { DateBlock } from "./DateBlock";
import { PartTimeBlock } from "./PartTimeBlock";
import { InternshipTerms } from "./InternshipTerms";
import HoverErrorComponent from "../../../../Components/useHoverError";

interface Props {
  dateInit(): void;
}

const Terms = (props: Props) => {
  const [isValid, setIsValid] = useState(false);
  const context = useContext(NewMissionContext);

  useEffect(() => {
    if (!context.state.startDate) {
      props.dateInit();
    }

    if (context.state.subPageTerms === 1) {
      setIsValid(!!context.state.errors?.slide2 && Object.keys(context.state.errors.slide2).length === 0);
    } else {
      setIsValid(!!context.state.errors?.slideInternship && Object.keys(context.state.errors.slideInternship).length === 0);
    }
  })

  const handleNextStep = () => {
    if (isValid) {
      if (context.state.missionType !== "internship") {
        context.handleNextPage();
      } else {
        if (context.state.subPageTerms === 1) {
          context.setSubPageTerms(2);
        } else {
          context.handleNextPage();
        }
      }
    }
  }

  return (
    <NewMissionConsumer>
      {(context: newMissionProps) => (
        <section
          className="slide-two"
        >
          <div className="content">
            <h3>Nouvelle annonce</h3>

            {/* INPUT BLOCKS */}
            {
              context.state.subPageTerms === 1
              ?
                context.state.missionType !== "internship" 
                ?
                  <>
                    <DateBlock {...context} />
                    {
                      !context.state.isFullTime && <PartTimeBlock {...context} />
                    }
                    <SkillsBlock {...context} />
                    {context.state.missionType !== 'volunteering' && <RemunerationBlock {...context} />}
                  </>
                :
                  <>
                    <SkillsBlock {...context} />
                    <RemunerationBlock {...context} />
                  </>
              :
                <InternshipTerms {...context} />
            }

            {/* VALIDATE BUTTON */}
            <HoverErrorComponent
              className="validate-terms"
              setIsOpen={context.displayErrors}>
              <ValidButton
                onClick={() => handleNextStep()}
                condition={isValid}
              />
            </HoverErrorComponent>
          </div>

          <Tips
            {...context.state}
            onMouseEnter={context.setTipOpen}
            severalTips={true}
            threeTips={true}
            smallText={
              <div className="small-text">
                <span> </span>
                <Link to="/authentification">
                  <span className="green-text"> </span>
                </Link>
              </div>
            }
            texts={
              context.state.tipOpen === 'tip1' ? tip1 :
                context.state.tipOpen === 'tip2' ? tip2 :
                  tip3
            }
          />
        </section>
      )}
    </NewMissionConsumer>
  );
}

export default Terms;

const tip1 = [
  "Renseignez les dates de la mission. Vous pouvez choisir de ne mettre qu’une date de début, ou qu’une date de fin.",
  "Les candidats pourront postuler pour une partie ou la totalité de la durée de la mission.",
  "Un emploi du temps partiel, avec des horaires flexibles a plus de chance d’attirer les candidats."
]

const tip2 = [
  "Décrivez les compétences nécessaires à la réalisation de la mission en fonction des pathologies de vos patients.  Les candidats pourront s’assurer qu’ils ont les compétences requises pour le poste."
]

const tip3 = [
  "La rémunération sera affichée aux candidats. C’est un élément souvent décisif dans leur prise de décision, soyez le plus précis et transparent possible."
]