import React from "react";

interface Props {
  name: string;
  condition: boolean;
  text: string;
  handleToggle: any;
}

const CheckButton = (props: Props) => {
  return (
    <div>
      {!props.condition ? (
        <li
          className="is-not-checked"
          onClick={() => props.handleToggle(props.name)}
        >
          {props.text}
          <div className="check-box">
            <div className="inner-check-box" />
          </div>
        </li>
      ) : (
          <li
            className="is-checked"
            onClick={() => props.handleToggle(props.name)}
          >
            {props.text}
            <div className="check-box-blue">
              <div className="inner-check-box-blue" />
            </div>
          </li>
        )}
    </div>
  );
};

export default CheckButton;
