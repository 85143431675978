import React from "react";
import { OfficeState } from "./OfficePage";
import AuthTextInput from "../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import classNames from "classnames";

interface Props {
  socialLinks: OfficeState["socialLinks"];
  updateLinks: Function;
}

const GeneralSocialLinks = (props: Props) => {
  return (
    <div style={{ marginTop: 30 }}>
      <h4>Vous êtes présents sur les réseaux sociaux ?</h4>
      <div className="social-links">
        {Object.keys(props.socialLinks).map((item: any) =>
          renderSocialLink(item, props.socialLinks, props.updateLinks)
        )}
      </div>
    </div>
  );
};

const renderSocialLink = (
  key: keyof Props["socialLinks"],
  socialLinks: Props["socialLinks"],
  updateLinks: Function
) => {
  const switchLink = (logo: string) => {
    switch (logo) {
      case "facebook":
        return { logo: "fab fa-facebook-f", placeholder: "Facebook" };
      case "linkedin":
        return { logo: "fab fa-linkedin-in", placeholder: "LinkedIn" };
      case "youtube":
        return { logo: "fab fa-youtube", placeholder: "Vidéo Youtube" };
      case "instagram":
        return { logo: "fab fa-instagram", placeholder: "Instagram" };
      case "twitter":
        return { logo: "fab fa-twitter", placeholder: "Twitter" };
      case "web":
        return { logo: "fas fa-ellipsis-h", placeholder: "Votre site Web" };
      default:
        return { logo: "", placeholder: "" };
    }
  };

  return (
    <div className="social-link" key={key}>
      <div
        className={classNames(
          "social-logo",
          socialLinks[key] && "social-logo-blue"
        )}>
        <i className={switchLink(key).logo} />
      </div>
      <AuthTextInput
        value={socialLinks[key]}
        condition={!!socialLinks[key]}
        name={key}
        handleInput={updateLinks}
        placeHolder={switchLink(key).placeholder}
        className="social-input"
      />
    </div>
  );
};

export default GeneralSocialLinks;
