import React from "react";
import { OfficeState, ProfessionProps } from "./OfficePage";
import ValidButton from "../../Components/Forms/Buttons/ValidButton/ValidButton";
import Loader from "react-loader-spinner";
import allProfessions from "../../Constants/professions";
import {
  Select,
  FormControl,
  MenuItem,
  InputBase,
  Divider,
  TextField
} from "@material-ui/core";
import CustomSelect from "../../Components/Forms/Inputs/SelectInput";
import AuthTextInput from "../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import Toggle from "../../Components/Forms/Inputs/Toggle/Toggle";

interface Props extends OfficeState {
  handleUpdateOffice: Function;
}

interface StaffProps {
  profession: string;
  quantity: number;
}

type dayOfWeek = keyof openingHoursWeek;
type typeRange = keyof rangeHours;

export interface OrganisationState {
  professions: ProfessionProps[];
  placesInDayHospital: number;
  beds: number;
  openingHours: openingHoursWeek | null;
}

interface State extends OrganisationState {
  displayOrganisationsDetails: boolean;
  addStaffOpen: boolean;
  newStaffMember: StaffProps;
  professionsFree: ProfessionProps[];
  displayOpeningHours: boolean;
}

export default class Organisation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayOrganisationsDetails: true,
      addStaffOpen: false,
      beds: this.props.beds,
      professions: this.props.professions,
      placesInDayHospital: this.props.placesInDayHospital,
      professionsFree: [],
      newStaffMember: {
        profession: "",
        quantity: 1
      },

      displayOpeningHours: false,
      openingHours: this.props.openingHours
    };
  }

  handleAddStaffMember = () => {
    const staff = [...this.state.professions];
    if (
      this.state.newStaffMember &&
      this.state.newStaffMember.profession !== "" &&
      this.state.newStaffMember.quantity > 0
    ) {
      staff.push(this.state.newStaffMember);
      this.setState({
        professions: staff,
        newStaffMember: { profession: "", quantity: 1 }
      });
    }
  };

  handleRemoveStaffMember = (index: any) => {
    const staff = [...this.state.professions];
    staff.splice(index, 1);
    this.setState({ professions: staff });
  };

  filterAllProfessions = () => {
    const professions = allProfessions;
    const officeProfession = this.state.professions;
    return professions.filter((p: any) => {
      let counter = 0;
      officeProfession.forEach(op => {
        if (p.front === op.profession) counter++;
      });
      return counter === 0;
    });
  };

  handleChangeQuantitySelect = (e: any) => {
    if (e?.target?.value >= 0) {
      this.setState({
        ...this.state,
        newStaffMember: {
          ...this.state.newStaffMember,
          quantity: parseInt(e.target.value)
        }
      });
    }
  };

  handleInput = (event: any) => {
    const { name, value } = event.target as {
      name: keyof State;
      value: any;
    };
    this.setState({ ...this.state, [name]: value });
  };

  makeProfessionsMenu = () => {
    let professions = this.filterAllProfessions();
    const { newStaffMember } = this.state;
    return (
      <div className="profession-select-wrapper">
        <FormControl>
          <InputBase
            name="quantity"
            value={newStaffMember?.quantity}
            placeholder="1"
            required
            type="number"
            onChange={this.handleChangeQuantitySelect}
            className={"profession-select-qty"}
          />
        </FormControl>
        <FormControl>
          <Select
            displayEmpty
            value={newStaffMember?.profession}
            name="profession"
            className="profession-select-component"
            input={<CustomSelect />}
            onChange={e =>
              this.setState({
                ...this.state,
                newStaffMember: {
                  ...this.state.newStaffMember,
                  profession: e.target.value as string
                }
              })
            }>
            <MenuItem disabled value="">
              <p className="select-placeholder">Sélectionner une profession</p>
            </MenuItem>
            {professions.map((profession, i) => (
              <MenuItem key={i} value={profession.front}>
                {profession.front}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <button className="add-staff" onClick={this.handleAddStaffMember}>
          <i className="fas fa-plus"></i>
        </button>
      </div>
    );
  };

  makeProfessionRow = (profession: any, index: number) => {
    const handleChange = (event: any) => {
      if (event.target?.value > 0) {
        let updatedProfs = this.state.professions;
        updatedProfs[index].quantity = event?.target?.value;
        this.setState({ professions: updatedProfs });
      }
    };
    return (
      <div key={index} className="profession-row-wrapper">
        <FormControl>
          <InputBase
            placeholder="1"
            required
            value={profession.quantity}
            type="number"
            className={"profession-select-qty"}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <InputBase
            className="profession-row-input"
            value={profession.profession}
          />
        </FormControl>
        <button
          className="remove-staff"
          onClick={() => this.handleRemoveStaffMember(index)}>
          <i className="fas fa-minus"></i>
        </button>
      </div>
    );
  };

  handleNewTime = async (e: any) => {
    let day = e.target.id.split("-");
    let dayPart: typeRange = day[1];
    let dayNbr: number = day[0].slice(-1);
    let dayWord: dayOfWeek = day[0].slice(0, day[0].length - 1);
    let newTime = e.target.value;

    let newDay = this.state!.openingHours![dayWord];
    newDay![dayNbr]![dayPart] = newTime;
  };

  handleToggle = async (e: any) => {
    e.preventDefault();

    let day = e.currentTarget.id.split("-");
    let dayWord: dayOfWeek = day[1];
    let newDay = this.state!.openingHours![dayWord];
    if (!!newDay) {
      newDay = null;
    } else {
      newDay = [{ start: "09:00", end: "18:00" }];
    }

    await this.setState((prevState: any) => ({
      openingHours: {
        ...prevState.openingHours,
        [dayWord]: newDay
      }
    }));
  };

  addOpeningHours = async (e: any) => {
    e.preventDefault();

    let day = e.currentTarget.id.split("-");
    let dayWord: dayOfWeek = day[1];

    let newDay = this.state!.openingHours![dayWord];
    if (newDay && newDay.length === 0) {
      newDay.push({ start: "09:00", end: "18:00" });
    } else if (newDay && newDay.length === 1) {
      newDay.push({ start: "14:00", end: "18:00" });
    }

    await this.setState((prevState: any) => ({
      openingHours: {
        ...prevState.openingHours,
        [dayWord]: newDay
      }
    }));
  };

  removeSecondOpeningHours = (e: any) => {
    let target = e.currentTarget.id.split("-");
    let day: dayOfWeek = target[1];
    let index = target[2];
    let newDay = this.state!.openingHours![day];

    if (newDay) {
      newDay.splice(index, 1);
      this.setState((prevState: any) => ({
        openingHours: {
          ...prevState.openingHours,
          [day]: newDay
        }
      }));
    }
  };

  handleToggleOpeningHours = () => {
    this.state.displayOpeningHours
      ? this.setState({
          openingHours: null
        })
      : this.setState({
          openingHours: {
            monday: [{ start: "09:00", end: "18:00" }],
            tuesday: [{ start: "09:00", end: "18:00" }],
            wednesday: [{ start: "09:00", end: "18:00" }],
            thursday: [{ start: "09:00", end: "18:00" }],
            friday: [{ start: "09:00", end: "18:00" }],
            saturday: null,
            sunday: null
          }
        });

    this.setState({ displayOpeningHours: !this.state.displayOpeningHours });
  };

  componentWillMount() {
    if (this.state.openingHours) {
      this.setState({
        openingHours: this.props.openingHours,
        displayOpeningHours: true
      });
    }
  }

  render() {
    return (
      <div>
        <div className="subtitle">
          <h3>Organisation</h3>
          {this.state.displayOrganisationsDetails ? (
            <i
              onClick={() =>
                this.setState({
                  displayOrganisationsDetails: !this.state
                    .displayOrganisationsDetails
                })
              }
              className="fas fa-chevron-circle-down"
            />
          ) : (
            <i
              onClick={() =>
                this.setState({
                  displayOrganisationsDetails: !this.state
                    .displayOrganisationsDetails
                })
              }
              className="fas fa-chevron-circle-right"
            />
          )}
        </div>
        {this.state.displayOrganisationsDetails && (
          <div className="step-block">
            <h4>Praticiens présents</h4>
            <div className="add-practitionner">
              {this.makeProfessionsMenu()}
            </div>
            <Divider />
            <div>
              {this.state.professions.map((profession: any, index: number) =>
                this.makeProfessionRow(profession, index)
              )}
            </div>

            <div className="beds-and-places">
              <div className="beds">
                <h4>Nombre de lits</h4>
                <AuthTextInput
                  condition={!!this.state.beds}
                  type="number"
                  name="beds"
                  value={this.state.beds}
                  handleInput={this.handleInput}
                  min={0}
                  maxLength={50}
                />
              </div>
              <div>
                <h4>Nombre de places en hôpital de jour</h4>
                <AuthTextInput
                  condition={!!this.state.placesInDayHospital}
                  type="number"
                  name="placesInDayHospital"
                  value={this.state.placesInDayHospital}
                  handleInput={this.handleInput}
                  min={0}
                  maxLength={50}
                />
              </div>
            </div>

            <div className="opening-hours-block">
              <h4>Horaires d'ouverture</h4>

              {this.state.displayOpeningHours && (
                <div
                  className="remove-openinghours"
                  onClick={this.handleToggleOpeningHours}>
                  <span>Supprimer les horaires d'ouverture</span>
                </div>
              )}

              {this.state.displayOpeningHours ? (
                <div className="week">
                  <div className="day">
                    <div className="main-line">
                      <span className="day">Lundi</span>
                      <Toggle
                        condition={!!this.state?.openingHours?.monday}
                        handleToggle={this.handleToggle}
                        id="toggle-monday"
                      />
                      {!!this.state?.openingHours?.monday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.monday &&
                        this.state.openingHours?.monday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`monday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900 // 15 min
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`monday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-monday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.monday &&
                        (!this.state.openingHours?.monday ||
                          this.state.openingHours?.monday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-monday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Mardi</span>
                      <Toggle
                        condition={!!this.state.openingHours?.tuesday}
                        handleToggle={this.handleToggle}
                        id="toggle-tuesday"
                      />
                      {!!this.state.openingHours?.tuesday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.tuesday &&
                        this.state.openingHours?.tuesday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`tuesday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`tuesday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-tuesday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.tuesday &&
                        (!this.state.openingHours?.tuesday ||
                          this.state.openingHours?.tuesday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-tuesday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Mercredi</span>
                      <Toggle
                        condition={!!this.state.openingHours?.wednesday}
                        handleToggle={this.handleToggle}
                        id="toggle-wednesday"
                      />
                      {!!this.state.openingHours?.wednesday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.wednesday &&
                        this.state.openingHours?.wednesday.map(
                          (time, index) => {
                            return (
                              <div className="time-line" key={index}>
                                <TextField
                                  id={`wednesday${index}-start`}
                                  type="time"
                                  defaultValue={time.start}
                                  inputProps={{
                                    step: 900
                                  }}
                                  onChange={this.handleNewTime}
                                />
                                <span> - </span>
                                <TextField
                                  id={`wednesday${index}-end`}
                                  type="time"
                                  defaultValue={time.end}
                                  inputProps={{
                                    step: 900
                                  }}
                                  onChange={this.handleNewTime}
                                />
                                {index > 0 && (
                                  <div className="remove-time-line">
                                    <i
                                      className="fas fa-times-circle"
                                      id={`remove-wednesday-${index}`}
                                      onClick={
                                        this.removeSecondOpeningHours
                                      }></i>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}

                      {!!this.state.openingHours?.wednesday &&
                        (!this.state.openingHours?.wednesday ||
                          this.state.openingHours?.wednesday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-wednesday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Jeudi</span>
                      <Toggle
                        condition={!!this.state.openingHours?.thursday}
                        handleToggle={this.handleToggle}
                        id="toggle-thursday"
                      />
                      {!!this.state.openingHours?.thursday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.thursday &&
                        this.state.openingHours?.thursday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`thursday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`thursday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-thursday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.thursday &&
                        (!this.state.openingHours?.thursday ||
                          this.state.openingHours?.thursday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-thursday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Vendredi</span>
                      <Toggle
                        condition={!!this.state.openingHours?.friday}
                        handleToggle={this.handleToggle}
                        id="toggle-friday"
                      />
                      {!!this.state.openingHours?.friday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.friday &&
                        this.state.openingHours?.friday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`friday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`friday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-friday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.friday &&
                        (!this.state.openingHours?.friday ||
                          this.state.openingHours?.friday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-friday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Samedi</span>
                      <Toggle
                        condition={!!this.state.openingHours?.saturday}
                        handleToggle={this.handleToggle}
                        id="toggle-saturday"
                      />
                      {!!this.state.openingHours?.saturday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.saturday &&
                        this.state.openingHours?.saturday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`saturday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`saturday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-saturday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.saturday &&
                        (!this.state.openingHours?.saturday ||
                          this.state.openingHours?.saturday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-saturday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="day">
                    <div className="main-line">
                      <span className="day">Dimanche</span>
                      <Toggle
                        condition={!!this.state.openingHours?.sunday}
                        handleToggle={this.handleToggle}
                        id="toggle-sunday"
                      />
                      {!!this.state.openingHours?.sunday ? (
                        <>
                          <span className="state open">Ouvert</span>
                        </>
                      ) : (
                        <>
                          <span className="state closed">Fermé</span>
                        </>
                      )}
                    </div>

                    <div className="times-line">
                      {!!this.state.openingHours?.sunday &&
                        this.state.openingHours?.sunday.map((time, index) => {
                          return (
                            <div className="time-line" key={index}>
                              <TextField
                                id={`sunday${index}-start`}
                                type="time"
                                defaultValue={time.start}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              <span> - </span>
                              <TextField
                                id={`sunday${index}-end`}
                                type="time"
                                defaultValue={time.end}
                                inputProps={{
                                  step: 900
                                }}
                                onChange={this.handleNewTime}
                              />
                              {index > 0 && (
                                <div className="remove-time-line">
                                  <i
                                    className="fas fa-times-circle"
                                    id={`remove-sunday-${index}`}
                                    onClick={this.removeSecondOpeningHours}></i>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!!this.state.openingHours?.sunday &&
                        (!this.state.openingHours?.sunday ||
                          this.state.openingHours?.sunday?.length < 2) && (
                          <div
                            onClick={this.addOpeningHours}
                            id="add-sunday"
                            className="add-time time-line">
                            + Ajouter un horaire
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="add-openinghours"
                  onClick={this.handleToggleOpeningHours}>
                  Horaires d'ouverture non renseignée.
                  <br />
                  <span>Ajouter des horaires</span>
                </div>
              )}
            </div>

            <div className="button-loader">
              <ValidButton
                onClick={() => this.props.handleUpdateOffice(this.state)}
                text={this.props.hasSaved ? "Enregistré" : "Enregistrer"}
                condition={true}
                className={
                  this.props.isSaving
                    ? "validate-orange"
                    : this.props.hasSaved
                    ? "validate-green"
                    : "validate-blue"
                }
              />
              {this.props.isSaving && (
                <div className="loader">
                  <Loader type="Puff" color="#29b6e5" height={30} width={30} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
