import React from "react";
import Header from "../../Components/Header/Header";
import Loader from "react-loader-spinner";
import Missions from "./Slide1/Missions";
import Candidates from "./Slide2/Candidates";
import {
  AuthConsumer,
  AuthContext
} from "../../Services/Contexts/AuthProvider";
import * as service from "../../Services/ads.services"

export interface AdsState {
  page: number;
  activeAds: AdsResponse;
  hiredAds: AdsResponse;
  expiredAds: AdsResponse;
  closedAds: AdsResponse;
  isLoading: Boolean;
  tab: "active" | "expired" | "hired" | "closed";
  cantEditIsOpen: boolean;
  publicViewTip: boolean;
  source: string | null;
  substitute: any;
  mission: MissionProps | null;
}

class Ads extends React.Component<AuthState, AdsState> {
  static contextType = AuthContext;
  constructor(props: AuthState) {
    super(props);
    this.state = {
      page: 1,
      activeAds: { data: [], total: 0 },
      hiredAds: { data: [], total: 0 },
      expiredAds: { data: [], total: 0 },
      closedAds: { data: [], total: 0 },
      isLoading: true,
      tab: "active",
      cantEditIsOpen: false,
      publicViewTip: false,
      source: null,
      substitute: null,
      mission: null,
    };
  }

  handlePrevPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleSelect = (name: keyof AdsState, value: any) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleToggle = (name: keyof AdsState) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name],
    });
  };

  handleGetMission = (mission: MissionProps) => {
    this.setState({ page: 2, mission });
  };

  handleMoveMission = (mission: MissionProps, destination: "hiredAds" | "closedAds") => {
    if (
      this.context.state.subscriptionStatus === "subscription_ended" ||
      this.context.state.subscriptionStatus === "never_subscribed" ||
      this.context.state.currentPlan === "zen"
    ) {
      if (destination !== "closedAds") {
        this.context.popBarMessage(
          "Vous devez souscrire à l'offre Smile ou Happy pour modifier vos annonces"
        );
        return;
      }
    }

    const { hiredAds, activeAds, expiredAds, closedAds, source, substitute } = this.state;

    const activeAdsUpdated = { ...activeAds };
    const expiredAdsUpdated = { ...expiredAds };

    let destinationTabUpdated: AdsResponse = { data: [], total: 0 };

    if (destination === "hiredAds") {
      destinationTabUpdated = { ...hiredAds };

      service.setAdStatusToHired(mission._id, {
        // Update the ad status in the database
        substituteId: source === "appines" ? substitute!.id : null,
        source,
      });
    }

    if (destination === "closedAds") {
      destinationTabUpdated = { ...closedAds };

      service.deleteAd(mission._id); // Delete the ad in the database
    }

    // From active tab
    if (activeAdsUpdated.data.includes(mission)) {
      activeAdsUpdated.data.splice(activeAdsUpdated.data.indexOf(mission), 1);
      activeAdsUpdated.total ? activeAdsUpdated.total-- : (activeAdsUpdated.total = 0);

      // Force the display of the "filled - pourvu" tab without waiting for a response from the server
      if (destination === "hiredAds") {
        let missionToUpdate = { ...mission };
        missionToUpdate.hired.isFilled = true;
      }
    }

    // From expired tab
    if (expiredAdsUpdated.data.includes(mission)) {
      expiredAdsUpdated.data.splice(expiredAdsUpdated.data.indexOf(mission), 1);
      expiredAdsUpdated.total ? expiredAdsUpdated.total-- : (expiredAdsUpdated.total = 0);
    }

    destinationTabUpdated.data.unshift({ ...mission });
    destinationTabUpdated.total ? destinationTabUpdated.total++ : (destinationTabUpdated.total = 1);

    this.setState({
      ...this.state,
      activeAds: { ...activeAdsUpdated },
      expiredAds: { ...expiredAdsUpdated },
      [destination]: destinationTabUpdated,
    });
  };

  displayPopBarMessage = () => {
    if (
      this.context.state.subscriptionStatus !== "subscribed" ||
      this.context.state.currentPlan === "zen"
    ) {
      this.context.popBarMessage(
        "Pour accéder à l'ensemble des fonctionnalités, vous devez souscrire à l'offre Smile ou Happy"
      );
    }
  };

  // API CALLS TO GET ALL DIFFERENT CATEGORIES
  setAdsState = async () => {
    const allAds = await service.getAllAds(this.props.isDemoAccount);

    this.setState({
      ...allAds,
      isLoading: false,
    });
  };

  spinnerPage = () => {
    return (
      <section className="spinner-page">
        <Header user={this.props.user} />
        <AuthConsumer>
          {(context: AuthProps) => (
            <div>
              {(context.state.subscriptionStatus === "never_subscribed" ||
                context.state.subscriptionStatus === "subscription_ended") && (
                <div style={{ height: "30px" }} />
              )}
            </div>
          )}
        </AuthConsumer>
        <div>
          <Loader type="Puff" color="#29b6e5" height={100} width={100} />
        </div>
      </section>
    );
  };

  render() {
    if (this.state.isLoading) {
      return this.spinnerPage();
    } else {
      return (
        <div className="missions-ads">
          <Header user={this.props.user} />
          <AuthConsumer>
            {(context: AuthProps) => (
              <div>
                {!context.state.trialPeriod && !context.state.subscribed && (
                  <div style={{ height: "30px" }} />
                )}
              </div>
            )}
          </AuthConsumer>

          <AuthConsumer>
            {(context: AuthProps) =>
              context.state.subscriptionStatus &&
              this.state.page === 1 && (
                <Missions
                  user={this.props.user}
                  {...this.state}
                  isDemoAccount={context.state.isDemoAccount}
                  handleMoveMission={this.handleMoveMission}
                  handleSelect={this.handleSelect}
                  onMouseEnter={this.handleToggle}
                  onMouseLeave={this.handleToggle}
                  handleGetMission={this.handleGetMission}
                />
              )
            }
          </AuthConsumer>

          <AuthConsumer>
            {(context: AuthProps) =>
              context.state.subscriptionStatus &&
              this.state.page === 2 && (
                <Candidates
                  user={this.props.user}
                  mission={this.state.mission}
                  activeAds={this.state.activeAds.data}
                  handlePrevPage={this.handlePrevPage}
                  handleUpdateSeenSubscription={this.context.resetNewCandidate}
                />
              )
            }
          </AuthConsumer>
        </div>
      );
    }
  }
  async componentWillMount() {
    await this.setAdsState()
  }

  async componentDidMount() {
    this.context.resetNewCandidate();

    // Ugly solution to redirect to candidates from stats page...
    if (this.props.location?.state?.mission) {
      this.setState({
        mission: this.props.location?.state?.mission,
        page: 2,
      });
    }

    if (this.context.state.currentPlan) {
      this.displayPopBarMessage();
    }
  }

  async componentDidUpdate(prevProps:Readonly<AuthState>, prevState:Readonly<AdsState>) {
    if (prevProps.isDemoAccount !== this.props.isDemoAccount)
        await this.setAdsState();
  }
}

export default Ads;
