import React from 'react';
import { OfficeState } from './OfficePage';
import ValidButton from '../../Components/Forms/Buttons/ValidButton/ValidButton';
import Loader from 'react-loader-spinner';
import CheckButton from '../../Components/Forms/Buttons/CheckButton/CheckButton';
import AuthTextInput from '../../Components/Forms/Inputs/AuthTextInput/AuthTextInput';
import ListSelect from '../../Components/Forms/Inputs/ListSelect/ListSelect';
import CheckBox from '../../Components/Forms/Inputs/CheckBox/CheckBox';

interface Props extends OfficeState {
  updateServices: Function;
}

export interface ServicesState {
  //services
  hasParking?: boolean;
  hasSecretary?: boolean;
  hasServiceVehicle?: boolean;
  hasMutual?: boolean;
  hasRepresentativeCommittee?: boolean;
  transportRefund?: boolean;
  hasCrecheRoom?: boolean;
  hasContinuingEducation?: boolean;

  //restauration
  hasCompanyRestaurant?: boolean;
  hasEquipedKitchen?: boolean;
  hasMealVoucher?: boolean;
  
  // avantages
  collectiveBargainingAgreement?: string; // enum ?
  paidLeaveDays?: string;
  RTT?: number;
  overtime?: 'PAID' | 'LEAVE';
  hasSeniorityTakeOver?: boolean;
  hasCompanySavingPlan?: boolean;
  incentivePayment?: boolean;
  hasTimeSavingAccount?: boolean;
  otherBonus?: boolean;
}

interface State extends ServicesState {
  displayDetails: boolean;
  displayConvention: boolean;
}

export default class ServicesAndAdvantages extends React.Component<Props, State> {
  _listCollectiveBargainingAgreement: any;
  _paidLeaveDays: any;
  _overtime: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      displayDetails: true,
      displayConvention: false,
      hasParking: this.props.hasParking,
      hasSecretary: this.props.hasSecretary,
      hasServiceVehicle: this.props.hasServiceVehicle,
      hasMutual: this.props.hasMutual,
      hasRepresentativeCommittee: this.props.hasRepresentativeCommittee,
      transportRefund: this.props.transportRefund,
      hasCrecheRoom: this.props.hasCrecheRoom,
      hasContinuingEducation: this.props.hasContinuingEducation,

      hasCompanyRestaurant: this.props.hasCompanyRestaurant,
      hasEquipedKitchen: this.props.hasEquipedKitchen,
      hasMealVoucher: this.props.hasMealVoucher, //

      collectiveBargainingAgreement: this.props.collectiveBargainingAgreement, // enum ?
      paidLeaveDays: this.props.paidLeaveDays,
      RTT: this.props.RTT,
      overtime: this.props.overtime,
      hasSeniorityTakeOver: this.props.hasSeniorityTakeOver,
      hasCompanySavingPlan: this.props.hasCompanySavingPlan,
      incentivePayment: this.props.incentivePayment,
      hasTimeSavingAccount: this.props.hasTimeSavingAccount,
      otherBonus: this.props.otherBonus
    };
    this._listCollectiveBargainingAgreement = React.createRef();
    this._paidLeaveDays = React.createRef();
    this._listCollectiveBargainingAgreement = React.createRef();
    this._overtime = React.createRef();
  }

  updateState = (name: keyof State) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name]
    })
  }

  handleSelect = (name: string, value: any) => {
    this.setState({
      ...this.state,
      displayConvention: false,
      [name]: value.back
    });
  };

  handleInput = (e: any) => {
    const { name, value } = e.target;
    this.setState({ ...this.setState, [name]: value })
  }

  saveChange = () => {
    this.props.updateServices(this.state);
  }

  makePaidLeaves = () => {
    let paidLeave = [];
    for (let i = 5; i <= 12; i++) {
      paidLeave.push({ front: `${i} semaines`, back: i.toString() })
    }
    return paidLeave;
  }

  render() {
    const services = [
      { back: "hasParking", front: "Parking" },
      { back: "hasSecretary", front: "Secrétaire" },
      { back: "hasServiceVehicle", front: "Véhicule de service" },
      { back: "hasMutual", front: "Mutuelle" },
      { back: "hasRepresentativeCommittee", front: "Comité d'entreprise" },
      { back: "transportRefund", front: "Remboursement transport" },
      { back: "hasCrecheRoom", front: "Place en crêche" }
    ];

    const restauration = [
      { back: "hasCompanyRestaurant", front: "Restauration collective" },
      { back: "hasEquipedKitchen", front: "Cuisine équipée" },
      { back: "hasMealVoucher", front: "Tickets Restaurant" },
    ];

    const collectiveBargainingAgreement = [
      { back: "CC51", front: "CC 51 (Fehap)" },
      { back: "CC66", front: "CC 66" },
      { back: "CCPrivateHospitalization2002", front: "CC Hospitalisation privée du 18 avril 2002" },
      { back: "CenterAgainstCancer", front: "Centres de lutte contre le cancer" },
      { back: "CCU", front: "CCU" },
      { back: "thermalisme", front: "Du Thermalisme" },
      { back: "sport", front: "Du sport" },
      { back: "redCross", front: "Croix Rouge Française" },
      { back: "publicFunction", front: "Pas de convention collective (Fonction Publique)" },
      { back: "UGECAM", front: "UGECAM" },
      { back: "Others", front: "Autres" },
    ]

    const overtime = [
      { back: "PAID", front: "Payées" },
      { back: "LEAVE", front: "Congés" },
    ];

    const avantages: { back: keyof State, front: string }[] = [
      { back: "hasSeniorityTakeOver", front: "Reprise de l'ancienneté" },
      { back: "hasCompanySavingPlan", front: "Plan Épargne Entreprise" },
      { back: "incentivePayment", front: "Prime d'interéssement" },
      { back: "hasTimeSavingAccount", front: "Compte Épargne Temps" },
      { back: "otherBonus", front: "Autre(s) prime(s)" },
    ]

    return (
      <div>
        <div className="subtitle">
          <h3>Services et avantages</h3>
          {this.state.displayDetails ? (
            <i
              onClick={() => this.updateState('displayDetails')}
              className="fas fa-chevron-circle-down"
            />
          ) : (
            <i
              onClick={() => this.updateState('displayDetails')}
              className="fas fa-chevron-circle-right"
            />
          )}
        </div>
        {this.state.displayDetails && (
          <div className="step-block">
            <h4>Services</h4>
            <div className="buttons-list">
              {services.map((x: { back: any, front: any }, index) => {
                const slug: keyof State = x.back;
                return (
                  <div key={index}>
                    <CheckButton
                      name={x.back}
                      text={x.front}
                      handleToggle={this.updateState}
                      condition={!!this.state[slug]}
                    />
                  </div>
                )
              }
              )}
            </div>
            <h4>Restauration</h4>
            <div className="buttons-list">
              {restauration.map((x: { back: any, front: any }, index) => {
                const slug: keyof State = x.back;
                return (
                  <div key={index}>
                    <CheckButton
                      name={x.back}
                      text={x.front}
                      handleToggle={this.updateState}
                      condition={!!this.state[slug]}
                    />
                  </div>
                )
              }
              )}
            </div>
            <div>
              <h4>Avantages et rémunération</h4>
              <ListSelect
                value={collectiveBargainingAgreement.find((item) => item.back === this.state.collectiveBargainingAgreement)?.front}
                name="collectiveBargainingAgreement"
                placeHolder="Convention Collective"
                condition={true}
                list={collectiveBargainingAgreement}
                handleSelect={this.handleSelect}
                classname="collective-bargaining-agreement"
                width={435}
                ref={this._listCollectiveBargainingAgreement}
              />
              <div className="leaves-avantages">
                <ListSelect
                  value={this.makePaidLeaves().find((item) => item.back === this.state.paidLeaveDays)?.front}
                  name="paidLeaveDays"
                  placeHolder="Congés Payés"
                  condition={true}
                  list={this.makePaidLeaves()}
                  handleSelect={this.handleSelect}
                  classname="list-select"
                  width={200}
                  ref={this._paidLeaveDays}
                />
                <AuthTextInput
                  className="RTT"
                  condition={!!this.state.RTT}
                  value={this.state.RTT}
                  name="RTT"
                  handleInput={this.handleInput}
                  placeHolder="Nombre de RTT"
                  maxLength={50}
                />
                <ListSelect
                  value={overtime.find((item) => item.back === this.state.overtime)?.front}
                  name="overtime"
                  placeHolder="Heures Supplémentaires"
                  condition={true}
                  list={overtime}
                  handleSelect={this.handleSelect}
                  classname="list-select"
                  width={260}
                  ref={this._overtime}
                />
              </div>
            </div>
            <div className="avantages-checkboxes">
              {avantages.map((item, index) => {
                let itemState = this.state[item.back];
                return <CheckBox
                  key={index}
                  condition={!!itemState}
                  name={item.back}
                  text={item.front}
                  className="square"
                  handleCheckBox={this.updateState}
                />
              }
              )}
            </div>
            <div className="button-loader">
              <ValidButton
                onClick={this.saveChange}
                text={this.props.hasSaved ? 'Enregistré' : 'Enregistrer'}
                condition={true}
                className={
                  this.props.isSaving ? 'validate-orange' : this.props.hasSaved ? 'validate-green' : 'validate-blue'
                }
              />
              {this.props.isSaving && (
                <div className="loader">
                  <Loader
                    type="Puff"
                    color="#29b6e5"
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
