import React from "react";
import logo from "../../assets/img/logo-ecriture-noir.png";
import { AuthConsumer } from "../../Services/Contexts/AuthProvider";
import Button from "../Forms/Buttons/Button/Button";
import { Link, NavLink } from "react-router-dom";
import UserMenu from "./UserMenu/UserMenu";
import Loader from "react-loader-spinner";

const ColoredNavLink = (props: {
  to: string;
  children: React.ReactElement;
  displayPractitionerDetails?: boolean | undefined;
}) => (
  <NavLink
    to={props.to}
    activeStyle={
      !props.displayPractitionerDetails
        ? {
            fontWeight: "bold",
            color: "#29b6e5"
          }
        : {} // ? activeStyle attribute don't handle falsies values
    }>
    {props.children}
  </NavLink>
);

interface Props {
  user: UserProps;
  displayPractitionerDetails?: boolean | undefined;
}

class Header extends React.Component<Props> {
  state = { hambIsOpen: false };
  closeHamburger = () => {
    this.setState({ hambIsOpen: false });
  };

  openHamburger = () => {
    this.setState({ hambIsOpen: true });
  };

  setHomeLink = () => {
    if (process.env.NODE_ENV === "development") return "http://localhost:3000/";
    else if (process.env.REACT_APP_NODE_ENV === "int")
      return "https://dev.pro.appines.fr/remplacants";
    else if (process.env.REACT_APP_NODE_ENV === "production")
      return "https://app.pro.appines.fr/remplacants";
  };

  renderNotificationCount = (count: number) =>
    count ? (
      <div id="notification-badge">
        <p>{count}</p>
      </div>
    ) : null;

  render() {
    return (
      <AuthConsumer>
        {(context: AuthProps) => (
          <header id="header">
            <>
              <div className="container">
                <ul>
                  <a href={this.setHomeLink()}>
                    <img src={logo} alt="App'Ines" className="header-logo" />
                  </a>
                  <ColoredNavLink
                    to="/remplacants"
                    displayPractitionerDetails={this.props.displayPractitionerDetails}
                  >
                    <li>Accueil</li>
                  </ColoredNavLink>
                  <ColoredNavLink to="/mes-annonces">
                    <div style={{ position: "relative" }}>
                      <li>Annonces</li>
                    </div>
                  </ColoredNavLink>
                  <ColoredNavLink to="/mes-candidats">
                    <li>
                      Candidats
                    </li>
                  </ColoredNavLink>
                  <ColoredNavLink to="/office-page">
                    <li>Établissement</li>
                  </ColoredNavLink>
                  <ColoredNavLink to="/discussions">
                    <div style={{ position: "relative" }}>
                      <li>Discussions<span className={`new-feature ${context.state.TotalNewMessage > 0 ? "isNotifBadgeDisplayed" : ""}`}>✨ Nouveau !</span></li>
                      {this.renderNotificationCount(context.state.TotalNewMessage)}
                    </div>
                  </ColoredNavLink>
                </ul>
                <ul>
                  <li>
                    <Link
                      to={
                        (context.state.currentPlan === "smile" || context.state.currentPlan === "happy")
                          ? "/create-mission"
                          : "/pricing"
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!context.state.subscriptionStatus && (
                          <Loader type={"Puff"} height={40} width={40} color="#29b6e5" />
                        )}
                        <Button
                          condition={!!context.state.subscriptionStatus}
                          className={
                            context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
                              ? "validate-blue"
                              : "validate-disabled forbidden"
                          }
                          className2={"validate-disabled"}
                          text={
                            context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
                              ? "Créer annonce"
                              : "🔒 Créer annonce"
                          }
                          onClick={
                            !(context.state.currentPlan === "smile" || context.state.currentPlan === "happy") &&
                            (() =>
                              context.popBarMessage(
                                "Vous devez souscrire à l'offre Smile ou Happy pour créer une annonce"
                              ))
                          }
                        />
                      </div>
                    </Link>
                  </li>
                  <li className="logged-nav" onClick={() => context.handleToggle("userNavIsOpen")}>
                    <div>{this.props.user && this.props.user.firstName}</div>
                    {this.props.user.token ? (
                      <div
                        className="logo-container"
                        style={{ border: context.state.officeLogo ? "3px solid lightgrey" : "none"}}
                      >
                        {context.state.officeLogo ? (
                          <img src={context.state.officeLogo} alt="office logo" />
                        ) : (
                          <i className="fas fa-user-circle" />
                        )}
                      </div>
                    ) : (
                      <Link to="/authentification">
                        <i className="fas fa-user-circle" />
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
              <div className="hamburger-menu">
                <Link to="/remplacants">
                  <img src={logo} alt="App'Ines" className="logo" />
                </Link>
                {this.state.hambIsOpen ? (
                  <i className="fas fa-times" onClick={() => this.closeHamburger()} />
                ) : (
                  <i className="fas fa-bars" onClick={() => this.openHamburger()}>
                    {this.renderNotificationCount(context.state.TotalNewMessage)}
                  </i>
                )}
                {this.state.hambIsOpen && context.state.user?.token && (
                  <div className="hamburger-nav">
                    <ColoredNavLink to="/remplacants">
                      <li>Rechercher un candidat</li>
                    </ColoredNavLink>

                    {(context.state.currentPlan === "smile" || context.state.currentPlan === "happy") ? (
                      <Link to="/create-mission">
                        <li>Créer une annonce</li>
                      </Link>
                    ) : (
                      <Link
                        to="/pricing"
                        onClick={() =>
                          context.popBarMessage(
                            "Vous devez souscrire à l'offre Smile ou Happy pour créer une annonce"
                          )
                        }
                      >
                        <li>🔒 Créer une annonce</li>
                      </Link>
                    )}

                    <ColoredNavLink to="/mes-annonces">
                      <li>
                        Mes annonces {this.renderNotificationCount(context.state.newCandidates)}{" "}
                      </li>
                    </ColoredNavLink>
                    <ColoredNavLink to="/mes-candidats">
                      <li>
                        Mes candidats
                      </li>
                    </ColoredNavLink>
                    <ColoredNavLink to="/office-page">
                      <li>Établissement</li>
                    </ColoredNavLink>
                    <ColoredNavLink to="/mon-profil">
                      <li>Mon profil</li>
                    </ColoredNavLink>
                    <ColoredNavLink to="/pricing">
                      <li>Offres et tarifs</li>
                    </ColoredNavLink>
                  </div>
                )}
              </div>
            </>

            {context.state.userNavIsOpen && (
              <UserMenu handleLogOut={context.handleLogOut} handleToggle={context.handleToggle} />
            )}

            {context.state.barMessage && (
              <div className="message-bar">
                ℹ {context.state.barMessage}{" "}
                <button className="redirect-pricing" onClick={context.redirectToPricing}>
                  Voir nos tarifs
                </button>
                <button className="close" onClick={context.emptyBarMessage}>
                  Fermer
                </button>
              </div>
            )}
          </header>
        )}
      </AuthConsumer>
    );
  }
}

export default Header;
