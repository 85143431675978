import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import Header from "../../../Components/Header/Header";
import SubstitutePersonalPageGhost from "./SubstitutePersonalPageGhost";
import * as service from "../../../Services/candidate.services";
import { placeholders } from "../../../Constants/images";
import { byPass } from "../../../Tools/StringTools";
import Experiences from "./Experiences/Experiences";
import Courses from "./Courses/Courses";
import Locomotions from "./Locomotions/Locomotions";
import studentStatut from "../../../assets/img/student-statut.png";
import { renderDiplomaDate } from "../../../Tools/MathTools";
import ReactCountryFlag from "react-country-flag";
import dayjs from "dayjs";
import { calculateResponseTime } from "../../Discussions/CurrentDiscussion/CurrentDiscussion";
import { displayLastConnection } from "../../Discussions/TopDiscussionThumbnail/TopDiscussionThumbnail";
import DiscussionInfos from "./DiscussionInfos/DiscussionInfos";
import HolderOfficeInfos from "./HolderOfficeInfos/HolderOfficeInfos";
import UserHistoric from "./UserHistoric/UserHistoric";
import moment from "moment";
import duration from "dayjs/plugin/duration";
import fr from "dayjs/locale/fr";
import { useHistory } from "react-router-dom";
import AvailabilityInfos from "./AvailabilityInfos/AvailabilityInfos";
moment.locale("fr");
dayjs.extend(duration);
dayjs.locale(fr);

export default function SubstitutePersonalPage(props: Props) {
  const context = useContext(AuthContext);
  const history = useHistory();
  const practitionerId = useMemo(() => props.match.params.id, [props.match.params.id]);

  /*
   ! Reducer, states storage
   */
  const reducer = (state: State, action: { type: ActionType; payload?: any }): State => {
    switch (action.type) {
      case "getPractitionerDetails":
        return { ...state, ...action.payload };
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    practitioner: undefined,
  });

  const [isLiked, setIsLiked] = useReducer(
    (state: boolean, action: { type: "add" | "remove" }): boolean => {
      return action.type === "add";
    },
    false
  );

  /*
 ! Utilities
 */
  const handleLike = (id: string) => {
    // ? 'service.likePractitioner(id)' is asynchronous, but we don't wait for API result to update our global state (and context)
    service.likePractitioner(id);
    setIsLiked({ type: "add" });
    context.handleAddSubstituteToLikedList(id);
  };

  const handleUnlike = (id: string) => {
    // ? 'service.unlikePractitioner(id)' is asynchronous, but we don't wait for API result to update our global state (and context)
    service.unlikePractitioner(id);
    setIsLiked({ type: "remove" });
    context.handleRemoveSubstituteToLikedList(id);
  };

  useEffect(() => {
    (async () => {
      dispatch({
        type: "getPractitionerDetails",
        payload: {
          isLoading: false,
          practitioner: await service.getCandidateDetails(practitionerId),
        },
      });
    })();
  }, []);

  useEffect(() => {
    setIsLiked({ type: !!state.practitioner?.isLiked ? "add" : "remove" });
  }, [state.practitioner?.isLiked]);

  /*
  ! JSX rendering
  */
  return (
    <div>
      <Header user={props.user} displayPractitionerDetails />

      {state.isLoading ? (
        <SubstitutePersonalPageGhost />
      ) : (
        <div className="substitute-page">
          <div className="content">
            <div className="container">
              <div className="left-block">
                <div className="backward" onClick={() => history.push("/remplacants")}>
                  <i className="fas fa-chevron-left" />
                  <span>Retour</span>
                </div>
                {state.practitioner?.profession?.name?.FR && (
                  <>
                    <div className="profession">
                      <div className="profession-icon">
                        <i className="far fa-hand-paper" />
                      </div>
                      <p>{state.practitioner?.profession?.name?.FR}</p>
                    </div>
                  </>
                )}
                <div className="introduction">
                  <div className="avatar">
                    {state.practitioner?.picture?.thumbnail?.url ? (
                      <img src={state.practitioner?.picture?.thumbnail?.url} alt="avatar" />
                    ) : (
                      <img
                        src={
                          state.practitioner?.lastName
                            ? placeholders[
                                parseInt(
                                  state.practitioner?.lastName
                                    ?.charCodeAt(0)
                                    .toString()
                                    .slice(-1)
                                )
                              ]
                            : placeholders[1]
                        }
                        alt="monkey-avatar"
                      />
                    )}
                  </div>
                  <h2>Présentation</h2>
                  <div className="description">
                    {state.practitioner?.description ? (
                      byPass(state.practitioner?.description, state.practitioner?.lastName)
                    ) : (
                      <p className="grey">{`${state.practitioner?.firstName} n'a pas encore rempli sa description.`}</p>
                    )}
                  </div>
                  <h2>Compétences</h2>
                  <div className="skills">
                    {state.practitioner?.skills &&
                      state.practitioner?.skills.map((skill: Skill, index: number) => (
                        <div className="skill" key={index}>
                          {skill.name[context.state.language]}
                        </div>
                      ))}
                  </div>
                </div>

                {state.practitioner?.xpPro && <>{Experiences(state.practitioner)}</>}

                {state.practitioner?.courses && <>{Courses(state.practitioner)}</>}

                {state.practitioner?.locomotions && <>{Locomotions(state.practitioner)}</>}
              </div>

              <div className="main-block">
                <div className="top-block">
                  <div className="administrative-details">
                    {/* NAME + TAGS */}
                    <div className="name">
                      <h2>
                        {state.practitioner?.firstName}
                        {parseInt(state.practitioner?.diploma?.date || "") >=
                          new Date().getFullYear() &&
                          !state.practitioner?.identifier && (
                            <img src={studentStatut} alt="student-hat" title="Étudiant" />
                          )}

                        {state.practitioner?.type === "holder" ? (
                          <div className="type holder">
                            <i className="far fa-building" />
                            Titulaire
                          </div>
                        ) : (
                          <div className="type substitute">
                            <i className="fas fa-user" />
                            Candidat
                          </div>
                        )}
                      </h2>
                      {isLiked ? (
                        <i
                          className="fas fa-star is-favorite"
                          onClick={() => {
                            handleUnlike(practitionerId);
                          }}
                        />
                      ) : (
                        <i
                          className="far fa-star is-favorite"
                          onClick={() => {
                            handleLike(practitionerId);
                          }}
                        />
                      )}
                    </div>

                    {/* ELEMENTARY INFOS */}
                    <div className="infos">
                      <div>
                        <div className="graduation">
                          <div>{state.practitioner && renderDiplomaDate(state.practitioner)}</div>
                        </div>
                        <div className="school">
                          {state.practitioner?.diploma?.school && (
                            <>
                              {state.practitioner?.diploma?.school?.country ? (
                                <>
                                  <ReactCountryFlag
                                    svg
                                    style={{ marginRight: 5 }}
                                    countryCode={
                                      state.practitioner?.diploma?.school?.country || "FR"
                                    }
                                  />
                                  {state.practitioner?.diploma?.school?.name}
                                </>
                              ) : (
                                <p className="is-not-verified">École non précisée</p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="ordinal">
                          {state.practitioner?.ordinalDepartment && (
                            <div>
                              {state.practitioner?.gender === "male" ? "Inscrit " : "Inscrite "}à
                              l'Ordre du ({state.practitioner?.ordinalDepartment})
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        {state.practitioner?.identifier?.type ? (
                          <div className="is-verified">
                            <div>{state.practitioner?.identifier?.type.toUpperCase()}</div>
                            <i className="fas fa-check" />
                          </div>
                        ) : (
                          <div className="is-not-verified">
                            <div>Non vérifié</div>
                            <i className="fas fa-times" />
                          </div>
                        )}
                        {state.practitioner?.siret && (
                          <div className="is-verified">
                            <div>Siret</div>
                            <i className="fas fa-check" />
                          </div>
                        )}
                        {state.practitioner?.ordinal && (
                          <div className="is-verified">
                            <div>NºOrdre</div>
                            <i className="fas fa-check" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={"activity-details"}>
                    <h2>
                      Activité
                      <span className={"new-feature"}>✨ Nouveau !</span>
                    </h2>

                    {state.practitioner?.createdAt && (
                      <div>
                        <span>‍🛬 Inscription :</span>
                        <span>{dayjs(state.practitioner.createdAt).format("DD MMMM YYYY")}</span>
                      </div>
                    )}
                    {state.practitioner?.replyTimeMillisecond && (
                      <div>
                        <span>⏱ Délai de réponse :</span>
                        <span>
                          {calculateResponseTime(state.practitioner.replyTimeMillisecond)}
                        </span>
                      </div>
                    )}
                    {typeof state.practitioner?.replyRatePercentage === "number" && (
                      <div>
                        <span>💌 Taux de réponse :</span>
                        <span>
                          {state.practitioner.replyRatePercentage}
                          {" %"}
                        </span>
                      </div>
                    )}
                    {state.practitioner?.lastConnectionAt && (
                      <div>
                        <span>👩‍💻 Dernière connexion :</span>
                        <span>{displayLastConnection(state.practitioner.lastConnectionAt)}</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* DISCUSSION */}
                <div className={"middle-block premium-block"}>
                  <DiscussionInfos substitute={state.practitioner} />
                </div>

                {/* AVAILIBILITY (only substitutes) */}
                {state.practitioner?.type === "substitute" && (
                  <AvailabilityInfos
                    substituteId={practitionerId}
                    alert={state.practitioner.alert}
                  />
                )}

                {/* OFFICES (only holders) */}
                {state.practitioner?.offices && state.practitioner?.type === "holder" && (
                  <HolderOfficeInfos props={state.practitioner} />
                )}

                {/* HISTORIC */}
                {state.practitioner && (
                  <UserHistoric
                    user={state.practitioner}
                    female={state.practitioner.gender === "female"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
type State = {
  isLoading: boolean;
  practitioner: SubstituteProps | undefined;
};
type Props = {
  match: { params: { id: string } };
  user: UserProps;
};
type ActionType = "getPractitionerDetails";
