import React from 'react';
import moment from "moment";
import {byPass} from "../../../../Tools/StringTools";
import diploma from "../../../../assets/img/formation.png";


moment.locale('fr');

export default function Courses(props: SubstituteProps) {
  const sortByDate = (a: any, b: any) => { 
    var dateA = new Date(a.startDate).getTime();
    var dateB = new Date(b.startDate).getTime();
    return dateA < dateB ? 1 : -1;
  }

  return (
    <div className="courses">
      <h2>Formations continues</h2>
          {props?.courses.sort(sortByDate).map((course, index) => (
            <div className="course" key={index}>
              <div className="col-image">
                <img src={diploma} alt="course"/>
              </div>
              <div className="col-description">
                <div>{course.name}</div>
                <div className="courseorganization">{course.organization}</div>
                <div className="coursedate">{moment(course.startDate).format('MMM YYYY')} - {moment(course.endDate).format('MMM YYYY')}</div>
                {course.description && 
                  <div className="coursedescription">{byPass(course.description, props?.lastName)}</div>
                }
              </div>
            </div>
          ))}
    </div>
  )
}
