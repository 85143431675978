import React, { useRef, useState, useEffect, ReactElement } from 'react';
import HighlightedTip from '../HighlightedTip/HighlightedTip';

const HoverComponent = ({ children, text, deactivated, style, className }: Props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 })

  const _ref = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => setIsOpen(true);
  const handleMouseOut = () => setIsOpen(false);
  const handleMouseMove = (e: MouseEvent) => setPos({ x: e.clientX, y: e.clientY });

  useEffect(() => {
    const node = _ref.current;

    node?.addEventListener('mouseover', handleMouseOver);
    node?.addEventListener('mouseout', handleMouseOut);
    node?.addEventListener('mousemove', handleMouseMove);

    return () => {
      node?.removeEventListener('mouseover', handleMouseOver);
      node?.removeEventListener('mouseout', handleMouseOut);
      node?.removeEventListener('mousemove', handleMouseMove);
    };
  });

  return (
    <div ref={_ref} style={style} className={className}>
      {children}
      {isOpen && !deactivated &&
        <HighlightedTip
          x={pos.x - 100}
          y={pos.y + 30}
          text={text}
        />
      }
    </div>
  );
}

interface Props {
  children: ReactElement;
  deactivated?: boolean | undefined;
  text: any;
  style?: any;
  className?: string;
}

export default HoverComponent;