import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const CustomSelect = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
      icon: {
          fill: "#ef5d33",
      },
    minWidth: 285,
    borderRadius: 40,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '1px solid #ef5d33',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginRight: 10,
    fontSize: 20,

    fontWeight: 600,
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 40,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderColor: '#29b6e5',
      backgroundColor: '#FFF',
    },
  },
}))(InputBase);

export default CustomSelect;