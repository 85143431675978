
import React from 'react';

interface Props {
    text: string;
    closeBox: Function;
}

const InfoBox = ({ text, closeBox }: Props) => {
    return (
        <div className="info-box">
            <div className="info-box__icon"><i className="fas fa-info" /></div>
            <div className="info-box__text">{text}</div>
            <div className="info-box__close" onClick={() => closeBox('infoBoxSeen')}><i className="fas fa-times-circle" /></div>
        </div>
    )
}

export default InfoBox;