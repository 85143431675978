import React, { useEffect, useMemo, useState } from "react";
import AdsThumbnail from "../AdsThumbnail/AdsThumbnail";
import ReactPaginate from "react-paginate";
import * as service from "../../../../Services/ads.services";
import Loader from "react-loader-spinner";

export interface AdsThumbProps {
  tab: "active" | "expired" | "hired" | "closed";
  ads: { active: AdsResponse; expired: AdsResponse; hired: AdsResponse; closed: AdsResponse };
  user: UserProps;
  handleMoveMission: Function;
  cantEditIsOpen?: Boolean;
  onMouseEnter: Function;
  onMouseLeave: Function;
  isDemoAccount?: Boolean;
  source?: string | null | undefined;
  substitute?: SubstituteProps;
  handleSelect?: Function;
  publicViewTip?: Boolean;
  handleGetMission?: Function;
}

const AdsList = (props: AdsThumbProps) => {
  const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [ads, setAds] = useState<AdsResponse>(props.ads[props.tab]);
  const [page, setPage] = useState<number>(0);

  const numberOfPage = useMemo<number>(
    () => (props.ads[props.tab].total ? Math.ceil(props.ads[props.tab].total / 10) : 0),
    [props.ads, props.tab]
  );

  const handleGetAds = async (page: number) => {
    setIsLoading(true);

    if (props.tab === "active" && props.isDemoAccount)
      setAds(await service.getActiveDemoAds({ page: page }));

    if (props.tab === "active" && !props.isDemoAccount)
      setAds(await service.getActiveAds({ page: page }));

    if (props.tab === "hired") setAds(await service.getHiredAds({ page: page }));

    if (props.tab === "expired") setAds(await service.getExpiredAds({ page: page }));

    if (props.tab === "closed") setAds(await service.getClosedAds({ page: page }));

    setIsLoading(false);
  };

  useEffect(() => {
    !isInitialFetch
      ? (async () => handleGetAds(page))()
      : (() => {
          setIsInitialFetch(false);
          setIsLoading(false);
        })();
  }, [page]);

  useEffect(() => {
    setAds(props.ads[props.tab]);
    setPage(0);
  }, [props.tab, props.ads]);

  return (
    <section className="list-adsthumbnail">
      {!isLoading ? (
        ads.data.map((ads, index) => (
          <AdsThumbnail
            key={index}
            ads={ads}
            user={props.user}
            tab={props.tab}
            source={props.source}
            substitute={props.substitute}
            handleSelect={props.handleSelect}
            handleMoveMission={props.handleMoveMission}
            cantEditIsOpen={props.cantEditIsOpen}
            publicViewTip={props.publicViewTip}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            handleGetMission={props.handleGetMission}
          />
        ))
      ) : (
        <div className={"loader"}>
          <Loader type="Puff" color="#29b6e5" height={45} width={45} />
        </div>
      )}
      {numberOfPage > 1 && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={numberOfPage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={(page) => setPage(page.selected)}
          containerClassName={"pagination pagination-ads"}
          activeClassName={"active"}
          forcePage={page}
        />
      )}
    </section>
  );
};

export default AdsList;
