//ASSETS

//DEPENDENCIES
import React from "react";
import { fetchApi } from "../../../Services/Contexts/Api";
import axios from "axios";

//COMPONENTS
import Candidate from "./Candidate/Candidate";

//HOOKS
import { useState, useEffect } from "react";

//TYPESCRIPT
interface Props {
  user: UserProps;
  activeAds: MissionProps[] | null;
  mission: MissionProps | null;
  handlePrevPage: Function;
  handleUpdateSeenSubscription: Function;
}

const Candidates = (props: Props) => {
  // PROPS
  const {
    mission,
    handlePrevPage,
    handleUpdateSeenSubscription,
    activeAds,
    user
  } = props;

  //STATES
  const [missionSubscribers, setMissionsSubscribers] = useState(
    mission!.missionSubscribers
  );
  const [isLoading, setIsLoading] = useState(false); // manage loader display after accepting or rejecting a candidate

  //USE-EFFECT
  useEffect(() => {
    const subscriptionsIDs = mission?.missionSubscribers
      .filter(sub => !sub.seenAt)
      .map(_sub => _sub._id);

    if (subscriptionsIDs && subscriptionsIDs.length > 0) {
      fetchApi("/missions-subscriptions/updateSeenStatus", "PUT", {
        subscriptions: subscriptionsIDs
      });
    }
    handleUpdateSeenSubscription();
  }, []);

  //FUNCTIONS
  const handleRefuseCandidate = (subscriptionId: string) => {
    setIsLoading(true);
    axios
      .put(
        `${global.config.api}/missions-subscriptions/${subscriptionId}/change-status`,
        {
          status: "REFUSED"
        },
        {
          headers: {
            Authorization: "Bearer " + user.token
          }
        }
      )
      .then(response => {
        if (response.status === 200) {
          const subscribers = [...missionSubscribers];
          const index = subscribers.map(e => e._id).indexOf(subscriptionId);

          subscribers![index].status = "REFUSED";

          subscribers && setMissionsSubscribers(subscribers);
        }
        setIsLoading(false);
      });
  };

  const handleAcceptCandidate = (
    subscriptionId: string
  ) => {
    setIsLoading(true);
    axios
      .put(
        `${global.config.api}/missions-subscriptions/${subscriptionId}/accept`,
        {
          holder: {
            email: mission?.holder.email,
            firstName: mission?.holder.firstName,
            lastName: mission?.holder.lastName,
            id: mission?.holder.id,
            gender: mission?.holder.gender
          }
        },
        {
          headers: {
            Authorization: "Bearer " + user.token
          }
        }
      )
      .then(response => {
        if (response.status === 200) {
          const subscribers = [...missionSubscribers];
          const index = subscribers.map(e => e._id).indexOf(subscriptionId);

          subscribers![index].status = "ACCEPTED";

          subscribers && setMissionsSubscribers(subscribers);
        }
        setIsLoading(false);
      });
  };

  //RENDER
  return (
    <section className="candidates-page content">
      <div className="container">
        <h1 className="mission-title">
          Candidatures pour la mission {mission?.title}
        </h1>
        <div className="backward" onClick={() => handlePrevPage()}>
          <i className="fas fa-chevron-left" />
          <span>Retour</span>
        </div>
        {missionSubscribers?.map(
          subscriber =>
            (subscriber.status === "ACCEPTED" ||
              subscriber.status === "PENDING" ||
              subscriber.status === "REFUSED" ||
              subscriber.status === "CANCELED") && (
              <Candidate
                key={subscriber._id}
                mission={mission}
                subscriber={subscriber}
                activeMissions={activeAds}
                isLoading={isLoading}
                handleRefuseCandidate={handleRefuseCandidate}
                handleAcceptCandidate={handleAcceptCandidate}
              />
            )
        )}
      </div>
    </section>
  );
};

export default Candidates;
