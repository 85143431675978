import React from "react";
import Button from "../../../Components/Forms/Buttons/Button/Button";
import logo from "../../../assets/img/logo-ecriture-noir.png";
import { Link } from "react-router-dom";

const ResetSucces = () => {
  return (
    <div className="content slide-two">
      <img src={logo} alt="App'Ines" className="top-logo" />
      <div className="reset-title">Nous avons bien reçu votre demande</div>
      <div className="text">
        Si vous êtes client, nous vous enverrons immédiatement un e-mail contenant les instructions
        pour réinitialiser votre mot de passe. Si vous ne vous souvenez plus de votre email
        de connexion, écrivez-nous à support@appines.fr.
      </div>
      <Link to="/authentification">
        <Button className="green" text="Compris !" condition={true} />
      </Link>
    </div>
  );
};

export default ResetSucces;
