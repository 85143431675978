// DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";

// COMPONENTS
import Button from "../../../../Components/Forms/Buttons/Button/Button";
import MessageInputForm from "../../../Discussions/MessageInputForm/MessageInputForm";

// ASSETS
import { useHistory } from "react-router-dom";
import { fetchApi } from "../../../../Services/Contexts/Api";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import {
  generateString,
  handlePressKey,
} from "../../../Discussions/CurrentDiscussion/CurrentDiscussion";
import { ILastConnection } from "../../../Discussions/IDiscussions";

interface DiscussionInfosProps {
  substitute: SubstituteProps | undefined;
  hideTitle?: Boolean;
}

const DiscussionInfos = (props: DiscussionInfosProps) => {
  const [discussionId, setDiscussionId] = useState<string>("");
  const [discussionIsArchived, setDiscussionIsArchived] = useState(false);
  const [isLoading, setLoading] = useState<Boolean>();
  const [displayMessageModal, setDisplayMessageModal] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [canResumeDiscussion, setCanResumeDiscussion] = useState<Boolean>();
  const [canCreateDiscussion, setCanCreateDiscussion] = useState<Boolean>();
  const [isDemo, setIsDemo] = useState<Boolean>(false);
  const [showCallToAction, setShowCallToAction] = useState<Boolean>(false);

  const { substitute, hideTitle } = props;

  const context: AuthProps = useContext(AuthContext);

  const history = useHistory();

  const handleSendMessage = (event: KeyboardEvent | any) => {
    event.preventDefault();

    if (newMessage !== "") {
      setLoading(true);
      setDisplayMessageModal(false);
      setNewMessage("");

      // normal message
      if (discussionId) {
        fetchApi("/messages", "POST", {
          discussionId: discussionId,
          message: newMessage,
          returnId: generateString(),
        }).then(() => {
          setLoading(false);
          setDisplayConfirmationModal(true);
        });
      }

      // premium message
      if (!discussionId) {
        fetchApi("/discussion/premium", "POST", {
          receiverId: substitute?._id,
          message: newMessage,
        }).then((response) => {
          setLoading(false);
          setDiscussionId(response.data._id);
          setDisplayConfirmationModal(true);
        });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    setNewMessage(event.target.value);
    setCursorPosition(event.target.selectionStart);
  };

  const handleRedirectToDiscussion = (archive: Boolean = false, discussionId: string) => {
    history.push(`/discussions/${discussionId}?archive=${archive.toString()}`);
  };

  const handlePopBarMessage = (action: "create" | "resume" | "demo") => {
    if (action === "create") {
      context.popBarMessage("Vous devez souscrire à l'offre Happy pour créer une discussion");
    }

    if (action === "resume") {
      // prettier-ignore
      context.popBarMessage("Vous devez souscrire à l'offre Smile ou Happy pour reprendre une discussion");
    }

    if (action === "demo") {
      // prettier-ignore
      context.popBarMessage("🚫 Vous utilisez un compte de démonstration");
    }
  };

  // Fetch by clicking on the button, in candidate page
  const handleFetchDiscussion = () => {
    setLoading(true);

    fetchApi(
      `/discussion/get-by-participants?participants=${substitute?._id}&participants=${context.state.user.id}`
    )
      .then((res) => {
        setDiscussionId(res.data._id);
        setShowCallToAction(true);
        setDiscussionIsArchived(res.data.lastConnection?.find((c: ILastConnection) => c.userId === context.state.user.id)?.archive || false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Fetch by useEffect on profile page
  useEffect(() => {
    if (!hideTitle) {
      handleFetchDiscussion();
    }
  }, []);

  useEffect(() => {
    const regex = new RegExp("demo@appines.fr", "i");
    context?.state?.email && setIsDemo(regex.test(context.state.email));
  }, [context?.state?.email]);

  useEffect(() => {
    setCanResumeDiscussion(
      context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
    );
  }, [context.state.currentPlan]);

  useEffect(() => {
    setCanCreateDiscussion(context.state.currentPlan === "happy" && !isDemo);
  }, [context.state.currentPlan, isDemo]);

  return (
    <div
      className={`discussions-infos${hideTitle ? " hide-title" : ""}`}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {!hideTitle && (
        <>
          <h2>
            <span role={"img"} className={"emoji"}>
              💬
            </span>
            Discutez avec {substitute?.firstName}
            <span className={"new-feature"}>✨ Nouveau !</span>
          </h2>

          <div className={"light-text"}>
            ✨ Grâce à notre messagerie sécurisée, vous pouvez désormais échanger avec{" "}
            {substitute?.firstName} directement depuis votre espace !
          </div>
        </>
      )}

      {/*-- CALL TO ACTION --*/}
      <div className="call-to-action">
        {isLoading && <Loader type={"Puff"} color={"#29b6e5"} width={50} height={50} />}

        {!showCallToAction && hideTitle && !isLoading && (
          <Button
            iconLeft={"💬 "}
            condition={true}
            onClick={() => handleFetchDiscussion()}
            text={`Envoyez un message à ${substitute?.firstName}`}
            className={"validate-blue small"}
          />
        )}

        {/*-- SHOW CALL TO ACTION --*/}
        {/*discussion already exists*/}
        {discussionId && !isLoading && showCallToAction && (
          <>
            {!substitute?.visibility.isVisible && substitute?.visibility.to && (
              <div>
                Profil indisponible jusqu'au{" "}
                {new Date(substitute?.visibility.to).toLocaleDateString()}
              </div>
            )}
            Vous avez une discussion {discussionIsArchived ? "archivée" : "en cours"} avec{" "}
            {substitute?.firstName}
            <Button
              iconLeft={canResumeDiscussion ? "👩‍💻 " : "🔒 "}
              condition={true}
              onClick={() => handleRedirectToDiscussion(discussionIsArchived, discussionId)}
              text={"Ouvrir la discussion"}
              className={"validate-blue small"}
            />
          </>
        )}

        {/*have to create discussion*/}
        {!discussionId && !isLoading && showCallToAction && (
          <>
            {!substitute?.visibility.isVisible && substitute?.visibility.to
              ? `Profil indisponible jusqu'au ${new Date(
                  substitute?.visibility.to
                ).toLocaleDateString()}`
              : `Vous n'avez jamais échangé avec ${substitute?.firstName}`}
            <Button
              iconLeft={canCreateDiscussion && substitute?.visibility.isVisible ? "✨ " : "🔒 "}
              condition={!!substitute?.visibility.isVisible}
              onClick={
                canCreateDiscussion
                  ? () => setDisplayMessageModal(true)
                  : () => handlePopBarMessage(isDemo ? "demo" : "create")
              }
              text={`Créer une nouvelle discussion avec ${substitute?.firstName}`}
              className={canCreateDiscussion ? "validate-blue small" : "forbidden small"}
              className2={"forbidden small"}
            />
          </>
        )}
      </div>

      {/*-- MODAL --*/}
      {/* message input */}
      <div className={"modal"} style={{ display: displayMessageModal ? "flex" : "none" }}>
        <i className={"fa far fa-times"} onClick={() => setDisplayMessageModal(false)} />
        Envoyez votre message à {substitute?.firstName}. Vous retrouverez vos échanges dans l'onglet
        Discussions
        <MessageInputForm
          inputValue={newMessage}
          handleInputChange={handleInputChange}
          handleSendMessage={(discussionId: string) => handleSendMessage(discussionId)}
          handlePressKey={(event) =>
            handlePressKey(event, newMessage, setNewMessage, setCursorPosition)
          }
          cursorPosition={cursorPosition}
          placeholder={
            'Saisissez votre message et cliquez sur "Envoyer"\n\n' +
            `"Bonjour ${substitute?.firstName},\n` +
            `J\'aurai une mission à vous proposer, êtes-vous disponible pour en discuter ?\n\n${context.state.user.firstName}"`
          }
        />
      </div>

      {/* sending confirmation */}
      <div className={"modal"} style={{ display: displayConfirmationModal ? "flex" : "none" }}>
        <i className={"fa far fa-times"} onClick={() => setDisplayConfirmationModal(false)} />
        Votre message a bien été envoyé ! ✅
        <Button
          iconLeft={"👩‍💻 "}
          condition={true}
          onClick={() => {
            history.push(`/discussions/${discussionId}`);
          }}
          text={"Ouvrir la discussion"}
          className={"validate-blue small"}
        />
        <Button
          iconLeft={"⏪ "}
          condition={true}
          onClick={() => setDisplayConfirmationModal(false)}
          text={"Précédent"}
          className={"validate-blue small"}
        />
      </div>
    </div>
  );
};

export default DiscussionInfos;
