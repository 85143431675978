import React from "react";
import AuthTextInput from "../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import { OfficeState } from "./OfficePage";
import { Editor } from "react-draft-wysiwyg";
import prefixes from "../../assets/prefixes.json";
import { validatePhoneNumber } from "../../Tools/MathTools";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ValidButton from "../../Components/Forms/Buttons/ValidButton/ValidButton";
import Loader from "react-loader-spinner";
import GeneralSocialLinks from "./generalSocialLinks";
import CheckBox from "../../Components/Forms/Inputs/CheckBox/CheckBox";

interface Props extends OfficeState {
  updateGeneralInfos: Function;
}

export interface GeneralInfosState {
  name: string | null;
  parentOffice: string | null;
  number: string | null;
  street: string | null;
  zipCode: string | null;
  city: string | null;
  phoneNumber: string | null;
  editorState: EditorState | undefined;
  isUrbanFreeZone: boolean;
  isRuralDevelopmentZone: boolean;
  description: string | null;
  socialLinks: {
    facebook?: string;
    linkedin?: string;
    web?: string;
    youtube?: string;
    twitter?: string;
    instagram?: string;
  };
  countryCode?: string;
}

interface State extends GeneralInfosState {
  displayDetails: boolean;
}

export default class GeneralInfos extends React.Component<Props, State> {
  state = {
    displayDetails: true,
    name: this.props.name,
    parentOffice: this.props.parentOffice,
    number: this.props.number,
    street: this.props.street,
    zipCode: this.props.zipCode,
    city: this.props.city,
    countryCode: this.props.countryCode,
    phoneNumber: this.props.phoneNumber,
    editorState: this.props.editorState,
    description: this.props.description,
    isUrbanFreeZone: this.props.isUrbanFreeZone,
    isRuralDevelopmentZone: this.props.isRuralDevelopmentZone,
    socialLinks: {
      facebook: this.props.socialLinks.facebook,
      linkedin: this.props.socialLinks.linkedin,
      web: this.props.socialLinks.web,
      youtube: this.props.socialLinks.youtube,
      twitter: this.props.socialLinks.twitter,
      instagram: this.props.socialLinks.instagram
    }
  };

  handleInput = (event: any) => {
    const { name, value } = event.target as { name: keyof State; value: any };
    if (name === "phoneNumber" && value.length < 11) {
      this.setState({ ...this.state, [name]: value });
    } else if (name === "phoneNumber" && value >= 12) {
      this.setState({ phoneNumber: this.state.phoneNumber });
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  };

  updateSocialLinks = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      socialLinks: {
        ...this.state.socialLinks,
        [name]: value
      }
    });
  };

  toggleCheckbox = (name: keyof State) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name]
    });
  };

  convertDescription = (description: EditorState) => {
    let draft = convertToRaw(description.getCurrentContent());

    const newBlocks = draft.blocks.map(block => {
      return { ...block, text: block.text.trim() };
    });

    const newDraft = { ...draft, blocks: newBlocks };

    return draftToHtml(newDraft).replace(/\n/g, "<br/>");
  };

  handleEditorInput = (description: EditorState) => {
    this.setState({ editorState: description });
    this.setState({ description: this.convertDescription(description) });
  };

  saveChange = () => {
    this.props.updateGeneralInfos(this.state);
  };

  render() {
    return (
      <div>
        <div className="subtitle">
          <h3>Informations générales</h3>
          {this.state.displayDetails ? (
            <i
              onClick={() =>
                this.setState({ displayDetails: !this.state.displayDetails })
              }
              className="fas fa-chevron-circle-down"
            />
          ) : (
            <i
              onClick={() =>
                this.setState({ displayDetails: !this.state.displayDetails })
              }
              className="fas fa-chevron-circle-right"
            />
          )}
        </div>
        {this.state.displayDetails && (
          <div>
            <div className="step-block">
              <h4>Coordonnées</h4>
              <div className="address">
                {/*marge en attendant le parent office*/}
                <div style={{ marginRight: "300px" }}>
                  <AuthTextInput
                    condition={!!this.state.name}
                    value={this.state.name}
                    placeHolder="Nom de l'établissement"
                    type="text"
                    minLength={1}
                    disabled={true}
                    isList={false}
                  />
                </div>
                {this.state.parentOffice && (
                  <AuthTextInput
                    value={this.state.parentOffice}
                    placeHolder="Numéro FINESS"
                    type="text"
                    minLength={1}
                    condition={!!this.state.parentOffice}
                    disabled={true}
                    isList={false}
                  />
                )}
                <AuthTextInput
                  value={this.state.number}
                  name="number"
                  handleInput={this.handleInput}
                  placeHolder="Numéro de rue"
                  type="text"
                  minLength={1}
                  condition={!!this.state.number}
                  disabled={false}
                  isList={false}
                />
                <AuthTextInput
                  value={this.state.street}
                  name="street"
                  handleInput={this.handleInput}
                  placeHolder="Nom de la voie"
                  type="text"
                  minLength={1}
                  condition={!!this.state.street}
                  disabled={false}
                  isList={false}
                />
                <AuthTextInput
                  value={this.state.zipCode}
                  name="zipCode"
                  handleInput={this.handleInput}
                  placeHolder="Code postal"
                  type="text"
                  minLength={1}
                  condition={!!this.state.zipCode}
                  disabled={false}
                  isList={false}
                />
                <AuthTextInput
                  value={this.state.city}
                  name="city"
                  handleInput={this.handleInput}
                  placeHolder="Ville"
                  type="text"
                  minLength={1}
                  maxLength={50}
                  condition={!!this.state.city}
                  disabled={false}
                  isList={false}
                />
              </div>
              <div className="full-phone-number">
                {/* <AuthTextInput
                          name="countryCode"
                          value={this.state.countryCode}
                          placeHolder="Indicatif"
                          type="text"
                          minLength={1}
                          condition={this.state.countryCode}
                          disabled={true}
                          isList={true}
                        /> */}
                <div className="country-selector">
                  <select
                    onChange={event => this.handleInput(event)}
                    name="countryCode"
                    id="country-code"
                    value={this.state.countryCode}>
                    {prefixes.map(prefix => (
                      <option key={prefix.name} value={prefix.dial_code}>
                        {prefix.dial_code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="phone-number">
                  <AuthTextInput
                    placeHolder="Téléphone (ex: 0655555555)"
                    type="number"
                    minLength={9}
                    maxLength={10}
                    handleInput={this.handleInput}
                    value={this.state.phoneNumber}
                    name="phoneNumber"
                    condition={
                      !!(
                        (this.state.phoneNumber &&
                          this.state.phoneNumber === "") ||
                        (this.state.phoneNumber &&
                          this.state.phoneNumber.length >= 9)
                      ) &&
                      validatePhoneNumber(
                        this.state.phoneNumber,
                        this.state.countryCode
                      )
                    }
                    disabled={false}
                    isList={false}
                  />
                </div>
              </div>
              <div className="general-area-infos">
                <CheckBox
                  condition={!!this.state.isRuralDevelopmentZone}
                  text="Zone de Revitalisation Rurale"
                  name="isRuralDevelopmentZone"
                  className="square"
                  handleCheckBox={this.toggleCheckbox}
                />
                <CheckBox
                  condition={!!this.state.isUrbanFreeZone}
                  name="isUrbanFreeZone"
                  text="Zone Franche Urbaine"
                  className="square"
                  handleCheckBox={this.toggleCheckbox}
                />
              </div>
              <Editor
                toolbar={{
                  options: ["inline", "blockType", "list"],
                  inline: {
                    options: ["bold", "italic", "underline"]
                  },
                  blockType: {
                    options: ["Normal"]
                  },
                  list: {
                    options: ["unordered", "ordered"]
                  },
                  fontSize: {
                    options: [16]
                  },
                  fontFamily: {
                    options: ["Tahoma"]
                  },
                  textAlign: {
                    options: ["left"]
                  }
                }}
                toolbarOnFocus
                placeholder={
                  "Décrivez de manière exhaustive votre établissement.\n\n" +
                  "Le copier/coller de votre site internet n'est pas recommandé." +
                  "Pour ne pas être pénalisé par les moteurs de recherche, veuillez écrire du contenu original.\n\n" +
                  "Présentez les services, les avantages de votre établissement, l'accès en transport et tout ce qui peut vous permettre de vous démarquer des autres centres de soins du secteur."
                }
                editorState={this.state.editorState}
                toolbarClassName="toolbar"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={this.handleEditorInput}
              />
              <GeneralSocialLinks
                updateLinks={this.updateSocialLinks}
                socialLinks={this.state.socialLinks}
              />
            </div>
            <div className="button-loader">
              <ValidButton
                onClick={this.saveChange}
                text={this.props.hasSaved ? "Enregistré" : "Enregistrer"}
                condition={true}
                className={
                  this.props.isSaving
                    ? "validate-orange"
                    : this.props.hasSaved
                    ? "validate-green"
                    : "validate-blue"
                }
              />
              {this.props.isSaving && (
                <div className="loader">
                  <Loader type="Puff" color="#29b6e5" height={30} width={30} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
