// DEPENDENCIES
import React, { useState, useEffect } from "react";

const Simulator = (props: any) => {
  /* --- Props --- */
  const { pricing, switchValues, showSimulator, handleShowSimulator, contratTypeSelected, setSelectedType } = props;

  /* --- States --- */
  const [salaryPeriodicity, setSalaryPeriodicity] = useState("mensuel");
  const [monthlyReferenceSalary, setMonthlyReferenceSalary] = useState(0);
  const [annualyReferenceSalary, setAnnualyReferenceSalary] = useState(0);
  const [candidateRemuneration, setCandidateRemuneration] = useState(0);

  const [missionDuration, setMissionDuration] = useState(1);

  const [durationIsVisible, setDurationIsVisible] = useState(false);
  const [summaryIsVisible, setSummaryIsVisible] = useState(false);

  const [amount, setAmount] = useState(0);

  /* --- Handlers --- */
  const handleCurrency = (amount: number) => {
    return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(amount);
  };

  const handleDurationVisibility = () => {
    if (
      (contratTypeSelected === "cdd" && salaryPeriodicity === "mensuel" && monthlyReferenceSalary > 1500) ||
      (contratTypeSelected === "cdd" && salaryPeriodicity === "annuel" && annualyReferenceSalary > 15000)
    ) {
      setDurationIsVisible(true);
    } else {
      setDurationIsVisible(false);
    }
  };

  const handleSummaryVisibility = () => {
    if (
      (contratTypeSelected === "cdd" && durationIsVisible) ||
      (contratTypeSelected === "cdi" && salaryPeriodicity === "mensuel" && monthlyReferenceSalary > 1500) ||
      (contratTypeSelected === "cdi" && salaryPeriodicity === "annuel" && annualyReferenceSalary > 15000)
    ) {
      setSummaryIsVisible(true);
    } else {
      setSummaryIsVisible(false);
    }
  };

  const handleCandidateRemuneration = () => {
    if (contratTypeSelected === "cdd" && salaryPeriodicity === "mensuel") {
      setCandidateRemuneration(missionDuration * monthlyReferenceSalary);
    }

    if (contratTypeSelected === "cdd" && salaryPeriodicity === "annuel") {
      setCandidateRemuneration((missionDuration * annualyReferenceSalary) / 12);
    }

    if (contratTypeSelected === "cdi" && salaryPeriodicity === "mensuel") {
      setCandidateRemuneration(monthlyReferenceSalary * 12);
    }

    if (contratTypeSelected === "cdi" && salaryPeriodicity === "annuel") {
      setCandidateRemuneration(annualyReferenceSalary);
    }
  };

  const handleAmount = () => {
    if (contratTypeSelected === "cdd" && salaryPeriodicity === "mensuel") {
      const amount = Number((monthlyReferenceSalary * missionDuration * pricing.zen.cdd.commission) / 100);
      setAmount(amount);
    }

    if (contratTypeSelected === "cdd" && salaryPeriodicity === "annuel") {
      const amount = Number(((annualyReferenceSalary / 12) * missionDuration * pricing.zen.cdd.commission) / 100);
      setAmount(amount);
    }

    if (contratTypeSelected === "cdi" && salaryPeriodicity === "mensuel") {
      const amount = (monthlyReferenceSalary * 12 * pricing.zen.cdi.commission) / 100;
      setAmount(amount);
    }

    if (contratTypeSelected === "cdi" && salaryPeriodicity === "annuel") {
      const amount = (annualyReferenceSalary * pricing.zen.cdi.commission) / 100;
      setAmount(amount);
    }
  };

  /* --- Side effects --- */
  useEffect(() => {
    handleDurationVisibility();
    handleSummaryVisibility();
    handleCandidateRemuneration();
    handleAmount();
  }, [contratTypeSelected, durationIsVisible, missionDuration, monthlyReferenceSalary, annualyReferenceSalary, salaryPeriodicity]);

  return (
    <div className="pricing-simulator" style={{ opacity: showSimulator ? "1" : "0", pointerEvents: showSimulator ? "auto" : "none" }}>
      <div className="content">
        <i
          className="fas fa-times fa-2x"
          style={{ position: "absolute", top: "25px", right: "25px" }}
          onClick={() => handleShowSimulator()}></i>

        <h1>Simuler votre tarif</h1>

        {/* CONTRACT TYPE */}
        <div>
          <div>Type de contrat à pourvoir :</div>
          <div className="selector">
            {Object.entries(switchValues.zen).map((type: any, index: number) => {
              //Object.entries({a: 0, c: 2, b: 1}) <=> [[a, 0], [c, 2], [b, 1]] | array[0] : key | array[1] : value
              return (
                <button
                  key={index}
                  className={`zen ${type[0] === contratTypeSelected && "selected"}`}
                  onClick={() => {
                    setSelectedType(type[0]);
                  }}>
                  {type[1]}
                </button>
              );
            })}
          </div>
        </div>

        {/* SALARY */}
        <div style={{ visibility: contratTypeSelected ? "visible" : "hidden" }}>
          <div>Salaire {salaryPeriodicity} brut de référence :</div>
          <div>
            <input
              type="number"
              value={salaryPeriodicity === "mensuel" ? monthlyReferenceSalary : annualyReferenceSalary}
              onChange={(e: any) => {
                salaryPeriodicity === "mensuel" ? setMonthlyReferenceSalary(e.target.value) : setAnnualyReferenceSalary(e.target.value);
              }}
              step={100}
            />
            <span>€ </span>
            <span>
              <div className="selector">
                <button className={`zen ${salaryPeriodicity === "mensuel" && "selected"}`} onClick={() => setSalaryPeriodicity("mensuel")}>
                  mensuel
                </button>
                <button className={`zen ${salaryPeriodicity === "annuel" && "selected"}`} onClick={() => setSalaryPeriodicity("annuel")}>
                  annuel
                </button>
              </div>
            </span>
          </div>
        </div>

        {/* DURATION */}
        <div
          style={{
            visibility: durationIsVisible ? "visible" : "hidden"
          }}>
          <div>Durée de la mission :</div>
          <div className="range">
            <input
              type="range"
              value={missionDuration}
              onChange={(e: any) => setMissionDuration(e.target.value)}
              step={0.5}
              min="1"
              max="36"
            />
            <input
              type="number"
              value={missionDuration}
              onChange={(e: any) => setMissionDuration(e.target.value)}
              min="1"
              max="36"
              step={0.5}
            />
            <span>mois</span>
          </div>
        </div>

        {/* SUMMARY */}
        <div
          className="summary"
          style={{
            visibility: summaryIsVisible ? "visible" : "hidden"
          }}>
          <div>Type de contrat : {contratTypeSelected.toUpperCase()}</div>
          <div style={{ visibility: contratTypeSelected === "cdd" && summaryIsVisible ? "visible" : "hidden" }}>
            Durée de la mission : {missionDuration} mois
          </div>
          <div>
            Rémunération du candidat : {handleCurrency(candidateRemuneration)} {contratTypeSelected === "cdd" ? "brut" : "brut annuel"}
          </div>

          <div className="amount">Coût du recrutement App'Ines : {handleCurrency(amount)} HT</div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;
