export const reasons = [
  { front: "Vacances d'un praticien", back: "vacation" },
  { front: "Départ d'un praticien", back: "leave" },
  { front: "Formation d'un praticien", back: "formation" },
  { front: "Congés parentaux d'un praticien", back: "childleave" },
  { front: "Arrêt maladie d'un praticien", back: "disease" },
  { front: "Désistement d'un candidat", back: "discontinuance" },
  { front: "Surcroît d'activité", back: "too_much_activity" },
  {
    front: "Développement d'une nouvelle activité",
    back: "new_activity_development"
  },
  {
    front: "Baisse de l'activité d'un praticien présent",
    back: "practitian_less_active"
  },
  { front: "Arrêt de l'activité du titulaire responsable des soins", back: "practitian_stop_activity" },
  { front: "Autre", back: "other" }
];
