import React, { useContext, useMemo } from "react";
import SubstituteThumbnailGhost from "./SubstituteThumbnail/SubstituteThumbnailGhost";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import monkey from "../../../assets/img/substitutes-list/appines-singe-confus.svg";
import Skeleton from "react-loading-skeleton";
import SubstituteThumbnail from "./SubstituteThumbnail/SubstituteThumbnail";

const SubstitutesList = (props: SubstitutesListProps) => {
  const context = useContext(AuthContext);
  const searchResults = useMemo(() => {
    if (props.type === "holder")
      return props.count > 1
        ? ` • ${props.count} profils à contacter`
        : ` • ${props.count} profil à contacter`;

    let profileType: string = "";
    if (props.minXp === "student") profileType = props.count > 1 ? "étudiants" : "étudiant";
    if (props.minXp === "equivalence") profileType = "en cours d'équivalence";
    if (props.minXp === "graduated") profileType = props.count > 1 ? "diplômés" : "diplômé";

    if (props.isAvailable)
      return props.count > 1
        ? ` • ${props.count} profils ${profileType} en recherche`
        : ` • ${props.count} profil ${profileType} en recherche`;

    return props.count > 1
      ? ` • ${props.count} profils ${profileType} disponibles`
      : ` • ${props.count} profil ${profileType} disponible`;
  }, [props.isAvailable, props.count, props.minXp]);

  if (props.isLoading) {
    return (
      <div>
        <div className="all-substitutes">
          <div className="content">
            <div className="substitutes-result">
              <div className="total-substitutes">
                <Skeleton width={400} />
              </div>
              <div className="all-thumbnails">
                <SubstituteThumbnailGhost />
                <SubstituteThumbnailGhost />
                <SubstituteThumbnailGhost />
                <SubstituteThumbnailGhost />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="all-substitutes">
      <>
        <div className="content">
          {!props.count || props.count === 0 ? (
            <div className="no-result">
              <img src={monkey} alt="Singe surpris" className="surprised-monkey" />
              <p>Aucun profil ne correspond à votre recherche, essayez d'élargir vos filtres.</p>
            </div>
          ) : (
            <div className="substitutes-result">
              <div className="total-substitutes">
                <span>
                  {props.profession?.name[context.state.language] || "Toutes les professions"}
                </span>
                {searchResults}
              </div>
              <div className="all-thumbnails">
                {props.allSubstitutes.map((substitute: SubstituteProps) => (
                  <SubstituteThumbnail
                    isLoading={props.isLoading}
                    user={props.user}
                    substitute={substitute}
                    key={substitute._id}
                    handleGetPractitionerDetails={() =>
                      props.handleGetPractitionerDetails(substitute._id)
                    }
                    handleLike={props.handleLike}
                    handleUnlike={props.handleUnlike}
                    isHolder={substitute.type === "holder"}
                    liked={props.liked}
                  />
                ))}
                {props.preventScroll && props.allSubstitutes.length < props.count && (
                  <>
                    <SubstituteThumbnailGhost />
                    <SubstituteThumbnailGhost />
                  </>
                )}
              </div>

              <div
                className="up-arrow"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              >
                <i className="fas fa-arrow-up" />
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default SubstitutesList;

type SubstitutesListProps = {
  user: UserProps;
  handleGetPractitionerDetails: Function;
  handleLike: Function;
  handleUnlike: Function;
  isLoading: boolean;
  allSubstitutes: SubstituteProps[];
  profession: Profession | null;
  minXp: "student" | "equivalence" | "graduated";
  count: number;
  liked: { substituteId: string; date: Date }[];
  isAvailable: boolean;
  preventScroll: boolean;
  type: "holder" | "substitute";
};
