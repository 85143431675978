import React from "react";
import logo from "../../assets/img/logo-ecriture-noir.png";
import { NewMissionConsumer } from "../../Services/Contexts/NewMissionProvider";
import WhereAndWhat from "./Slides/Slide1/WhereAndWhat";
import Terms from "./Slides/Slide2/Terms";
import Description from "./Slides/Slide3/Description";
import Success from "./Slides/Slide4/Success";
import { Link } from "react-router-dom";

interface Props {
  user: UserProps;
  history: any;
  recoverData: Function;
  currentPlan: string;
}

interface State {
  loading: boolean;
  edit: boolean;
}

class NewMission extends React.Component<Props, State> {
  state = { loading: false, edit: false };

  render() {
    if (this.state.loading) return <p>plop</p>
    return (
      <NewMissionConsumer>
        {(context: newMissionProps) => (
          <div>
            <div className="create-mission">
              <div>
                <Link to="/remplacants">
                  <img src={logo} alt="App'Ines" className="top-logo" />
                </Link>
                {context.state.page >= 1 && context.state.page < 4 && (
                  <div
                    className="backward"
                    onClick={() => context.handlePreviousPage()}
                  >
                    <i className="fas fa-chevron-left" />
                    <span>Retour</span>
                  </div>
                )}
                {context.state.page === 1 && (
                  <div
                    className="backward"
                    onClick={() => {
                      context.wipeData();
                      window.history.back();
                    }}
                  >
                    <i className="fas fa-chevron-left" />
                    <span>Retour</span>
                  </div>
                )}
              </div>

              {context.state.page === 1 && (
                <WhereAndWhat office={this.props.user.office} isEdit={this.state.edit} userCurrentPlan={this.props.currentPlan}/>
              )}
              {context.state.page === 2 && (
                <Terms dateInit={context.dateInit} />
              )}
              {context.state.page === 3 && (
                <Description
                  userID={this.props.user.id}
                  officeID={this.props.user.office.id}
                  missionID={context.state._id}
                />
              )}
              {context.state.page === 4 && (
                <Success
                  initCreationPage={context.initCreationPage}
                  handleExitCreation={context.handleExitCreation}
                  isEdit={this.state.edit}
                  displayLauchCandidateSearchButton={context.state.displayLauchCandidateSearchButton}
                />
              )}

            </div>
          </div>
        )}
      </NewMissionConsumer>
    );
  }
  componentDidMount() {
    const adToEdit = this.props.history?.location?.state;
    if (adToEdit) {
      this.setState({ loading: true, edit: true })
      this.props.recoverData(adToEdit).then(() => this.setState({ loading: false }))
    }
  }
}

export default NewMission;
