import React, { useState, useEffect, useContext } from "react";
import HoverComponent from "../../Components/useHover";
import { shortenWord, renderDiplomaDate } from "../../Tools/MathTools";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Services/Contexts/AuthProvider";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import * as service from "../../Services/candidate.services";

// Images
import diploma from "../../assets/img/formation_2.svg";
import { placeholders } from "../../Constants/images";
import DiscussionInfos from "../SubstitutesPage/SubstitutePersonalPage/DiscussionInfos/DiscussionInfos";

interface TabMapGender {
  [name: string]: {
    [name: string]: string;
  };
}
interface CandidateThumbnail {
  tabSelected: string;
  substituteData: {
    substitute: SubstituteProps;
    lastMission: any;
  };
  key: number;
  onChange: Function;
}

export default function MyCandidatesThumbnail(props: CandidateThumbnail) {
  let history = useHistory();
  const context = useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    setIsLiked(!!props?.substituteData?.substitute?.isLiked);
  }, []);

  const getDateCaption = () => {
    switch (props?.tabSelected) {
      case "isFavorited":
        if (props?.substituteData?.substitute?.likedDate)
          return (
            sentenceCaption[props?.substituteData?.substitute?.gender][
              "isLiked"
            ] +
            moment(props?.substituteData?.substitute?.likedDate).format(
              "DD MMM YYYY"
            )
          );
        return null;

      case "isNotified":
        return (
          sentenceCaption[props?.substituteData?.substitute?.gender][
            "isNotified"
          ] +
          moment(props?.substituteData?.lastMission?.date).format("DD MMM YYYY")
        );

      case "isInterested":
        if (props?.substituteData?.lastMission?.social?.liked)
          return (
            sentenceCaption[props?.substituteData?.substitute?.gender][
              "likedMission"
            ] +
            moment(props?.substituteData?.lastMission?.date).format(
              "DD MMM YYYY"
            )
          );
        return (
          sentenceCaption[props?.substituteData?.substitute?.gender][
            "isInterested"
          ] +
          moment(props?.substituteData?.lastMission?.date).format("DD MMM YYYY")
        );

      case "isHired":
        if (props?.substituteData?.lastMission?.title)
          return (
            sentenceCaption[props?.substituteData?.substitute?.gender][
              "isHired"
            ] +
            `"${cropSentence(props?.substituteData?.lastMission?.title)}" le ` +
            moment(props?.substituteData?.lastMission?.date).format(
              "DD MMM YYYY"
            )
          );
        return (
          sentenceCaption[props?.substituteData?.substitute?.gender][
            "isHired"
          ] +
          moment(props?.substituteData?.lastMission?.date).format("DD MMM YYYY")
        );

      case "isRefused":
        return (
          sentenceCaption[props?.substituteData?.substitute?.gender][
            "isRefused"
          ] +
          moment(props?.substituteData?.lastMission?.date).format("DD MMM YYYY")
        );

      default:
        break;
    }
  };

  const cropSentence = (string: string, size?: number) => {
    let nbr = size || 34;
    return string.length > nbr ? string.slice(0, nbr) + "..." : string;
  };

  const firstUppercase = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const sentenceCaption: TabMapGender = {
    male: {
      isLiked: "Ajouté le ",
      isNotified: "Notifié par l'une de vos annonces le ",
      isInterested: "A consulté l'une de vos annonces le ",
      likedMission: "A mis en coup de coeur l'une de vos annonces le ",
      isHired: "A pourvu le poste ",
      isRefused: "Candidat refusé le "
    },
    female: {
      isLiked: "Ajoutée le ",
      isNotified: "Notifiée par l'une de vos annonces le ",
      isInterested: "A consultée l'une de vos annonces le ",
      likedMission: "A mise en coup de coeur l'une de vos annonces le ",
      isHired: "A pourvu le poste ",
      isRefused: "Candidate refusée le "
    }
  };

  const handleLike = () => {
    let id = props?.substituteData?.substitute?._id;

    if (id) {
      service.likePractitioner(id);
      setIsLiked(true);
      context.handleAddSubstituteToLikedList(id);
      props.onChange();
    }
  };

  const handleUnlike = () => {
    let id = props?.substituteData?.substitute?._id;

    if (id) {
      service.unlikePractitioner(id);
      setIsLiked(false);
      context.handleRemoveSubstituteToLikedList(id);
      props.onChange();
    }
  };

  const handleRedirectToCandidate = () => {
      history.push(`/remplacants/${props?.substituteData?.substitute?._id}`);
  };

  return (
    <div className="candidates_thumbnail" onClick={handleRedirectToCandidate}>
      {props.substituteData.substitute.professionId && (
        <div className="profession">
          <div className="profession-icon">
            <i className="far fa-hand-paper" />
          </div>
          <p>
            {context.state.allProfessions.find(
              (profession: Profession) =>
                profession._id === props.substituteData.substitute.professionId
            )?.name.FR || "Inconnue"}
          </p>
        </div>
      )}
      <div className="caption">
        {getDateCaption()}
      </div>

      <div className="avatar">
        <img
          alt="profil"
          src={
            props?.substituteData?.substitute?.picture?.thumbnail?.url
              ? props?.substituteData?.substitute?.picture?.thumbnail?.url
              : placeholders[
                  parseInt(
                    props?.substituteData?.substitute?.lastName
                      ?.charCodeAt(0)
                      .toString()
                      .slice(-1)
                  )
                ]
          }
        />
        {props?.substituteData?.substitute?.alert?.length > 0 && (
          <>
            <div className="is-available">
              <div className="blue-circle" />
              <div>En recherche</div>
            </div>
          </>
        )}
      </div>

      <div className="content">
        <div className="content"/>
        <div className="line1">
          <h4>
            {firstUppercase(props?.substituteData?.substitute?.firstName)}{" "}
            {props?.substituteData?.substitute?.lastName.substr(0, 1)}
          </h4>
          {props.substituteData.substitute.type === "holder" && (
            <div className="holder-badge">
              <i className="far fa-building"/>
              Titulaire
            </div>
          )}
        </div>
        <div className="line2">
          {props.substituteData?.substitute?.diploma ? (
            <>
              <ReactCountryFlag
                svg
                style={{ marginRight: 10 }}
                countryCode={
                  props.substituteData?.substitute?.diploma?.country || "FR"
                }
              />
              <span className="caption-graduation">
                {renderDiplomaDate(props?.substituteData?.substitute)}
              </span>
            </>
          ) : (
            <>
              <img src={diploma} alt="course" />
              <span
                className="caption-graduation no-graduation"
                style={{ marginRight: 10 }}>
                Pas de diplôme renseigné
              </span>
            </>
          )}
        </div>
        <div className="line3">
          <div className="skills">
            <i className="fas fa-book" />
            {props?.substituteData?.substitute?.skills?.length <= 2 ? (
              props?.substituteData?.substitute?.skills?.map(
                (skill: Skill, index: any) => {
                  return (
                    <div className="skill" key={index}>
                      {shortenWord(skill.name.FR, 22)}
                    </div>
                  );
                }
              )
            ) : props?.substituteData?.substitute?.skills?.length > 0 ? (
              <>
                <div className="skill">
                  {shortenWord(
                    props?.substituteData?.substitute.skills[0].name.FR,
                    22
                  )}
                </div>

                <HoverComponent
                  text={props?.substituteData?.substitute?.skills?.map(
                    (skill: any, index: any) => (
                      <div key={index}>{skill.name.FR}</div>
                    )
                  )}>
                  <div className="skill-popup">
                    {`+ ${props?.substituteData?.substitute?.skills?.length -
                      1}`}
                  </div>
                </HoverComponent>
              </>
            ) : (
              <div className="no-skill">Pas de compétence renseignée</div>
            )}
          </div>
        </div>
      </div>
      <div className="star">
        {isLiked ? (
          <>
            <i
              className="fas fa-star is-favorite"
              onClick={(e) => {
                e.stopPropagation();
                handleUnlike();
              }}
            />
          </>
        ) : (
          <>
            <i
              className="fas fa-star is-not-favorite"
              onClick={(e) => {
                e.stopPropagation();
                handleLike();
              }}
            />
          </>
        )}
      </div>

      <div className="contact-infos">
        <DiscussionInfos substitute={props.substituteData.substitute} hideTitle />
      </div>
    </div>
  );
}
