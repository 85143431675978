import React from "react";
import { Link } from "react-router-dom";
import profileOn from "../../../assets/img/user-nav/mon-profil-on.png";
import pricingOn from "../../../assets/img/user-nav/pricing-on.png";

interface Props {
  handleLogOut: Function;
  handleToggle: Function;
}

class UserMenu extends React.Component<Props> {
  render() {
    return (
      <nav className="user-menu">
        <Link
          to="/mon-profil"
          onClick={() => this.props.handleToggle("userNavIsOpen")}>
          <li>
            <img src={profileOn} alt="nav logo" />
            <div>Mon Profil</div>
          </li>
        </Link>

        {/* <Link
          to="/mes-alertes"
          onClick={() => this.props.handleToggle("userNavIsOpen")}
        >
          <li>
            <img
              src={alertsOn}
              alt="nav logo"
            />
            <div>Mes alertes</div>
          </li>
        </Link> */}

        <Link
          to="/pricing"
          onClick={() => this.props.handleToggle("userNavIsOpen")}>
          <li>
            <img src={pricingOn} alt="nav logo" />
            <div>Offres et tarifs</div>
          </li>
        </Link>

        {/* <a
          href="https://drive.google.com/open?id=1vDFB8fmtU9paE2JTiNgUdiA4uazo1DqH"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => this.props.handleToggle("userNavIsOpen")}
        >
          <li>
            <img
              src={faq}
              alt="nv logo"
            />
            <div>F.A.Q.</div>
          </li>
        </a> */}
        <div className="signout" onClick={() => this.props.handleLogOut()}>
          Se déconnecter
        </div>
      </nav>
    );
  }
}
export default UserMenu;
