import React from 'react'
import Skeleton from 'react-loading-skeleton';

export default function MyCandidatesThumbnailGhost() {
  return (
    <>
      <div className="candidates_thumbnail ghost">
        <div className="profession ghost">
        </div>

        <div className="caption">
          <Skeleton width={130} />
        </div>

        <div className="avatar">
          <Skeleton circle={true} height={130} width={130} />
        </div>

        <div className="content">
          <div className="content"></div>
          <div className="line1">
            <Skeleton width={130} />
          </div>
          <div className="line2 ghost">
            <Skeleton width={240} />
          </div>
          <div className="line3 ghost">
            <Skeleton width={270} />
          </div>
        </div>

        <div className="star ghost">
          <i className="fas fa-star ghost"/>
        </div>

        <div className="contact-infos">
          <hr></hr>
          <div className="contact-infos-details">
            <Skeleton width={130} />
            <Skeleton width={170} />
          </div>
        </div>
      </div>
  </>
  )
}
