// DEPENDENCIES
import React, { useState } from "react";
import dayjs from "dayjs";
import Loader from "react-loader-spinner";

// ASSETS
import { calculateResponseTime } from "../CurrentDiscussion/CurrentDiscussion";
import { fetchApi } from "../../../Services/Contexts/Api";

export const displayLastConnection = (date: string | Date) => {
  if (
    dayjs(date)
      .add(1, "day")
      .isAfter(dayjs())
  )
    return "Moins de 24h";

  if (
    dayjs(date)
      .add(1, "week")
      .isAfter(dayjs())
  )
    return "Moins d'une semaine";

  if (
    dayjs(date)
      .add(1, "month")
      .isAfter(dayjs())
  )
    return "Moins d'un mois";

  if (
    dayjs(date)
      .add(1, "month")
      .isBefore(dayjs())
  )
    return "Plus d'un mois";
};

interface TopDiscussionThumbnailProps {
  firstName: string;
  candidateActivity: {
    disconnectedAt?: string;
    isActive?: Boolean;
  };
  candidateGender: string;
  discussionId: string;
  archive: Boolean;
  replyRatePercentage?: number;
  replyTimeMillisecond?: number;
}

const TopDiscussionThumbnail = (props: TopDiscussionThumbnailProps) => {
  const {
    firstName,
    candidateActivity,
    candidateGender,
    replyRatePercentage,
    replyTimeMillisecond,
    discussionId,
    archive,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [archived, setArchived] = useState(archive);

  const handleArchiveDiscussion = () => {
    setLoading(true);

    fetchApi(`/discussions/archive/${discussionId}`, "PUT").then(() => {
      setLoading(false);
      setArchived(true);
    });
  };

  return (
    <div className={"top-discussion-thumbnail"}>
      <div className={"firstname"}>{firstName}</div>
      <div className={"stats"}>
        {replyTimeMillisecond && (
          <span>
            <span role={"img"} aria-label={"emoji-delay"}>
              ⏱
            </span>{" "}
            Délai de réponse : {calculateResponseTime(replyTimeMillisecond)}
          </span>
        )}
        {typeof replyRatePercentage === "number" && (
          <span>
            <span role={"img"} aria-label={"emoji-love-message"}>
              💌
            </span>{" "}
            Taux de réponse : {replyRatePercentage} %
          </span>
        )}
        {candidateActivity?.isActive && (
          <span>
            <span className={"isActive"} />
            {candidateGender === "male" ? "Connecté" : "Connectée"}
          </span>
        )}
        {!candidateActivity?.isActive && candidateActivity?.disconnectedAt && (
          <span>
            <span role={"img"} aria-label={"emoji-candidate"}>
              👩‍💻
            </span>{" "}
            Dernière connexion : {displayLastConnection(candidateActivity.disconnectedAt)}
          </span>
        )}

        {isLoading && (
          <span className={"loader"}>
            <Loader type={"Puff"} height={25} width={25} color={"#29b6e5"} />
          </span>
        )}

        {!isLoading && !archived && (
          <span className={"to-archive"} onClick={handleArchiveDiscussion}>
            Archiver la discussion
            <i className="fas fa-inbox" />
          </span>
        )}

        {!isLoading && archived && <span className={"archived"}>Archivée</span>}
      </div>
      <div className={"presentation"}>Votre messagerie enrichie avec {firstName}</div>
      <div className={"details"}>
        Grâce à cette espace sécurisé, centralisez tous vos échanges avec vos candidats, toute
        l'année
      </div>
    </div>
  );
};

export default TopDiscussionThumbnail;
