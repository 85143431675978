import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { placeholders } from "../../../../Constants/images";
import { renderDiplomaDate, shortenWord } from "../../../../Tools/MathTools";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import HoverComponent from "../../../../Components/useHover";

interface Props {
  isLoading: boolean;
  user: UserProps;
  liked: { substituteId: string; date: Date }[];
  substitute: SubstituteProps;
  key: string;
  handleGetPractitionerDetails: Function;
  handleLike: Function;
  handleUnlike: Function;
  isHolder: boolean;
}

export default function SubstituteThumbnail(props: Props) {
  /*
  ! React context and constants initialization
  */
  const context = useContext(AuthContext);
  /*
  ? useMemo : Returns a memoized value for performance optimization : https://reactjs.org/docs/hooks-reference.html#usememo
  */

  /* *Profession name*/
  const professionMemoized: string | undefined = useMemo(() => {
    const professionId = props.substitute.professionId;
    const index = context.state.allProfessions.map((e: Profession) => e._id).indexOf(professionId);
    return index ? context.state.allProfessions[index]?.name.FR : undefined;
  }, [props.substitute.professionId, context.state.allProfessions]);

  /* *Avatar url*/
  const pictureUrlMemoized: string = useMemo(() => {
    if (props.substitute?.picture?.thumbnail?.url) return props.substitute?.picture?.thumbnail?.url;

    //prettier-ignore
    return placeholders[parseInt(props.substitute.firstName?.charCodeAt(0).toString().slice(-1))];
  }, [props.substitute?.picture?.thumbnail?.url]);

  /* *Skills container formatted*/
  const skillsMemoized: JSX.Element = useMemo(() => {
    const skills = props.substitute.skills;

    if (!skills) return <div className="no-skills" />;

    if (skills.length <= 2)
      return (
        <div className="skills">
          {skills.map((skill, index) => (
            <div key={index} className="skill">
              {shortenWord(skill.name[context.state.language], 15)}
            </div>
          ))}
        </div>
      );

    return (
      <div className="skills">
        <div className="skill">{shortenWord(skills[0].name[context.state.language], 15)}</div>
        <HoverComponent
          text={skills.map((skill, index) => (
            <div key={index}>{skill.name[context.state.language]}</div>
          ))}
        >
          <div className="skill-popup">{`+ ${skills.length - 1}`}</div>
        </HoverComponent>
      </div>
    );
  }, [props.substitute.skills]);

  /* *Office location*/
  const officeLocationMemoized: JSX.Element | null = useMemo(() => {
    if (
      props.isHolder &&
      props?.substitute?.officeConcerned?.address?.city &&
      props?.substitute?.officeConcerned?.address?.zipCode
    )
      return (
        <>
          <i className="fas fa-map-marker-alt"></i>
          <p>{`${
            props.substitute.officeConcerned.address.city
          } (${props.substitute.officeConcerned.address.zipCode.slice(0, 2)})`}</p>
        </>
      );

    return null;
  }, [props.substitute.officeConcerned?.address]);

  const isLikedInitialValue = useMemo(() => !!props.substitute.isLiked, [props.substitute.isLiked]);

  const [isLiked, setIsLiked] = useReducer(
    (state: boolean, action: { type: "add" | "remove" }): boolean => {
      return action.type === "add";
    },
    isLikedInitialValue
  );

  /*
  ! useEffect
  */
  useEffect(() => {
    const { substitutesLiked } = context.state.substitutesPage.state;
    const substitutesLikedIds = substitutesLiked.map((substitute) => substitute.substituteId);

    if (substitutesLikedIds.includes(props.substitute._id)) setIsLiked({ type: "add" });
    else setIsLiked({ type: "remove" });
  }, [context.state.substitutesPage.state.substitutesLiked]);
  return (
    /*
    ! JSX rendering
    */
    <div className="substitute-thumbnail">
      {/* *Favorite tag*/}
      <i
        className={isLiked ? "fas fa-star is-favorite" : "far fa-star is-favorite"}
        onClick={
          isLiked
            ? () => {
                setIsLiked({ type: "remove" });
                props.handleUnlike(props.substitute._id);
              }
            : () => {
                setIsLiked({ type: "add" });
                props.handleLike(props.substitute._id);
              }
        }
      />

      {/* *First name*/}
      <div className="firstName">{props.substitute.firstName}</div>

      {/* *Avatar*/}
      <div className="avatar">
        <img src={pictureUrlMemoized} alt="avatar" />
      </div>

      {/* *Profession*/}
      {professionMemoized ? (
        <div className="profession">
          <div className="profession-icon">
            <i className="far fa-hand-paper" />
          </div>
          <p>{professionMemoized}</p>
        </div>
      ) : (
        <div className="profession empty" />
      )}

      {/* *Holder tag*/}
      {props.isHolder && (
        <div className="holder">
          <i className="far fa-building"></i>
          Titulaire
        </div>
      )}

      {/* *Graduation*/}
      {!props.isHolder && (
        <div className="graduation-year">{renderDiplomaDate(props.substitute)}</div>
      )}

      {/* *Skills*/}
      {skillsMemoized}

      {/* *Office location*/}
      {officeLocationMemoized}

      {/* *Go to profile*/}
      <ValidButton
        condition={true}
        text="Voir le profil"
        onClick={props.handleGetPractitionerDetails}
      />
    </div>
  );
}
