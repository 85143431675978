import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import MyCandidatesThumbnail from "./MyCandidatesThumbnail";
import MyCandidatesThumbnailGhost from "./MyCandidatesThumbnailGhost";
import { AuthConsumer, AuthContext } from "../../Services/Contexts/AuthProvider";
import ReactPaginate from "react-paginate";
import { fetchApi } from "../../Services/Contexts/Api";
import Skeleton from "react-loading-skeleton";
import { useLogRocketIdentify } from "../../Tools/customHooks";

interface TabCountMap {
  [name: string]: number;
}

interface TabSentenceMap {
  [name: string]: string;
}

export const MyCandidates = (props: AuthState) => {
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [tabSelected, setTabSelected] = useState("isFavorited");
  const [substitutes, setSubstitutes] = useState([]);
  const [tabCount, setTabCount] = useState<TabCountMap>({
    isFavorited: 0,
    isNotified: 0,
    isInterested: 0,
    isHired: 0,
    isRefused: 0
  });
  const [page, setPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(12);
  const [isLoadingTab, setIsLoadingTab] = useState(true);

  useLogRocketIdentify(context);

  useEffect(() => {
    updateTabCount();

    fetchApi(`/my-candidates/${tabSelected}?&index=${page}`).then(
      async response => {
        if (response?.status === 200) {
          await setSubstitutes(response.data.substitutes);
          setIsLoading(false);
        }
      }
    );
  }, [tabSelected, page]);

  const sentenceNoCandidates: TabSentenceMap = {
    isFavorited: "Aucun candidats mis en favoris",
    isNotified: "Aucun candidats notifiés par vos annonces",
    isInterested: "Aucun candidats intéressés par vos annonces",
    isHired: "Aucun candidats recrutés",
    isRefused: "Aucun candidats refusés"
  };

  const handleTabSelected = (tabName: string) => {
    if (!isLoading) {
      setIsLoading(true);
      setPage(0);
      setTabSelected(tabName);
    }
  };

  const updateTabCount = async (inBackground = false) => {
    if (inBackground) {
      let tempTabCount: any = { ...tabCount };
      await fetchApi(`/my-candidates/counts`).then(async response => {
        if (response?.status === 200) {
          tempTabCount = response.data;
        }
        await setTabCount(tempTabCount);
      });
    } else {
      setIsLoadingTab(true);
      let tempTabCount: any = { ...tabCount };
      await fetchApi(`/my-candidates/counts`).then(async response => {
        if (response?.status === 200) {
          tempTabCount = response.data;
        }
        await setTabCount(tempTabCount);
        setIsLoadingTab(false);
      });
    }
  };

  const handleFavorited = () => {
    updateTabCount(true).then();
  };

  const handlePageClick = async (data: any) => {
    let selected = data.selected;
    await window.scrollTo({
      top: 0,
      left: 0
    });
    setPage(selected);
    setIsLoading(true);
  };

  if (isLoading || isLoadingTab) {
    return (
      /* SKELETON */
      <>
        <Header user={props.user} />
        <AuthConsumer>
          {(context: AuthProps) => (
            <div>
              {(context.state.subscriptionStatus === "never_subscribed" ||
                context.state.subscriptionStatus === "subscription_ended") && (
                <div style={{ height: "30px" }} />
              )}
            </div>
          )}
        </AuthConsumer>
        <section className="my-candidates-page content">
          <div className="container">
            <div className="main-block">
              {/* SKELETON */}
              <h2>Bonjour {props.user.firstName}</h2>
              <div className="office-name">
                <h4>Retrouvez votre vivier de candidats</h4>
                <span className="establishment-name">
                  {props.user.office.name}
                </span>
              </div>
              <ul className="status" style={{ cursor: "default" }}>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
                <li style={{ cursor: "default" }}>
                  <Skeleton width={90} />
                </li>
              </ul>

              <MyCandidatesThumbnailGhost />
              <MyCandidatesThumbnailGhost />
              <MyCandidatesThumbnailGhost />
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <Header user={props.user} />
        <section className="my-candidates-page content">
          <div className="container">
            <div className="main-block">
              <h2>Bonjour {props.user.firstName}</h2>
              <div className="office-name">
                <h4>Retrouvez votre vivier de candidats</h4>
                <span
                  className="establishment-name"
                  title={props.user.office.name}>
                  {props.user.office.name}
                </span>
              </div>
              <ul className="status">
                <li
                  className={
                    tabSelected === "isFavorited" ? "blue-link" : ""
                  }
                  onClick={() =>
                    tabSelected !== "isFavorited" &&
                    handleTabSelected("isFavorited")
                  }>
                  Favoris{" "}
                  {!isLoadingTab ? (
                    `(${tabCount.isFavorited ? tabCount.isFavorited : "0"})`
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </li>
                <li
                  className={
                    tabSelected === "isNotified"
                      ? "blue-link vl"
                      : ""
                  }
                  onClick={() =>
                    tabSelected !== "isNotified" &&
                    handleTabSelected("isNotified")
                  }>
                  Notifiés{" "}
                  {!isLoadingTab ? (
                    `(${tabCount.isNotified ? tabCount.isNotified : "0"})`
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </li>
                <li
                  className={
                    tabSelected === "isInterested" ? "blue-link" : ""
                  }
                  onClick={() =>
                    tabSelected !== "isInterested" &&
                    handleTabSelected("isInterested")
                  }>
                  Intéressés{" "}
                  {!isLoadingTab ? (
                    `(${tabCount.isInterested ? tabCount.isInterested : "0"})`
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </li>
                <li
                  className={
                    tabSelected === "isHired" ? "blue-link" : ""
                  }
                  onClick={() =>
                    tabSelected !== "isHired" && handleTabSelected("isHired")
                  }>
                  Recrutés{" "}
                  {!isLoadingTab ? (
                    `(${tabCount.isHired ? tabCount.isHired : "0"})`
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </li>
                <li
                  className={
                    tabSelected === "isRefused" ? "blue-link" : ""
                  }
                  onClick={() =>
                    tabSelected !== "isRefused" &&
                    handleTabSelected("isRefused")
                  }>
                  Refusés{" "}
                  {!isLoadingTab ? (
                    `(${tabCount.isRefused ? tabCount.isRefused : "0"})`
                  ) : (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                </li>
              </ul>

              {/* // TODO: for small screens
              <div className="filter-block">
                <h3>Filtres</h3>
                <div className="line-1">
                  <div className="select-temp">Kinésithérapeute</div>
                  <h4 className="hide-tablet">Vos annonces</h4>
                  <div className="select-temp breakline">
                    CDD Kinésithérapeute blablabla
                  </div>
                </div>

                <div className="line-2">
                  <CheckBox
                    condition={false}
                    handleCheckBox={undefined}
                    className="square"
                    text={"Candidats débloqués seulement"}
                  />
                  <CheckBox
                    condition={true}
                    handleCheckBox={undefined}
                    className="square"
                    text={"Blabla Filtre #2"}
                  />
                  <CheckBox
                    condition={false}
                    handleCheckBox={undefined}
                    className="square"
                    text={"Blabla Filtre #3"}
                  />
                </div>
              </div>*/}

              <div>
                {substitutes.length > 0 ? (
                  substitutes.filter((substitute: any) => !!substitute.substitute._id).map((substitute: any, index) => {
                    let data = {
                      substitute: substitute.substitute,
                      lastMission: substitute.mostRecentMission
                    };
                    return (
                      <MyCandidatesThumbnail
                        tabSelected={tabSelected}
                        substituteData={data}
                        key={index}
                        onChange={() => handleFavorited()}
                      />
                    );
                  })
                ) : (
                  <div className="no-candidates">
                    {sentenceNoCandidates[tabSelected]}
                  </div>
                )}
              </div>
            </div>

            {tabCount[tabSelected] > resultsPerPage && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(tabCount[tabSelected] / resultsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </section>
      </>
    );
  }
};

export default MyCandidates;
