import React from "react";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PracticesState } from "../Practices";

const valuetext = (value: number): string => {
  return `${value}%`;
};

const VerticalSlider = withStyles({
  root: {
    color: "#29b6e5",
    padding: "0 0"
  },
  thumb: {
    height: 20,
    width: 40,
    borderRadius: 20,
    right: -11,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},

  track: {
    border: "3px solid currentColor",
    borderRadius: 4
  },
  rail: {
    backgroundColor: "lightgrey",
    border: `3px solid lightgrey`,
    borderRadius: 4,
    bottom: "0px"
  }
})(Slider);

interface Props extends PracticesState {
  getValue: Function;
  sliderRef1: any;
  sliderRef2: any;
  sliderRef3: any;
}

const Sliders = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex"
          }}
        >
          <div
            style={{
              height: "200px",
              width: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ marginBottom: 20 }}>{`${props.value1} %`}</div>
            <VerticalSlider
              step={5}
              ref={props.sliderRef1}
              onChange={(e, val) => props.getValue(e, val, "value1")}
              orientation="vertical"
              getAriaValueText={valuetext}
              value={props.value1}
              min={0}
              max={100}
              aria-labelledby="vertical-slider"
            />
            <div style={{ marginTop: 15 }}>Sur place</div>
          </div>
          <div
            style={{
              height: "200px",
              width: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ marginBottom: 20 }}>{`${props.value2} %`}</div>
            <VerticalSlider
              step={5}
              onChange={(e, val) => props.getValue(e, val, "value2")}
              orientation="vertical"
              getAriaValueText={valuetext}
              value={props.value2}
              min={0}
              max={100}
              aria-labelledby="vertical-slider"
            />
            <div style={{ marginTop: 15 }}>Domicile</div>
          </div>
          <div
            style={{
              height: "200px",
              width: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ marginBottom: 20 }}>{`${props.value3} %`}</div>
            <VerticalSlider
              step={5}
              orientation="vertical"
              getAriaValueText={valuetext}
              value={props.value3}
              min={0}
              max={100}
              disabled
              aria-labelledby="vertical-slider"
            />
            <div style={{ marginTop: 15 }}>Autre</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sliders;
