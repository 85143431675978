import React from 'react';
import { OfficeState } from './OfficePage';
import ValidButton from '../../Components/Forms/Buttons/ValidButton/ValidButton';
import Loader from 'react-loader-spinner';
import CheckButton from '../../Components/Forms/Buttons/CheckButton/CheckButton';
import Sliders from './Sliders/Sliders';

interface Props extends OfficeState {
  updateOffice: Function;
}

export interface PracticesState {
  value1: number;
  value2: number;
  value3: number;
  isHalfHourSession: boolean;
  offNomenclature: boolean;
  hasExtraFees: boolean;
  hasBalneo: boolean;
}

interface State extends PracticesState {
  displayDetails: boolean;
  isRightTotal: boolean;
}

export default class Practices extends React.Component<Props, State> {
  sliderRef1: any;
  sliderRef2: any;
  sliderRef3: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      displayDetails: true,
      value1: this.props.value1,
      value2: this.props.value2,
      value3: this.props.value3,
      isHalfHourSession: this.props.isHalfHourSession,
      offNomenclature: this.props.offNomenclature,
      hasExtraFees: this.props.hasExtraFees,
      hasBalneo: this.props.hasBalneo,
      isRightTotal: true,
    };
    this.sliderRef1 = React.createRef();
    this.sliderRef2 = React.createRef();
    this.sliderRef3 = React.createRef();
  }

  saveChange = () => {
    this.props.updateOffice(this.state);
  }

  limitRange = (num: number, min: number, max: number) => {
    const MIN = min >= 0 ? min : 0;
    const MAX = max <= 100 ? max : 100;
    return Math.min(Math.max(num, MIN), MAX)
  }

  handleAddPatientBase = () => {
    this.setState({
      value1: 0,
      value2: 0,
      value3: 100
    })
  }

  getValue = async (event: any, value: any, name: string) => {
    const { value1, value2 } = this.state;
    if (name === "value1") {
      while (value + value2 <= 100) {
        this.setState({
          value1: value,
          value3: (100 - (value + value2))
        });
        return;
      }
      this.setState({
        value1: value,
        value2: 100 - value
      })
    } else if (name === "value2") {
      while (value + value1 <= 100) {
        this.setState({
          value2: value,
          value3: (100 - (value + value1)),
        });
        return;
      }
      this.setState({
        value1: 100 - value,
        value2: value
      })
    }
  };

  handleToggle = (name: keyof State) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name]
    });
  };

  render() {
    const activities = [
      { back: "hasExtraFees", front: "Dépassement d'honoraires d'exigence" },
      { back: "offNomenclature", front: "Hors-nomenclature" },
      { back: "isHalfHourSession", front: "1 patient / 30mn" },
      { back: "hasBalneo", front: "Balnéo" }
    ];

    return (
      <div>
        <div className="subtitle">
          <h3>Pratiques</h3>
          {this.state.displayDetails ? (
            <i
              onClick={() => this.setState({ displayDetails: !this.state.displayDetails })}
              className="fas fa-chevron-circle-down"
            />
          ) : (
              <i
                onClick={() => this.setState({ displayDetails: !this.state.displayDetails })}
                className="fas fa-chevron-circle-right"
              />
            )}
        </div>
        {this.state.displayDetails && (
          <div className="step-block">
            <h4>Activités</h4>
            <div>
              <div className="buttons-list">
                {activities.map((x: { back: any, front: any }, index) => {
                  const slug: keyof State = x.back;
                  return (
                    <div key={index}>
                      <CheckButton
                        name={x.back}
                        text={x.front}
                        handleToggle={this.handleToggle}
                        condition={!!this.state[slug]}
                      />
                    </div>
                  );
                })
                }
              </div>
            </div>
            <h4>Répartition de la patientèle</h4>
            {this.state.value1 === 0 && this.state.value2 === 0 && this.state.value3 === 0
              ?
                <div 
                  className="add-patientbase"
                  onClick={this.handleAddPatientBase}
                >
                  Répartition de la patientèle non renseignée.<br/>
                  <span>Ajouter la répartition de la clientèle</span>
                </div>
              :
              <Sliders
                {...this.state}
                sliderRef1={this.sliderRef1}
                sliderRef2={this.sliderRef2}
                sliderRef3={this.sliderRef3}
                getValue={this.getValue}
              />
            }
            <div className="button-loader">
              <ValidButton
                onClick={this.saveChange}
                text={this.props.hasSaved ? 'Enregistré' : 'Enregistrer'}
                condition={true}
                className={
                  this.props.isSaving ? 'validate-orange' : this.props.hasSaved ? 'validate-green' : 'validate-blue'
                }
              />
              {this.props.isSaving && (
                <div className="loader">
                  <Loader
                    type="Puff"
                    color="#29b6e5"
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
