import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext
} from "react";
import Header from "../../../Components/Header/Header";
import UseHover from "../../../Components/useHover";
import Chart from "chart.js";
import Calendar from "react-calendar";
import AuthTextInput from "../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import { fetchApi } from "../../../Services/Contexts/Api";
import { dateFormat } from "../../../Tools/StringTools";
import history from "../../../history";
import Skeleton from "./AdsStatisticsGhost";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import Button from "../../../Components/Forms/Buttons/Button/Button";
import { placeholders } from "../../../Constants/images";
import dayjs from "dayjs";

const information = {
  print:
    "Le nombre de fois où l'annonce est apparue dans la liste globale des annonces App'Ines",
  click: "Le nombre de fois où les candidats ont cliqué sur l'annonce",
  clickAvg:
    "Le nombre de clics par rapport à l'apparition de l'annonce sur App'Ines",
  applicationAvg:
    "Le nombre de candidatures par rapport aux clics sur l'annonce"
};

const informationBis = {
  printed:
    "Le nombre de fois où l'annonce est apparue dans la liste globale des annonces App'ines",
  searched:
    "Le nombre de fois où l'annonce est apparue dans les résultats lors d'une recherche sur App'Ines",
  seen: "Le nombre de fois où l'annonce a été consultée"
};

const colorsRgb = {
  printed: "64, 182, 161",
  searched: "251, 201, 143",
  seen: "3, 184, 226"
};

const colorsHex = {
  printed: "#40B6A1",
  searched: "#FBC98F",
  seen: "#03B8E2"
};

const labels: labelsChart = {
  mPrinted: "Affichages",
  gPrinted: "Moyenne affichages App'Ines",
  mSearched: "Recherches",
  gSearched: "Moyenne recherches App'Ines",
  mSeen: "Consultations",
  gSeen: "Moyenne consultations App'Ines"
};

const statsDefault = {
  mPrinted: 0,
  gPrinted: 0,
  mSeen: 0,
  gSeen: 0,
  mSeenPct: 0,
  gSeenPct: 0,
  mCandidated: 0,
  gCandidated: 0,
  mCandidatedPct: 0,
  gCandidatedPct: 0
};

interface labelsStats {
  [index: string]: number;
}

interface labelsChart {
  [index: string]: string;
}

interface dataDay {
  date: string;
  global?: {
    date: string;
    dividerPrinted?: number;
    dividerSearched?: number;
    dividerSeen?: number;
    dividerSubscribed?: number;
    notifiedToday?: number;
    totalPrintedToday?: any;
    totalSearchedToday?: any;
    totalSeenToday?: any;
    totalSubscribedToday?: any;
    _id: string;
  };
  mission?: {
    createdAt: string;
    exercise: AllExercises;
    endDate?: string;
    totalPrintedPerDay?: any;
    totalSearchedPerDay?: any;
    totalSeenPerDay?: any;
    totalSubscribedPerDay?: any;
  };
}

type typeColor = "printed" | "searched" | "seen";

export default function AdsStatistics(props: AuthState) {
  var date = new Date();
  date.setDate(date.getDate() - 7);

  const [missionProps, setMissionProps] = useState<any>(null);
  const context = useContext(AuthContext);
  const [candidates, setCandidates] = useState<any>([]);

  // Calendar
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const refCalendar = useRef<HTMLInputElement>(null);

  // Stats
  const [stats, setStats] = useState<labelsStats>(statsDefault);
  const [statsReady, setStatsReady] = useState(false);

  // Chart
  const [chartInstance, setChartInstance] = useState<any>(null);
  const [chartSelected, setChartSelected] = useState<typeColor>("printed");
  const [refreshChart, setRefreshChart] = useState(false);
  const [chartReady, setChartReady] = useState(false);

  /* ⬇️ -- For demo only  ⬇️ -- */
  const maxDataValue = 20;
  const fakeDayLabelsGenerated: any = [];
  const fakeDatasGenerated: any = {
    mPrinted: [],
    mSeen: [],
    mSearched: [],
    gPrinted: [],
    gSeen: [],
    gSearched: []
  };

  for (let i = 0; i < 8; i++) { // last 7 days
    fakeDayLabelsGenerated.push(
      dayjs()
        .subtract(7 - i, "day")
        .format("DD/MM/YYYY")
        .toString()
    );

    // generate random values for mission
    fakeDatasGenerated.mPrinted.push(Math.floor(Math.random() * maxDataValue));
    fakeDatasGenerated.mSeen.push(Math.floor(Math.random() * fakeDatasGenerated.mPrinted[i]));
    fakeDatasGenerated.mSearched.push(Math.floor(Math.random() * fakeDatasGenerated.mSeen[i]));

    // generate random global stats values
    fakeDatasGenerated.gPrinted.push(Math.floor(Math.random() * maxDataValue));
    fakeDatasGenerated.gSeen.push(Math.floor(Math.random() * fakeDatasGenerated.gPrinted[i]));
    fakeDatasGenerated.gSearched.push(Math.floor(Math.random() * fakeDatasGenerated.gSeen[i]));
  }

  // get sum
  const fakeMPrintedReduced = fakeDatasGenerated.mPrinted.reduce((pv:any, cv:any) => (pv + cv));
  const fakeMSeenReduced = fakeDatasGenerated.mSeen.reduce((pv:any, cv:any) => (pv + cv));
  const fakeGPrintedReduced = fakeDatasGenerated.gPrinted.reduce((pv:any, cv:any) => (pv + cv));
  const fakeGSeenReduced = fakeDatasGenerated.gSeen.reduce((pv:any, cv:any) => (pv + cv));

  // store fakeDatas
  const [fakeDayLabels, setFakeDayLabels] = useState(fakeDayLabelsGenerated);
  const [fakeDatas, setFakeDatas] = useState(fakeDatasGenerated);
  const [fakeMPrinted, setFakeMPrinted] = useState(fakeMPrintedReduced);
  const [fakeMSeen, setMSeen] = useState(fakeMSeenReduced);
  const [fakeGPrinted, setGPrinted] = useState(fakeGPrintedReduced);
  const [fakeGSeen, setGSeen] = useState(fakeGSeenReduced);

  /* ⬆️ -- For demo only ⬆️ -- */

  // First render of the chart
  const useHookWithRefCallback = () => {
    const ref = useRef(null);
    const setRef = useCallback(node => {
      let canvas: any = document.getElementById("canvas");
      const ctx = canvas?.getContext("2d");
      if (node) {
        fetchApi("/missions/" + props?.match?.params?.missionId)
          .then(response => {
            setMissionProps(response.data);
            let exercise = response.data?.exercise;
            fetchApi(
              `/stats/${response.data?._id}?startDate=${startDate}&stopDate=${endDate}&exercise=${response.data?.exercise}&noPrevious=true`
            ).then(response => {
              if (ctx) {
                let chartConfig = loadChartConfig(
                  response.data.current,
                  exercise,
                  ctx,
                  (missionProps?.status === "INVISIBLE" || false)
                );
                clearPreviousChart();
                var chart = new Chart(ctx, chartConfig);
                setChartInstance(chart);
                setChartReady(true);
              }
            });

            setStatsData(response.data);
          })
          .catch(error => {
            history.push("/mes-annonces");
          });
      }

      ref.current = node;
    }, []);

    return [setRef];
  };

  const [chartContainer] = useHookWithRefCallback();

  // Set the id of the mission
  useEffect(() => {
    if (props?.location?.state) {
      setMissionProps(props?.location?.state);
      setCandidates(props.location.state.missionSubscribers);
    } else if (props?.match?.params?.missionId) {
      setMissionProps({ _id: props?.match?.params?.missionId });
    }
  }, []);

  // Load mission's data, especially exercise who's needed
  useEffect(() => {
    if (missionProps && !missionProps?.exercise) {
      fetchApi("/missions/" + missionProps._id)
        .then(async response => {
          await setMissionProps(response.data);
        })
        .catch(error => {
          history.push("/mes-annonces");
        });
    }
  }, [missionProps]);

  const setStatsData = (mission: any) => {
    // STATS
    if (mission?._id && mission?.exercise && !statsReady) {
      let limitDate = new Date("2021-02-01");
      let dateCreatedAt;
      if (new Date(mission.createdAt) < limitDate) {
        dateCreatedAt = limitDate;
      } else {
        dateCreatedAt = mission.createdAt;
      }

      if (missionProps?.status !== "INVISIBLE") {
      fetchApi(
        `/stats/${
          mission?._id
        }?startDate=${dateCreatedAt}&stopDate=${new Date()}&exercise=${
          mission?.exercise
        }&noPrevious=true`
      )
        .then(response => {
          let days = response.data.current;
          let datas: labelsStats = {
            mPrinted: 0,
            gPrinted: 0,
            mSeen: 0,
            gSeen: 0,
            mSeenPct: 0,
            gSeenPct: 0,
            mCandidated: 0,
            gCandidated: 0,
            mCandidatedPct: 0,
            gCandidatedPct: 0
          };

          days.map((day: dataDay) => {
            datas.mPrinted += day.mission?.totalPrintedPerDay?.printed | 0;
            datas.mSeen += day.mission?.totalSeenPerDay?.seen | 0;
            datas.mCandidated +=
              day.mission?.totalSubscribedPerDay?.subscribed | 0;

            datas.gPrinted +=
              Math.round(
                day.global?.totalPrintedToday?.exercisePrinted[
                  mission.exercise
                ] / (day?.global?.dividerPrinted ?? 0)
              ) | 0;
            datas.gSeen +=
              Math.round(
                day.global?.totalSeenToday?.exerciseSeen[mission.exercise] /
                  (day.global?.dividerSeen ?? 0)
              ) | 0;
            datas.gCandidated +=
              Math.round(
                day.global?.totalSubscribedToday?.exerciseSubscribed[
                  mission.exercise
                ] / (day.global?.dividerSubscribed ?? 0)
              ) | 0;

            return datas;
          });

          // Averages values
          datas.mPrinted = Math.round(datas.mPrinted);
          datas.mSeenPct = (100 * datas.mSeen) / datas.mPrinted;
          datas.mCandidatedPct = (100 * datas.mCandidated) / datas.mSeen;

          datas.gPrinted = Math.round(datas.gPrinted);
          datas.gSeenPct = (100 * datas.gSeen) / datas.gPrinted;
          datas.gCandidatedPct = (100 * datas.gCandidated) / datas.gSeen;
          setStats(datas);
          setStatsReady(true);
        })
        .catch(error => {});
      }
    }
  };

  useEffect(() => {
    // CHART
    let chart: any = document.getElementById("canvas");
    if (chart) {
      if (missionProps?._id && missionProps?.exercise) {
        fetchApi(
          `/stats/${missionProps?._id}?startDate=${startDate}&stopDate=${endDate}&exercise=${missionProps?.exercise}&noPrevious=true`
        )
          .then(async response => {
            let canvas: any = document.getElementById("canvas");
            const ctx = canvas?.getContext("2d") as any;
            if (ctx) {
              let chartConfig = loadChartConfig(
                response.data.current,
                missionProps?.exercise,
                ctx,
                missionProps?.status === "INVISIBLE"
              );
              clearPreviousChart();
              var chart = new Chart(ctx, chartConfig);
              setChartInstance(chart);
            }
          })
          .catch(error => {});
      }

      setRefreshChart(false);
    }

    if (missionProps && missionProps?.status !== "INVISIBLE") {
      setStatsData(missionProps);
    }

    if (missionProps && missionProps?.status === "INVISIBLE") {
      setStats({
        mPrinted: fakeMPrinted,
        gPrinted: fakeGPrinted,
        mSeen: fakeMSeen,
        gSeen: fakeGSeen,
        mSeenPct: Math.floor(fakeMSeen / fakeMPrinted * 100),
        gSeenPct: Math.floor(fakeGSeen / fakeGPrinted * 100),
        mCandidated: 1,
        gCandidated: 0,
        mCandidatedPct: 0,
        gCandidatedPct: 0
      });
    }
  }, [chartSelected, refreshChart, statsReady, chartReady]);

  const loadChartConfig = (days: any, exercise: AllExercises, ctx: any, isDemo: Boolean) => {
    let dayLabels: string[] = [];
    let datas: any = {
      mPrinted: [],
      mSeen: [],
      mSearched: [],
      gPrinted: [],
      gSeen: [],
      gSearched: []
    };
    let chartConfig;
    const rgb = colorsRgb[chartSelected];
    const color = colorsHex[chartSelected];

    if (!isDemo) {
    days.map((day: dataDay) => {
      dayLabels.push(dateFormat(day.date));

      datas.mPrinted.push(day.mission?.totalPrintedPerDay?.printed | 0);
      datas.mSeen.push(day.mission?.totalSeenPerDay?.seen | 0);
      datas.mSearched.push(day.mission?.totalSearchedPerDay?.searched | 0);

      datas.gPrinted.push(
        Math.round(
          day.global?.totalPrintedToday?.exercisePrinted[exercise] /
            (day.global?.dividerPrinted ?? 0)
        ) | 0
      );
      datas.gSeen.push(
        Math.round(
          day.global?.totalSeenToday?.exerciseSeen[exercise] /
            (day.global?.dividerSeen ?? 0)
        ) | 0
      );
      datas.gSearched.push(
        Math.round(
          day.global?.totalSearchedToday?.exerciseSearched[exercise] /
            (day.global?.dividerSearched ?? 0)
        ) | 0
      );

      return datas;
    });
    } else {
      dayLabels = fakeDayLabels;
      datas = fakeDatas;
    }

    ctx?.clearRect(0, 0, 900, 450);
    const gradient = ctx?.createLinearGradient(0, 30, 0, 450);
    gradient?.addColorStop(0, `rgba(${rgb}, 1)`);
    gradient?.addColorStop(1, `rgba(${rgb}, 0.05)`);

    chartConfig = {
      type: "line",
      data: {
        labels: dayLabels,
        datasets: [
          {
            label:
              labels[
                "m" +
                  chartSelected.charAt(0).toUpperCase() +
                  chartSelected.slice(1)
              ],
            backgroundColor: gradient,
            borderColor: color,
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointRadius: 3,
            pointHoverRadius: 5,
            data:
              datas[
                "m" +
                  chartSelected.charAt(0).toUpperCase() +
                  chartSelected.slice(1)
              ]
          },
          {
            label:
              labels[
                "g" +
                  chartSelected.charAt(0).toUpperCase() +
                  chartSelected.slice(1)
              ],
            backgroundColor: "transparent",
            borderColor: color,
            borderDash: [5, 2],
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointRadius: 3,
            pointHoverRadius: 5,
            data:
              datas[
                "g" +
                  chartSelected.charAt(0).toUpperCase() +
                  chartSelected.slice(1)
              ]
          }
        ]
      },
      options: {
        legend: {
          display: true,
          position: "bottom",
          labels: { boxWidth: 30, padding: 30 }
        },
        scales: {
          xAxes: [
            {
              ticks: { display: true },
              gridLines: { display: false, zeroLineWidth: null }
            }
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                display: true,
                callback: function(value: any, index: any, values: any) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
              gridLines: { display: true, zeroLineWidth: 2 }
            }
          ]
        }
      }
    };

    return chartConfig;
  };

  // Clear and destroy previous chart to avoid a visual bug on hovering
  const clearPreviousChart = () => {
    if (chartInstance) {
      chartInstance?.clear();
      chartInstance.destroy();
      setChartInstance(null);
    }
  };

  const handleNewDate = (newDate: any) => {
    setStartDate(newDate[0]);
    setEndDate(newDate[1]);
    setRefreshChart(true);
    setDisplayCalendar(false);
  };

  const handleChartSelected = (e: any) => {
    setChartSelected(e.target.value);
  };

  const showPercentage = (number: number) => {
    if (number < 1) {
      if (number > 0) {
        return "<1%";
      }
    }
    return `${Math.round(number) || "0"}%`;
  };

  const getLengthDate = (start: Date, end: Date) => {
    let days = end.getTime() - start.getTime();
    days = days / (1000 * 3600 * 24);
    days = Math.round(days);

    let months = days / 30;
    let remain = days % 30;

    let today = new Date();
    let dateToday = `${today.getDate()} ${today.getMonth()} ${today.getFullYear()}`;
    let dateEnd = `${end.getDate()} ${end.getMonth()} ${end.getFullYear()}`;

    if (months >= 1) {
      if (dateToday === dateEnd && remain === 0) {
        return `${
          months === 1 ? "Dernier mois" : `${Math.floor(months)} derniers mois`
        }`;
      } else {
        return `${Math.floor(months)} mois${
          remain > 0 ? ` et ${remain} jours` : ""
        }`;
      }
    } else {
      if (days > 1) {
        return `${days} ${dateToday === dateEnd ? "derniers" : ""} jours`;
      } else {
        return `${dateToday === dateEnd ? "Aujourd'hui" : "1 jour"}`;
      }
    }
  };

  // Detect a click outside the calendar
  const handleClick = (e: any) => {
    if (refCalendar.current && !refCalendar?.current?.contains(e.target)) {
      setDisplayCalendar(false);
    }
  };

  const handleSeeCandidate = (e: any) => {
    if (
      context.state.subscriptionStatus === "subscribed" ||
      context.state.subscriptionStatus === "DEMO"
    ) {
      history.push(`/mes-annonces`, {
        mission: { ...missionProps, missionSubscribers: candidates }
      });
    } else {
      context.popBarMessage(
        `Vous devez souscrire à l'offre Happy pour voir les candidatures`
      );
    }
  };

  return (
    <>
      <Header user={props.user} />

      {(!statsReady || !chartReady) && <Skeleton />}
      <section
        className="mission-stats"
        onClick={handleClick}
        style={{ display: `${!statsReady || !chartReady ? "none" : "block"}` }}>
        <div className="container">
          <h2 className="title">
            Statistiques de l'annonce {missionProps?.title}
          </h2>

          <div className="block block-first">
            <div
              className="backward"
              onClick={() => {
                history.push(`/mes-annonces`);
              }}>
              <i className="fas fa-chevron-left" />
              <span>Retour</span>
            </div>

            {missionProps?.status && missionProps.status === 'INVISIBLE' && (<div className="demo-label"><span><i className="fas fa-info-circle" />Démo</span></div>)}

            <h3>
              Parcours des candidats
              <UseHover
                text={
                  <p>
                    Parcours des candidats depuis la création de l'annonce{" "}
                    <br />
                    <br /> * données à partir de Février 2021
                  </p>
                }
                className="info">
                <i className="fas fa-info-circle" />
              </UseHover>
            </h3>

            <div className="candidates-journey">
              <UseHover text={information.print} className="info">
                <div className="journey-point">
                  <div className="average">
                    <p>Affichages</p>
                    <span>{stats?.mPrinted}</span>
                  </div>
                  <div className="average global">
                    <p>Moyenne App'Ines</p>
                    <span>{stats?.gPrinted}</span>
                  </div>
                </div>
              </UseHover>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              <UseHover text={information.clickAvg} className="info">
                <div className="journey-point">
                  <div className="average">
                    <p>Taux de clics</p>
                    <span>{showPercentage(stats?.mSeenPct)}</span>
                  </div>
                  <div className="average global">
                    <p>Moyenne App'Ines</p>
                    <span>{showPercentage(stats?.gSeenPct)}</span>
                  </div>
                </div>
              </UseHover>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              <UseHover text={information.click} className="info">
                <div className="journey-point">
                  <div className="average">
                    <p>Clics</p>
                    <span>{stats?.mSeen}</span>
                  </div>
                  <div className="average global">
                    <p>Moyenne App'Ines</p>
                    <span>{stats?.gSeen}</span>
                  </div>
                </div>
              </UseHover>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              {/* 
              // TAUX DE CANDIDATURE
              <UseHover text={information.applicationAvg} className="info">
                <div className="journey-point">
                  <div className="average">
                    <p>Taux de candidatures</p>
                    <span>{showPercentage(stats?.mCandidatedPct)}</span>
                  </div>
                  <div className="average global">
                    <p>Moyenne App'Ines</p>
                    <span>{showPercentage(stats?.gCandidatedPct)}</span>
                  </div>
                </div>
              </UseHover>
              
              <span><i className="fas fa-chevron-right"></i></span>   
              */}

              <UseHover text={""} deactivated={true} className="info">
                <div className="journey-point">
                  <div className="average">
                    <p>Candidatures</p>
                    <span>{stats?.mCandidated}</span>
                  </div>

                  <div
                    className="preview-candidates"
                    onClick={
                      candidates.length > 0
                        ? handleSeeCandidate
                        : () => history.push("/create-mission", missionProps)
                    }>
                    {missionProps?.status === "CANCELED" ? null : missionProps
                        ?.hired?.isFilled ? null : (
                      <>
                        {candidates.length > 0 ? (
                          candidates.length > 4 ? (
                            <>
                              <span className="candidate candidate-more">
                                <span>
                                  {candidates.length - 3 < 100
                                    ? `+${candidates.length - 3}`
                                    : ">99"}
                                </span>
                              </span>
                              <span className="candidate">
                                <img
                                  src={
                                    candidates[0].substitute?.picture?.thumbnail?.url ||
                                    placeholders[
                                      parseInt(
                                        candidates[0]?.substitute?.lastName
                                          ?.charCodeAt(0)
                                          .toString()
                                          .slice(-1)
                                      )
                                    ]
                                  }
                                  alt="preview candidate"
                                />
                              </span>
                              <span className="candidate">
                                <img
                                  src={
                                    candidates[1].substitute?.picture?.thumbnail?.url ||
                                    placeholders[
                                      parseInt(
                                        candidates[1]?.substitute?.lastName
                                          ?.charCodeAt(0)
                                          .toString()
                                          .slice(-1)
                                      )
                                    ]
                                  }
                                  alt="preview candidate"
                                />
                              </span>
                              <span className="candidate">
                                <img
                                  src={
                                    candidates[2].substitute?.picture?.thumbnail?.url ||
                                    placeholders[
                                      parseInt(
                                        candidates[2]?.substitute?.lastName
                                          ?.charCodeAt(0)
                                          .toString()
                                          .slice(-1)
                                      )
                                    ]
                                  }
                                  alt="preview candidate"
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="candidate">
                                <img
                                  src={candidates[0].substitute?.picture?.thumbnail?.url}
                                  alt="preview candidate"
                                />
                              </span>
                              <span
                                className={
                                  candidates[1]?.substitute
                                    ? "candidate"
                                    : "candidate empty"
                                }>
                                {candidates[1]?.substitute ? (
                                  <img
                                    src={
                                      candidates[1]?.substitute?.picture?.thumbnail?.url ||
                                      placeholders[
                                        parseInt(
                                          candidates[1]?.substitute?.lastName
                                            ?.charCodeAt(0)
                                            .toString()
                                            .slice(-1)
                                        )
                                      ]
                                    }
                                    alt="preview candidate"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                              <span
                                className={
                                  candidates[2]?.substitute
                                    ? "candidate"
                                    : "candidate empty"
                                }>
                                {candidates[2]?.substitute ? (
                                  <img
                                    src={
                                      candidates[2]?.substitute?.picture?.thumbnail?.url ||
                                      placeholders[
                                        parseInt(
                                          candidates[2]?.substitute?.lastName
                                            ?.charCodeAt(0)
                                            .toString()
                                            .slice(-1)
                                        )
                                      ]
                                    }
                                    alt="preview candidate"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                              <span
                                className={
                                  candidates[3]?.substitute
                                    ? "candidate"
                                    : "candidate empty"
                                }>
                                {candidates[3]?.substitute ? (
                                  <img
                                    src={
                                      candidates[3]?.substitute?.picture?.thumbnail?.url ||
                                      placeholders[
                                        parseInt(
                                          candidates[3]?.substitute?.lastName
                                            ?.charCodeAt(0)
                                            .toString()
                                            .slice(-1)
                                        )
                                      ]
                                    }
                                    alt="preview candidate"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            {missionProps?.endDate ? (
                              <Button
                                condition={true}
                                className="validate-blue button-edit-mission"
                                text={
                                  new Date(missionProps?.endDate) < new Date()
                                    ? "Réactiver l'annonce"
                                    : "Améliorer l'annonce"
                                }
                                iconLeft={
                                  new Date(missionProps?.endDate) <
                                  new Date() ? (
                                    <i className="fas fa-sync-alt"></i>
                                  ) : (
                                    <i className="fas fa-pen"></i>
                                  )
                                }
                              />
                            ) : (
                              <Button
                                condition={true}
                                className="validate-blue button-edit-mission"
                                text="Améliorer l'annonce"
                                iconLeft={<i className="fas fa-pen"></i>}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {/* <div className="average global">
                    <p>&nbsp;</p>
                    <span>&nbsp;</span>
                  </div> */}
                </div>
              </UseHover>
            </div>
          </div>

          <div className="block block-second content">
            <div className="line">
              <h3>
                {chartSelected === "printed"
                  ? "Nombre d'affichages"
                  : chartSelected === "seen"
                  ? "Nombre de consultations"
                  : "Nombre de recherches"}
                <UseHover text={informationBis[chartSelected]} className="info">
                  <i className="fas fa-info-circle" />
                </UseHover>
              </h3>

              <div onChange={handleChartSelected} className="toggle-radio">
                <div>
                  <input
                    type="radio"
                    name="chartSelected"
                    id="printed"
                    value="printed"
                    checked={chartSelected === "printed"}
                    onChange={handleChartSelected}
                  />
                  <label htmlFor="printed">Affichages</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="chartSelected"
                    id="seen"
                    value="seen"
                    checked={chartSelected === "seen"}
                    onChange={handleChartSelected}
                  />
                  <label htmlFor="seen">Consultations</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="chartSelected"
                    id="searched"
                    value="searched"
                    checked={chartSelected === "searched"}
                    onChange={handleChartSelected}
                  />
                  <label htmlFor="searched">Recherches</label>
                </div>
              </div>
            </div>

            <div ref={refCalendar} className="period">
              <p>Période* : </p>
              {missionProps?.status !== "INVISIBLE" &&
              (<AuthTextInput
                type="text"
                minLength={1}
                isList={true}
                value={`${dateFormat(startDate)} - ${dateFormat(endDate)}`}
                className="calendar-toggle"
                condition={true}
                disabled={true}
                handleToggle={() => setDisplayCalendar(!displayCalendar)}
                onClick={() => setDisplayCalendar(!displayCalendar)}
              />)}
              {displayCalendar && (
                <Calendar
                  calendarType="ISO 8601"
                  selectRange={true}
                  value={[startDate, endDate]}
                  locale="fr-FR"
                  className="calendar"
                  returnValue="range"
                  onChange={handleNewDate}
                  minDate={new Date("01 Feb 2021 00:00:00 GMT")}
                  maxDate={new Date()}
                />
              )}
              <p className="caption">{getLengthDate(startDate, endDate)}</p>
            </div>

            <canvas ref={chartContainer} id="canvas" />

            <p className="post-scriptum">
              * données disponibles seulement à partir de Février 2021
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
