import React, { useEffect, useState } from "react";
import { convertTime } from "../../../../Tools/MathTools";
import { capitalize } from "../../../../Tools/StringTools";
import ListSelect from "../../../../Components/Forms/Inputs/ListSelect/ListSelect";

interface Days {
  [key: string]: string | undefined;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}
type DaysKey = keyof Days | undefined;

interface HolderOfficeInfosProps {
  props: SubstituteProps;
}

export default function HolderOfficeInfos(
  substituteProps: HolderOfficeInfosProps
) {
  const [officeSelected, setOfficeSelected] = useState<OfficeHolderProps>();
  const [officeSelectedLabel, setOfficeSelectedLabel] = useState<string>("");
  const [urlMap, setUrlMap] = useState<string>("");
  const [offices, setOffices] = useState<OfficeHolderProps[]>();
  const [listOffices, setListOffices] = useState<string[]>();

  useEffect(() => {
    if (substituteProps?.props?.offices) {
      // set nearest office by default
      if (substituteProps?.props?.nearestOffice) {
        let indexNearestOffice = substituteProps.props.offices.findIndex(
          ({ _id }) => _id === substituteProps?.props?.nearestOffice?._id
        );
        setOfficeSelected(substituteProps.props.offices[indexNearestOffice]);
      } else {
        setOfficeSelected(substituteProps.props.offices[0]);
      }

      // offices sort by zipCode
      let offices = substituteProps.props.offices;
      offices.sort(function(a, b) {
        return a.address.zipCode === b.address.zipCode
          ? 0
          : a.address.zipCode < b.address.zipCode
          ? -1
          : 1;
      });

      setOffices(offices);
    }
  }, []);

  useEffect(() => {
    setListOffices(getListOffices());
  }, [offices]);

  useEffect(() => {
    if (officeSelected) {
      const urlMap = createUrlSnapshotGoogleMaps();
      setUrlMap(urlMap);
    }
  }, [officeSelected]);

  const days: Days = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche"
  };

  const getListOffices = () => {
    let list: any = [];

    offices?.map(office => {
      let nameRef = `${office.address.zipCode.slice(0, 2)} - ${capitalize(
        office.address.city
      )}`;
      let nameTmp = `${office.address.zipCode.slice(0, 2)} - ${capitalize(
        office.address.city
      )}`;

      let rep = 2;
      while (list.find((officeName: string) => officeName === nameTmp)) {
        nameTmp = nameRef;
        nameTmp += ` (${rep})`;
        rep++;
      }
      list.push(nameTmp);
      return null;
    });
    return list;
  };

  const handleSelectOffice = (name: string, item: string, index: number) => {
    if (listOffices) {
      setOfficeSelectedLabel(listOffices[index]);
    }
    setOfficeSelected(offices?.[index]);
  };

  // FUNCTIONS FOR DISPLAYING
  function createUrlSnapshotGoogleMaps() {
    const coords = getLatLng(officeSelected);
    let snapshot = generateStaticUrl(
      /** LAT */ coords[0],
      /** LNG */ coords[1],
      5000,
      false
    );
    return snapshot;
  }

  function getLatLng(officeSelected: any): [number, number] {
    let lat, lng;
    lng = officeSelected.loc.coordinates[0];
    lat = officeSelected.loc.coordinates[1];
    return [lat, lng];
  }

  function generateStaticUrl(
    lat: number,
    lng: number,
    ray?: number,
    autoZoom?: boolean
  ) {
    const height = "460";
    const width = "460";

    const zoom = autoZoom ? "" : "&zoom=11";
    let uri = "https://maps.googleapis.com/maps/api/staticmap?";
    let staticMapSrc = `center=${lat},${lng}&size=${width}x${height}${zoom}&path=fillcolor:0x40b6a138|color:0x40b6a1|weight:1`;
    const r = 6371;
    const rad = ray || 1000;
    const detail = 1;
    const pi = Math.PI;
    const _lat = (lat * pi) / 180;
    const _lng = (lng * pi) / 180;
    const d = rad / 1000 / r;
    for (let i = 0; i <= 360; i += detail) {
      const brng = (i * pi) / 180;
      let pLat = Math.asin(
        Math.sin(_lat) * Math.cos(d) +
          Math.cos(_lat) * Math.sin(d) * Math.cos(brng)
      );
      const pLng =
        ((_lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(_lat),
            Math.cos(d) - Math.sin(_lat) * Math.sin(pLat)
          )) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;
      staticMapSrc += "|" + pLat + "," + pLng;
    }
    return (
      uri + encodeURI(staticMapSrc) + `&key=${global.config.googlemaps_key}`
    );
  }

  return (
    <>
      {officeSelected && (
        <div className="middle-block holder-office">
          <div className="header">
            <h2>Cabinet</h2>
            <ListSelect
              name="holderOffices"
              placeHolder={`Cabinet de ${capitalize(
                substituteProps.props.firstName
              )}`}
              classname=""
              width={250}
              condition={offices!.length > 1}
              value={
                officeSelectedLabel
                  ? officeSelectedLabel
                  : `${officeSelected.address.zipCode.slice(
                      0,
                      2
                    )} - ${capitalize(officeSelected.address.city)}`
              }
              listString={listOffices}
              handleSelect={handleSelectOffice}
            />
          </div>

          <div className="content">
            <div className="map">
              <img src={urlMap} alt="" />
            </div>

            <div className="details">
              <div className="details-block type">
                {officeSelected.isSolo ? (
                  <span>
                    <i className="fas fa-user" />
                    Cabinet individuel
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-users" />
                    Cabinet de groupe
                  </span>
                )}
              </div>

              <div className="details-block patient-base">
                <h3>
                  <i className="fas fa-chart-pie"></i>Répartition patientèle
                </h3>
                {officeSelected.patientBase &&
                (officeSelected.patientBase.office ||
                  officeSelected.patientBase.atHome ||
                  officeSelected.patientBase.other) ? (
                  <div className="line">
                    <div>
                      <span>{officeSelected.patientBase.office ?? 0}%</span>
                      <p>Sur place</p>
                    </div>

                    <div>
                      <span>{officeSelected.patientBase.atHome ?? 0}%</span>
                      <p>Domicile</p>
                    </div>

                    <div>
                      <span>{officeSelected.patientBase.other ?? 0}%</span>
                      <p>Autres</p>
                    </div>
                  </div>
                ) : (
                  <div className="line disabled">
                    <div>
                      <span>-</span>
                      <p>Sur place</p>
                    </div>

                    <div>
                      <span>-</span>
                      <p>Domicile</p>
                    </div>

                    <div>
                      <span>-</span>
                      <p>Autres</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="details-block opening-hours">
                <h3>
                  <i className="fas fa-clock"></i>Horaires d'ouvertures
                </h3>
                {officeSelected.openingHours ? (
                  <>
                    {Object.keys(officeSelected.openingHours).map(
                      (dayBack, index) => {
                        let day: DaysKey = Object.keys(days).find(
                          key => key === dayBack
                        );
                        let times: any = [];

                        if (!day) return null;

                        if (officeSelected.openingHours?.[day]) {
                          officeSelected?.openingHours?.[day]?.map(
                            (time: any) => {
                              let start = convertTime(time.start);
                              let end = convertTime(time.end);
                              times.push({ start: start, end: end });
                            }
                          );
                        }

                        return (
                          <div className="day" key={index}>
                            <p>{days[day]}</p>

                            {officeSelected?.openingHours?.[day] ? (
                              <div className="times">
                                {times.map((time: any, index: any) => {
                                  if (!time.start || !time.end) {
                                    return (
                                      <span className="closed">
                                        <p>Fermé</p>
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <div key={index}>
                                        <span>
                                          {time.start} - {time.end}
                                        </span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <div className="times">
                                <span className="closed">
                                  <p>Fermé</p>
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    <div className="day disabled">
                      <p>Non renseignées</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
