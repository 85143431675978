import React, {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";
import ListSelectWithInput from "../../../../Components/Forms/Inputs/ListSelectWithInput/ListSelectWithInput";
import { ReactComponent as HolderImage } from "../../../../assets/img/holder.svg";
import { ReactComponent as SubstituteImage } from "../../../../assets/img/substitute.svg";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";
import * as service from "../../../../Services/ads.services";
import ListSelectMultipleWithInput from "../../../../Components/Forms/Inputs/ListSelectMultipleWithInput/ListSelectMultipleWithInput";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

export default function FiltersBlock(props: Props) {
  const _skills = useRef<HTMLDivElement>(null);
  const context = useContext(AuthContext);
  const substitutePageContext: SubstitutesPageState = context.state.substitutesPage.state;
  const professions: Profession[] = useMemo(() => context.state.allProfessions, [
    context.state.allProfessions,
  ]);
  const skillsMemoized: Promise<Skill[]> = useMemo(() => service.getSkills(), []);
  const [skills, setSkills] = useState<Skill[]>([]);

  const activeProfessions: Profession[] = useMemo(
    () => professions.filter((profession: Profession) => profession.status.FRANCE !== "WAITING"),
    [professions]
  );
  const nonActiveProfessions: Profession[] = useMemo(
    () =>
      professions
        .filter((profession: Profession) => profession.status.FRANCE === "WAITING")
        .map((profession: Profession) => {
          const waitingLabel = " (Bientôt ⏳)";

          if (profession.name.FR.indexOf(waitingLabel) === -1) {
            profession.name.FR += waitingLabel;
          }

          return profession;
        }),
    [professions]
  );
  const selectedProfession: Profession | null = useMemo(() => substitutePageContext.profession, [
    substitutePageContext,
  ]);

  const skillsSelected: Skill[] = useMemo(() => substitutePageContext.skills, [
    substitutePageContext.skills,
  ]);

  const locationSliderMemoized: JSX.Element | null = useMemo(() => {
    if (substitutePageContext.type === "holder") {
      return (
        <>
          <h3>Périmètre de recherche</h3>
          <span
            className="location-label"
            onClick={() => {
              if (!props.isLoading) updateMaxDistance(100);
            }}
          >{`Dans un rayon de ${substitutePageContext.maxDistance} km`}</span>
          <div className={"sub-filter"}>
            <MaxDistanceSlider
              defaultValue={100}
              step={10}
              value={substitutePageContext.maxDistance}
              marks={[
                {
                  value: 10,
                  label: "< 10km",
                },
                { value: 50, label: "< 50km" },
                {
                  value: 100,
                  label: "< 100km",
                },
              ]}
              min={10}
              max={100}
              onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => {
                if (!props.isLoading) updateMaxDistance(newValue);
              }}
            />
          </div>
        </>
      );
    }

    if (substitutePageContext.type === "substitute" && substitutePageContext.exercices.length > 0) {
      return (
        <>
          <h3>Périmètre de recherche</h3>
          {substitutePageContext.maxDistance < 100 ? (
            <span
              className="location-label"
              onClick={
                substitutePageContext.type === "substitute"
                  ? () => {
                      if (!props.isLoading) updateMaxDistance(100);
                    }
                  : undefined
              }
            >{`Dans un rayon de ${substitutePageContext.maxDistance} km`}</span>
          ) : (
            <span style={{ visibility: "hidden" }} className="location-label">
              France entière
            </span>
          )}
          <div className={"sub-filter"}>
            <MaxDistanceSlider
              defaultValue={100}
              step={10}
              value={substitutePageContext.maxDistance}
              marks={[
                {
                  value: 10,
                  label: "< 10km",
                },
                { value: 50, label: "< 50km" },
                {
                  value: 100,
                  label: "France entière",
                },
              ]}
              min={0}
              max={100}
              onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => {
                if (!props.isLoading) updateMaxDistance(newValue);
              }}
            />
          </div>
        </>
      );
    }

    return null;
  }, [
    substitutePageContext.type,
    substitutePageContext.exercices.length,
    substitutePageContext.maxDistance,
    props.isLoading,
  ]);

  const updateProfessionSelected = useCallback(
    (sourceList: string, value: Profession): void => {
      !props.isLoading && props.handleSelectProfession(value);
    },
    [substitutePageContext.profession, props.isLoading]
  );
  const updateType = useCallback(
    (type: "substitute" | "holder"): void => {
      if (substitutePageContext.type !== type && !props.isLoading)
        props.handleUpdateProfileType(type);
    },
    [substitutePageContext.type, props.isLoading]
  );
  const updateMaxDistance = useCallback(
    (value: number | number[]) => {
      if (!props.isLoading) props.handleUpdateMaxDistanceSearch(value);
    },
    [props.isLoading]
  );
  const updateExercises = useCallback(
    (exercices: Exercise[]): void => {
      if (!props.isLoading)
        if (exercices.every((e: string) => substitutePageContext.exercices.includes(e as Exercise)))
          props.handleRemoveExercise(exercices);
        else props.handleAddExercise(exercices);
    },
    [substitutePageContext.exercices, props.isLoading]
  );
  const updateMinXp = useCallback(
    (value: "student" | "equivalence" | "graduated"): void => {
      !props.isLoading && props.handleMinXp(value);
    },
    [props.isLoading]
  );
  const handleSkillCheckbox = useCallback(
    (selectedList: Skill[], skill: Skill): void => {
      if (new Set(selectedList).has(skill)) props.handleRemoveSkill(skill);
      else props.handleAddSkill(skill);
    },
    [substitutePageContext.skills]
  );

  useEffect(() => {
    (async () => {
      const skillsFiltered = async () => {
        if (selectedProfession?._id)
          return (await skillsMemoized).filter((skill: Skill) => {
            return (
              skill.professionIds.includes(selectedProfession._id) ||
              skill.professionIds.length === 0
            );
          });

        return await skillsMemoized;
      };

      setSkills(await skillsFiltered());
    })();
  }, [selectedProfession?._id]);

  return (
    <section
      className={"filters"}
      id="filters"
      style={{
        backgroundColor: props.isLoading ? "rgb(250,250,250)" : "",
        filter: props.isLoading ? "grayscale()" : "",
        cursor: props.isLoading ? "progress" : "",
      }}
    >
      {/*  *HEADER */}
      <div className="header">
        <h2>Filtres</h2>
        <i className="fas fa-redo-alt reload" onClick={props.handleResetFilters} />
      </div>

      {/* *PROFESSION */}
      <h3>Profession</h3>
      <div className="profession sub-filter">
        <ListSelectWithInput
          value={selectedProfession?.name[context.state.language]}
          name="filter-profession"
          placeHolder={"Profession recherchée"}
          condition
          handleSelect={updateProfessionSelected}
          list={activeProfessions}
          listDisabled={nonActiveProfessions}
          width={220}
          sorted={true}
          ref={React.createRef()}
          inputPlaceholder={"Profession"}
        />
      </div>

      {/* *PROFILE */}
      <h3>Profil</h3>
      <div className="sub-filter">
        <div className="type">
          <div
            className={`type-select ${substitutePageContext.type === "holder" ? "selected" : ""}`}
            onClick={() => updateType("holder")}
          >
            <HolderImage />
            Titulaires
          </div>

          <div
            className={`type-select ${
              substitutePageContext.type === "substitute" ? "selected" : ""
            }`}
            onClick={() => updateType("substitute")}
          >
            <SubstituteImage />
            Candidats
          </div>
        </div>
      </div>

      {/* *ADS TYPE */}
      {substitutePageContext.type === "substitute" && (
        <>
          <h3>Types de poste</h3>
          <div className="sub-filter">
            <CheckBox
              condition={["cdd", "cdi"].every((e: string) =>
                substitutePageContext.exercices.includes(e as Exercise)
              )}
              handleCheckBox={() => updateExercises(["cdd", "cdi"])}
              className="square"
              text={"Salariat (CDD - CDI)"}
            />
            <CheckBox
              condition={substitutePageContext.exercices.includes("substitute")}
              handleCheckBox={() => updateExercises(["substitute"])}
              className="square"
              text={"Vacation"}
            />
            <CheckBox
              condition={substitutePageContext.exercices.includes("internship")}
              handleCheckBox={() => updateExercises(["internship"])}
              className="square"
              text={"Stage"}
            />
          </div>
        </>
      )}

      {/* *LOCATION */}
      {locationSliderMemoized}

      {/* *SKILLS */}
      <h3>Compétences</h3>
      <div className="sub-filter content">
        <div className={"skills"}>
          <ListSelectMultipleWithInput
            list={skills}
            selectedList={skillsSelected}
            condition={skillsSelected.length > 0}
            handleCheckbox={handleSkillCheckbox}
            placeHolder={"Compétences attendues"}
            inputPlaceholder={"ex: Pédiatrie"}
            ref={_skills}
            sorted={true}
            language={context.state.language}
            value={
              skillsSelected.length === 1
                ? skillsSelected.length + " sélectionnée"
                : skillsSelected.length > 1
                ? skillsSelected.length + " sélectionnées"
                : ""
            }
          />
        </div>

        <div className={"skills-list"}>
          {substitutePageContext.skills.map((skill) => (
            <span key={skill._id} onClick={() => props.handleRemoveSkill(skill)}>
              {skill.name[context.state.language]}
            </span>
          ))}
        </div>
      </div>

      {/* *EXPERIENCES */}
      {substitutePageContext.type === "substitute" && (
        <>
          <h3>Statut</h3>
          <div className="sub-filter">
            <CheckBox
              condition={substitutePageContext.minXp === "student"}
              handleCheckBox={
                substitutePageContext.minXp !== "student" ? () => updateMinXp("student") : undefined
              }
              className="square radio"
              text={"Étudiant"}
            />
            <CheckBox
              condition={substitutePageContext.minXp === "equivalence"}
              handleCheckBox={
                substitutePageContext.minXp !== "equivalence"
                  ? () => updateMinXp("equivalence")
                  : undefined
              }
              className="square radio"
              text={"Équivalence en cours"}
            />
            <CheckBox
              condition={substitutePageContext.minXp === "graduated"}
              handleCheckBox={
                substitutePageContext.minXp !== "graduated"
                  ? () => updateMinXp("graduated")
                  : undefined
              }
              className="square radio"
              text={"Diplômé"}
            />
          </div>
        </>
      )}
    </section>
  );
}

type Props = {
  handleResetFilters: MouseEventHandler;
  handleSelectProfession: Function;
  handleUpdateProfileType: Function;
  handleUpdateMaxDistanceSearch: Function;
  handleAddExercise: Function;
  handleRemoveExercise: Function;
  handleAddSkill: Function;
  handleMinXp: Function;
  handleRemoveSkill: Function;
  isLoading: boolean;
};

const MaxDistanceSlider = withStyles(() => ({
  root: {
    width: 225,
    color: "#25bba5",
    "& .MuiSlider-markLabel": { fontFamily: "Arial Rounded MT Bold", fontSize: "10px" },
  },
}))(Slider);
