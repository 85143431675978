import Axios from "axios";
// import moment from "moment";
import { fetchApi } from "./Api";

const makeBody = (practitioner: any) => {
  const office: OfficeProps =
    (practitioner.offices && practitioner.offices[0]) || practitioner.office;
  return {
    email: practitioner.email,
    emailBlacklisted: false,
    smsBlacklisted: false,
    attributes: {
      NOM: practitioner.lastName,
      PRENOM: practitioner.firstName,
      CIVILITE: practitioner.gender,
      // LAST_SESSION_AT: moment().format("yyy-MM-dd"),
      TYPE: "holder_pro",
      USER_ID: practitioner._id,
      IS_PRO: true,
      NUMERO_FINESS_ETABLISSEMENT: office.finess,
      NUMERO_SIRET_SITE: office.siret,
      RAISON_SOCIALE_SITE: practitioner.office.name,
      NUMERO_VOIE: office.address.number,
      LIBELLE_VOIE: office.address.street,
      CODE_POSTAL: office.address.zipCode,
      LIBELLE_COMMUNE: office.address.city,
      LIBELLE_PAYS: office.address.country,
      ROLE: practitioner.role,
      REGISTERED: true,
      NB_CREDITS: office.credits,
      TELEPHONE: practitioner.phoneNumber,
      IS_PREMIUM: practitioner?.premiumSubscription?.subscribed,
      // "REFERRAL_CODE":  this.sponsorCode ? this.sponsorCode : undefined,
      //   HAS_ALERT: !!(practitioner.alert && practitioner.alert.length),
      HAS_AVATAR: !!office.logo,
      HAS_OFFICE_PICTURE: office.pictures?.length > 0,
      HAS_ONGOING_MISSION: practitioner.missions?.length > 0
    },
    listIds: [global.config.sendinblue_list] // 24 prod, 25 int
  };
};

const getUser = (email: string) => {
  const endpoint = `${global.config.sendinblue_url}/contacts/` + email;
  return Axios(endpoint, {
    method: "get",
    headers: {
      "api-key": global.config.sendinblue_key
    }
  });
};

export const updateUserSendinBlue = (id: any) => {
  fetchApi(`/practitioner/${id}`).then(res => {
    if (res.status === 200) {
      const practitioner = res.data;
      getUser(practitioner.email)
        .then((res: any) => updateUser(practitioner))
        .catch((err: any) => createUser(practitioner));
    }
  });
};

export const updateUser = (practitioner: any) => {
  const endpoint = `${global.config.sendinblue_url}/contacts/${practitioner.email}`;
  Axios(endpoint, {
    method: "put",
    data: makeBody(practitioner),
    headers: {
      "api-key": global.config.sendinblue_key
    }
  });
};

export const createUser = (practitioner: any) => {
  const endpoint = `${global.config.sendinblue_url}/contacts`;
  Axios(endpoint, {
    method: "post",
    data: makeBody(practitioner),
    headers: {
      "api-key": global.config.sendinblue_key
    }
  });
};
