// DEPENDENCIES
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLogRocketIdentify, useQuery } from "../../Tools/customHooks";
import { fetchApi } from "../../Services/Contexts/Api";

// COMPONENTS
import Header from "../../Components/Header/Header";
import DiscussionThumbnail from "./DiscussionThumbnail/DiscussionThumbnail";
import SubstituteThumbnailLight from "./SubstituteThumbnailLight/SubstituteThumbnailLight";
import CurrentDiscussion from "./CurrentDiscussion/CurrentDiscussion";
import { IDiscussion } from "./IDiscussions";
import { AuthContext } from "../../Services/Contexts/AuthProvider";

// ASSETS
import defaultAvatarEts from "../../assets/img/user-circle-solid.svg";
import Loader from "react-loader-spinner";
import SupportThumbnail from "./SupportThumbnail/SupportThumbnail";
import SubscriptionPending from "./SubscriptionPending/SubscriptionPending";

const Discussion = (props: AuthProps) => {
  const context = useContext(AuthContext);
  const history = useHistory();

  const query = useQuery();
  const { discussion_id } = useParams();

  const currentUserId = props.state.user.id;
  const socket = props.state.socketInstance;
  const discussionsRef = useRef<HTMLDivElement>(null);
  const lastDiscussionRef = useRef<HTMLDivElement>(null);

  // -- States --
  const [officeLogo, setOfficeLogo] = useState<string>("");
  const [discussions, setDiscussions] = useState<IDiscussion[]>([]);
  const [selectedDiscussion, setSelectedDiscussion] = useState<any>();
  const [selectedCandidate, setSelectedCandidate] = useState<any>();
  const [customerSuccessOfficerData, setCustomerSucessOfficerData] = useState<any>();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsIsLoading, setSubscriptionsIsLoading] = useState(true);
  // prettier-ignore
  const [showArchivedDiscussions, setShowArchivedDiscussions] = useState<Boolean>(query.get("archive") === "true");
  const [discussionsIsLoading, setDiscussionsIsLoading] = useState<Boolean>(true);

  const [oldDiscussionsLoading, setOldDiscussionsLoading] = useState(false);
  const [noMoreDiscussions, setNoMoreDiscussions] = useState(false);
  const [page, setPage] = useState(0);

  // -- Helpers --
  const handleDiscussionThumbnailOnClick = (discussion: IDiscussion) => {
    setSelectedDiscussion(discussion);
  };

  const handleClickArchiveToggle = (buttonActive: "active" | "archive") => {
    if (buttonActive === "active" ? showArchivedDiscussions : !showArchivedDiscussions) {
      setDiscussionsIsLoading(true);
      setDiscussions([]);
      setNoMoreDiscussions(false);
      setPage(0);
      setShowArchivedDiscussions(buttonActive === "archive");
      setSelectedDiscussion({});
    }
  };

  const handleFetchSubscriptions = () => {
    setSubscriptionsIsLoading(true);
    fetchApi(`/missions-subscriptions/${currentUserId}/${selectedCandidate?._id}`).then(
      (response) => {
        setSubscriptions(response.data);
        setSubscriptionsIsLoading(false);
      }
    );
  };

  useLogRocketIdentify(context);

  // Handle fetching missions subscriptions data
  useEffect(() => {
    selectedCandidate?._id && handleFetchSubscriptions();
  }, [selectedCandidate?._id, currentUserId]);

  // Handle fetching discussion data if discussion id passed in url params ( cf. useParams() )
  useEffect(() => {
    if (!selectedDiscussion && discussion_id) {
      fetchApi(`/discussion/${discussion_id}`).then((response) => {
        setSelectedDiscussion(response.data);
      });
    }
  }, []);

  // Get current user avatar (office logo)
  useEffect(() => {
    context.state.officeLogo ? setOfficeLogo(context.state.officeLogo) : setOfficeLogo(defaultAvatarEts);
  }, [context.state.officeLogo]);

  // Get customer success officer data
  useEffect(() => {
    fetchApi("/customer-success-officer").then((res) => setCustomerSucessOfficerData(res.data));
  }, []);

  // Fetch discussions list
  useEffect(() => {
    if (!noMoreDiscussions) {
      setOldDiscussionsLoading(true);

      fetchApi(
        `/discussions?status=${showArchivedDiscussions ? "archived" : "active"}&page=${page}`
      ).then((response) => {
        setDiscussions(discussions.concat(response.data.data));
        setDiscussionsIsLoading(false);
        setOldDiscussionsLoading(false);

        if (response.data.data.length === 0 || response.data.data.length < 10) {
          setNoMoreDiscussions(true);
          setOldDiscussionsLoading(false);
        }
      });
    }
  }, [showArchivedDiscussions, page, noMoreDiscussions]);

  // Handle infinite scroll (discussions list)
  useEffect(() => {
    if (
      lastDiscussionRef.current &&
      // prettier-ignore
      document.documentElement.clientHeight - lastDiscussionRef.current.getBoundingClientRect().y > -100 &&
      !noMoreDiscussions &&
      !oldDiscussionsLoading
    ) {
      setOldDiscussionsLoading(true);
      setPage(page + 1);
    }

    const handleScroll = () => {
      if (
        lastDiscussionRef.current &&
        // prettier-ignore
        Math.ceil(document.documentElement.clientHeight - lastDiscussionRef.current.getBoundingClientRect().y) > -100 &&
        !noMoreDiscussions &&
        !oldDiscussionsLoading
      ) {
        setOldDiscussionsLoading(true);
        setPage(page + 1);
      }
    };

    discussionsRef.current && discussionsRef.current.addEventListener("scroll", handleScroll);
    return () => {
      discussionsRef.current && discussionsRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [page, noMoreDiscussions, oldDiscussionsLoading, lastDiscussionRef.current]);

  // Update selected candidate state
  useEffect(() => {
    if (selectedDiscussion?._id !== "") {
      setSelectedCandidate(
        selectedDiscussion?.users?.filter((user: any) => user._id !== currentUserId)[0]
      );
    }
  }, [selectedDiscussion, discussions]);

  // Socket.io : handle Socket room
  useEffect(() => {
    if (discussions.length > 0) {
      if (selectedDiscussion?._id) {
        socket?.emit("join-room", selectedDiscussion?._id);
      }
    }

    return () => {
      selectedDiscussion?._id && socket && socket.emit("leave-room", selectedDiscussion?._id);
    };
  }, [discussions.length, socket, selectedDiscussion?._id, showArchivedDiscussions]); // IMPORTANT : discussions.length, socket, selectedDiscussion?._id dependencies avoid re-rendering

  // Socket.io : update discussion listener
  useEffect(() => {
    if (discussions.length > 0) {
      socket?.on("update-discussion", (discussionUpdated: IDiscussion) => {
        if (
          discussionUpdated._id !== discussion_id ||
          discussionUpdated?.lastMessage?.date !== selectedDiscussion?.lastMessage?.date
        ) {
          const newDiscussionsList: IDiscussion[] = discussions.filter(
            (discussion: IDiscussion) => discussion._id !== discussionUpdated._id
          );

          newDiscussionsList.unshift(discussionUpdated);

          setDiscussions(
            newDiscussionsList.sort(
              (a: IDiscussion, b: IDiscussion) =>
                new Date(b.lastMessage.date).valueOf() - new Date(a.lastMessage.date).valueOf()
            )
          );
        }

        if (
          discussionUpdated.lastMessage.type === "MissionSubscription" &&
          discussionUpdated.lastMessage.content.substitute.id === selectedCandidate?._id
        ) {
          handleFetchSubscriptions();
        }
      });
    }
    return () => {
      socket?.off("update-discussion");
    };
  }, [discussions, socket, selectedCandidate?._id]);

  return (
    <>
      <Header user={props.state.user} />

      <div className={"discussions-container"}>
        <div className={"collapse-all-discussions"} ref={discussionsRef}>
          <div className={"archive-selection"}>
            Discussions avec les candidats
            <div className={"archive-toggle"}>
              <span
                className={!showArchivedDiscussions ? "selected" : ""}
                onClick={() => {
                  handleClickArchiveToggle("active");
                }}
              >
                Actives
                {props.state.ActiveNewMessage > 0 && (
                  <span className={"notification-badge"}>{props.state.ActiveNewMessage}</span>
                )}
              </span>
              <span
                className={showArchivedDiscussions ? "selected" : ""}
                onClick={() => {
                  handleClickArchiveToggle("archive");
                }}
              >
                Archivées
                {props.state.ArchivedNewMessage > 0 && (
                  <span className={"notification-badge"}>{props.state.ArchivedNewMessage}</span>
                )}
              </span>
            </div>
          </div>

          {/*--prettier-ignore*/}
          {!discussionsIsLoading && discussions.length === 0 && (
            <div className={"no-discussion"}>Aucune discussion</div>
          )}

          {discussionsIsLoading && (
            <div className={"loader"}>
              Chargement en cours ...
              <Loader type={"Puff"} height={50} width={50} color={"#27BBA5"} />
            </div>
          )}

          {discussions
            .filter((discussion: IDiscussion) => {
              if (showArchivedDiscussions) {
                return !!discussion.lastConnection.find((c) => c.userId === currentUserId)?.archive;
              } else {
                return !discussion.lastConnection.find((c) => c.userId === currentUserId)?.archive;
              }
            })
            .map((discussion: IDiscussion, index: number) => {
              return (
                <div
                  key={discussion._id}
                  onClick={() => handleDiscussionThumbnailOnClick(discussion)}
                  ref={index === discussions.length - 1 ? lastDiscussionRef : undefined}
                >
                  <DiscussionThumbnail
                    currentUserId={currentUserId}
                    currentPlan={props.state.currentPlan}
                    users={discussion.users}
                    lastMessage={discussion.lastMessage}
                    lastConnection={discussion.lastConnection}
                    selected={discussion?._id === selectedDiscussion?._id}
                  />
                </div>
              );
            })}

          {oldDiscussionsLoading && !discussionsIsLoading && (
            <div className={"old-discussion-loader"}>
              <Loader type={"Puff"} width={20} height={20} color={"#27BBA5"} />
            </div>
          )}

          {noMoreDiscussions && (
            <div className={"old-discussion-loader"}>
              Vous n'avez pas d'autres discussions{" "}
              {showArchivedDiscussions ? "archivées" : "actives"}
            </div>
          )}
        </div>

        {selectedDiscussion?.lastMessage && selectedCandidate && (
          <CurrentDiscussion
            key={selectedDiscussion._id}
            selectedCandidate={selectedCandidate}
            selectedDiscussion={selectedDiscussion}
            currentUserId={currentUserId}
            candidateAvatar={selectedCandidate?.picture?.thumbnail?.url || ""}
            officeLogo={officeLogo}
            socket={socket}
          />
        )}

        <div className={"candidate-profile"}>
          {selectedCandidate && !selectedDiscussion?.isSupport && (
            <SubstituteThumbnailLight
              substitute={{
                firstName: selectedCandidate.firstName,
                lastName: selectedCandidate.lastName,
                picture: selectedCandidate.picture,
                professionId: selectedCandidate.professionId,
                diploma: selectedCandidate.diploma,
                skills: selectedCandidate.skills,
                type: selectedCandidate.type,
                visibility: selectedCandidate.visibility,
              }}
              handleGetPractitionerDetails={() =>
                history.push(`/remplacants/${selectedCandidate._id}`)
              }
            />
          )}

          {selectedCandidate && selectedDiscussion?.isSupport && (
            <SupportThumbnail customerSuccessOfficerData={customerSuccessOfficerData} />
          )}

          {selectedCandidate && !selectedDiscussion?.isSupport && (
            <SubscriptionPending
              isLoading={subscriptionsIsLoading}
              missionsSubscriptions={subscriptions}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Discussion;
