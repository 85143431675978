import React from 'react';
import { OfficeState } from './OfficePage';
import ValidButton from '../../Components/Forms/Buttons/ValidButton/ValidButton';
import Loader from 'react-loader-spinner';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ListSelectMultiple from '../../Components/Forms/Inputs/ListSelectMultiple/ListSelectMultiple';
import { fetchApi } from "../../Services/Contexts/Api";
import { AuthContext } from "../../Services/Contexts/AuthProvider";


interface Props extends OfficeState {
  updateOffice: Function;
}

interface Software {
  _id: number;
  professionId: string;
  name: any;
}

export interface EquipementsState {
  editorStateTechnical: any;
  technicalDescription: string | null;
  softwares: any[];
}

interface State extends EquipementsState {
  displayEquipementsDetails: boolean;
  displaySoftwares: boolean;
  listSoftwares: Software[],
}

export default class Equipements extends React.Component<Props, State> {
  static contextType = AuthContext;
  node: any;
  _list: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      displayEquipementsDetails: true,
      editorStateTechnical: this.props.editorStateTechnical,
      technicalDescription: this.props.technicalDescription,
      displaySoftwares: false,
      softwares: this.props.softwares,
      listSoftwares: [],
    };
    this.node = React.createRef();
    this._list = React.createRef();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    fetchApi('/softwares')
    .then((response) => {
        this.setState({listSoftwares: response.data})
      })
  }

  handleClick = (e: any) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ displaySoftwares: false });
  }

  convertDescription = (description: EditorState) => {
    let draft = convertToRaw(description.getCurrentContent())

    const newBlocks = draft.blocks.map( block => { 
      return {...block, text: block.text.trim()}
    })
    
    const newDraft = {...draft, blocks: newBlocks }

    return draftToHtml(newDraft).replace(/\n/g, "<br/>");
  }

  handleEditorTechnicalInput = (description: EditorState) => {
    this.setState({
      editorStateTechnical: description,
      technicalDescription:  this.convertDescription(description)});
  };

  handlePushToArray = (array: any[], item: any) => {   
    let found = array.findIndex(itemArray => itemArray._id === item._id);

    if (found !== -1) {
      array.splice(found, 1);
    } else {
      array.push(item);
    }

    this.setState({ ...this.state, softwares: array });
  };

  handleSaveChange = async () => {
    if(this.state.technicalDescription === "<p></p><br/>") {
      await this.setState({technicalDescription: ""})
    }    
    this.props.updateOffice(this.state);
  }

  render() {
    if (this.state.displaySoftwares) {
      document.addEventListener('mousedown', this.handleClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleClick, false);
    }
    return (
      <div className="equipements-block">
        <div className="subtitle">
          <h3>Matériel et équipements</h3>
          {this.state.displayEquipementsDetails ? (
            <i
              onClick={() => this.setState({ displayEquipementsDetails: !this.state.displayEquipementsDetails })}
              className="fas fa-chevron-circle-down"
            />
          ) : (
              <i
                onClick={() => this.setState({ displayEquipementsDetails: !this.state.displayEquipementsDetails })}
                className="fas fa-chevron-circle-right"
              />
            )}
        </div>
        {this.state.displayEquipementsDetails && (
          <div className="step-block" >
            <div className="softwares-relative">
              <ListSelectMultiple 
                list={this.state.listSoftwares}
                selectedList={this.state.softwares}
                condition={this.state.softwares.length > 0}
                handleCheckbox={this.handlePushToArray}
                placeHolder={"Sélectionner les logiciels utilisés"}
                value={this.state.softwares.length > 0 ? `${this.state.softwares.length} logiciel${this.state.softwares.length > 1 ? "s" : ""} sélectionné${this.state.softwares.length > 1 ? "s" : ""}` : ""}
                ref={this._list}
                sorted={true}
                language={this.context.state.language}
              />

              <div className="selected-items">
                {
                  this.state.softwares.map((item: any, index: number) => (
                    <div 
                      key={index} 
                      className="selected-item"
                    >
                        <div>
                            {item.length >= 9
                                ? `${item.name.FR.substring(0, 9)}...`
                                : item.name.FR}
                        </div>
                        <i 
                          className="fas fa-times"
                          onClick={() => this.handlePushToArray(this.state.softwares, item)
                        } 
                        />
                    </div>))
                }
              </div>

              <Editor
                toolbar={{
                  options: ['inline', 'blockType', 'list'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                  blockType: {
                    options: ['Normal'],
                  },
                  list: {
                    options: ['unordered', 'ordered'],
                  },
                  fontSize: {
                    options: [16],
                  },
                }}
                placeholder="Description du plateau technique"
                editorState={this.state.editorStateTechnical}
                toolbarClassName="toolbar"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={this.handleEditorTechnicalInput}
                stripPastedStyles={true}
              />
            </div>
            <div className="button-loader">
              <ValidButton
                onClick={this.handleSaveChange}
                text={this.props.hasSaved ? 'Enregistré' : 'Enregistrer'}
                condition={true}
                className={
                  this.props.isSaving ? 'validate-orange' : this.props.hasSaved ? 'validate-green' : 'validate-blue'
                }
              />
              {this.props.isSaving && (
                <div className="loader">
                  <Loader
                    type="Puff"
                    color="#29b6e5"
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
