import React from 'react'
import moment from 'moment'
import { missionTypes } from "../../../../Constants/mission";

interface Props {
    user: SubstituteProps;
    female: boolean;
}

enum actionsBack {
    SUB_FAVORITED = 'substituteFavorited', // type, date, officeName
    SUB_CONTACTED = 'SubstituteContacted', // type, date, missionName
    SUB_HIRED = 'acceptedApplication', // type, date, missionName, missionType, officeName, officeAdmin 
    RECEIVED_APPLICATION = 'receivedApplication', // type, date, missionName, officeName
    REFUSED_APPLICATION = 'refusedApplication', // type, date, missionName, officeName
    MISSION_SEND = 'missionSend', // type, date, missionName, missionType, officeName, officeAdmin
    MISSION_SHARED = 'missionShared', // type, date, missionName, officeName
    MISSION_LIKED = 'missionLiked', // type, date, missionName, officeName
    MISSION_SUBSCRIBED = 'missionSuscribed', // type, date, missionName, officeName
    MISSION_CONSULTED = 'missionConsulted', // type, date, missionName, officeName
    USER_SUBSCRIBED = 'subscribed', // type, date
    RECEIVED_NOTIF = 'receivedNotification', // type, date, missionName, officeName
}

const actionsTitles = [
    { back: "substituteFavorited", front: "Profil mis en favori" },
    { back: "refusedApplication", front: "Candidature refusée" },
    { back: "acceptedApplication", front: "Poste pourvu" },
    { back: "missionSend", front: "Annonce envoyée" },
    { back: "missionLiked", front: "Annonce mise en coup de coeur" },
    { back: "SubstituteContacted", front: "Candidat contacté" },
    { back: "missionShared", front: "Partage de votre annonce" },
    { back: "missionSuscribed", front: "Candidature reçue" },
    { back: "missionConsulted", front: "Consultation de votre annonce" },
    { back: "receivedNotification", front: "Réception de notification" },
    { back: "subscribed", front: "Inscription" },
];



const UserHistoric = (props: Props) => {

    const { historic, firstName } = props.user;


    return (
        <div className="middle-block">
            <h2>Historique</h2>
            <div className="light-text">
                Retrouvez ici toutes les interactions de ce praticien
            </div>
            <div className="histories">
                {historic?.map((history: HistoryProps, index) => <div key={index}>{historyBlock(history, firstName, props.female)}</div>)}
            </div>
        </div>
    )
}

export default UserHistoric


const historyBlock = (history: HistoryProps, user: string, female: boolean) => {


    const renderDate = (date: Date | string | undefined) => {
        if (date)
            return moment(date).format('D MMM [\n] YYYY');
        else
            return 'Pas de\ndate'
    }

    const switchColors = (action: string) => {
        switch (action) {
            case actionsBack.SUB_FAVORITED:
                return 'green-light';
            case actionsBack.REFUSED_APPLICATION:
                return 'orange';
            case actionsBack.SUB_HIRED:
                return 'green';
            case actionsBack.MISSION_SEND:
                return 'yellow';
            case actionsBack.RECEIVED_NOTIF:
                return 'yellow';
            case actionsBack.MISSION_SUBSCRIBED:
                return 'blue';
            default:
                return '';
        }
    }

    const getMissionType = (mission: string) => {
        const frontType = missionTypes.find(missionType => missionType.back === mission)
        if (frontType)
            return (frontType.front)

    }

    const switchDesc = (history: HistoryProps, user: string, female: boolean) => {

        const toBold = (text?: string) => (<b>{text}</b>)

        // const addGender = (textmale: boolean) 

        switch (history.action) {
            case actionsBack.SUB_FAVORITED:
                return <p>{toBold(history.officeAdmin)} de l'établissement {toBold(history.officeName)} a mis le profil de {toBold(user)} dans ses favoris</p>
            case actionsBack.REFUSED_APPLICATION:
                return <p>Le profil de {toBold(user)} n'a pas été retenu pour l'annonce "{toBold(history.missionName)}" publiée par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.SUB_HIRED:
                if (history.missionType)
                    return <p>{toBold(user)} a été sélectionné{female ? "e" : ""} pour le poste de {toBold(getMissionType(history.missionType))} pour l'annonce "{toBold(history.missionName)}" de l'établissement {toBold(history.officeName)}</p>
                else
                    break;
            case actionsBack.MISSION_SEND:
                return <p>{toBold(history.officeAdmin)} de l'établissement {toBold(history.officeName)} a envoyé l'annonce "{toBold(history.missionName)}" à {toBold(user)}</p>
            case actionsBack.MISSION_LIKED:
                return <p>{toBold(user)} a mis en coup de coeur l'annonce "{toBold(history.missionName)}" publiée par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.MISSION_SUBSCRIBED:
                return <p>{toBold(user)} a candidaté à l'annonce "{toBold(history.missionName)}" publiée par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.SUB_CONTACTED:
                return <p>{toBold(user)} a été contacté{female ? "e" : ""} suite à sa candidature pour l'annonce "{toBold(history.missionName)}" publié par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.MISSION_CONSULTED:
                return <p>{toBold(user)} a consulté l'annonce "{toBold(history.missionName)}" publié par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.MISSION_SHARED:
                return <p>{toBold(user)} a partagé l'annonce "{toBold(history.missionName)}" publié par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.RECEIVED_NOTIF:
                return <p>{toBold(user)} a reçu une notification automatique suite à la publication de l'annonce "{toBold(history.missionName)}" publiée par l'établissement {toBold(history.officeName)}</p>
            case actionsBack.USER_SUBSCRIBED:
                return <p>{toBold(user)} s'est inscrit{female ? "e" : ""} sur App'Ines</p>
            default:
                return <p>erreur de description</p>
        }
    }

    const renderCircle = (action: string) => (
        <div className="circles">
            <div className={`outer-circle circle-${switchColors(action)}`} />
            <div className={`inner-circle circle-${switchColors(action)}`} />
            <div className="line" />
        </div>
    )

    return (
        <div className="history">
            <p className="history__date">
                {renderDate(history.date)}
            </p>
            <div className="history__circle">
                {renderCircle(history.action)}
            </div>
            <div className="history__desc">
                <h3>{actionsTitles.find((action) => action.back === history.action)?.front}</h3>
                <span>{switchDesc(history, user, female)}</span>
            </div>
        </div>
    )
}
