import React, { useState, useEffect } from "react";
import { NewMissionConsumer } from "../../../../Services/Contexts/NewMissionProvider";
import Tips from "../../../../Components/Tips/Tips";
import { Link } from "react-router-dom";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";
import AuthTextInput from "../../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HoverErrorComponent from "../../../../Components/useHoverError";
import Loader from "react-loader-spinner";
import { fetchApi } from "../../../../Services/Contexts/Api";

const containsForbiddenString = (text: string | undefined): boolean => {
  return !!(
    text?.match(/([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})/gi) ||
    text?.match(
      /(\+\d{1,4})?0?[1-7]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})/gi
    )
  );
};

interface Props {
  userID: string;
  officeID: string;
  missionID: string | undefined;
}

const Description = (props: Props) => {
  const [count, setcount] = useState(0);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    fetchApi("/all-missions/count").then(res => {
      if (res.status === 200) {
        setcount(res.data);
      }
    });
    return;
  }, []);

  const isValid = (context: newMissionProps) =>
    !containsForbiddenString(context.state.description) &&
    !!context.state.errors &&
    Object.keys(context.state.errors?.slide3).length === 0;

  const handleSendingMission = async (context: newMissionProps) => {
    context.setDisplayLauchCandidateSearchButton();

    const valid = await isValid(context);

    if (!isSending && valid) {
      setIsSending(true);

      if (valid) {
        await context.handleSendMission(
          props.userID,
          props.officeID,
          props.missionID
        );
        setIsSending(false);
      }
    }
  };

  return (
    <NewMissionConsumer>
      {(context: newMissionProps) => (
        <section className="slide-three">
          <div className="content">
            <h3>Nouvelle annonce</h3>
            <div
              className="options"
              onMouseEnter={() => context.setTipOpen("tip1")}>
              <CheckBox
                name="hasHousing"
                text="Logement  disponible"
                className="square"
                handleCheckBox={context.handleToggle}
                condition={context.state.hasHousing}
              />
              <CheckBox
                name="hasVehicle"
                text="Voiture nécessaire"
                className="square"
                handleCheckBox={context.handleToggle}
                condition={context.state.hasVehicle}
              />
            </div>
            <h4>Donnez un titre à votre annonce</h4>
            <div
              className="title-input"
              onMouseEnter={() => context.setTipOpen("tip2")}>
              <AuthTextInput
                type="text"
                minLength={1}
                maxLength={100}
                condition={!!context.state.title}
                disabled={false}
                name="title"
                isError={
                  !!context.state.errors?.slide3.title &&
                  context.state.displayErrors
                }
                handleInput={context.handleInput}
                value={context.state.title}
              />
              <div className="errors-block">
                {context.state.displayErrors && (
                  <div className="error">
                    {context.state?.errors?.slide3?.title}
                  </div>
                )}
              </div>
              {context.state.title && context.state.title.length === 100 && (
                <div className="length-error">
                  Le titre ne doit pas excéder 100 caractères pour une meilleure
                  lisibilité !
                </div>
              )}
            </div>
            <h4>Décrivez votre annonce</h4>
            <div
              style={
                context.state.description &&
                containsForbiddenString(context.state.description)
                  ? { color: "red" }
                  : {}
              }></div>
            <div
              className="description-input"
              onMouseEnter={() => context.setTipOpen("tip3")}>
              <Editor
                toolbar={{
                  options: ["inline", "blockType", "list"],
                  inline: {
                    options: ["bold", "italic", "underline"]
                  },
                  blockType: {
                    className: "rte-hidden",
                    options: ["Normal"]
                  },
                  list: {
                    options: ["unordered", "ordered"]
                  },
                  fontSize: {
                    className: "rte-hidden",
                    options: [16]
                  },
                  fontFamily: {
                    className: "rte-hidden",
                    options: ["Tahoma"]
                  },
                  textAlign: {
                    className: "rte-hidden",
                    options: ["left"]
                  },
                  colorPicker: {
                    className: "rte-hidden",
                    colors: []
                  },
                  link: {
                    className: "rte-hidden",
                    options: []
                  },
                  emoji: {
                    className: "rte-hidden"
                  },
                  embedded: {
                    className: "rte-hidden"
                  },
                  image: {
                    className: "rte-hidden"
                  },
                  remove: {
                    className: "rte-hidden"
                  },
                  history: {
                    className: "rte-hidden",
                    options: []
                  }
                }}
                placeholder="Décrivez votre mission..."
                editorState={context.state.editorState}
                toolbarClassName="toolbar"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={context.handleEditorInput}
                stripPastedStyles={true}
              />
            </div>
            <HoverErrorComponent
              className="validate-terms"
              setIsOpen={context.displayErrors}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ValidButton
                  className={
                    isValid(context)
                      ? isSending
                        ? "validate-white"
                        : "validate-blue"
                      : "validate-white"
                  }
                  onClick={() => handleSendingMission(context)}
                  condition={1}
                />
                {isSending && (
                  <div className="loader">
                    <Loader
                      type="Puff"
                      color="#29b6e5"
                      height={30}
                      width={30}
                    />
                    <p>Création de votre annonce...</p>
                  </div>
                )}
                {context.state?.apiError && (
                  <div className="errors-block" style={{ marginLeft: 10 }}>
                    <div className="error">{context.state.apiError}</div>
                  </div>
                )}
              </div>
            </HoverErrorComponent>
          </div>
          <Tips
            {...context.state}
            onMouseEnter={context.handleToggle}
            onMouseLeave={context.handleToggle}
            severalTips={true}
            threeTips={true}
            smallText={
              <div className="small-text">
                <span> </span>
                <Link to="/authentification">
                  <span className="green-text"> </span>
                </Link>
              </div>
            }
            texts={
              context.state.tipOpen === "tip1"
                ? tip1
                : context.state.tipOpen === "tip2"
                ? tip2(count)
                : tip3
            }
          />
        </section>
      )}
    </NewMissionConsumer>
  );
};

export default Description;

const tip1 = [
  "Ces critères sont déterminants dans le choix définitif des candidats."
];
const tip2 = (count: number) => [
  `Pensez à faire un titre court et impactant. Plus de ${count} missions sont disponibles sur App’Ines. Faites la différence.`
];
const tip3 = [
  "Vous avez d’autres critères que vous souhaitez communiquer aux candidats ?",
  "Donnez envie aux candidats de venir dans votre établissement.",
  "N’oubliez pas de compléter votre fiche établissement une fois l’annonce créée."
];
