import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react";
import SubstitutesList from "./SubstitutesList/SubstitutesList";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Services/Contexts/AuthProvider";
import * as service from "../../Services/candidate.services";
import Header from "../../Components/Header/Header";
import FiltersBlock from "./SubstitutesList/FiltersBlock/FiltersBlock";
import { useDebounce, useLogRocketIdentify } from "../../Tools/customHooks";

const SubstitutesPage = (props: AuthState) => {
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const context = useContext(AuthContext);
  const officeLocationMemoized = useMemo(() => context.state.office?.loc?.coordinates || [], [
    context.state.office?.loc,
  ]);

  /*
   ! Reducer, states storage
   */
  const reducer = (
    state: SubstitutesPageState,
    action: { type: ActionType; payload?: any }
  ): SubstitutesPageState => {
    const defaultFilters = {
      allSubstitutes: [],
      count: 0,
      isLoading: true,
      noMoreData: false,
      index: 0,
    };

    switch (action.type) {
      // ? Substitutes list controllers
      case "addToLiked":
      case "removeToLiked":
        return {
          ...state,
          ...action.payload,
        };

      case "addCandidatesToList":
        return {
          ...state,
          allSubstitutes: state.allSubstitutes.concat(action.payload.allSubstitutes),
          count: action.payload.count,
          substitutesLiked: state.substitutesLiked.concat(action.payload.substitutesLiked),
          isLoading: false,
          preventScroll: false,
        };

      case "updateCandidatesList":
        return {
          ...state,
          allSubstitutes: action.payload.allSubstitutes,
          count: action.payload.count,
          substitutesLiked: action.payload.substitutesLiked,
          isLoading: false,
          preventScroll: false,
          isAvailable: state.exercices.length > 0,
        };

      case "fetchMoreCandidates":
        return {
          ...state,
          preventScroll: true,
          index:
            state.preventScroll && state.count > state.allSubstitutes.length
              ? state.index
              : state.index + 1,
        };

      case "noMoreData":
        return {
          ...state,
          noMoreData: true,
          preventScroll: true,
        };
      // ? Filter block controllers
      case "resetFilters":
        return {
          ...state,
          profession: null,
          type: "substitute",
          exercices: [],
          skills: [],
          minXp: "graduated",
          maxDistance: 100, // ? > 90 : "France entière"
          ...defaultFilters,
        };

      case "updateProfessionSelected":
        return {
          ...state,
          profession: action.payload.profession,
          skills: [],
          ...defaultFilters,
        };

      case "updateProfileType":
        return {
          ...state,
          type: action.payload.type,
          maxDistance: 100,
          ...defaultFilters,
        };

      case "updateMaxDistanceSearch":
        return {
          ...state,
          maxDistance: action.payload.maxDistance,
          ...defaultFilters,
        };

      case "addExercise":
        return {
          ...state,
          exercices: [...state.exercices].concat(action.payload.exercisesToAdd),
          ...defaultFilters,
        };

      case "removeExercise":
        return {
          ...state,
          exercices: [...state.exercices].filter(
            (exercise: Exercise) => !action.payload.exercisesToDelete.includes(exercise)
          ),
          ...defaultFilters,
        };

      case "addSkill":
        return {
          ...state,
          skills: [...state.skills, action.payload.skill],
          ...defaultFilters,
        };

      case "removeSkill":
        return {
          ...state,
          skills: [...state.skills].filter(
            (skill: Skill) => skill._id !== action.payload.skill._id
          ),
          ...defaultFilters,
        };

      case "updateMinXp":
        return {
          ...state,
          minXp: action.payload.minXp,
          ...defaultFilters,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, context.state.substitutesPage.state);

  /* Returns a copy of the state with a delay of x milliseconds. */
  /* Like that, API calls are not executed too frequently (1 call max per x milliseconds) */
  /* For more details : https://usehooks.com/useDebounce/ */
  const debouncedState: SubstitutesPageState = useDebounce(state, 500);
  /*
 ! Utilities
 */
  const handleGetPractitionerDetails = async (id: string): Promise<void> => {
    history.push("/remplacants/" + id);
  };
  const handleLikePractitioner = (id: string): void => {
    service.likePractitioner(id);

    let likedListUpdated = [...state.substitutesLiked];
    likedListUpdated.push({ substituteId: id, date: new Date() });

    dispatch({
      type: "addToLiked",
      payload: {
        substitutesLiked: likedListUpdated,
      },
    });
  };
  const handleUnlikePractitioner = (id: string): void => {
    service.unlikePractitioner(id);

    dispatch({
      type: "removeToLiked",
      payload: {
        substitutesLiked: state.substitutesLiked.filter(
          (candidate: { substituteId: string }) => candidate.substituteId !== id
        ),
      },
    });
  };
  const handleGetCandidates = async (): Promise<void> => {
    const response = await service.getCandidatesProfiles(0, {
      type: state.type,
      professionId: state.profession?._id,
      exercise: state.exercices,
      skills: state.skills,
      expYear: state.minXp,
      location: officeLocationMemoized,
      maxDistance: state.maxDistance,
    });

    dispatch({
      type: "updateCandidatesList",
      payload: {
        allSubstitutes: response.data,
        count: response.total,
        substitutesLiked: response.data
          .filter((substitute) => substitute.isLiked)
          .map((substitute) => {
            return {
              substituteId: substitute._id,
              date: substitute.likedDate,
            };
          }),
      },
    });

    if (response.data.length < 10) dispatch({ type: "noMoreData" });
  };
  const handleGetMoreCandidates = async (page: number = 0): Promise<void> => {
    const response = await service.getCandidatesProfiles(page, {
      type: state.type,
      professionId: state.profession?._id,
      exercise: state.exercices,
      skills: state.skills,
      expYear: state.minXp,
      location: officeLocationMemoized,
      maxDistance: state.maxDistance,
    });

    dispatch({
      type: "addCandidatesToList",
      payload: {
        allSubstitutes: response.data,
        count: response.total,
        substitutesLiked: response.data
          .filter((substitute) => substitute.isLiked)
          .map((substitute) => ({ substituteId: substitute._id, date: substitute.likedDate })),
      },
    });

    if (response.data.length < 10) dispatch({ type: "noMoreData" });
  };
  const handleResetFilters = (): void => dispatch({ type: "resetFilters" });
  const handleUpdateProfession = async (profession: Profession): Promise<void> => {
    dispatch({ type: "updateProfessionSelected", payload: { profession: profession } });
  };
  const handleUpdateProfileType = (type: "holder" | "substitute") => {
    dispatch({ type: "updateProfileType", payload: { type: type } });
  };
  const handleUpdateMaxDistanceSearch = (value: number) => {
    dispatch({ type: "updateMaxDistanceSearch", payload: { maxDistance: value } });
  };
  const handleAddExercise = (exercises: Array<Exercise>): void => {
    dispatch({ type: "addExercise", payload: { exercisesToAdd: exercises } });
  };
  const handleRemoveExercise = (exercises: Array<Exercise>): void => {
    dispatch({ type: "removeExercise", payload: { exercisesToDelete: exercises } });
  };
  const handleAddSkill = (skill: Skill): void => {
    dispatch({ type: "addSkill", payload: { skill: skill } });
  };
  const handleRemoveSkill = (skill: Skill): void => {
    dispatch({ type: "removeSkill", payload: { skill: skill } });
  };
  const handleMinXp = (value: "student" | "equivalence" | "graduated"): void => {
    dispatch({ type: "updateMinXp", payload: { minXp: value } });
  };

  useLogRocketIdentify(context);

  /*
  ! useEffect hooks
  */
  useEffect(() => {
    context.handleUpdateSubstitutesPageContext(state);
  }, [state]);

  useEffect(() => {
    if (debouncedState.index === 0 && debouncedState.isLoading)
      (async () => {
        await handleGetCandidates();
      })();
  }, [
    debouncedState.index,
    debouncedState.isLoading,
    debouncedState.profession,
    debouncedState.type,
    debouncedState.exercices,
    debouncedState.skills,
    debouncedState.minXp,
  ]);

  useEffect(() => {
    if (
      state.index > 0 &&
      state.index * 10 > state.allSubstitutes.length - 1 && // ! avoid unnecessary fetching
      state.allSubstitutes.length < state.count && // ! avoid unnecessary fetching
      !state.noMoreData &&
      state.preventScroll
    )
      (async () => {
        await handleGetMoreCandidates(state.index);
      })();
  }, [
    state.index,
    state.noMoreData,
    state.allSubstitutes.length,
    state.count,
    state.noMoreData,
    state.preventScroll,
  ]);

  useEffect(() => {
    const clientBrowserHeight = document.documentElement.clientHeight;
    const refBottomY = ref?.current?.getBoundingClientRect().bottom;

    // ? handle screens with high resolution
    if (clientBrowserHeight === refBottomY && state.count > state.allSubstitutes.length)
      dispatch({ type: "fetchMoreCandidates" });
  }, [debouncedState.allSubstitutes.length]);

  useEffect(() => {
    // ? loads more candidates depending on the scroll
    const handleScroll = async () => {
      const clientBrowserHeight = document.documentElement.clientHeight;
      const refBottomY = Math.floor(ref?.current?.getBoundingClientRect().bottom || 0);

      if (Math.abs(clientBrowserHeight - refBottomY) <= 1 && !state.isLoading)
        dispatch({ type: "fetchMoreCandidates" });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [ref.current, state.allSubstitutes.length, state.isLoading]);

  return (
    <div ref={ref}>
      <Header user={props.user} />
      <FiltersBlock
        handleResetFilters={handleResetFilters}
        handleSelectProfession={handleUpdateProfession}
        handleUpdateProfileType={handleUpdateProfileType}
        handleUpdateMaxDistanceSearch={handleUpdateMaxDistanceSearch}
        handleAddExercise={handleAddExercise}
        handleRemoveExercise={handleRemoveExercise}
        handleAddSkill={handleAddSkill}
        handleRemoveSkill={handleRemoveSkill}
        handleMinXp={handleMinXp}
        isLoading={debouncedState.isLoading}
      />
      <SubstitutesList
        user={props.user}
        handleGetPractitionerDetails={handleGetPractitionerDetails}
        handleLike={(candidateId: string) => handleLikePractitioner(candidateId)}
        handleUnlike={(candidateId: string) => handleUnlikePractitioner(candidateId)}
        isLoading={debouncedState.isLoading}
        allSubstitutes={
          state.isLoading !== debouncedState.isLoading
            ? debouncedState.allSubstitutes
            : state.allSubstitutes
        }
        profession={debouncedState.profession}
        minXp={debouncedState.minXp}
        count={debouncedState.count}
        isAvailable={state.isAvailable}
        liked={state.substitutesLiked}
        preventScroll={state.preventScroll}
        type={state.type}
      />
    </div>
  );
};

export default SubstitutesPage;

type ActionType =
  // ? Substitutes list
  | "updateCandidatesList"
  | "addCandidatesToList"
  | "addToLiked"
  | "removeToLiked"
  | "fetchMoreCandidates"
  | "noMoreData"
  // ? Filter block
  | "resetFilters"
  | "updateProfessionSelected"
  | "updateProfileType"
  | "updateMaxDistanceSearch"
  | "addExercise"
  | "removeExercise"
  | "addSkill"
  | "removeSkill"
  | "updateMinXp";
