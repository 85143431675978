import React, { useEffect } from "react";
import { AuthConsumer } from "../../Services/Contexts/AuthProvider";
import history from '../../history';

const Home = () => {
  useEffect(() => {
    history.push('/remplacants')
  })
  return (
    <div>
      <AuthConsumer>
        {(context: AuthProps) => (
          <section className="home">
            {context.state.subscriptionStatus !== 'subscribed' && (
              <div style={{ height: "30px" }} />
            )}
            <div className="container">
              <div>{context.state.user.firstName}</div>
            </div>
          </section>
        )}
      </AuthConsumer>
    </div>
  );
};

export default Home;
