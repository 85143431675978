import React, { useState } from "react";
import Switch from "./Switch/Switch";
import { fetchApi } from "../../../Services/Contexts/Api";
import Loader from "react-loader-spinner";

interface CardProps {
  plan: any;
  benefits: any;
  pricing: any[];
  switchValues: any[];
  handleShowSimulator: Function;
  setChoice: Function;
  choice: string;
  userEmail: string;
  etsFiness: string;
  currentPlan: string;
}

const Card = (props: CardProps) => {
  const {
    plan,
    benefits,
    pricing,
    switchValues,
    handleShowSimulator,
    setChoice,
    choice,
    userEmail,
    etsFiness,
    currentPlan,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasErrorOccured, setHasErrorOccured] = useState(false);
  const [callbackRequested, setCallbackRequested] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    setHasErrorOccured(false);

    fetchApi("/subscriptions/hubspot/createTask", "POST", {
      plan: plan,
      finess: etsFiness,
      email: userEmail,
      choice: switchValues[plan][choice],
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setCallbackRequested(true);
        } else {
          setIsLoading(false);
          setHasErrorOccured(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setHasErrorOccured(true);
      });
  };

  return (
    <div className={`card ${plan}`}>
      {/* // ? PLAN */}
      <div className="title">{plan}</div>

      {/* // ? SLOGAN */}
      <div className="description">
        {plan === "smile" && "Profitez de la puissance de notre solution de matching"}
        {plan === "happy" && "Contactez tous les candidats en illimité"}
        {plan === "zen" && "Confiez-nous votre recherche"}
      </div>

      <Switch setChoice={setChoice} choice={choice} entries={switchValues[plan]} />

      {/* // ? PRICE */}
      <div className="price">
        {plan !== "zen" ? (
          <>
            <span>{pricing[plan][choice].amountPerMonth}</span>
            <span>€ HT / mois</span>
          </>
        ) : (
          <>
            <span>{pricing[plan][choice].commission}%</span>
          </>
        )}
      </div>

      {plan !== "zen" ? (
        <div className="details-price">{`soit ${pricing[plan][choice].amountPerMonth *
          pricing[plan][choice].numberOfMonths}€ HT`}</div>
      ) : (
        <div className="details-price">
          {choice === "cdd" ? " du salaire brut du candidat" : " du salaire brut annuel"}
        </div>
      )}

      <button className={`${plan} toggle-simulator`} onClick={() => handleShowSimulator()}>
        Simuler votre tarif
      </button>

      <div className="call-to-action">
        {!callbackRequested && !isLoading && currentPlan !== plan && (
          <button onClick={handleClick}>Je suis intéressé</button>
        )}

        {currentPlan === plan && (
          <button disabled={true} className={"current-plan"}>
            Mon offre actuelle
          </button>
        )}

        {isLoading && (
          <div className="loader">
            Traitement en cours
            <Loader type="Puff" color="#29b6e5" height={15} width={15} />
          </div>
        )}

        {callbackRequested && !isLoading && !hasErrorOccured && (
          <div className="success">
            ✅ Votre demande a bien été prise en compte, nous prendrons contact avec vous très
            rapidement
          </div>
        )}

        {hasErrorOccured && (
          <div className="error">
            Une erreur s'est produite. Veuillez réessayer ou contactez-nous à l'adresse suivante :
            contact@appines.fr
          </div>
        )}
      </div>

      {/* // ? LIST OF BENEFITS */}
      {// ! --- SMILE OR HAPPY PLAN ---
      plan !== "zen" ? (
        <div className="details">
          {benefits[plan].map((benefit: any, index: number) => (
            <div className="label" key={index}>
              <i className="fas fa-check" />
              {benefit}
            </div>
          ))}
        </div>
      ) : (
        // ! --- ZEN PLAN ---
        <div className="details">
          {benefits[plan].map((benefit: any, index: number) => {
            const category: any = Object.keys(benefit)[0];

            const items: any = Object.values(benefit)[0];

            return (
              <div>
                <div className="category" key={index}>
                  {category}
                </div>
                {items.map((item: any, index: number) => (
                  <div key={index} className="label">
                    <i className="fas fa-check" />
                    {item}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Card;
