import React from "react";

const AdminMessageThumbnail = (props: {
  title?: any;
  date?: string;
  candidateFirstName?: string;
  type?: string;
  status?: string;
}) => {
  return (
    <div className={"admin-message"}>
      {props.type === "MissionSubscription" &&
        props.status === "ACCEPTED" &&
        `✨ Vous avez accepté la candidature de ${props.candidateFirstName} pour l'annonce "${props.title}" le ${props.date}`}

      {props.type === "MissionSubscription" &&
        props.status === "REFUSED" &&
        `🚫 Vous avez refusé la candidature de ${props.candidateFirstName} pour l'annonce "${props.title}" le ${props.date}`}

      {props.type === "MissionSubscription" &&
        props.status === "CANCELED" &&
        `🚫 ${props.candidateFirstName} a annulé sa candidature pour l'annonce "${props.title}" le ${props.date}`}

      {props.type === "System" && `📣 ${props.title}`}

      {props.type === "Start" && `🏁 Début de la conversation`}

      {!props.type && `${props.title}`}
    </div>
  );
};

export default AdminMessageThumbnail;
