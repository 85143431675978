import { fetchApi } from "./Contexts/Api";

/**
 * It fetches candidates profiles from the backend
 * @param {number} [page=0] - number = 0,
 * @param {SearchCandidatePayload} payload - SearchCandidatePayload
 * @returns A promise that resolves to an object with the following shape:
 */
export const getCandidatesProfiles = async (
  page: number = 0,
  payload: SearchCandidatePayload
): Promise<CandidateResponse> => {
  // ? SUBSTITUTE
  if (payload.type === "substitute") {
    const bodyParams: BodyParams = {};

    if (payload.professionId) bodyParams.professionId = payload.professionId;
    if (payload.exercise.length > 0) bodyParams.exercise = payload.exercise;
    if (payload.skills.length > 0) bodyParams.skills = payload.skills;
    if (payload.expYear) bodyParams.expYear = payload.expYear;
    if (payload.maxDistance === 100) bodyParams.zone = "France";
    if (payload.maxDistance < 100)
      bodyParams.point = {
        coordinates: payload.location,
        maxDistance: payload.maxDistance * 1000,
        name: Date.now().toString(),
      };

    return (
      await fetchApi(`/search/candidates?page=${page}`, "post", {
        ...bodyParams,
        status: "ACTIVE",
      })
    ).data;
  }

  // ? HOLDER
  const bodyParams: BodyParams = {
    point: { coordinates: payload.location, maxDistance: payload.maxDistance * 1000 },
  };

  if (payload.professionId) bodyParams.professionId = payload.professionId;
  if (payload.skills.length > 0) bodyParams.skills = payload.skills;

  return (
    await fetchApi(`/search/holder?page=${page}`, "post", {
      ...bodyParams,
    })
  ).data;
};

/**
 * It makes a DELETE request to the endpoint /like-practitioner/:candidateId, where :candidateId is the id of the candidate
 * that the user wants to remove from their favorites
 * @param {string} candidateId - string
 */
export const unlikePractitioner = (candidateId: string): void => {
  const endpoint = "/like-practitioner/";
  (() => fetchApi(endpoint + candidateId, "delete"))();
};

/**
 * It makes a PUT request to the endpoint /like-practitioner/:candidateId, where :candidateId is the id of the candidate
 * that the user wants to add to their favorites
 * @param {string} candidateId - string
 */
export const likePractitioner = (candidateId: string): void => {
  const endpoint = "/like-practitioner/";
  (() => fetchApi(endpoint + candidateId, "put"))();
};

/**
 * It fetches a candidate's details from the API and returns them
 * @param {string} candidateId - The id of the candidate you want to get details for.
 * @returns An object with the following properties:
 *   - id: string
 *   - name: string
 *   - email: string
 *   - phone: string
 *   - address: string
 *   - city: string
 *   - state: string
 *   - zip: string
 *   - skills: string[]
 *   - availability: string[]
 *   - experience: string[]
 *   - education
 */
export const getCandidateDetails = async (candidateId: string): Promise<SubstituteProps> => {
  const endpoint = "/practitioner/";
  return (await fetchApi(endpoint + candidateId)).data;
};

/**
 * It fetches the candidate's research data from the backend
 * @param {string} candidateId - The id of the candidate you want to get the researches for.
 * @param {number} page
 * @returns An array of objects.
 */
export const getCandidateResearches = async (
  candidateId: string,
  page: number = 0
): Promise<{ inSector: Array<Research>; outSector: Array<Research>; outSectorTotal: number }> => {
  const endpoint = "/researches/user/";
  const data = (await fetchApi(endpoint + candidateId + "?page=" + page)).data;

  return {
    inSector: data.inSector.data,
    outSector: data.outSector.data,
    outSectorTotal: data.outSector.total || 0,
  };
};

type SearchCandidatePayload = {
  type: "substitute" | "holder";
  professionId: string | undefined;
  exercise: Array<Exercise>;
  skills: Skill[];
  expYear: "student" | "equivalence" | "graduated";
  location: Array<number | undefined>;
  maxDistance: number;
};
type BodyParams = {
  professionId?: string;
  exercise?: Array<Exercise>;
  skills?: Skill[];
  expYear?: "student" | "equivalence" | "graduated";
  point?: { coordinates: Array<number | undefined>; maxDistance: number; name?: string };
  zone?: "France";
};
