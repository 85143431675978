// DEPENDENCIES
import React, { useContext, useState } from "react";

// COMPONENTS
import ValidButton from "../../../Components/Forms/Buttons/ValidButton/ValidButton";

// ASSETS
import { ICustomerSuccessData } from "../IDiscussions";
import Loader from "react-loader-spinner";
import { fetchApi } from "../../../Services/Contexts/Api";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";

const SupportThumbnail = (props: { customerSuccessOfficerData: ICustomerSuccessData }) => {
  const { customerSuccessOfficerData } = props;

  const [isLoading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<Boolean>(false);
  const [hubspotTaskCreated, setHubspotTaskCreated] = useState<Boolean>(false);

  const context = useContext(AuthContext);

  const handleCreateHubspotTask = () => {
    setLoading(true);

    fetchApi("/discussions/support/createHubspotTask", "POST", {
      email: context.state.email,
      finess: context.state.user.office.finess,
    })
      .then(() => {
        setLoading(false);
        setHubspotTaskCreated(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className={"support-thumbnail"}>
      <div className={"title"}>Besoin d'aide ?</div>
      <div className={"role"}>Votre référent App'Ines :</div>
      <div>
        <div className="avatar">
          <img src={customerSuccessOfficerData?.avatarUrl} alt="monkey-avatar" />
        </div>
        <div className={"identity"}>
          <div>{customerSuccessOfficerData?.firstName} </div>
          <div>{customerSuccessOfficerData?.lastName}</div>
        </div>
      </div>

      <div className={"mail"}>
        <i className="fas fa-envelope" />
        <a target={"_blank"} href={`mailto:${customerSuccessOfficerData?.email}`}>
          {customerSuccessOfficerData?.email}
        </a>
      </div>

      <div className={"cta"}>
        {!isLoading && error && (
          <div className={"hubspot-error"}>
            Une erreur s'est produite. Veuillez réessayer ou contactez-nous :{" "}
            <a target={"_blank"} href={"mailto:contact@appines.fr"}>
              contact@appines.fr
            </a>
          </div>
        )}

        {!isLoading && !hubspotTaskCreated && (
          <ValidButton
            condition={true}
            text="Je souhaite être recontacté"
            onClick={handleCreateHubspotTask}
          />
        )}

        {isLoading && <Loader type={"Puff"} height={30} width={30} color={"#29b6e5"} />}

        {!isLoading && hubspotTaskCreated && (
          <div>
            ✅ Votre demande a bien été prise en compte, nous prendrons contact avec vous très
            rapidement
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportThumbnail;
