import React from "react";
import axios from "axios";
import { AuthConsumer } from "../../../../Services/Contexts/AuthProvider";
import { AdsThumbProps } from "../AdsList/AdsList";
import UseHoverComponent from "../../../../Components/useHover";
import history from "../../../../history";
import { RightIcons } from "./RightIcons";
import { FillMission } from "./FillMission";
import { byPass } from "../../../../Tools/StringTools";
import dayjs from 'dayjs';
import Loader from "react-loader-spinner";

interface State {
  isLoading: Boolean;
  linkIsReady: Boolean;
  linkIsCopied: boolean;
  viewers: any[];
  shares: number;
  views: number;
  likes: number;
  willShare: boolean;
  deepLink: string;
  newCandidates: number;
}

interface Props extends Omit<AdsThumbProps, "ads"> {
  ads: MissionProps;
}

class AdsThumnbnail extends React.Component<Props, State> {
  public shareRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      linkIsReady: false,
      linkIsCopied: false,
      viewers: [],
      shares: 0,
      views: 0,
      likes: 0,
      willShare: false,
      deepLink: "",
      newCandidates: 0
    };
    this.shareRef = React.createRef();
  }

  exercises: any = {
    substitute: "Vacation",
    cdi: "CDI",
    cdd: "CDD",
    assistantship: "Assistanat",
    collaboration: "Collaboration",
    volunteering: "Volontariat",
    internship: "Stage"
  };

  handleToggle = (name: keyof State) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name]
    });
  };

  handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.state.deepLink as string);
    } else {
      // Extremly ugly hack for edge copy to clipboard support.
      const deeplinkbtn = document.getElementById("deeplink");
      var textArea: any = document.createElement("textarea");
      textArea.value = deeplinkbtn?.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
    }
    this.setState({ linkIsCopied: true }, () => {
      setTimeout(
        () => this.setState({ linkIsCopied: false, willShare: false }),
        1000
      );
    });
  };

  handleShare = (e: any) => {
    e.stopPropagation();
    if (
      e.target.className === "share" ||
      e.target.className === "fas fa-share-alt"
    ) {
      this.setState({
        willShare: !this.state.willShare
      });
    }
  };

  renderDates = (startDate: Date, endDate: Date | null) => {
    startDate = new Date(startDate);
    endDate = endDate ? new Date(endDate) : null;

    if (this.props.ads.exercise !== "cdi" && endDate) {
      return (
        <div>{`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</div>
      );
    } else {
      return <div>{`${startDate.toLocaleDateString()} - indéterminée`}</div>;
    }
  };

  editAd = () => {
    history.push("/create-mission", this.props.ads);
  };

  generateDeepLink = async () => {
    const options = {
      branch_key: `${global.config.branch_key}`,
      channel: "social-networks",
      feature: "mission-sharing",
      campaign: "Share Pro Website",
      data: {
        $canonical_identifier: "missions/" + this.props.ads._id,
        $og_title: this.props.ads.title,
        $og_description: "",
        $og_image_url: "",
        $desktop_url: "http://www.appines.fr"
      }
    };

    if (this.props.ads.description) {
      options["data"]["$og_description"] = byPass(
        this.props.ads.description,
        undefined
      );
    }
    if (this.props.ads.office.pictures.length > 0) {
      options["data"]["$og_image_url"] = this.props.ads.office.pictures[0]?.thumbnail?.url;
    }

    let res;

    try {
      this.setState({isLoading: true});

      res = await axios.post(
        `${global.config.api}/cors-anywhere`,
        {
          method: "POST",
          endPoint: `${global.config.branch_url}`,
          body: options,
          headers: {
            "Content-Type": "application/json"
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      
      this.setState({
        isLoading: false,
        linkIsReady: true,
        deepLink: res.data.url,
        willShare: true
      });
      
    } catch (error) {
      this.setState({ isLoading: true, deepLink: "Une erreur est survenue", willShare: true });
    }

    return res?.data?.url;
  };

  handleClickOutside(event: any, ref: any) {
    if (ref && !ref?.current?.contains(event.target)) {
      this.setState({ willShare: false });
    }
  }

  handleSeeStats = () => {
    history.push(`/mission/stats/${this.props.ads._id}`, this.props.ads);
  };

  capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", e =>
      this.handleClickOutside(e, this.shareRef)
    );
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", e =>
      this.handleClickOutside(e, this.shareRef)
    );
  }

  render() {
    const job: AllExercises = this.props.ads.exercise;

    const isMissionBoosted = !!(this.props.tab === "active" && this.props.ads.boostInfos);
    const boostDuration =
      !isMissionBoosted && this.props.ads.boostInfos?.endDate
        ? 0
        : dayjs(this.props.ads.boostInfos?.endDate).diff(dayjs(), "d");

    return (
      <div className="ads">
        <AuthConsumer>
          {(context: AuthProps) => (
            <div>
              <div className="thumbmail-and-buttons">
                <div className="thumbnail">
                  {context.state.isDemoAccount && (
                    <span className="demo-ad">Mission de démonstration</span>
                  )}
                  {this.props.ads.hired.isFilled && <div className="green-filled">Pourvue</div>}

                  {isMissionBoosted && context.state.subscriptionStatus === "subscribed" && !this.props.ads.hired.isFilled &&(
                    <div className="peach-filled">
                      <i className="fas fa-rocket" />
                      Boostée (reste {boostDuration} jour{boostDuration > 1 && "s"})
                    </div>
                  )}

                  <div className="left-block">
                    <div className="profession">
                      <div className="profession-icon">
                        <i className="far fa-hand-paper" />
                      </div>
                      <p>
                        {this.props.ads.profession.name
                          ? this.props.ads.profession.name.FR
                          : this.capitalizeFirstLetter(this.props.ads.profession)}
                      </p>
                    </div>

                    <div>
                      <h4>{this.props.ads.title}</h4>
                      <div className="bottom-content">
                        <div className="dates">
                          <i className="far fa-calendar" />
                          {this.renderDates(this.props.ads.startDate, this.props.ads.endDate)}
                        </div>
                        <div className="job-type">
                          <i className="fas fa-pen-nib" />
                          <div>{this.exercises[job]}</div>
                        </div>
                      </div>
                    </div>
                    {this.props.tab === "active" && (
                      <div
                        className={
                          context.state.currentPlan === "smile" ||
                          context.state.currentPlan === "happy"
                            ? "share"
                            : "share forbidden"
                        }
                        onClick={
                          context.state.currentPlan === "smile" ||
                          context.state.currentPlan === "happy"
                            ? this.state.linkIsReady
                              ? this.handleShare
                              : this.generateDeepLink
                            : () =>
                                context.popBarMessage(
                                  "Vous devez souscrire à l'offre Smile ou Happy pour partager vos annonces"
                                )
                        }
                      >
                        {this.state.linkIsReady && !this.state.isLoading && (
                          <>
                            <UseHoverComponent text="Diffusez votre annonce en seulement 1 clic">
                              <i className="fas fa-share-alt" />
                            </UseHoverComponent>
                            {this.state.willShare && (
                              <div className="share-popup" ref={this.shareRef}>
                                <p>Partager ce lien :</p>
                                <p className="deeplink" onClick={this.handleCopy}>
                                  <span id="deeplink">{this.state.deepLink}</span>
                                  <i className="far fa-copy" />
                                </p>
                                {this.state.linkIsCopied && (
                                  <span className={"link-copied"}>
                                    copié dans le presse papier !
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        )}

                        {!this.state.linkIsReady && !this.state.isLoading && (
                          <UseHoverComponent text="Diffusez votre annonce en seulement 1 clic">
                            <i className="fas fa-share-alt" />
                          </UseHoverComponent>
                        )}

                        {this.state.isLoading && (
                          <>
                            <UseHoverComponent text="Diffusez votre annonce en seulement 1 clic">
                              <i className="fas fa-share-alt" />
                            </UseHoverComponent>
                            <div className="share-popup" ref={this.shareRef}>
                              <p className="deeplink">
                                <span id="deeplink">Génération du lien</span>
                                <Loader type={"Puff"} color={"#29b6e5"} height={10} width={10} />
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {this.props.tab === "closed" && <div className="share-transparent" />}
                  </div>
                </div>
                <RightIcons
                  tab={this.props.tab}
                  mission={this.props.ads}
                  handleMoveMission={this.props.handleMoveMission}
                />
                {this.props.tab === "active" && (
                  <FillMission
                    mission={this.props.ads}
                    source={this.props.source}
                    substitute={this.props.substitute}
                    handleSelect={this.props.handleSelect}
                    handleMoveMission={this.props.handleMoveMission}
                  />
                )}
              </div>
              <div className="datas">
                {this.props.tab === "active" && !!context.state.subscriptionStatus && (
                  <>
                    {this.props.ads.missionSubscribers.length > 0 ? (
                      <div
                        className={`blue-applications ${
                          context.state.currentPlan === "smile" ||
                          context.state.currentPlan === "happy"
                            ? ""
                            : "forbidden"
                        }`}
                        onClick={
                          this.props.tab === "active" &&
                          this.props.ads.missionSubscribers.length > 0 &&
                          (context.state.currentPlan === "smile" ||
                            context.state.currentPlan === "happy")
                            ? () =>
                                this.props.handleGetMission &&
                                this.props.handleGetMission(this.props.ads)
                            : () => {
                                context.popBarMessage(
                                  `Vous devez souscrire à l'offre Smile ou Happy pour voir les candidatures`
                                );
                              }
                        }
                      >
                        <i className="far fa-user" />
                        Candidatures ({this.props.ads.missionSubscribers.length})
                      </div>
                    ) : (
                      <div>
                        <i className="far fa-user" />
                        Candidatures ({this.props.ads.missionSubscribers.length})
                      </div>
                    )}
                  </>
                )}
                <UseHoverComponent text="Nombre de consultations de votre annonce">
                  <div>
                    <i className="far fa-eye" />
                    {this.state.views}
                  </div>
                </UseHoverComponent>
                <UseHoverComponent text="Nombre de mis en favori de votre annonce">
                  <div>
                    <i className="far fa-heart" />
                    {this.state.likes}
                  </div>
                </UseHoverComponent>
                <UseHoverComponent text="Nombre de partages de votre annonce">
                  <div>
                    <i className="fas fa-share-alt" />
                    {this.state.shares}
                  </div>
                </UseHoverComponent>
                <div
                  className={
                    context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
                      ? "blue-applications"
                      : "blue-applications forbidden"
                  }
                  onClick={
                    context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
                      ? this.handleSeeStats
                      : () =>
                          context.popBarMessage(
                            "Vous devez souscrire à l'offre Smile ou Happy pour visualiser les statistiques d'annonces"
                          )
                  }
                >
                  <i className="far fa-chart-bar" />
                  Stats
                </div>
              </div>
            </div>
          )}
        </AuthConsumer>
      </div>
    );
  }
  componentDidMount() {
    // GET NUMBER OF VIEWS, SHARES, LIKES OF ANY ADS
    this.props.ads?.social?.map(practitioner => {
      this.setState(prevState => ({
        likes: prevState.likes + (practitioner.liked ? 1 : 0),
        shares: prevState.shares + practitioner.shared.times,
        views: prevState.views + practitioner.seen.times
      }));
      return null;
    });

    // CHECK IF NEW CANDIDATES
    let newCandidates = this.props.ads.missionSubscribers.filter(
      missionSubscriber =>
        !missionSubscriber.seenAt &&
        missionSubscriber.status === "PENDING"
    );
    this.setState({ newCandidates: newCandidates.length });
  }
}

export default AdsThumnbnail;
