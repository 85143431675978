import React from "react";

interface Props {
  name: string;
  category: string;
  isSelected: boolean;
  dbRole: string;
  handleSelect: any;
}

const SelectButton = (props: Props) => {

  return (
    <button
      type="button"
      className={!props.isSelected ? "role" : "role-blue"}
      onClick={() => props.handleSelect(props.name, props.dbRole)}
    >
      {props.category}
    </button>
  );
};

export default SelectButton;
