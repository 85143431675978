// DEPENDENCIES
import React from "react";
import { Redirect, Route, Router } from "react-router-dom";
import history from "./history";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

// COMPONENTS
import Home from "./Containers/Home/Home";
import Subscription from "./Containers/Subscription/Subscription";
import AuthProvider, { AuthConsumer } from "./Services/Contexts/AuthProvider";
import Authentification from "./Containers/Authentification/Authentification";
import ResetPasswordAuth from "./Containers/ResetPasswordAuth/ResetPasswordAuth";
import NewMission from "./Containers/NewMission/NewMission";
import OfficePage from "./Containers/OfficePage/OfficePage";
import Ads from "./Containers/Ads/Ads";
import AdsStatistics from "./Containers/Ads/Statistics/AdsStatistics";
import MyCandidates from "./Containers/MyCandidates/MyCandidates";
import SubstitutesPage from "./Containers/SubstitutesPage/SubstitutesPage";
import Pricing from "./Containers/Pricing/Pricing";
import MyProfile from "./Containers/MyProfile/MyProfile";
import ErrorPage from "./Containers/ErrorPage/ErrorPage";
import ResponsiveError from "./Components/ResponsiveError/ResponsiveError";
import NewMissionProvider, { NewMissionConsumer } from "./Services/Contexts/NewMissionProvider";
import Discussion from "./Containers/Discussions/Discussions";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// UTILS
import { getGlobalConfig } from "./Tools/getGlobalConfig";
import SubstitutePersonalPage from "./Containers/SubstitutesPage/SubstitutePersonalPage/SubstitutePersonalPage";

// LOG ROCKET
LogRocket.init(
  process.env.REACT_APP_NODE_ENV === "production"
    ? "f4mlss/react-app-proappinesfr"
    : "f4mlss/react-app-devproappinesfr",
  {
    network: {
      requestSanitizer: (request) => {
        if (request.body) {
          const requestBodyParsed = JSON.parse(request.body);

          /* !! Hiding the password for LogRocket !! */
          if (requestBodyParsed.password) {
            requestBodyParsed.password = "********";
          }

          request.body = JSON.stringify(requestBodyParsed);
        }

        return request;
      }
    }
  }
);
setupLogRocketReact(LogRocket);

// SET UP DEVELOPMENT ENVIRONMENT
global.config = getGlobalConfig();

// GOOGLE  ANALYTICS SET UP  FOR  DEV, INTE & PROD
ReactGA.initialize(global.config.analytics.trackingId, { gaOptions: { siteSpeedSampleRate: global.config.analytics.siteSpeedSampleRate } });
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
ReactGA.gtag('js', new Date());

let prevScrollpos = window.pageYOffset;

// TODO : refactor event listener
window.addEventListener("scroll", () => {
  let currentScrollPos = window.pageYOffset;

  let headerElem = document.getElementById("header");
  let rootDiv = document.getElementById("root");
  let upArrow = Array.from(
    document.getElementsByClassName("up-arrow") as HTMLCollectionOf<HTMLElement>
  );

  // if i'm scolling upwar
  if (
    headerElem &&
    prevScrollpos > currentScrollPos &&
    currentScrollPos + window.innerHeight < rootDiv!.clientHeight && // manage Safari issue
    window.location.pathname !== "/error" &&
    window.location.pathname !== "/create-mission"
  ) {
    headerElem.style.top = "0";
    if (
      window.location.pathname === "/remplacants" &&
      document.getElementById("filters")
    ) {
      if (window.innerWidth < 1025) {
        document.getElementById(
          "filters"
        )!.style.height = `${window.innerHeight - 150}px`;
        document.getElementById("filters")!.style.top = "-60px";
      } else {
        document.getElementById(
          "filters"
        )!.style.height = `${window.innerHeight - 200}px`;
        document.getElementById("filters")!.style.top = "0";
      }
    }
    // if i'm scolling downward
  } else if (
    headerElem &&
    prevScrollpos < currentScrollPos &&
    currentScrollPos > 120 && // manage Safari issue
    window.location.pathname !== "/error" &&
    window.location.pathname !== "/create-mission"
  ) {
    headerElem.style.top = "-120px";
    if (
      window.location.pathname === "/remplacants" &&
      document.getElementById("filters")
    ) {
      document.getElementById("filters")!.style.top = "-120px";
      document.getElementById("filters")!.style.height = `${window.innerHeight -
        90}px`;
      if (window.innerWidth < 1025) {
        document.getElementById("filters")!.style.top = "-120px";
      }
    }
  }
  prevScrollpos = currentScrollPos;

  // manage up-arrow display (path : /remplacants)
  if (upArrow[0]) {
    if (currentScrollPos <= 500) {
      upArrow[0].style.bottom = "-70px";
    } else {
      upArrow[0].style.bottom = "30px";
    }
  }
});

window.addEventListener("wheel", () => {
  const rootDivHeight = document.getElementById("root")?.clientHeight; //
  const windowHeight = window.innerHeight;

  if (rootDivHeight === windowHeight) {
    let headerElem = document.getElementById("header");
    if (headerElem?.style) headerElem.style.top = "0";

    if (document.getElementById("filters")) {
      if (window.innerWidth < 1025) {
        document.getElementById(
          "filters"
        )!.style.height = `${window.innerHeight - 150}px`;
        document.getElementById("filters")!.style.top = "-60px";
      } else if (document.getElementById("filters")) {
        document.getElementById(
          "filters"
        )!.style.height = `${window.innerHeight - 200}px`;
        document.getElementById("filters")!.style.top = "0";
      }
    }
  }
});

class App extends React.Component {
  componentDidMount() {
    // const auth = this.context;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }

  render() {
    return (
      <div>
        <div className="responsive-app">
          <Helmet>
            {/*-- GOOGLE ADS --*/}
            {/* Global site tag (gtag.js)*/}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${global.config.ads.trackingId}`}
            />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "${global.config.ads.trackingId}");`}
            </script>

            {/*-- BING ADS--*/}
            <script>
              {`(function(w, d, t, r, u) {
                var f, n, i;
                w[u] = w[u] || [], f = function() {
                  var o = { ti: "${global.config.bing.conversion_id}" };
                  o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad");
                },
                  n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function() {
                  var s = this.readyState;
                  s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null);
                },
                  i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i);
              })
              (window, document, "script", "//bat.bing.com/bat.js", "uetq");`}
            </script>

            {/*-- FACEBOOK PIXEL --*/}
            <script>
              {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${global.config.facebook.pixel_id}');
                fbq('track', 'PageView');`}
            </script>
          </Helmet>

          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              src={`https://www.facebook.com/tr?id=${global.config.facebook.pixel_id}&ev=PageView&noscript=1`}
            />
          </noscript>

          <Router history={history}>
            <AuthProvider>
              {/* <Header /> */}

              <AuthConsumer>
                {(context: any) => (
                  <div onClick={context.state.userNavIsOpen ? () => context.handleToggle("userNavIsOpen") : undefined}>
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return <Home {...context.state} />;
                      }}
                    />

                    <Route exact path="/pricing" render={() => context.state.user.token ? <Pricing {...context.state} /> : <Authentification />} />

                    <Route
                      exact
                      path="/authentification"
                      render={() => {
                        if (context.state.user.token && !context.state.isUserTokenExpired) {
                          return <Redirect to={"/remplacants"}/>
                        }

                        return <Authentification />
                      }}
                    />

                    <Route
                      exact
                      path="/validation"
                      render={() => {
                        if (context.state.user.token && !context.state.isUserTokenExpired) {
                          return <Redirect to={"/remplacants"}/>
                        }

                        return <Authentification accountVerification/>
                      }}
                    />

                    <Route
                      exact
                      path="/mission/stats/:missionId"
                      render={props => {
                        if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                          return <AdsStatistics {...props} {...context.state} />;
                        } else {
                          return <Authentification />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/mon-profil"
                      render={props => {
                        if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                          return <MyProfile {...props} {...context.state} />;
                        } else {
                          return <Authentification />;
                        }
                      }}
                    />
                    <NewMissionProvider>
                      <NewMissionConsumer>
                        {(missionContext: any) => (
                          <Route
                            exact
                            path="/create-mission"
                            render={props => {
                              if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                                return <NewMission {...props} {...context.state} recoverData={missionContext.recoverData} />;
                              } else {
                                return <Authentification />;
                              }
                            }}
                          />
                        )}
                      </NewMissionConsumer>
                      <Route
                        exact
                        path="/remplacants"
                        render={props => {
                          if (context.state.user.token && context.state.user.office && !context.state.isUserTokenExpired) {
                            return <SubstitutesPage {...props} {...context.state} />;
                          } else {
                            return <Authentification />;
                          }
                        }}
                      />
                      <Route
                        exact
                        path="/remplacants/:id"
                        render={props => {
                          if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                            return <SubstitutePersonalPage {...props} {...context.state}/>;
                          } else {
                            return <Authentification />;
                          }
                        }}
                      />
                    </NewMissionProvider>
                    <Route
                      exact
                      path="/office-page"
                      render={props => {
                        if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                          return <OfficePage {...props} {...context.state} />;
                        } else {
                          return <Authentification />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/mes-annonces"
                      render={props => {
                        if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                          return <Ads {...props} {...context.state} />;
                        } else {
                          return <Authentification />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/mes-candidats"
                      render={props => {
                        if (context.state.user.token && context.state.user.office  && !context.state.isUserTokenExpired) {
                          return <MyCandidates {...props} {...context.state} />;
                        } else {
                          return <Authentification />;
                        }
                      }}
                    />
                    {/* <Route
                          exact
                          path="/mes-alertes"
                          render={props => {
                            if (context.state.user.token && context.state.user.office) {
                              return <UserAlerts {...props} {...context.state} />;
                            } else {
                              return <Authentification />;
                            }
                          }}
                        /> */}

                    {/* -- Deactivate subscription link for now -- */}

                    {/*<Route*/}
                    {/*  path="/subscription"*/}
                    {/*  render={() => <Subscription />}*/}
                    {/*/>*/}
                    <Route
                      path="/reset-password"
                      render={props => {
                        return <ResetPasswordAuth {...props} />;
                      }}
                    />

                    <Route path="/error" component={ErrorPage} />

                    <Route path="/discussions/:discussion_id?" render={() => {
                      if (context.state.user.token  && !context.state.isUserTokenExpired)
                        return <Discussion {...context}/>

                      return <Authentification/>
                    }} />
                  </div>
                )}
              </AuthConsumer>
            </AuthProvider>
          </Router>
        </div>
        <CookieConsent
          sameSite={"none"}
          cookieSecurity={true}
          buttonText="Je comprends"
          style={{ background: "#29b6e5" }}
          buttonStyle={{
            background: "white",
            borderRadius: 20,
            padding: "8px 20px"
          }}
          contentStyle={{ fontSize: 17 }}>
          <span aria-label="jsx-ally/accessible-emoji" role="img">
            🍪
          </span>{" "}
          En poursuivant votre navigation sans modifier vos paramètres, vous acceptez l'utilisation des cookies susceptibles de réaliser des
          statistiques de visite.
        </CookieConsent>
        <div className="small-screens">
          <ResponsiveError />
        </div>
      </div>
    );
  }
}

export default App;
