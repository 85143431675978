import React, { useRef, useEffect } from "react";
import AuthTextInput from "../../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import Calendar from "react-calendar";
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox";
import moment from "moment";
import { dateFormat } from "../../../../Tools/StringTools";

export const DateBlock = (context: newMissionProps) => {
  const _calendar1 = useRef<HTMLDivElement>(null);
  const _calendar2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        (context.state.displayCalendar1 || context.state.displayCalendar2) &&
        _calendar1.current &&
        !_calendar1.current.contains(event?.target) &&
        _calendar2.current &&
        !_calendar2.current.contains(event?.target)
      ) {
        context.handleCloseAllNavs();
      }
    }

    function handleEscapeDown(event: any) {
      if (event.keyCode == 27) {
        context.handleCloseAllNavs();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeDown);
    return () => {
      // Unbind the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeDown);
    };
  });

  return (
    <div className="calendars-div" onClick={e => e.stopPropagation()}>
      <div onMouseEnter={() => context.setTipOpen("tip1")}>
        <h4>Quelles dates et emploi du temps ?</h4>
        <div className="calendars">
          <div className="start-calendar calendar" ref={_calendar1}>
            <div onClick={() => context.handleToggle("displayCalendar1")}>
              <AuthTextInput
                type="text"
                placeHolder="Indiquez une date de début"
                value={
                  context.state.startDate
                    ? dateFormat(context.state.startDate)
                    : ""
                }
                minLength={1}
                condition={!!context.state.startDate}
                disabled={true}
                handleToggle={() => context.handleToggle("displayCalendar1")}
                isList={true}
              />
            </div>
            {context.state.displayCalendar1 && (
              <Calendar
                calendarType="ISO 8601"
                locale="fr-FR"
                className={
                  context.state.startDate
                    ? "blue-calendar calendar"
                    : "orange-calendar calendar"
                }
                onChange={date => context.handleSelect("startDate", date)}
                minDate={new Date()}
                value={
                  new Date(
                    context.state.startDate?.getFullYear() as number,
                    context.state.startDate?.getMonth() as number,
                    context.state.startDate?.getDate() as number
                  )
                }
                // minDate={context.state.startDate}
              />
            )}
          </div>
          {context.state.missionType !== "cdi" && (
            <div className="end-calendar calendar" ref={_calendar2}>
              <div onClick={() => context.handleToggle("displayCalendar2")}>
                <AuthTextInput
                  type="text"
                  placeHolder={
                    !context.state.displayCalendar2
                      ? "Indiquez une date de fin"
                      : ""
                  }
                  value={
                    context.state.endDate && dateFormat(context.state.endDate)
                  }
                  minLength={1}
                  condition={!!context.state.endDate}
                  disabled={true}
                  isList={true}
                  isError={
                    context.state.displayErrors &&
                    context.state.missionType !== "volunteering"
                  }
                />
              </div>
              {context.state.displayCalendar2 && (
                <Calendar
                  calendarType="ISO 8601"
                  locale="fr-FR"
                  minDate={moment(context.state.startDate)
                    .add(1, "day")
                    .toDate()}
                  className={
                    context.state.endDate ? "blue-calendar" : "orange-calendar"
                  }
                  onChange={date => context.handleSelect("endDate", date)}
                  value={
                    context.state.missionType === "cdi"
                      ? undefined
                      : new Date(
                          context.state.startDate?.getFullYear() as number,
                          context.state.startDate?.getMonth() as number,
                          (context.state.startDate?.getDate() as number) + 1
                        )
                  }
                />
              )}
            </div>
          )}
        </div>
        <div className="errors-block">
          {context.state.displayErrors && (
            <div className="error">{context.state?.errors?.slide2?.date}</div>
          )}
        </div>
      </div>
      <Options
        handleToggle={context.handleToggle}
        isFullTime={context.state.isFullTime}
        flexibleSchedule={context.state.flexibleSchedule}
        isSubstituteAllowedtoMakeLiberal={
          context.state.isSubstituteAllowedtoMakeLiberal
        }
        missionType={context.state.missionType}
      />
    </div>
  );
};

interface OptionsProps {
  handleToggle: Function;
  isFullTime: boolean;
  flexibleSchedule: boolean;
  isSubstituteAllowedtoMakeLiberal: boolean;
  missionType: string;
}

const Options = ({
  handleToggle,
  isFullTime,
  flexibleSchedule,
  isSubstituteAllowedtoMakeLiberal,
  missionType
}: OptionsProps) => (
  <div className="time">
    <CheckBox
      name="isFullTime"
      text="Temps plein"
      className="square"
      handleCheckBox={handleToggle}
      condition={isFullTime}
    />
    <CheckBox
      name="isPartTime"
      text="Temps partiel"
      className="square"
      handleCheckBox={handleToggle}
      condition={!isFullTime}
    />
    <CheckBox
      name="flexibleSchedule"
      text="Emploi du temps flexible"
      className="square"
      handleCheckBox={handleToggle}
      condition={flexibleSchedule}
    />
    {(missionType === "cdi" || missionType === "cdd") && (
      <CheckBox
        name="isSubstituteAllowedtoMakeLiberal"
        text="Travail libéral possible"
        className="square"
        handleCheckBox={handleToggle}
        condition={isSubstituteAllowedtoMakeLiberal}
      />
    )}
  </div>
);
