// DEPENDENCIES
import React from "react";
import Loader from "react-loader-spinner";

// COMPONENTS
import SubscriptionThumbnail from "./SubscriptionThumbnail/SubcriptionThumbnail";

const SubscriptionPending = (props: { isLoading: Boolean; missionsSubscriptions: any[] }) => {
  const { isLoading, missionsSubscriptions } = props;

  return (
    <div className={"subscription-pending"}>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type={"Puff"} height={30} width={30} color={"#25bba5"} />
        </div>
      ) : (
        <>
          {missionsSubscriptions.length === 0 ? (
            <h1 className={"header light"}>Aucune candidature en attente</h1>
          ) : (
            <h1 className={"header"}>
              {missionsSubscriptions.length} candidature
              {missionsSubscriptions.length > 1 ? "s" : ""} en attente
            </h1>
          )}
          <div className={"subscriptions"}>
            {missionsSubscriptions
              .sort(
                (a: any, b: any) =>
                  new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
              )
              .map((subscription: any) => (
                <SubscriptionThumbnail key={subscription._id} subscription={subscription} />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionPending;
