import React, { useState } from 'react';
import CheckBox from "../../../../Components/Forms/Inputs/CheckBox/CheckBox"

type dayOfWeek = keyof planningWeek;
type partOfDay = keyof planningDay;

interface checkBoxDayProps {
  day: dayOfWeek;
  part: partOfDay;
}

let emptyPlanning = {
  'monday': {morning: false, afternoon: false, night: false},
  'tuesday': {morning: false, afternoon: false, night: false},
  'wednesday': {morning: false, afternoon: false, night: false},
  'thursday': {morning: false, afternoon: false, night: false},
  'friday': {morning: false, afternoon: false, night: false},
  'saturday': {morning: false, afternoon: false, night: false},
  'sunday': {morning: false, afternoon: false, night: false},
}

export const PartTimeBlock = (context: newMissionProps) => {  
    const [planning, setPlanning] = useState<planningWeek>(context.state.planning ?? emptyPlanning);

    const CheckBoxDay = (props : checkBoxDayProps) => {    
      const handleCheckBoxDay = async () => {
        let newPlanning = {...planning};
        newPlanning[props.day][props.part] = !planning[props.day][props.part];
        await setPlanning(newPlanning);
        context.handlePlanning(newPlanning);
      }
      
      return (
        <CheckBox
          name={`${props.day}-${props.part}`}
          text=""
          className="circle"
          handleCheckBox={handleCheckBoxDay}
          condition={planning[props.day][props.part]}
        />
      )
    }

    return (
        <div onClick={e => e.stopPropagation()} className="part-time">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Lundi</th>
                <th>Mardi</th>
                <th>Mercredi</th>
                <th>Jeudi</th>
                <th>Vendredi</th>
                <th>Samedi</th>
                <th>Dimanche</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="part-day">Matin</td>
                <td><CheckBoxDay day="monday" part="morning" /></td>
                <td><CheckBoxDay day="tuesday" part="morning" /></td>
                <td><CheckBoxDay day="wednesday" part="morning" /></td>
                <td><CheckBoxDay day="thursday" part="morning" /></td>
                <td><CheckBoxDay day="friday" part="morning" /></td>
                <td><CheckBoxDay day="saturday" part="morning" /></td>
                <td><CheckBoxDay day="sunday" part="morning" /></td>
              </tr>

              <tr>
                <td className="part-day">Après-Midi</td>
                <td><CheckBoxDay day="monday" part="afternoon" /></td>
                <td><CheckBoxDay day="tuesday" part="afternoon" /></td>
                <td><CheckBoxDay day="wednesday" part="afternoon" /></td>
                <td><CheckBoxDay day="thursday" part="afternoon" /></td>
                <td><CheckBoxDay day="friday" part="afternoon" /></td>
                <td><CheckBoxDay day="saturday" part="afternoon" /></td>
                <td><CheckBoxDay day="sunday" part="afternoon" /></td>
              </tr>

              {/* <tr>
                <td className="part-day">Soir</td>
                <td><CheckBoxDay day="monday" part="night" /></td>
                <td><CheckBoxDay day="tuesday" part="night" /></td>
                <td><CheckBoxDay day="wednesday" part="night" /></td>
                <td><CheckBoxDay day="thursday" part="night" /></td>
                <td><CheckBoxDay day="friday" part="night" /></td>
                <td><CheckBoxDay day="saturday" part="night" /></td>
                <td><CheckBoxDay day="sunday" part="night" /></td>
              </tr> */}
            </tbody>
          </table>
          {context.state.errors?.slide2?.planning && context.state.displayErrors
            && <div className="error">{context.state.errors.slide2.planning}</div>}
        </div>
    )
}
