//DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

// ASSETS
import defaultAvatar from "../../../assets/img/grey-monkey.svg";
import formatTimeStamp from "../../../Tools/formatTimeStamp";
import { AuthContext } from "../../../Services/Contexts/AuthProvider";
import { byPass } from "../../../Tools/StringTools";

const DateThumbnail = (props: { date?: Date | string }) => {
  return (
    <div className={"date-thumbnail"}>
      <div>{dayjs(props.date).format("DD")}</div>
      <div>{dayjs(props.date).format("MMM")}</div>
      <div>{dayjs(props.date).format("YYYY")}</div>
    </div>
  );
};

interface MissionProps {
  title: string;
  startDate: Date;
  endDate?: Date;
  timeStamp: string;
  messageContent: string;
  avatarUrl: string;
}

const MissionThumbail = (props: MissionProps) => {
  const { title, startDate, endDate, timeStamp, messageContent, avatarUrl } = props;

  const context = useContext(AuthContext);

  const [avatar, setAvatar] = useState<string>();
  const [userIsPremium, setUserIsPremium] = useState(false);

  useEffect(() => {
    setUserIsPremium(
      context.state.currentPlan === "smile" || context.state.currentPlan === "happy"
    );
  }, [context.state.currentPlan]);

  useEffect(() => {
    if (!avatarUrl) {
      setAvatar(defaultAvatar);
    } else {
      setAvatar(avatarUrl);
    }
  }, [avatarUrl]);

  const handleImgError = () => {
    setAvatar(defaultAvatar);
  };

  return (
    <div className={"mission-thumbnail"}>
      <div className={"timestamp"}>{formatTimeStamp(timeStamp)}</div>
      <div className={"title"}>Candidature pour la mission</div>
      <div className={"mission-title"}>{title}</div>
      <div className={"date-title"}>Disponibilités :</div>
      <div className={"dates"}>
        {startDate && <DateThumbnail date={startDate} />}
        {!endDate ? (
          <div className="date-thumbnail">Pas de date de fin</div>
        ) : (
          <DateThumbnail date={endDate} />
        )}
      </div>
      <div className={"message-content"}>
        {userIsPremium ? messageContent : byPass(messageContent)}
      </div>
      <div className={"avatar"}>
        <img src={avatar} alt={"avatar"} onError={handleImgError} />
      </div>
    </div>
  );
};

export default MissionThumbail;
