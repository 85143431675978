import React from "react";
import classNames from 'classnames';

interface Props {
  condition: boolean;

  // optional
  className?: string;
  id?: string,
  value?: any;
  handleToggle?: Function;
}

const Toggle = (props: Props) => {
  return (
    <>
      {
        props.condition 
          ?
            <div
              className={classNames(props.className, "toggle")}
              id={props.id}
              onClick={e => { e.stopPropagation(); props.handleToggle && props.handleToggle(e) }}
            >
              <div 
                className="white-circle"
              />
            </div>
          :
            <div
              className={classNames(props.className, "toggle disabled")}
              id={props.id}
              onClick={e => { e.stopPropagation(); props.handleToggle && props.handleToggle(e) }}
            >
              <div className="white-circle" />
            </div>
      }
    </>
    );
};

export default Toggle;
