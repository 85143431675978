import React from "react";
import Skeleton from "react-loading-skeleton";
import placeholderChart from "../../../assets/img/placeholder-charts.png";

export default function AdsStatisticsGhost() {
  return (
    <div>
      <section className="mission-stats">
        <div className="container">
          <h2 className="title">
            <Skeleton width={800} />
          </h2>
          <div className="block block-first">
            <h3>
              <Skeleton width={270} />
            </h3>
            <div className="candidates-journey skeleton">
              <div className="journey-point">
                <Skeleton width={115} height={15} />
                <Skeleton
                  width={100}
                  height={30}
                  style={{ marginBottom: "5px" }}
                />
                <Skeleton width={115} height={15} />
                <Skeleton width={100} height={20} />
              </div>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              <div className="journey-point">
                <Skeleton width={115} height={15} />
                <Skeleton
                  width={100}
                  height={30}
                  style={{ marginBottom: "5px" }}
                />
                <Skeleton width={115} height={15} />
                <Skeleton width={100} height={20} />
              </div>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              <div className="journey-point">
                <Skeleton width={115} height={15} />
                <Skeleton
                  width={100}
                  height={30}
                  style={{ marginBottom: "5px" }}
                />
                <Skeleton width={115} height={15} />
                <Skeleton width={100} height={20} />
              </div>

              <span>
                <i className="fas fa-chevron-right"></i>
              </span>

              <div className="journey-point">
                <Skeleton width={115} height={15} />
                <Skeleton
                  width={100}
                  height={30}
                  style={{ marginBottom: "5px" }}
                />
                <Skeleton width={115} height={15} />
                <Skeleton width={100} height={20} />
              </div>
            </div>
          </div>

          <div className="block block-second content">
            <div className="line">
              <h3>
                <Skeleton width={245} />
              </h3>
            </div>

            <img src={placeholderChart} alt="chart-placeholder" />
          </div>
        </div>
      </section>
    </div>
  );
}
