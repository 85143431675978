import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import CheckBox from "../CheckBox/CheckBox";

interface Props {
  condition: boolean;
  list: any[];
  selectedList: any[];
  language: Language;

  // optional
  className?: string;
  disabled?: Boolean;
  type?: string;
  placeHolder?: any;
  name?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  required?: boolean;
  autoComplete?: string;
  isError?: boolean;
  handleInput?: Function;
  handleToggle?: Function;
  handleCheckbox?: Function;
  onClick?: Function;
  style?: any;
  value?: any;
  limit?: number;
  sorted?: boolean;
  inputPlaceholder?: string;
}

const ListSelectMultipleWithInput = React.forwardRef((props: Props, ref: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const htmlInput = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const regex = new RegExp(inputValue, "i");

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref.current.contains(event?.target)) {
        setIsOpen(false);
      }
    }

    function handleEscapeDown(event: any) {
      if (event.keyCode === 27) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeDown);
    return () => {
      // Unbind the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeDown);
    };
  }, [ref]);

  useEffect(() => {
    const sort = (array: any) => {
      return array.sort(function(a: any, b: any) {
        if (a.name[props.language] < b.name[props.language]) {
          return -1;
        }
        if (a.name[props.language] > b.name[props.language]) {
          return 1;
        }
        return 0;
      });
    };
    if (props.sorted === true) {
      sort(props.list);
    }
  }, [props]);

  const isFind = (value: DynamicListElement) => {
    let found = props.selectedList.find(item => item._id === value._id);
    return !!found;
  };

  return (
    <div className="list-global" ref={ref}>
      <div
        className={classNames(
          "list",
          props.condition ? "list-success" : props.isError ? "list-error" : "list-empty",
          isOpen && "open",
          props.className
        )}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick && props.onClick();
          setIsOpen(!isOpen);
          setTimeout(() => htmlInput.current?.focus(), 300);
        }}
      >
        <input
          className={
            props.condition ? "blue-input" : props.isError ? "error-input" : "orange-input"
          }
          type={props.type}
          value={props.value}
          placeholder={props.placeHolder}
          name={props.name}
          onChange={(event) => props.handleInput && props.handleInput(event)}
          minLength={props.minLength}
          maxLength={props.maxLength ? props.maxLength : 2048}
          disabled={true}
          min={props.min}
          max={props.max}
          required={props.required}
          autoComplete={props.autoComplete}
          style={props.style}
        />
        <i
          className={classNames(
            "fas fa-caret-down",
            props.condition ? "blue-selector" : props.isError ? "error-selector" : "orange-selector"
          )}
        />
      </div>

      {isOpen && (
        <ul
          className={classNames(
            "items-list",
            props.selectedList.length >= 1 && "items-list-success"
          )}
        >
          <div className="ListSelectMultiple">
            <input
              ref={htmlInput}
              className={`ListSelectMultiple__input ${
                props.condition ? "blue-border" : props.isError ? "error-border" : "orange-border"
              }`}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(true);
              }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={props.inputPlaceholder || "Filtre"}
            />
          </div>

          {props.list
            .sort((a, b) => (a.name[props.language] > b.name[props.language] ? 1 : -1))
            .filter((item) => regex.test(item.name[props.language]))
            .map((item, index) => (
              <li
                className="item"
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleCheckbox &&
                    props.handleCheckbox(props.selectedList, item, props.limit);
                }}
              >
                <CheckBox
                  name="item"
                  text={item.name[props.language]}
                  className="square"
                  condition={isFind(item)}
                  handleCheckBox={undefined}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
});

export default ListSelectMultipleWithInput;
