import React, { Fragment, useRef } from "react";
import AuthTextInput from "../../../../Components/Forms/Inputs/AuthTextInput/AuthTextInput";
import ListSelect from "../../../../Components/Forms/Inputs/ListSelect/ListSelect";
import { remunerationType } from "../../../../Constants/mission";
import classNames from "classnames";

const correctSpellingValue = (
  remuneration: any,
  mission: any,
  display: boolean = false
) => {
  if (display) {
    return "";
  }

  if (remuneration.back) {
    if (remuneration.back === "retrocession") {
      if (mission !== "substitute") {
        return "Redevance";
      }
      return remuneration.front;
    } else {
      return remuneration.front;
    }
  }

  return "";
};

const filterRemuneration = (remuneration: any, mission: any) => {
  if (mission === "internship") {
    if (
      remuneration.back === "all-time" ||
      remuneration.back === "free" ||
      remuneration.back === "mensual-indemnity"
    ) {
      return remuneration;
    }
  } else {
    if (remuneration.back) {
      if (remuneration.back === "retrocession") {
        if (mission !== "substitute") {
          return "Redevance";
        }
        return remuneration;
      } else if (
        remuneration.back === "free" ||
        remuneration.back === "all-time" ||
        remuneration.back === "mensual-indemnity"
      ) {
        return null;
      } else {
        return remuneration;
      }
    }
  }

  return false;
};

// REFACTO all this component might be a good idea...
export const RemunerationBlock = (context: newMissionProps) => {
  const _remuneration = useRef<HTMLDivElement>(null);

  const handleSelect = (name: string, value: any) => {
    context.handleSelect(name, value);
  };

  return (
    <>
      <div
        className={`remuneration-container ${
          context.state.missionType === "internship" ? "internship" : ""
        }`}
        onMouseEnter={() => context.setTipOpen("tip3")}>
        {context.state.missionType !== "internship" ? (
          <>
            <h4>Actes (facultatif) et rémunération :</h4>
            <ActPerWeekBlock {...context} />
          </>
        ) : (
          <h4>Rémunération</h4>
        )}

        <div onClick={e => e.stopPropagation()}>
          <div className="remuneration">
            <div className="remuneration-type">
              <ListSelect
                version={1}
                value={correctSpellingValue(
                  context.state.remunerationType,
                  context.state.missionType,
                  context.state.displayRemunerationType
                )}
                name="remunerationType"
                placeHolder="Type de rémunération"
                classname="remunerations-list"
                condition={true}
                list={remunerationType.map(type =>
                  filterRemuneration(type, context.state.missionType)
                )}
                handleSelect={handleSelect}
                isError={
                  !!context.state?.errors?.slide2?.remunerationType &&
                  !!context.state.displayErrors
                }
                ref={_remuneration}
              />

              <div className="errors-block">
                {context.state.displayErrors && (
                  <div className="error">
                    {context.state?.errors?.slide2?.remunerationType}
                  </div>
                )}
              </div>
            </div>

            {context.state.remunerationType.back !== "depends-on-profile" &&
              context.state.remunerationType.back !== "free" && (
                <div className="remuneration-amount">
                  <RemunerationAmountBlock {...context} />

                  <div className="errors-block remuneration-amount">
                    {context.state.displayErrors && (
                      <Fragment>
                        <div className="error">
                          {context.state?.errors?.slide2?.remuneration}
                        </div>
                        <div className="error">
                          {context.state?.errors?.slide2?.retrocession}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}

            {context.state.remunerationType.back !== "depends-on-profile" &&
              context.state.remunerationType.back !== "free" &&
              context.state.missionType !== "internship" && (
                <div
                  className="remuneration-comment"
                  onMouseEnter={() => context.setTipOpen("tip2")}>
                  <AuthTextInput
                    type="text"
                    minLength={1}
                    maxLength={100}
                    condition={
                      context.state.remunerationComment
                        ? context.state.remunerationComment.length > 5
                        : false
                    }
                    name="remunerationComment"
                    handleInput={context.handleInput}
                    value={context.state.remunerationComment}
                    placeHolder="Une précision ? (facultatif)"
                  />
                  {context.state.remunerationComment &&
                    context.state.remunerationComment.length === 100 && (
                      <div className="length-error">
                        Le commentaire ne doit pas excéder 100 caractères pour
                        une meilleure lisibilité !
                      </div>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const RemunerationAmountBlock = (context: newMissionProps) => {
  const isError =
    !!context.state.errors?.slide2.remuneration && context.state.displayErrors;
  const remuType =
    context.state.remunerationType.back === "retrocession" ? "retro" : "remu";
  const condition = !!(remuType === "remu"
    ? context.state.remuneration
    : context.state.retroPercent);
  return (
    <div className="input-block">
      <div
        className={classNames(
          "remuneration-input-wrapper",
          condition ? "blue-input" : isError ? "error-input" : "orange-input"
        )}>
        <input
          className="remuneration-input"
          type="number"
          name="remuneration"
          onChange={event => context.handleInput && context.handleInput(event)}
          placeholder={remuType === "retro" ? "00" : "0000"}
          min={0}
          value={
            remuType !== "retro"
              ? context.state.remuneration
              : context.state.retroPercent
          }
        />
        {remuType === "retro" ? "%" : "€"}
      </div>
    </div>
  );
};

const ActPerWeekBlock = (context: newMissionProps) => (
  <div className="acts-per-week">
    <div>Nombre d'actes par semaine entre</div>
    <AuthTextInput
      type="number"
      name="minActPerWeek"
      condition={
        Number(context.state.minActPerWeek) > 0 ||
        Number(context.state.maxActPerWeek) > 0
      }
      disabled={false}
      handleInput={context.handleInput}
      min={0}
      max={500}
      value={context.state.minActPerWeek}
    />
    <div>et</div>
    <AuthTextInput
      type="number"
      name="maxActPerWeek"
      condition={
        Number(context.state.minActPerWeek) > 0 ||
        Number(context.state.maxActPerWeek) > 0
      }
      disabled={false}
      handleInput={context.handleInput}
      min={
        Number(context.state.minActPerWeek) > 0
          ? Number(context.state.minActPerWeek) + 1
          : 0
      }
      max={500}
      value={
        Number(context.state.minActPerWeek) > 0
          ? Math.max(
              Number(context.state.minActPerWeek) + 1,
              Number(context.state.maxActPerWeek)
            )
          : Number(context.state.maxActPerWeek)
      }
    />
    {/* {numError && <span className="numError">Les caractères spéciaux ne sont pas pris en compte, merci de mettre seulement des chiffres</span>} */}
  </div>
);
