import React from 'react';
import moment from "moment";
import {byPass} from "../../../../Tools/StringTools";
import suitcase from "../../../../assets/img/pro.png";

moment.locale('fr');

export default function Experiences(props: SubstituteProps) {

  const sortByDate = (a: any, b: any) => { 
    var dateA = new Date(a.startDate).getTime();
    var dateB = new Date(b.startDate).getTime();
    return dateA < dateB ? 1 : -1;
  }

  return (
    <>
      <div className="experiences">
        <h2>Expériences Pro</h2>
            {props?.xpPro.sort(sortByDate).map((xp, index) => (
              <div className="experience" key={index}>
                <div className="col-image">
                  <img src={suitcase} alt="experience"/>
                </div>
                <div className="col-description">
                  <div>{xp.title}</div>
                  {xp.isOnGoing 
                    ?
                    <div className="xpdate">Depuis {moment(xp.startDate).format('MMMM YYYY')}</div>
                    :
                    <div className="xpdate">{moment(xp.startDate).format('MMM YYYY')} - {moment(xp.endDate).format('MMM YYYY')}</div>
                  }
                  {xp.description &&
                    <div className="xpdescription">{byPass(xp.description, props?.lastName)}</div>
                  }
                  { xp.source === "App'Ines" &&
                    <div className="xpcaption">{props.firstName} s'est inscrit sur App'Ines</div>
                  }
                </div>
              </div>
            ))}
      </div>
    </>
  )
}
