import React from "react";

const Switch = (props: any) => {
  const { choice, setChoice, entries } = props;

  return (
    <div className="switch">
      {Object.keys(entries).map((entry: any, index: number) => (
        <button key={index} className={choice === entry ? "selected" : ""} onClick={() => setChoice(entry)}>
          {entries[entry]}
        </button>
      ))}
    </div>
  );
};

export default Switch;
