import prefixes from "../assets/prefixes.json";
import moment from "moment";

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const convertTime = (time: string | number, toBack?: boolean) => {
  if (toBack) {
    /*
    string to seconds (int)
    storedTime = hours * 3600 + minutes * 60 + seconds
    */
    if (typeof time === "string") {
      let timeArray = time.toString().split(":");
      let hours = parseInt(timeArray[0]);
      let minutes = parseInt(timeArray[1]);

      let storedTime = hours * 3600 + minutes * 60;
      return storedTime;
    } else if (typeof time === "number") {
      return time;
    }
  } else {
    /*
      seconds (int) to string
      hours = storedTime / 3600 ##SHOULD BE INTEGER NOT FLOAT
      leaves = storedTime - (hours * 3600)
      minutes = leaves / 60
    */
    if (typeof time === "number") {
      let resultHours = Math.floor(time / 3600);
      let leaves = time - resultHours * 3600;

      if (leaves < 0) {
        // remove the minus sign ("-")
        leaves = parseInt(leaves.toString().slice(1));
      }

      let resultMinutes = leaves / 60;

      if (resultHours < 10 && resultHours >= 0) {
        var hours = "0" + resultHours.toString();
      } else {
        hours = resultHours.toString();
      }

      if (resultMinutes < 10 && resultMinutes >= 0) {
        var minutes = "0" + resultMinutes.toString();
      } else {
        minutes = resultMinutes.toString();
      }

      let storedTime = `${hours}:${minutes}`;
      return storedTime;
    } else if (typeof time === "string") {
      return time;
    }
  }
};

const handleConvertMonthsToText = (month: string) => {
  if (typeof month === "number") month = [month as number].toString();
  if (month === "01" || month === "1") {
    return "Janvier";
  } else if (month === "02" || month === "2") {
    return "Février";
  } else if (month === "03" || month === "3") {
    return "Mars";
  } else if (month === "04" || month === "4") {
    return "Avril";
  } else if (month === "05" || month === "5") {
    return "Mai";
  } else if (month === "06" || month === "6") {
    return "Juin";
  } else if (month === "07" || month === "7") {
    return "Juillet";
  } else if (month === "08" || month === "8") {
    return "Août";
  } else if (month === "09" || month === "9") {
    return "Septembre";
  } else if (month === "10") {
    return "Octobre";
  } else if (month === "11") {
    return "Novembre";
  } else if (month === "12") {
    return "Décembre";
  }
};

const renderDiplomaDate = (sub: SubstituteProps) => {
  let text = "Pas de diplôme renseigné";
  if (!sub.diploma) {
    return text;
  }

  const { diploma, identifier, gender } = sub;
  const { date } = diploma;
  // TODO: Manage the case of profession where identifier is not needed (profession.mandatory : false)
  if (!identifier && moment(date).isSameOrAfter(moment(), "year")) {
    text = "Futur" + (gender === "female" ? "e" : "");
    text += " diplômé" + (gender === "female" ? "e" : "");

    if (diploma?.school?.countryFlag) text += " en " + diploma.school.countryFlag;

    text +=
      " • " +
      moment(date)
        .format("YYYY")
        .toString();
  } else if (moment(date).isSameOrBefore(moment(), "year") || identifier) {
    text = "Diplômé" + (gender === "female" ? "e" : "");
    if (diploma?.school?.countryFlag) text += " en " + diploma.school.countryFlag;
    text +=
      " • " +
      moment(date)
        .format("YYYY")
        .toString();
  }
  return text;
};

const shortenWord = (word: string, length: number) => {
  if (word.length < length) {
    return word;
  } else {
    return word.substring(0, length - 3) + "...";
  }
};

const createCalendarList = () => {
  let startMonth = new Date().getMonth() + 1;
  let resetMonth = 0;
  //@ts-ignore TODO: Check getYear
  let startYear = new Date().getYear() + 1900;
  let monthList = [];

  for (let i = 0; i < 12; i++) {
    if (i === 0) {
      if (startMonth < 10) {
        monthList.push({
          month: "0" + startMonth.toString(),
          year: startYear.toString()
        });
      } else {
        monthList.push({
          month: startMonth.toString(),
          year: startYear.toString()
        });
      }
    } else if (i > 0 && startMonth < 12) {
      startMonth = new Date().getMonth() + 1 + i;
      //@ts-ignore TODO: Check getYear
      startYear = new Date().getYear() + 1900;
      if (startMonth < 10) {
        monthList.push({
          month: "0" + startMonth.toString(),
          year: startYear.toString()
        });
      } else {
        monthList.push({
          month: startMonth.toString(),
          year: startYear.toString()
        });
      }
    } else {
      resetMonth++;
      if (resetMonth < 10) {
        monthList.push({
          month: "0" + resetMonth.toString(),
          year: (startYear + 1).toString()
        });
      } else {
        monthList.push({
          month: resetMonth.toString(),
          year: (startYear + 1).toString()
        });
      }
    }
  }
  return monthList;
};

const validatePhoneNumber = (rawNumber: any, countryCode?: string) => {
  if (rawNumber?.length < 5) return false;
  try {
    const prefix = prefixes.find(
      (_prefix: any) => _prefix.dial_code === countryCode
    );
    const parsedNumber = phoneUtil.parseAndKeepRawInput(
      rawNumber,
      prefix?.code
    );
    const formatedNumber = phoneUtil.format(parsedNumber, PNF.E164);
    const reparsedNumber = phoneUtil.parseAndKeepRawInput(
      formatedNumber,
      prefix?.code
    );
    const isValid = phoneUtil.isValidNumberForRegion(
      reparsedNumber,
      prefix?.code
    );

    return !!isValid;
  } catch (e) {
    return false;
  }
};

export {
  handleConvertMonthsToText,
  shortenWord,
  createCalendarList,
  validatePhoneNumber,
  phoneUtil,
  renderDiplomaDate,
  PNF,
  convertTime
};
