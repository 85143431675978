//ASSETS
import { placeholders } from "../../../../Constants/images";
import { dateFormat } from "../../../../Tools/StringTools";
import { byPass } from "../../../../Tools/StringTools";

//DEPENDENCIES
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

//COMPONENTS
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";

//HOOKS
import { useState, useEffect } from "react";
import React from "react";

//TYPESCRIPT
interface Props {
  key: string;
  mission: MissionProps | null;
  subscriber: SubscriberProps;
  activeMissions: MissionProps[] | null;
  isLoading: Boolean;
  handleRefuseCandidate: Function;
  handleAcceptCandidate: Function;
}

const Candidate = (props: Props) => {
  // PROPS
  const {
    mission,
    subscriber,
    isLoading,
    handleRefuseCandidate,
    handleAcceptCandidate
  } = props;

  // STATES
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmRefuse, setConfirmRefuse] = useState(false);
  const [status, setStatus] = useState("");

  // USE-EFFECT
  useEffect(() => {
    setStatus(subscriber.status);
  }, [subscriber.status]);

  // FUNCTIONS

  const handleConfirmAccept = () => {
    setConfirmAccept(true);
    setConfirmRefuse(false);
  };

  const handleConfirmRefuse = () => {
    setConfirmAccept(false);
    setConfirmRefuse(true);
  };

  // RENDER
  return (
    <div className="candidate-block">
      <Link
        to={{
          pathname: "/remplacants/" + subscriber.substitute.id,
        }}>
        <div className="candidate">
          {status === "REFUSED" && <div className="refused-status">Refusée</div>}
          {status === "ACCEPTED" && <div className="accepted-status">Acceptée</div>}
          {status === "CANCELED" && <div className="canceled-status">Annulée</div>}
          <div className="avatar">
            <img
              className="profile-pic"
              alt="profil"
              src={
                subscriber.substitute?.picture?.thumbnail?.url ||
                  subscriber.substitute.avatarUrl ||
                   placeholders[
                      parseInt(
                        subscriber.substitute?.lastName
                          ?.charCodeAt(0)
                          .toString()
                          .slice(-1) || "1"
                      )
                    ]
              }
            />
          </div>
          <div className="text-block">
            <div className="name">
              {subscriber.substitute.firstName[0]}
              {subscriber.substitute.firstName.substring(1).toLowerCase()}
            </div>
            <div className="dates">
              <i className="far fa-calendar-minus" />
              <div>
                {dateFormat(subscriber.startDate)} -{" "}
                {subscriber.endDate
                  ? dateFormat(subscriber.startDate)
                  : "indéterminée"}
              </div>
            </div>
            <div className="introduction-text">
              {subscriber.message
                ? byPass(subscriber.message)
                : "Le remplaçant n'a pas laissé de message."}
            </div>
          </div>
        </div>
      </Link>
      {/* // ! isLoading && candidate accepted */}
      {isLoading && confirmAccept && (
        <div className="button-loader">
          <ValidButton
            className="validate-disabled"
            text="Acceptée"
            condition={true}
            onClick={() =>
              handleAcceptCandidate(subscriber._id, subscriber.substitute.id)
            }
          />
          <div className="loader">
            <Loader type="Puff" color="white" height={30} width={30} />
          </div>
        </div>
      )}
      {/* // ! isLoading && candidate refused */}
      {isLoading && confirmRefuse && (
        <div className="button-loader">
          <ValidButton
            className="validate-disabled"
            text="Refusée"
            condition={true}
            onClick={() => handleRefuseCandidate(subscriber._id)}
          />
          <div className="loader">
            <Loader type="Puff" color="white" height={30} width={30} />
          </div>
        </div>
      )}
      {status !== "REFUSED" &&
        status !== "ACCEPTED" &&
        status !== "CANCELED" &&
        mission?.exercise !== "internship" &&
        !isLoading && (
          <div className="buttons">
            {!confirmAccept ? (
              <ValidButton
                text="Accepter"
                className="validate-blue"
                condition={true}
                onClick={() => handleConfirmAccept()}
              />
            ) : (
              <ValidButton
                className="validate-blue"
                text="Confirmer ?"
                condition={true}
                onClick={() =>
                  handleAcceptCandidate(
                    subscriber._id,
                    subscriber.substitute.id
                  )
                }
              />
            )}
            {!confirmRefuse ? (
              <ValidButton
                className="validate-red-white"
                text="Refuser"
                condition={true}
                onClick={() => handleConfirmRefuse()}
              />
            ) : (
              <ValidButton
                className="validate-red"
                text="Confirmer ?"
                condition={true}
                onClick={() => handleRefuseCandidate(subscriber._id)}
              />
            )}
          </div>
        )}
    </div>
  );
};

export default Candidate;
