import React, { useEffect, useState, useRef, useContext } from "react";
import { fetchApi } from "../../../../Services/Contexts/Api";
import { AuthContext } from "../../../../Services/Contexts/AuthProvider";

import ListSelectMultipleWithInput from "../../../../Components/Forms/Inputs/ListSelectMultipleWithInput/ListSelectMultipleWithInput";

export const SkillsBlock = (context: newMissionProps) => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const _skills = useRef<HTMLDivElement>(null);
  const contextAuth = useContext(AuthContext);

  useEffect(() => {
    if (context.state.profession._id) {
      fetchApi(`/skills?professionId=${context.state.profession._id}`).then(
        response => {
          setSkills(response.data);
        }
      );
    } else {
      fetchApi(`/skills`).then(response => {
        setSkills(response.data);
      });
    }
  }, []);

  const renderSkills = (context: any) => {
    return context.state.skills.map((skill: any, index: number) => (
      <div key={index} className="confirmed-skill">
        <div>
          {skill.name[contextAuth.state.language].length >= 9
            ? `${skill.name[contextAuth.state.language].substring(0, 9)}...`
            : skill.name[contextAuth.state.language]}
        </div>
        <i
          className="fas fa-times"
          onClick={() => context.handlePushToArray(context.state.skills, skill)}
        />
      </div>
    ));
  };

  return (
    <div onMouseEnter={() => context.setTipOpen("tip2")} className="skills-div">
      <h4>Compétences requises</h4>
      <div onClick={e => e.stopPropagation()}>
        <div className="skills">
          <ListSelectMultipleWithInput
            list={skills.filter((skill: any) => !(skill.isValidated === false))}
            selectedList={context.state.skills}
            condition={context.state.skills.length >= 1}
            handleCheckbox={context.handlePushToArray}
            limit={10}
            placeHolder={"Sélectionnez de une à 10 compétences"}
            inputPlaceholder={"Rechercher une compétence"}
            ref={_skills}
            value={
              context.state.skills.length > 0
                ? `${context.state.skills.length} compétence${
                    context.state.skills.length > 1 ? "s" : ""
                  } sélectionné${context.state.skills.length > 1 ? "es" : "e"}`
                : ""
            }
            onClick={() => context.handleToggle("displaySkills")}
            sorted={true}
            isError={
              !!context.state.errors?.slide2?.skills &&
              context.state.displayErrors
            }
            language={contextAuth.state.language}
          />

          <div className="all-confirmed-skills">
            {context.state.errors?.slide2?.skills &&
            context.state.displayErrors ? (
              <div className="error">{context.state.errors.slide2.skills}</div>
            ) : (
              renderSkills(context)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
