import React from "react";
import envelop from "../../../../assets/img/envelop.png";
import rightLeaves from "../../../../assets/img/annonce-diffusee.svg";
import ValidButton from "../../../../Components/Forms/Buttons/ValidButton/ValidButton";
import { NewMissionContext } from "../../../../Services/Contexts/NewMissionProvider";
import { AuthConsumer } from "../../../../Services/Contexts/AuthProvider";
import history from "../../../../history";

interface Props {
  isEdit: boolean;
  handleExitCreation(): void;
  initCreationPage(): void;
  displayLauchCandidateSearchButton: boolean;
}

interface State {
  substituteNumber: number;
}

class Success extends React.Component<Props, State> {
  static contextType = NewMissionContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      substituteNumber: 0,
    };
  }

  /*
   * It updates the context of the substitute's page with the data of the mission that was just created.
   * Then it redirects the user to the substitutes page and launches a candidate search with the mission criteria
   */
  handleLaunchCandidateSearch = (AuthContext: AuthProps) => {
    this.props.handleExitCreation();

    AuthContext.handleUpdateSubstitutesPageContext({
      ...AuthContext.state.substitutesPage.state,
      isLoading: true,
      skills: this.context.state.skills,
      profession: this.context.state.profession,
      type: "substitute",
      exercices: (() => {
        if (this.context.state.missionType === "cdd" || this.context.state.missionType === "cdi")
          return ["cdd", "cdi"];
        return [this.context.state.missionType];
      })(),
      minXp: (() => {
        if (this.context.state.missionType === "internship") return "student";
        return "graduated";
      })(),
    });

    history.push("/remplacants");
  };

  componentWillMount = () => {
    this.context.wipeData();
  };

  render() {
    return (
      <section className="slide-four">
        <img src={rightLeaves} alt="feuillage" className="right-leaves" />
        <div className="content">
          <div className="header">
            <img src={envelop} alt="enveloppe" className="envelop" />
            <h2>Annonce {this.props.isEdit ? "modifiée" : "diffusée"}</h2>
          </div>
          <div className={this.state.substituteNumber ? `text` : `text hidden`}>
            Votre annonce est désormais visible aux {this.state.substituteNumber} kinésithérapeutes.
          </div>
          <div className="buttons-redirect">
            <ValidButton
              condition={true}
              text="Créer une nouvelle annonce"
              onClick={() => this.props.initCreationPage()}
            />
            <ValidButton
              condition={true}
              text="Voir mes annonces"
              onClick={() => {
                this.props.handleExitCreation();
                history.push("/mes-annonces");
              }}
            />

            {this.props.displayLauchCandidateSearchButton && (
              <AuthConsumer>
                {(context) => (
                  <ValidButton
                    condition={true}
                    text="✨ Voir les profils correspondants"
                    onClick={() => this.handleLaunchCandidateSearch(context)}
                  />
                )}
              </AuthConsumer>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Success;
