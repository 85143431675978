import React from "react";

interface Props {
  condition: any;
  onClick: Function;

  // optional
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  text?: string;
  onTouchStart?: Function;
  validate?: Function;
}

const ValidButton = (props: Props) => {
  return (
    <>
      {props.condition ? (
        <button
          type={props.type}
          className={props.className ? props.className : "validate-blue"}
          onClick={() => props.onClick()}
        >
          {props.text ? props.text : "Valider"}
        </button>
      ) : (
          <button
            className="validate-white"
            disabled
            onTouchStart={() => { props.onTouchStart && props.onTouchStart() }}
          >
            {props.text ? props.text : "Valider"}
          </button>
        )}
    </>
  );
};

export default ValidButton;
