import slugify from "slugify";

const phoneRegex = /((?:\+|00)[17](?: |-|\.|_)?|(?:\+|00)[1-9]\d{0,2}(?: |-|\.|_)?|(?:\+|00)1-\d{3}(?: |-|\.|_)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-|\.|_)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-|\.|_)[0-9]{3}(?: |-|\.|_)[0-9]{4})|([0-9]{7}))/gm;
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/gm;
const websiteRegex = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gm


const capitalize = (name: string) => {
  return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
};

const cleanStr = (string: string | null) =>
  string ? slugify(string.toLowerCase()) : null;

const byPass = (value: any, name?: string) => {
    if (!value || typeof value !== 'string')
      return value;
    let newValue = value.replace(phoneRegex, '(numéro masqué)');
    newValue = newValue.replace(emailRegex, '(email masqué)');
    newValue = newValue.replace(websiteRegex, '(url masquée)');

    if (name)
      newValue = newValue.replace(new RegExp(name.toLowerCase(), 'igm'), '(nom masqué)');

    return newValue;
}

// Return on format DD/MM/YYYY
const dateFormat = (dateGiven:Date | string) => {
  let date = new Date(dateGiven)
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day.toString().length > 1 ? day : "0"+day}/${month.toString().length > 1 ? month : "0"+month}/${year.toString()}`
}

const isValidEmail = (email: string | null): boolean => {
  if (!email) return false;

  const input = document.createElement('input');
  input.type = 'email';
  input.value = email;

  return input.checkValidity();
}

export { capitalize, cleanStr, byPass, dateFormat, isValidEmail};
