import React, { ReactEventHandler, useEffect, useState } from "react";
import formatTimeStamp from "../../../Tools/formatTimeStamp";

// ASSETS
import defaultAvatar from "../../../assets/img/grey-monkey.svg";
import defaultAvatarEts from "../../../assets/img/user-circle-solid.svg";
import Loader from "react-loader-spinner";

interface MessageProps {
  sendingInProgress?: Boolean;
  id: string;
  text: string;
  avatarUrl: string;
  timeStamp: string;
  authorIsCandidate: Boolean;
}

const displayBreakLine = (text: string, id: string, className?: string) => {
  return text
    .trim()
    .split("\n")
    .map((line: string, index: number) => {
      if (line === "") {
        return <div key={id + index} className={className} />;
      }

      return (
        <div key={id + index} className={className}>
          {line}
        </div>
      );
    });
};

const Avatar = (props: {
  authorIsCandidate: Boolean;
  avatar: string | undefined;
  handleImgError: ReactEventHandler<HTMLImageElement>;
}) => {
  const { authorIsCandidate, avatar, handleImgError } = props;

  return (
    <div className={`avatar ${authorIsCandidate ? "to-left" : "to-right"}`}>
      <img src={avatar} alt={"avatar"} onError={handleImgError} />
    </div>
  );
};

const MessageThumbnail = (props: MessageProps) => {
  const { id, text, avatarUrl, timeStamp, authorIsCandidate, sendingInProgress } = props;

  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    if (!avatarUrl) {
      authorIsCandidate ? setAvatar(defaultAvatar) : setAvatar(defaultAvatarEts);
    } else {
      setAvatar(avatarUrl);
    }
  }, []);

  const handleImgError = () => {
    authorIsCandidate ? setAvatar(defaultAvatar) : setAvatar(defaultAvatarEts);
  };

  return (
    <div
      className={`message-thumbnail ${!authorIsCandidate ? "current-user" : ""} ${
        sendingInProgress ? "sending" : ""
      }`}
    >
      {displayBreakLine(text, id, "text")}

      {!sendingInProgress ? (
        <>
          <div className={"timestamp"}>{formatTimeStamp(timeStamp)}</div>
          <Avatar
            authorIsCandidate={authorIsCandidate}
            avatar={avatar}
            handleImgError={handleImgError}
          />
        </>
      ) : (
        <div className={"loader"}>
          <Loader type="Puff" color="#27BBA5" height={30} width={30} />
        </div>
      )}
    </div>
  );
};

export default MessageThumbnail;
