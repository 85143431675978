import React from 'react';
import HoverComponent from "../../../../Components/useHover";

// Locomotions SVGs
import {ReactComponent as ByFoot} from '../../../../assets/img/locomotions/byFoot.svg'
import {ReactComponent as ByBike} from '../../../../assets/img/locomotions/byBike.svg'
import {ReactComponent as ByMotorBike} from '../../../../assets/img/locomotions/byMotorBike.svg'
import {ReactComponent as ByCar} from '../../../../assets/img/locomotions/byCar.svg'
import {ReactComponent as ByBus} from '../../../../assets/img/locomotions/byBus.svg'
import {ReactComponent as ByTramway} from '../../../../assets/img/locomotions/byTramway.svg'
import {ReactComponent as ByMetro} from '../../../../assets/img/locomotions/byMetro.svg'


export default function Locomotions(props: SubstituteProps) {
  const locomotions = [['A pied', ByFoot], ['Moto', ByMotorBike], ['Voiture', ByCar], ['Bus', ByBus], ['Vélo', ByBike], ['Tramway', ByTramway], ['Métro', ByMetro]];

  return (
    <div className="locomotions">
      <h2>Moyens de locomotion</h2>
      <div className="locomotions-logos">
        {
          locomotions.map((locomotion, index) => {
            let NameComponent = locomotion[1];
            let className = "locomotion";

            props?.locomotions.includes(locomotion[0].toString()) && (className += ' locomotion-active');
            
            return <div className={`${className} ${locomotion[1].toString().toLowerCase()}`} key={index}>
                      <HoverComponent text={locomotion[0]}>
                        <NameComponent />
                      </HoverComponent>
                    </div>
          })
        }
      </div>
    </div>
  )
}
